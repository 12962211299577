import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import Box from '@mui/material/Box';
import { Fragment, useState } from 'react';
import { PickSessionTableRows } from './PickSessionTableRows';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { TableEmpty } from '../../../../components/TableEmpty';
import PickSessionsReport from '../../../../domain/automator/pickSessions/PickSessionsReport';
import ReducedPickSession from '../../../../domain/automator/pickSessions/ReducedPickSession';
import { getDateOnly } from '../../../../helpers/getDateOnly';
import { CollapsableTableRowDivider } from '../../shared/CollapsableTableRowDivider';

interface PickSessionListTableProps {
  pickSessionsReport: PickSessionsReport;
  pickSession?: ReducedPickSession;
}

export const PickSessionListTable = ({
  pickSessionsReport,
  pickSession,
}: PickSessionListTableProps) => {
  const determineInitialSelectedDates = () => {
    const dates = pickSessionsReport.datesWithPickSessions.sort(
      (a, b) => new Date(b).getTime() - new Date(a).getTime()
    );

    const selectedDates = dates.slice(0, 4);

    if (pickSession?.createdDateTime) {
      const createdDate = getDateOnly(pickSession.createdDateTime);
      if (!selectedDates.includes(createdDate)) {
        selectedDates.push(createdDate);
      }
    }
    return selectedDates;
  };

  const [selectedDates, setSelectedDates] = useState<string[]>(determineInitialSelectedDates());
  const { t } = useTranslation();

  const onDateSelect = (date: string) => {
    if (selectedDates.includes(date)) {
      setSelectedDates(selectedDates.filter((d) => d !== date));
    } else {
      setSelectedDates([...selectedDates, date]);
    }
  };

  if (pickSessionsReport.datesWithPickSessions.length === 0) {
    return <TableEmpty message={t(tokens.automator.pick_sessions.no_items)} />;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={10}
                align="left"
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {pickSessionsReport.datesWithPickSessions
              .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
              .map((date) => (
                <Fragment key={date}>
                  <CollapsableTableRowDivider
                    date={date}
                    isCollapsable={true}
                    isCollapsed={selectedDates.includes(date)}
                    onClick={() => onDateSelect(date)}
                  />
                  {selectedDates.includes(date) && (
                    <PickSessionTableRows
                      selectedPickSessionId={pickSession?.id}
                      date={date}
                    />
                  )}
                </Fragment>
              ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import SearchTerm from '../../../../../../domain/pd/SearchTerm';
import ProductRankSearchTerm from '../../../../../../domain/pd/ProductRankSearchTerm';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../../../locales/translationTokens';
import { useUpdateIsRelevantProductSearchTermSetting } from '../../../../../../api/pd/productPerformances/useUpdateIsRelevantProductSearchTermSetting';
import { useTranslation } from 'react-i18next';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Stack from '@mui/material/Stack';

interface ProductRankSearchTermTableRowProps {
  productRankSearchTerm: ProductRankSearchTerm;
  onSelect: (searchTerm: SearchTerm) => void;
  isSelected: boolean;
}

export const ProductRankSearchTermTableRow = ({
  productRankSearchTerm,
  onSelect,
  isSelected,
}: ProductRankSearchTermTableRowProps) => {
  const { mutate: updateIsRelevantProductSearchTerm, isLoading } =
    useUpdateIsRelevantProductSearchTermSetting();

  const { t } = useTranslation();

  const doUpdate = (isRelevant: boolean) => {
    const data = {
      product_id: productRankSearchTerm.productId,
      search_term_id: productRankSearchTerm.searchTerm.id,
      is_relevant: isRelevant,
    };
    updateIsRelevantProductSearchTerm(data, {
      onSuccess: async () => {
        toast.success(
          t(tokens.pd.performance.product_rank_search_term_category_table.is_relevant_updated)
        );
      },
    });
  };

  return (
    <TableRow
      style={{
        backgroundColor: isSelected ? '#ff0' : '#fff',
        cursor: 'pointer',
      }}
    >
      <TableCell onClick={() => onSelect(productRankSearchTerm.searchTerm)}>
        SEARCH_TERM_CHIP
      </TableCell>
      <TableCell onClick={() => onSelect(productRankSearchTerm.searchTerm)}>
        {productRankSearchTerm.searchTerm.searchTerm}
      </TableCell>
      <TableCell onClick={() => onSelect(productRankSearchTerm.searchTerm)}>
        <Stack
          direction="row"
          spacing={1}
        >
          {productRankSearchTerm.averageRank}{' '}
          {productRankSearchTerm.averageRankTrend > 0 ? (
            <ArrowDropUpIcon style={{ color: 'green' }} />
          ) : (
            <ArrowDropDownIcon style={{ color: 'red' }} />
          )}
        </Stack>
      </TableCell>
      <TableCell onClick={() => onSelect(productRankSearchTerm.searchTerm)}>
        {productRankSearchTerm.totalImpressions}
      </TableCell>
      <TableCell onClick={() => onSelect(productRankSearchTerm.searchTerm)}>
        {productRankSearchTerm.totalSearchVolume}
      </TableCell>
      <TableCell onClick={() => doUpdate(!productRankSearchTerm.isRelevant)}>
        {productRankSearchTerm.isRelevant ? 'YES' : 'NO'}
        {isLoading && '...'}
      </TableCell>
    </TableRow>
  );
};

import FormHelperText from '@mui/material/FormHelperText';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import ApplicationError from '../../../../domain/automator/ApplicationError';
import { TableBodyPlaceholder } from '../../../../components/TableBodyPlaceholder';
import { Box } from '@mui/material';

interface OrdersErrorMessageProps {
  error: ApplicationError;
}

const OrdersErrorMessage = ({ error }: OrdersErrorMessageProps) => {
  const { t } = useTranslation();

  const renderMessage = (code?: number) => {
    switch (code) {
      case 10016:
        return tokens.automator.orders.errors.request_already_in_progress;
      default:
        return tokens.automator.orders.errors.unknown_error;
    }
  };

  return (
    <TableBodyPlaceholder>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="15px"
      >
        <FormHelperText
          sx={{ fontSize: '20px' }}
          error
        >
          {t(renderMessage(error.code))}
        </FormHelperText>
      </Box>
    </TableBodyPlaceholder>
  );
};

export default OrdersErrorMessage;

import Alert from '@mui/material/Alert';

interface WarningAlertProps {
  onClose?: () => void;
  children?: React.ReactNode;
}

export const WarningAlert = ({ onClose, children }: WarningAlertProps) => {
  return (
    <Alert
      onClose={onClose}
      sx={{ backgroundColor: 'rgb(255, 244, 229)', color: 'rgb(102, 60, 0)' }}
      variant="standard"
      severity="warning"
    >
      {children}
    </Alert>
  );
};

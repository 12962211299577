import { useEffect } from 'react';

export const useScrollIntoView = <T extends HTMLElement>(
  key: number | null,
  keysToRefsMap: Map<number, T | null>,
  dependencyArray: any[] = []
) => {
  useEffect(() => {
    if (key === null) {
      return;
    }

    const ref = keysToRefsMap.get(key);
    if (ref) {
      setTimeout(() => {
        ref?.scrollIntoView({ block: 'start' });

        window.scrollBy(0, -50);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, keysToRefsMap, ...dependencyArray]);
};

import { PerformanceEventType } from '../../../../../domain/pd/PerformanceEventType';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DeliveryCode } from '../../../../../domain/pd/DeliveryCode';
import BundlePrice from '../../../../../domain/pd/BundlePrice';
import { convertCentsToEuros } from '../../../helpers/convertCentsToEuros';
import convertRatioToPercentage from '../../../helpers/convertRatioToPercentage';
import { TimelineEvent } from './TimeLineEventMarker';

interface TimelineEventMarkerMessageProps {
  timelineEvent: TimelineEvent;
}

export const TimelineEventMarkerMessage = ({ timelineEvent }: TimelineEventMarkerMessageProps) => {
  const getValue = (
    value: undefined | DeliveryCode | BundlePrice | number | string | boolean,
    type?: PerformanceEventType
  ) => {
    if (!value) {
      return null;
    }
    switch (type) {
      case PerformanceEventType.OFFER_PRICE:
        return (
          <Typography variant="body2">
            {convertCentsToEuros((value as BundlePrice).unitPriceInCents)}
          </Typography>
        );
      case PerformanceEventType.OFFER_VOLUME_DISCOUNT:
        return (
          <Typography variant="body2">
            {(value as BundlePrice).quantity +
              'x - ' +
              convertCentsToEuros((value as BundlePrice).unitPriceInCents)}
          </Typography>
        );
      case PerformanceEventType.OFFER_DELIVERY_CODE:
        return <Typography variant="body2">{value as DeliveryCode}</Typography>;

      case PerformanceEventType.CAMPAIGN_ACOS_PERCENTAGE:
        return <Typography variant="body2">{convertRatioToPercentage(value as number)}</Typography>;

      case PerformanceEventType.CAMPAIGN_DAILY_BUDGET:
        return <Typography variant="body2">{convertCentsToEuros(value as number)}</Typography>;

      case PerformanceEventType.CAMPAIGN_TOTAL_BUDGET:
        return <Typography variant="body2">{convertCentsToEuros(value as number)}</Typography>;

      case PerformanceEventType.PRODUCT_RATING:
        return <Typography variant="body2">{value as number}</Typography>;

      case PerformanceEventType.COMPETITOR_PRODUCT_PRICE:
        return <Typography variant="body2">{convertCentsToEuros(value as number)}</Typography>;

      case PerformanceEventType.COMPETITOR_PRODUCT_IS_IN_STOCK:
        return (
          <Typography variant="body2">
            {(value as boolean) ? 'true >>> false' : 'false >>> true'}
          </Typography>
        );

      default:
        return value as string;
    }
  };

  const fromValue = getValue(timelineEvent.value1, timelineEvent.type);
  const toValue = getValue(timelineEvent.value2, timelineEvent.type);

  return (
    <Stack
      direction="column"
      gap={1}
    >
      <Typography variant="body2">{timelineEvent.type}</Typography>
      <Stack direction="row">
        {fromValue}
        {toValue ? <Typography variant="body2">&nbsp;&gt;&gt;&gt;&nbsp;</Typography> : ''}
        {toValue}
      </Stack>
    </Stack>
  );
};

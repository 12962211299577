import Box from '@mui/material/Box';
import { LatestDays } from '../LatestDaysDropdownSelect';
import getLatestDateByLatestDays from '../../../../../../helpers/getLatestDateByLatestDays';
import { DeviationBarChart } from './DeviationBarChart';
import { tokens } from '../../../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { useFetchAssortmentImpressions } from '../../../../../../api/pd/productPerformances/useFetchAssortmentImpressions';
import getYesterday from '../../../../../../helpers/getYesterday';

interface AssortmentImpressionsDeviationChartProps {
  storeId: number;
  latestDays: LatestDays;
}

export const AssortmentImpressionsDeviationChart = ({
  storeId,
  latestDays,
}: AssortmentImpressionsDeviationChartProps) => {
  const { data: assortmentImpressions, isLoading } = useFetchAssortmentImpressions(
    storeId,
    getLatestDateByLatestDays(latestDays),
    getYesterday()
  );

  const { t } = useTranslation();

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  const assortmentImpressionsSorted = assortmentImpressions!.assortmentImpressions.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  const chartSeries = [
    {
      name: t(tokens.pd.performance.assortment_impressions_chart.impressions),
      data: assortmentImpressionsSorted.map(
        (assortmentImpression) => assortmentImpression.deviation
      ),
    },
  ];

  return (
    <DeviationBarChart
      header={t(tokens.pd.performance.search_term_volume_chart.title)}
      chartSeries={chartSeries}
      categories={assortmentImpressionsSorted.map(
        (assortmentImpression) => assortmentImpression.date
      )}
    />
  );
};

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ProposedAction from '../../../../domain/automator/alerts/ProposedAction';
import Stack from '@mui/material/Stack';
import { DismissAlertsActionIcon } from './DismissAlertsActionIcon';
import EventTrigger from '../../../../domain/automator/eventRules/EventTrigger';
import { ExecuteAllAlertActionsButton } from './ExecuteAllAlertActionsButton';
import Typography from '@mui/material/Typography';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import Chip from '@mui/material/Chip';
import uniqueBy from '../../../pd/helpers/uniqueBy';
import { EventRuleInfoButton } from './EventRuleInfoButton';

interface AlertsListTableEventTriggerGroupRowProps {
  eventTrigger: EventTrigger;
  proposedActions: ProposedAction[];
  onClick: () => void;
}

export const AlertsListTableEventTriggerGroupRow = ({
  proposedActions,
  eventTrigger,
  onClick,
}: AlertsListTableEventTriggerGroupRowProps) => {
  const { t } = useTranslation();

  const isNew = proposedActions.some((alert) => !alert.isSeen);

  const amountOfAlerts = uniqueBy(proposedActions, (action) => action.order.id).length;

  return (
    <TableRow
      style={{ cursor: 'pointer' }}
      hover
      onClick={onClick}
    >
      <TableCell width={350}>
        <Stack
          direction="row"
          justifyContent={'space-between'}
          alignItems={'center'}
          gap={2.5}
        >
          <EventRuleInfoButton
            showInfo={true}
            eventTrigger={eventTrigger}
            distributionParty={proposedActions[0].order.distributionParty}
          />

          {isNew && (
            <Box>
              <Chip
                size="small"
                label={t(tokens.common.is_new)}
                variant={'filled'}
                color={'warning'}
              />
            </Box>
          )}
        </Stack>
      </TableCell>

      <TableCell>
        <Typography
          style={{ fontSize: 17, fontWeight: 500 }}
          variant="body1"
        >
          {amountOfAlerts + ' ' + t(tokens.automator.orders.orders)}
        </Typography>
      </TableCell>

      <TableCell />

      <TableCell>
        <Stack
          direction="row"
          gap={3}
          justifyContent={'flex-end'}
        >
          {proposedActions.some((alert) => !alert.isExecuted) && (
            <ExecuteAllAlertActionsButton proposedActions={proposedActions} />
          )}

          {proposedActions.some((alert) => !alert.isDismissed) && (
            <DismissAlertsActionIcon alertIds={proposedActions.map((alert) => alert.id)} />
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

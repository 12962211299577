import { tokens } from '../../../../../locales/translationTokens';
import IconCopyButton from '../../../shared/IconCopyButton';

interface CopyStreetNameButtonProps {
  aggregateId: number;
  streetName: string;
}

const CopyStreetNameButton = ({ aggregateId, streetName }: CopyStreetNameButtonProps) => {
  return (
    <IconCopyButton
      value={streetName}
      label={tokens.automator.orders.buttons.copy.copy_street_name}
      toastMessage={tokens.automator.orders.buttons.copy.street_name_copied}
      activeCopyButtonState={{
        activeButtonId: aggregateId + streetName,
        activeAggregateId: aggregateId,
      }}
    />
  );
};

export default CopyStreetNameButton;

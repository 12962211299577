import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { SHARE_OF_VOICES } from '../pdApiPaths';
import { getDateStringFromDate } from '../../../helpers/getDateStringFromDate';
import { ShareOfVoicesQueryKey } from '../../queryKeys/ShareOfVoicesQueryKey';
import ShareOfVoices from '../../../domain/pd/ShareOfVoices';

export const useFetchShareOfVoices = (
  campaignId: number,
  searchTermId: number,
  startDate: Date,
  endDate: Date
) => {
  const url = usePdApiAccountUrl(
    SHARE_OF_VOICES +
      `?campaignId=${campaignId}&searchTermId=${searchTermId}&startDate=${getDateStringFromDate(
        startDate
      )}&endDate=${getDateStringFromDate(endDate)}`
  );
  return useFetchData<ShareOfVoices>(
    url,
    ShareOfVoicesQueryKey(campaignId, searchTermId, startDate, endDate)
  );
};

import { useAuthorizedPost } from '../../useAuthorizedPost';
import { PRODUCT_REORDER_INTERVAL_PATH } from '../pdApiPaths';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';

export const useUpdateProductReorderInterval = (productReorderIntervalId: number) => {
  const url = usePdApiAccountUrl(PRODUCT_REORDER_INTERVAL_PATH + '/' + productReorderIntervalId);
  return useAuthorizedPost<undefined, UpdateProductReorderIntervalData>(url, undefined, undefined);
};

export interface UpdateProductReorderIntervalData {
  date: string;
  amount_of_days: number;
}

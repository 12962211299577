import { RESENDS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { ResendStatus } from '../../../domain/automator/resends/ResendStatus';
import Resends from '../../../domain/automator/resends/Resends';
import { ResendsQueryKey } from './ResendsQueryKey';

export const useFetchResends = (status: ResendStatus) => {
  const url = useAutomatorApiAccountUrl(RESENDS_PATH + '?status=' + status);
  return useFetchData<Resends>(url, ResendsQueryKey(status));
};

import { REIMBURSEMENTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import Reimbursement from '../../../domain/automator/reimbursements/Reimbursement';
import { ReimbursementsQueryKey } from '../../queryKeys/ReimbursementsQueryKey';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';

export const useDismissReimbursement = (reimbursementId: number) => {
  const url = useAutomatorApiAccountUrl(REIMBURSEMENTS_PATH + '/' + reimbursementId + '/dismiss');
  return useAuthorizedPost<Reimbursement, undefined>(url, [
    ReimbursementsQueryKey(true),
    ReimbursementsQueryKey(false),
    EndpointQueryKey.ACCOUNT,
  ]);
};

import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { HandlingResult } from '../../../../domain/automator/returns/HandlingResult';

interface handlingResultChipProps {
  handlingResult: HandlingResult;
}

const HandlingResultChip = ({ handlingResult }: handlingResultChipProps) => {
  const { t } = useTranslation();

  const determineLabel = (handlingResult: HandlingResult) => {
    switch (handlingResult) {
      case HandlingResult.RETURN_RECEIVED:
        return tokens.automator.returns.handling_result.return_received;
      case HandlingResult.EXCHANGE_PRODUCT:
        return tokens.automator.returns.handling_result.exchange_product;
      case HandlingResult.RETURN_DOES_NOT_MEET_CONDITIONS:
        return tokens.automator.returns.handling_result.return_does_not_meet_conditions;
      case HandlingResult.REPAIR_PRODUCT:
        return tokens.automator.returns.handling_result.repair_product;
      case HandlingResult.CUSTOMER_KEEPS_PRODUCT_PAID:
        return tokens.automator.returns.handling_result.customer_keeps_product_paid;
      case HandlingResult.STILL_APPROVED:
        return tokens.automator.returns.handling_result.still_approved;
      case HandlingResult.CUSTOMER_KEEPS_PRODUCT_FREE_OF_CHARGE:
        return tokens.automator.returns.handling_result.customer_keeps_product_free_of_charge;
      case HandlingResult.RETURN_ITEM_LOST:
        return tokens.automator.returns.handling_result.return_item_lost;
      case HandlingResult.EXCESSIVE_RETURN:
        return tokens.automator.returns.handling_result.excessive_return;
      case HandlingResult.STILL_RECEIVED:
        return tokens.automator.returns.handling_result.still_received;
      case HandlingResult.CANCELLED_BY_CUSTOMER:
        return tokens.automator.returns.handling_result.cancelled_by_customer;
      case HandlingResult.FAILED_TO_CREATE_SHIPPING_LABEL:
        return tokens.automator.returns.handling_result.failed_to_create_shipping_label;
      case HandlingResult.UNDER_INVESTIGATION:
        return tokens.automator.returns.handling_result.under_investigation;
      case HandlingResult.EXPIRED:
        return tokens.automator.returns.handling_result.expired;
      case HandlingResult.FAILED_TO_COLLECT_BY_TRANSPORTER:
        return tokens.automator.returns.handling_result.failed_to_collect_by_transporter;
      default:
        return 'default';
    }
  };

  const determineColor = (handlingResult: HandlingResult) => {
    switch (handlingResult) {
      case HandlingResult.RETURN_RECEIVED:
        return 'success';
      case HandlingResult.EXCHANGE_PRODUCT:
        return 'warning';
      case HandlingResult.RETURN_DOES_NOT_MEET_CONDITIONS:
        return 'error';
      case HandlingResult.REPAIR_PRODUCT:
        return 'warning';
      case HandlingResult.CUSTOMER_KEEPS_PRODUCT_PAID:
        return 'warning';
      case HandlingResult.STILL_APPROVED:
        return 'success';
      case HandlingResult.CUSTOMER_KEEPS_PRODUCT_FREE_OF_CHARGE:
        return 'warning';
      case HandlingResult.RETURN_ITEM_LOST:
        return 'error';
      case HandlingResult.EXCESSIVE_RETURN:
        return 'error';
      case HandlingResult.STILL_RECEIVED:
        return 'primary';
      case HandlingResult.CANCELLED_BY_CUSTOMER:
        return 'warning';
      case HandlingResult.FAILED_TO_CREATE_SHIPPING_LABEL:
        return 'error';
      case HandlingResult.UNDER_INVESTIGATION:
        return 'primary';
      case HandlingResult.FAILED_TO_COLLECT_BY_TRANSPORTER:
        return 'warning';
      case HandlingResult.EXPIRED:
        return 'warning';
      default:
        return 'default';
    }
  };

  return (
    <Chip
      style={{ width: 150 }}
      size="small"
      label={t(determineLabel(handlingResult))}
      color={determineColor(handlingResult)}
      variant="filled"
    />
  );
};

export default HandlingResultChip;

import TableCell from '@mui/material/TableCell';
import Order from '../../../../domain/automator/orders/Order';
import { tokens } from '../../../../locales/translationTokens';
import { CanNotProcessBox } from './CanNotProcessBox';
import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';
import { OrderItemCard } from './OrderItemCard';
import OrderItem from '../../../../domain/automator/orders/OrderItem';
import { OrdersMode } from '../OrdersPage';
import Stack from '@mui/material/Stack';

interface OrderItemsTableCellProps {
  order: Order;
  mode: OrdersMode;
  distributionParty: DistributionParty;
  onSelect: (orderItem: OrderItem) => void;
  selectedOrderItemIds: number[];
}

export const OrderItemsTableCell = ({
  order,
  mode,
  onSelect,
  selectedOrderItemIds,
}: OrderItemsTableCellProps) => {
  if (
    order.isPartiallyProcessedExternally ||
    order.hasConflictingHandoverDateTime ||
    order.distributionParty == DistributionParty.MIXED ||
    order.id == -1
  ) {
    return (
      <TableCell>
        {order.isPartiallyProcessedExternally && (
          <CanNotProcessBox
            message={tokens.automator.orders.can_not_process_external_processed_order}
          />
        )}

        {order.hasConflictingHandoverDateTime && (
          <CanNotProcessBox
            message={
              tokens.automator.orders.can_not_process_order_with_conflicting_handover_date_times
            }
          />
        )}

        {order.distributionParty == DistributionParty.MIXED && (
          <CanNotProcessBox
            message={tokens.automator.orders.can_not_process_order_with_mixed_distribution_parties}
          />
        )}

        {order.id == -1 && (
          <CanNotProcessBox message={tokens.automator.orders.can_not_process_order_loading_error} />
        )}
      </TableCell>
    );
  } else {
    return (
      <TableCell>
        <Stack
          direction="column"
          gap={2}
        >
          {order.items.map((item) => {
            return (
              <OrderItemCard
                order={order}
                orderItem={item}
                key={item.id}
                mode={mode}
                onSelect={onSelect}
                isSelected={selectedOrderItemIds && selectedOrderItemIds.includes(item.id)}
              />
            );
          })}
        </Stack>
      </TableCell>
    );
  }
};

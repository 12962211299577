import { DropdownSelect } from '../../../../automator/shared/DropdownSelect';
import { tokens } from '../../../../../locales/translationTokens';

interface RollingAverageDaySelectProps {
  onSelect: (rollingAverageDays: number) => void;
  rollingAverageDays: number;
}

const RollingAverageDaySelect = ({
  onSelect,
  rollingAverageDays,
}: RollingAverageDaySelectProps) => {
  const options = [
    {
      key: 1,
      value: '1d',
    },
    {
      key: 7,
      value: '7d',
    },
    {
      key: 14,
      value: '14d',
    },
    {
      key: 30,
      value: '30d',
    },
  ];

  return (
    <DropdownSelect
      options={options}
      onSelect={(key) => {
        onSelect(key);
      }}
      selected={rollingAverageDays}
      label={tokens.pd.campaign_dropdown_select.label}
    />
  );
};

export default RollingAverageDaySelect;

import { TableCell, TableRow } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import ShipmentDayReport from '../../../../domain/automator/shipments/ShipmentDayReport';
import ShipmentPerDayRow from './ShipmentsPerDayRow';
import Box from '@mui/material/Box';
import ShipmentStepper from './stepper/ShipmentStepper';
import Shipment from '../../../../domain/automator/shipments/Shipment';
import IconButton from '@mui/material/IconButton';
import LoadShipmentEventsReport from '../../../../domain/automator/shipments/LoadShipmentEventsReport';
import getDayOfTheWeek from '../../../../helpers/getDayOfTheWeek';
import getWeekNumberOfYear from '../../../../helpers/getWeekNumberOfYear';

interface ShipmentsDayReportRowProps {
  date: string;
  shipmentDayReport?: ShipmentDayReport;
  loadShipmentEventsReport?: LoadShipmentEventsReport;
  shipments?: Shipment[];
  isExpanded: boolean;
  onExpand: (date: string) => void;
  isLoading: boolean;
}

const ShipmentsDayReportRow = ({
  date,
  shipmentDayReport,
  loadShipmentEventsReport,
  isExpanded,
  onExpand,
  shipments,
  isLoading,
}: ShipmentsDayReportRowProps) => {
  const { t } = useTranslation();

  const totalShipments = shipmentDayReport ? shipmentDayReport.totalShipments : 0;

  const dayOfTheWeek = getDayOfTheWeek(date);

  const weekNumberOfYear = getWeekNumberOfYear(date);

  const paddingHorizontal = totalShipments > 0 ? 10 : 15;

  return (
    <>
      <TableRow
        hover={true}
        style={{
          cursor: totalShipments > 0 ? 'pointer' : undefined,
          backgroundColor: isExpanded && totalShipments > 0 ? '#F8F9FA' : 'white',
        }}
        onClick={() => totalShipments > 0 && onExpand(date)}
      >
        <TableCell
          style={{ paddingTop: paddingHorizontal, paddingBottom: paddingHorizontal }}
          width={40}
        >
          <Typography variant="subtitle1">{t(dayOfTheWeek).toUpperCase()}</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="subtitle2">
            W{weekNumberOfYear} {date}
          </Typography>
        </TableCell>

        <TableCell
          style={{
            paddingTop: paddingHorizontal,
            paddingBottom: paddingHorizontal,
          }}
          align="left"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={10}
          >
            {totalShipments == 0 ? (
              <Typography variant="body2">
                {t(tokens.automator.shipments.no_shipments_on_this_day)}
              </Typography>
            ) : (
              <ShipmentStepper shipmentDayReport={shipmentDayReport} />
            )}
          </Box>
        </TableCell>
        <TableCell
          style={{ paddingTop: paddingHorizontal, paddingBottom: paddingHorizontal }}
          align="right"
        >
          {totalShipments != 0 && <IconButton>{isExpanded ? '-' : '+'}</IconButton>}
        </TableCell>
      </TableRow>

      {isExpanded && totalShipments > 0 && (
        <ShipmentPerDayRow
          isLoading={isLoading}
          shipments={shipments}
          loadShipmentEventsReport={loadShipmentEventsReport}
          isExpanded={isExpanded}
        />
      )}

      {isExpanded && totalShipments > 0 && (
        <TableRow style={{ backgroundColor: '#F8F9FA' }}>
          <TableCell colSpan={5}></TableCell>
        </TableRow>
      )}
    </>
  );
};

export default ShipmentsDayReportRow;

import { DialogId } from '../../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../../../../../store/dialogs/useCloseDialog';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../locales/translationTokens';
import { ApplicationDialog } from '../../../../../components/ApplicationDialog';
import ProductReorderInterval from '../../../../../domain/pd/ProductReorderInterval';
import { UpdateProductReorderIntervalForm } from '../components/forms/UpdateProductReorderIntervalForm';

export const UpdateProductReorderIntervalDialog = () => {
  const id = DialogId.UPDATE_PRODUCT_REORDER_INTERVAL;
  const { isOpen, data } = useIsDialogOpen(id);
  const closeDialog = useCloseDialog(id);
  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          title={t(
            tokens.pd.performance.update_product_reorder_interval_form.update_reorder_interval
          )}
        >
          <UpdateProductReorderIntervalForm
            productReorderInterval={data.productReorderInterval}
            onClick={closeDialog}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface UpdateProductReorderIntervalDialogData {
  productReorderInterval: ProductReorderInterval;
}

import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { useActiveCopyButton } from '../../../store/activeCopyButton/useActiveCopyButton';
import { ActiveCopyButtonState } from '../../../store/activeCopyButton/activeCopyButtonsSlice';
import ActionButton from '../../../components/ActionButton';

interface IconCopyButtonProps {
  label: string;
  value: string;
  toastMessage: string;
  maxLength?: number;
  activeCopyButtonState?: ActiveCopyButtonState;
}

const IconCopyButton = ({
  activeCopyButtonState,
  label,
  value,
  toastMessage,
  maxLength,
}: IconCopyButtonProps) => {
  const { t } = useTranslation();

  const { activeButtonId, setActiveButton } = useActiveCopyButton();

  const handleCopy = async (value: string) => {
    let truncatedValue = value;
    if (maxLength && value.length > maxLength) {
      // Shorten from the left if value is too long
      truncatedValue = value.slice(-maxLength);
    }

    try {
      await navigator.clipboard.writeText(truncatedValue);
      activeCopyButtonState && setActiveButton(activeCopyButtonState);
      toast.success(t(toastMessage));
    } catch (error) {
      console.error('Failed to copy: ', error);
    }
  };

  const isActive =
    activeButtonId &&
    activeCopyButtonState &&
    activeCopyButtonState.activeButtonId === activeButtonId;

  return (
    <ActionButton
      color={isActive ? 'info' : 'inherit'}
      variant={isActive ? 'contained' : 'outlined'}
      style={{ height: 10 }}
      size="small"
      label={label}
      onClick={() => {
        handleCopy(value).then((_) => {});
      }}
    />

    // <IconTooltipButton
    //   icon={icon}
    //   iconSize={iconSize}
    //   tooltip={t(tooltip)}
    //
    //   color={isActive ? 'red' : undefined}
    //   border={isActive ? '1px solid red' : undefined}
    // />
  );
};

export default IconCopyButton;

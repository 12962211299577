import * as React from 'react';
import ActionButton from '../../../../components/ActionButton';
import { tokens } from '../../../../locales/translationTokens';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useTranslation } from 'react-i18next';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

interface OpenCreateResendDialogButtonProps {
  orderId: number;
  isDisabled?: boolean;
}

const OpenCreateResendDialogButton = ({
  orderId,
  isDisabled,
}: OpenCreateResendDialogButtonProps) => {
  const openCreateResendDialog = useOpenDialog(DialogId.CREATE_RESEND);
  const { t } = useTranslation();

  return (
    <ActionButton
      size="small"
      onClick={() => openCreateResendDialog({ orderId: orderId })}
      label={t(tokens.automator.resolutions.dialogs.create_resend.create_resend)}
      variant="text"
      color="primary"
      isDisabled={isDisabled}
      icon={<ArrowCircleRightIcon />}
    />
  );
};

export default OpenCreateResendDialogButton;

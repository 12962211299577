import { useTranslation } from 'react-i18next';
import CustomerInvoiceRequest from '../../../../domain/automator/customerInvoiceRequests/CustomerInvoiceRequest';
import Grid from '@mui/material/Grid';
import { CustomerInvoiceRequestDataItem } from './CustomerInvoiceRequestDetailsItem';
import { tokens } from '../../../../locales/translationTokens';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import Stack from '@mui/material/Stack';
import { UpdateCustomerInvoiceLine } from './UpdateCustomerInvoiceLine';

interface CustomerInvoiceRequestsListTableProps {
  customerInvoiceRequest: CustomerInvoiceRequest;
}

export const CustomerInvoiceRequestsListTable = ({
  customerInvoiceRequest,
}: CustomerInvoiceRequestsListTableProps) => {
  const { t } = useTranslation();

  const billingDetails = customerInvoiceRequest.customerInvoice?.billingDetails;

  const openDialog = useOpenDialog(DialogId.UPDATE_UPDATED_BILLING_DETAILS);

  const determineStreet = () => {
    return (
      billingDetails?.streetName +
      ' ' +
      billingDetails?.houseNumber +
      ' ' +
      billingDetails?.houseNumberExtension
    );
  };

  const openUpdateCustomerInvoiceBillingDetailsDialog = () => {
    openDialog({ customerInvoice: customerInvoiceRequest.customerInvoice! });
  };

  return (
    <Stack
      paddingX={2}
      paddingY={1}
      direction="row"
      justifyContent="space-between"
    >
      {billingDetails && (
        <Stack
          gap={10}
          direction="row"
        >
          <Grid
            item
            xs={5}
            onClick={() => openUpdateCustomerInvoiceBillingDetailsDialog()}
            style={{ cursor: 'pointer' }}
          >
            <CustomerInvoiceRequestDataItem
              label={t(tokens.automator.customer_invoice_requests.details.company_name)}
              value={
                billingDetails?.company ||
                billingDetails?.company ||
                billingDetails.firstName + ' ' + billingDetails.surname
              }
              width={200}
            />
            <CustomerInvoiceRequestDataItem
              label={t(
                tokens.automator.customer_invoice_requests.details.chamber_of_commerce_number
              )}
              value={billingDetails?.kvkNumber || '-'}
              width={200}
            />
            <CustomerInvoiceRequestDataItem
              label={t(tokens.automator.customer_invoice_requests.details.vat_number)}
              value={billingDetails?.vatNumber || '-'}
              width={200}
            />
          </Grid>
          <Grid
            item
            xs={5}
            onClick={() => openUpdateCustomerInvoiceBillingDetailsDialog()}
            style={{ cursor: 'pointer' }}
          >
            <CustomerInvoiceRequestDataItem
              label={t(tokens.automator.customer_invoice_requests.details.street)}
              value={determineStreet()}
              width={150}
            />
            <CustomerInvoiceRequestDataItem
              label={t(tokens.automator.customer_invoice_requests.details.postal_code)}
              value={billingDetails?.zipCode || ''}
              width={150}
            />
            <CustomerInvoiceRequestDataItem
              label={t(tokens.automator.customer_invoice_requests.details.city)}
              value={billingDetails?.city || ''}
              width={150}
            />
          </Grid>
        </Stack>
      )}

      <Grid
        item
        xs={2}
        paddingRight={10}
      >
        <Stack padding={2}>
          {customerInvoiceRequest.customerInvoice!.customerInvoiceLines.map((item, index) => (
            <UpdateCustomerInvoiceLine
              key={index}
              customerInvoiceLine={item}
            />
          ))}
        </Stack>
      </Grid>
    </Stack>
  );
};

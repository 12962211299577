import { SlimTableCell } from '../../../../../../components/SlimTableCell';
import Box from '@mui/material/Box';
import { CompetitorTableCellData } from './CompetitorProductPerformancesTable';
import { CompetitorProductPerformanceTableCellContent } from './CompetitorProductPerformanceTableCellContent';

interface CompetitorProductPerformanceTableCellProps {
  cells: CompetitorTableCellData[];
  onHover: (productId: number | null) => void;
}

export const CompetitorProductPerformanceTableCell = ({
  cells,
  onHover,
}: CompetitorProductPerformanceTableCellProps) => {
  if (cells.length === 0) {
    return (
      <SlimTableCell
        style={{
          backgroundColor: 'WHITE',
          height: '25px',
          overflow: 'hidden',
        }}
        onMouseEnter={() => onHover(null)}
        onMouseLeave={() => onHover(null)}
      >
        <Box
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            boxSizing: 'border-box',
            zIndex: 3,
            color: 'black',
            padding: '0 4px',
            whiteSpace: 'nowrap',
          }}
        >
          {'-'}
        </Box>
      </SlimTableCell>
    );
  }

  const cellsSorted = cells.sort((a, b) => a.initialPosition - b.initialPosition);

  const generateSecondaryColorBar = (cells: CompetitorTableCellData[]) => {
    const length = cells.length === 1 ? 2 : cells.length;

    return (
      <Box
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '10px',
          display: 'flex',
        }}
      >
        {cells.map((cell, index) => (
          <Box
            key={index}
            style={{
              border: cell.isHovered ? '2px solid BLACK' : '1px solid transparent',
              backgroundColor: cell.color,
              width: `${100 / length}%`,
            }}
          >
            <CompetitorProductPerformanceTableCellContent
              fontSize={8}
              cell={cell}
            />
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <SlimTableCell
      style={{
        backgroundColor: cellsSorted[0].color,
        height: '20px',
        overflow: 'hidden',
      }}
      onMouseEnter={() => onHover(cellsSorted[0].productId)}
      onMouseLeave={() => onHover(null)}
    >
      <Box
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: cellsSorted[0].isHovered ? '2px solid BLACK' : '1px solid transparent',
          boxSizing: 'border-box',
          zIndex: 3,
          color: 'black',
          padding: '0 4px',
          whiteSpace: 'nowrap',
        }}
      >
        <CompetitorProductPerformanceTableCellContent
          fontSize={12}
          cell={cellsSorted[0]}
        />
        <Box>{cells.length > 0 ? cells[0].impressions : '-'}</Box>
        {generateSecondaryColorBar(cellsSorted.slice(1))}
      </Box>
    </SlimTableCell>
  );
};

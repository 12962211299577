import ReturnItem from '../../../../domain/automator/returns/ReturnItem';
import TableRow from '@mui/material/TableRow';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import Box from '@mui/material/Box';
import HandlingResultChip from './HandlingResultChip';
import ProcessingResultChip from './ProcessingResultChip';
import Typography from '@mui/material/Typography';
import StillAcceptReturnProcessingResultButton from './StillAcceptReturnProcessingResultButton';

interface ReturnProcessingResultListTableProps {
  returnItem: ReturnItem;
}

export const ReturnProcessingResultListTable = ({
  returnItem,
}: ReturnProcessingResultListTableProps) => {
  const returnProcessingResults = returnItem.returnProcessingResults;

  return (
    <>
      {returnProcessingResults.map((returnProcessingResult, index) => {
        return (
          <TableRow key={index}>
            <SlimTableCell hasBorderBottom={false}>
              <Typography variant="subtitle1">{returnProcessingResult.quantity}x</Typography>
            </SlimTableCell>
            <SlimTableCell hasBorderBottom={false}>
              <ProcessingResultChip processingResult={returnProcessingResult.processingResult} />
            </SlimTableCell>
            <SlimTableCell hasBorderBottom={false}>
              <HandlingResultChip handlingResult={returnProcessingResult.handlingResult} />
            </SlimTableCell>

            <SlimTableCell
              width={200}
              hasBorderBottom={false}
            >
              <Box>{returnProcessingResult.processingDateTime}</Box>
            </SlimTableCell>
            <SlimTableCell hasBorderBottom={false}>
              <StillAcceptReturnProcessingResultButton
                returnProcessingResult={returnProcessingResult}
              />
            </SlimTableCell>
          </TableRow>
        );
      })}
    </>
  );
};

import { useSelector } from 'react-redux';
import { RootState } from '../Store';
import { DialogId } from './DialogId';
import { DialogIdDataTypeMap } from './DialogIdDataMap';

export const useIsDialogOpen = <T extends DialogId>(id: T) => {
  const dialogState = useSelector((state: RootState) => state.dialogs[id]);

  return {
    isOpen: dialogState?.isOpen ?? false,
    data: dialogState?.data as DialogIdDataTypeMap[T] | undefined,
  };
};

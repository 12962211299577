import { PROPOSED_ACTIONS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { useUpdateData } from '../../useUpdateData';
import { useProposedActionQueryKeysUpdater } from './useProposedActionQueryKeysUpdater';
import ProposedActions from '../../../domain/automator/alerts/ProposedActions';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';
import { ProposedActionsReportQueryKey } from '../../queryKeys/ProposedActionsReportQueryKey';

export const useDismissProposedActions = () => {
  const updater = useProposedActionQueryKeysUpdater();
  const updateData = useUpdateData(updater, 'proposedActions');

  const onSuccess = (alerts: ProposedActions) => {
    updateData(alerts);
  };

  const url = useAutomatorApiAccountUrl(PROPOSED_ACTIONS_PATH + '/dismiss');
  return useAuthorizedPost<ProposedActions, DismissAlertData>(
    url,
    [EndpointQueryKey.ACCOUNT, ProposedActionsReportQueryKey()],
    undefined,
    onSuccess
  );
};

export interface DismissAlertData {
  alert_ids: number[];
}

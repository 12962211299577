import * as React from 'react';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ReturnItemResolutionCaseMenu from './ReturnItemResolutionCaseMenu';
import CreateReturnMenu from './CreateReturnMenu';
import PopoverButton from '../../shared/PopoverButton';
import { useTranslation } from 'react-i18next';

interface ReturnResolutionCasePopoverButtonProps {
  orderId: number;
  returnItemId: number | null;
}

export default function ReturnResolutionCasePopoverButton({
  orderId,
  returnItemId,
}: ReturnResolutionCasePopoverButtonProps) {
  const { t } = useTranslation();

  return (
    <PopoverButton
      id={'return-resolution-case-actions'}
      button={
        <ActionButton
          icon={<KeyboardArrowDownIcon />}
          color={'primary'}
          label={t(tokens.automator.returns.returns)}
          size="small"
          variant="text"
        />
      }
      content={
        <>
          {returnItemId ? (
            <ReturnItemResolutionCaseMenu returnItemId={returnItemId} />
          ) : (
            <CreateReturnMenu orderId={orderId} />
          )}
        </>
      }
    />
  );
}

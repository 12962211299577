import Stack from '@mui/material/Stack';
import { tokens } from '../../../../locales/translationTokens';
import DaysUntilReturnText from '../../shipments/components/DaysUntilReturnText';
import ShipmentOverdueChip from '../../shipments/components/ShipmentOverdueChip';
import { isOverDue } from '../../shipments/components/ShipmentRow';
import TransporterChip from '../../shipments/components/TransporterChip';
import HasReturnedToSenderIndicator from '../../shipments/components/HasReturnedToSenderIndicator';
import { ShipmentTrackAndTraceTextField } from '../../shipments/components/ShipmentTrackAndTraceTextField';
import { useFetchOrderShipment } from '../../../../api/automator/orders/useFetchOrderShipment';
import { OrderSummaryCardItem } from './OrderSummaryCardItem';

interface OrderSummaryShipmentCardItemProps {
  orderId: number;
}

export const OrderSummaryShipmentCardItem = ({ orderId }: OrderSummaryShipmentCardItemProps) => {
  const { data: shipment, isLoading } = useFetchOrderShipment(orderId);

  if (isLoading) {
    return <>...loading</>;
  }

  if (!shipment) {
    return <></>;
  }

  const hasTrackAndTraceUrl = shipment?.transporterInfos.some((t) => t.trackAndTraceUrl != null);

  return (
    <OrderSummaryCardItem label={tokens.automator.order_summary.shipment}>
      {shipment && (
        <Stack
          alignItems="top"
          gap={2}
        >
          <Stack
            gap={'0.5rem'}
            direction={'row'}
          >
            {!hasTrackAndTraceUrl && <ShipmentTrackAndTraceTextField shipmentId={shipment.id} />}

            {shipment.isTrackAndTraceable && (
              <>
                {shipment.daysUntilReturn != null && (
                  <DaysUntilReturnText daysUntilReturn={shipment.daysUntilReturn!} />
                )}
              </>
            )}
          </Stack>

          <Stack
            direction="row"
            alignItems={'center'}
            gap={1}
          >
            {isOverDue(shipment) && <ShipmentOverdueChip />}

            {shipment.hasReturnedToSender && <HasReturnedToSenderIndicator />}

            {shipment.transporterInfos.map((transporterInfo, index) => (
              <TransporterChip
                key={index}
                transporterCode={transporterInfo.transporterCode}
                trackAndTrace={transporterInfo.trackAndTraceUrl}
                distributionParty={shipment.order.distributionParty}
              />
            ))}
          </Stack>
        </Stack>
      )}
    </OrderSummaryCardItem>
  );
};

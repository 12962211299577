import { CUSTOMER_INVOICE_REQUESTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import CustomerInvoiceRequests from '../../../domain/automator/customerInvoiceRequests/CustomerInvoiceRequests';
import { CustomerInvoiceRequestsQueryKey } from '../../queryKeys/CustomerInvoiceRequestsQueryKey';

export const useFetchInvoiceCustomerRequests = () => {
  const url = useAutomatorApiAccountUrl(CUSTOMER_INVOICE_REQUESTS_PATH);
  return useFetchData<CustomerInvoiceRequests>(url, CustomerInvoiceRequestsQueryKey());
};

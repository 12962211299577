import Chip from '@mui/material/Chip';
import { convertCentsToEuros } from '../../../pd/helpers/convertCentsToEuros';

interface ReimbursementStatusChipProps {
  amountInCents: number | null;
}

export const ReimbursementStatusChip = ({ amountInCents }: ReimbursementStatusChipProps) => {
  //const { t } = useTranslation();

  return (
    <Chip
      label={amountInCents ? '€ ' + convertCentsToEuros(amountInCents) : 'REQUESTED'}
      variant="filled"
      color={amountInCents ? 'success' : 'info'}
    />
  );
};

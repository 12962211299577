import { SHIPMENTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';
import { useFetchDataPolled } from '../../useFetchDataPolled';
import ShipmentsReport from '../../../domain/automator/shipments/ShipmentsReport';
import { useQueryClient } from 'react-query';
import { ShipmentsQueryKey, ShipmentsRequestType } from '../../queryKeys/ShipmentsQueryKey';
import Shipments from '../../../domain/automator/shipments/Shipments';
import { getLastDays } from '../../../helpers/getLastDays';

export const useFetchShipmentsReport = () => {
  const url = useAutomatorApiAccountUrl(SHIPMENTS_PATH + '/report');

  const queryClient = useQueryClient();

  return useFetchDataPolled<ShipmentsReport>(url, EndpointQueryKey.SHIPMENTS_REPORT, {
    initialPollingInterval: 3500,
    stopCondition: (data: ShipmentsReport | undefined) => {
      if (!data) {
        return false;
      }
      return !data.loadEventsReport.shouldPoll;
    },
    startCondition: (data: ShipmentsReport | undefined) => {
      if (!data) {
        return false;
      }
      return data.loadEventsReport.shouldPoll;
    },
    updateState: (data: ShipmentsReport | undefined) => {
      const dayQueryKeys = getLastDays(14).map((date) => {
        return ShipmentsQueryKey(ShipmentsRequestType.ALL, date);
      });

      const specifiedQueryKeys = [
        ShipmentsQueryKey(ShipmentsRequestType.RETURNED_TO_SENDER, null),
        ShipmentsQueryKey(ShipmentsRequestType.OVERDUE, null),
        ShipmentsQueryKey(ShipmentsRequestType.AT_PICK_UP_POINT, null),
      ];

      const queryKeys = [...dayQueryKeys, ...specifiedQueryKeys];

      data?.loadEventsReport.recentlyFinishedShipments.forEach((finishedShipment) => {
        queryKeys.forEach((queryKey) => {
          const shipments = queryClient.getQueryData<Shipments>(queryKey);

          if (shipments) {
            // Create a copy of shipments data for modification
            let updatedShipments = [...shipments.shipments];

            // Find the matching shipment and update latestEvent data
            updatedShipments = updatedShipments.map((shipment) => {
              if (finishedShipment.id === shipment.id) {
                return { ...shipment, latestEvent: finishedShipment.latestEvent };
              }
              return shipment;
            });

            // Update the cache data outside the loop
            queryClient.setQueryData(queryKey, { ...shipments, shipments: updatedShipments });
          }
        });
      });
    },
    incrementFactor: 1.1,
  });
};

import { CUSTOMER_INVOICE_BUNDLES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import CustomerInvoicesBundles from '../../../domain/automator/customerInvoiceRequests/CustomerInvoicesBundles';
import { CustomerInvoicesBundlesQueryKey } from '../../queryKeys/CustomerInvoicesBundlesQueryKey';

export const useFetchCustomerInvoicesBundles = () => {
  const url = useAutomatorApiAccountUrl(CUSTOMER_INVOICE_BUNDLES_PATH);
  return useFetchData<CustomerInvoicesBundles>(url, CustomerInvoicesBundlesQueryKey());
};

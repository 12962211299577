import { TableCell, TableRow } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import getDayOfTheWeek from 'src/helpers/getDayOfTheWeek';
import { ReactNode } from 'react';
import getWeekNumberOfYear from '../../../helpers/getWeekNumberOfYear';

interface CollapsableTableRowDividerProps {
  date: string;
  additionalInfo?: ReactNode;
  isCollapsable: boolean;
  isCollapsed: boolean;
  onClick?: () => void;
}

export const CollapsableTableRowDivider = ({
  date,
  additionalInfo,
  isCollapsable,
  isCollapsed,
  onClick,
}: CollapsableTableRowDividerProps) => {
  const { t } = useTranslation();

  const dayOfTheWeek = getDayOfTheWeek(date);
  const weekNumberOfYear = getWeekNumberOfYear(date);

  return (
    <TableRow
      hover={true}
      style={{
        cursor: isCollapsable ? 'pointer' : undefined,
        backgroundColor: isCollapsed ? '#F8F9FA' : 'white',
      }}
      onClick={onClick}
    >
      <TableCell width={40}>
        <Typography variant="subtitle1">{t(dayOfTheWeek).toUpperCase()}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="subtitle2">
          W{weekNumberOfYear} {date}
        </Typography>
      </TableCell>
      <TableCell align="left">{additionalInfo}</TableCell>
      <TableCell align="right">
        {isCollapsable && <IconButton>{isCollapsed ? '+' : '-'}</IconButton>}
      </TableCell>
    </TableRow>
  );
};

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { DownloadReturnReasonReportCsvButton } from './DownloadReturnReasonReportCsvButton';

export const ReturnReasonReportTable = () => {
  const { t } = useTranslation();

  function generateQuarters() {
    const startYear = 2024;
    const endYear = new Date().getFullYear(); // or any end year you want
    const quarters = [
      { name: 'Q1', start: '01-01', end: '03-31' },
      { name: 'Q2', start: '04-01', end: '06-30' },
      { name: 'Q3', start: '07-01', end: '09-30' },
      { name: 'Q4', start: '10-01', end: '12-31' },
    ];
    const quarterYears: Quarter[] = [];

    for (let year = startYear; year <= endYear; year++) {
      quarters.forEach((quarter) => {
        quarterYears.push({
          name: `${year} ${quarter.name}`,
          startDate: `${year}-${quarter.start}`,
          endDate: `${year}-${quarter.end}`,
        });
      });
    }

    return quarterYears;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">{t(tokens.automator.reports.quarter)}</TableCell>
              <TableCell align="right">{t(tokens.automator.reports.download)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {generateQuarters().map((quarter) => {
              return (
                <TableRow key={quarter.name}>
                  <TableCell align="left">{quarter.name}</TableCell>
                  <TableCell align="right">
                    <DownloadReturnReasonReportCsvButton
                      startDate={quarter.startDate}
                      endDate={quarter.endDate}
                      label={tokens.automator.reports.download}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};

interface Quarter {
  name: string;
  startDate: string;
  endDate: string;
}

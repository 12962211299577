import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { CUSTOMER_INVOICES_PATH } from '../automatorApiPaths';
import CustomerInvoiceRequests from '../../../domain/automator/customerInvoiceRequests/CustomerInvoiceRequests';
import { CustomerInvoiceRequestsQueryKey } from '../../queryKeys/CustomerInvoiceRequestsQueryKey';

export const useMailCustomerInvoice = (customerInvoiceId: number) => {
  const url = useAutomatorApiAccountUrl(
    `${CUSTOMER_INVOICES_PATH}/` + customerInvoiceId + '/send-email'
  );

  return useAuthorizedPost<CustomerInvoiceRequests, undefined>(url, [
    CustomerInvoiceRequestsQueryKey(),
  ]);
};

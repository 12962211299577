import { QueryKeysUpdater } from '../../useUpdateData';
import CustomerInvoiceRequest from '../../../domain/automator/customerInvoiceRequests/CustomerInvoiceRequest';
import { CustomerInvoiceRequestsQueryKey } from '../../queryKeys/CustomerInvoiceRequestsQueryKey';

export const useCustomerInvoiceRequestQueryKeysUpdater =
  (): QueryKeysUpdater<CustomerInvoiceRequest> => {
    return {
      [CustomerInvoiceRequestsQueryKey()]: customerInvoiceRequestUpdater(),
    };
  };

const customerInvoiceRequestUpdater = () => {
  return {
    addCriteria: (_: CustomerInvoiceRequest) => {
      return true;
    },
  };
};

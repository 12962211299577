import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';
import Order from '../../../../domain/automator/orders/Order';
import { ConfirmCreatePickSessionForm } from './ConfirmCreatePickSessionForm';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { TransporterCode } from '../../../../domain/automator/shipments/TransporterCode';
import { LabelGeneration } from '../../../../domain/automator/pickSessions/LabelGeneration';

export const ConfirmCreatePickSessionDialog = () => {
  const id = DialogId.CONFIRM_CREATE_PICK_SESSION;
  const { isOpen, data } = useIsDialogOpen(id);
  const closeDialog = useCloseDialog(id);
  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          title={t(tokens.automator.pick_sessions.confirm_start_pick_session)}
          centerTitle
        >
          <ConfirmCreatePickSessionForm
            onClick={closeDialog}
            orders={data.orders}
            distributionParty={data.distributionParty}
            quickTransporterCode={data.transporterCode}
            labelGeneration={data.labelGeneration}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface ConfirmShipmentDialogData {
  orders: Order[];
  distributionParty: DistributionParty;
  transporterCode: TransporterCode | null;
  labelGeneration?: LabelGeneration;
}

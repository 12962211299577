import SettingsIcon from '@mui/icons-material/Settings';
import MenuList from '@mui/material/MenuList';
import * as React from 'react';
import { PackageCheck } from '@untitled-ui/icons-react';
import { LocalShipping, Widgets } from '@mui/icons-material';
import { ThreadPhase } from '../../../../../domain/automator/messages/ThreadPhase';
import PopoverButton from '../../../shared/PopoverButton';
import { ActionIcon } from '../../../orders/components/ActionIcon';
import { tokens } from '../../../../../locales/translationTokens';
import SpeedDialMenuItem from '../../../shared/speeddial/SpeeddialMenuItem';

interface ThreadPhasePopoverButtonProps {
  setPhase: (phase: ThreadPhase) => void;
}

export const ThreadPhasePopoverButton = ({ setPhase }: ThreadPhasePopoverButtonProps) => {
  return (
    <PopoverButton
      id={'template-select'}
      button={
        <ActionIcon
          icon={<SettingsIcon />}
          tooltip={tokens.automator.speed_dial.speed_dial}
          color={'primary'}
          iconSize={20}
        />
      }
      content={
        <MenuList>
          <SpeedDialMenuItem
            icon={<PackageCheck />}
            label={tokens.automator.orders.orders}
            onClick={() => setPhase(ThreadPhase.ORDER)}
          />
          <SpeedDialMenuItem
            icon={<LocalShipping />}
            label={tokens.automator.shipments.shipments}
            onClick={() => setPhase(ThreadPhase.SHIPMENT)}
          />
          <SpeedDialMenuItem
            icon={<Widgets />}
            label={tokens.automator.returns.returns}
            onClick={() => setPhase(ThreadPhase.RETURN)}
          />
        </MenuList>
      }
    />
  );
};

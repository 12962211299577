import Typography from '@mui/material/Typography';

interface DaysUntilExpirationTextProps {
  daysSinceRegistration: number;
}

const DaysUntilExpirationText = ({ daysSinceRegistration }: DaysUntilExpirationTextProps) => {
  const days = daysSinceRegistration < 0 ? 0 : daysSinceRegistration;

  return (
    <Typography
      paddingTop={'0.1rem'}
      variant="body2"
    >
      {`${days}`}
    </Typography>
  );
};

export default DaysUntilExpirationText;

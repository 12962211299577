import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { PRODUCTS } from '../pdApiPaths';
import ContestedCategories from '../../../domain/pd/ContestedCategories';
import { ContestedCategoriesQueryKey } from '../../queryKeys/ContestedCategoriesQueryKey';

export const useFetchContestedCategories = (productId: number) => {
  const url = usePdApiAccountUrl(PRODUCTS + '/' + productId + '/contested-categories');
  return useFetchData<ContestedCategories>(url, ContestedCategoriesQueryKey(productId));
};

import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { tokens } from '../../../../locales/translationTokens';
import { toast } from 'react-hot-toast';
import ActionButton from '../../../../components/ActionButton';
import { Email } from '@mui/icons-material';
import { Stack } from '@mui/material';
import MessageTemplate from '../../../../domain/automator/messages/MessageTemplate';
import { useUpdateMessageTemplate } from '../../../../api/automator/emails/useUpdateMessageTemplate';
import { EmailDocumentsList } from '../../resolutions/components/EmailDocumentsList';
import Box from '@mui/material/Box';
import HtmlEditor from '../../autoEmail/components/HtmlEditor';
import { EmailVariableSelection } from './EmailVariableSelection';
import { useFetchMessageTemplate } from '../../../../api/automator/emails/useFetchMessageTemplate';

interface UpdateMessageTemplateFormProps {
  onUpdate?: (messageTemplate: MessageTemplate) => void;
  messageTemplateId: number;
}

export const UpdateMessageTemplateForm = ({
  onUpdate,
  messageTemplateId,
}: UpdateMessageTemplateFormProps) => {
  const { data: messageTemplate, isLoading: isLoadingMessageTemplate } =
    useFetchMessageTemplate(messageTemplateId);

  useEffect(() => {
    setName(messageTemplate?.name ?? '');
    setSubject(messageTemplate?.subject ?? '');
    setBody(messageTemplate?.body ?? '');
  }, [messageTemplate]);

  const { mutate: updateMessageTemplate, isLoading: isLoadingUpdateMessageTemplate } =
    useUpdateMessageTemplate(messageTemplateId);

  const [name, setName] = useState(messageTemplate?.name ?? '');
  const [subject, setSubject] = useState(messageTemplate?.subject ?? '');
  const [body, setBody] = useState(messageTemplate?.body ?? '');

  const [insertVariable, setInsertVariable] = useState<string | null>(null);

  const { t } = useTranslation();

  const handleUpdate = async () => {
    updateMessageTemplate(
      {
        subject: subject,
        body: body,
        name: name,
      },
      {
        onSuccess: async (messageTemplate: MessageTemplate) => {
          onUpdate?.(messageTemplate);
          toast.success(
            t(tokens.automator.resolutions.dialogs.update_email_template.email_template_updated)
          );
        },
      }
    );
  };

  if (isLoadingMessageTemplate) {
    return <div>Loading...</div>;
  }

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <Stack
        direction={'row'}
        minWidth={'60%'}
        gap={2}
      >
        <Stack
          direction="column"
          gap={1}
        >
          <TextField
            id="name-field"
            label={t(tokens.automator.resolutions.dialogs.update_email_template.name)}
            variant="filled"
            fullWidth
            style={{ marginBottom: '1em' }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <TextField
            id="subject-field"
            label={t(tokens.automator.resolutions.dialogs.update_email_template.subject)}
            variant="filled"
            fullWidth
            style={{ marginBottom: '1em' }}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />

          <HtmlEditor
            content={body}
            onChange={setBody}
            insertVariable={insertVariable}
          />

          {messageTemplate && messageTemplate.documents.length > 0 && (
            <EmailDocumentsList documents={messageTemplate.documents} />
          )}

          <Box paddingTop={2}>
            <ActionButton
              icon={<Email />}
              label={t(
                tokens.automator.resolutions.dialogs.update_email_template.update_email_template
              )}
              onClick={handleUpdate}
              isLoading={isLoadingUpdateMessageTemplate}
              variant="contained"
              color="primary"
              size="small"
            />
          </Box>
        </Stack>
        <EmailVariableSelection onSelectVariable={setInsertVariable} />
      </Stack>
    </form>
  );
};

import { useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { useCreateRetailer } from '../../../../api/automator/retailers/useCreateRetailer';
import ActionButton from '../../../../components/ActionButton';
import { toast } from 'react-hot-toast';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';

interface AddRetailerFormProps {
  onClick?: () => void;
}

const AddRetailerForm = ({ onClick }: AddRetailerFormProps) => {
  const { t } = useTranslation();

  const { mutate, isLoading } = useCreateRetailer();

  const openInfoDialog = useOpenDialog(DialogId.INFO);

  const [formData, setFormData] = useState({
    client_id: '',
    secret: '',
  });

  const handleChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const doAddRetailer = () => {
    mutate(formData, {
      onSuccess: () => {
        toast.success(tokens.automator.retailers.add_retailer.add_retailer_success);
        onClick?.();
        openInfoDialog({
          title: tokens.automator.tutorials.retailer_added_tutorial_title,
          message: tokens.automator.tutorials.retailer_added_tutorial_message,
          buttons: [
            {
              label:
                tokens.automator.tutorials.retailer_added_download_return_scanner_app_button_label,
              onClick: () => {
                window.open(
                  'https://blue-engine.tawk.help/article/retourscanner-app-downloaden',
                  '_blank'
                );
              },
            },
            {
              label:
                tokens.automator.tutorials.retailer_added_activate_customer_questions_button_label,
              onClick: () => {
                window.open(
                  'https://blue-engine.tawk.help/article/activeren-bolcom-klantvragen',
                  '_blank'
                );
              },
            },
          ],
        });
      },
    });
  };

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      gap={2}
    >
      <Typography
        variant="h6"
        gutterBottom
      >
        {t(tokens.automator.retailers.add_retailer.add_retailer_explanation)}
      </Typography>

      <TextField
        fullWidth
        label={t(tokens.automator.retailers.add_retailer.client_id)}
        name="client_id"
        value={formData.client_id}
        onChange={handleChange}
        variant="outlined"
        margin="normal"
      />

      <TextField
        fullWidth
        label={t(tokens.automator.retailers.add_retailer.secret)}
        name="secret"
        value={formData.secret}
        onChange={handleChange}
        variant="outlined"
        margin="normal"
        type="password"
      />

      <ActionButton
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => {
          doAddRetailer();
        }}
        isLoading={isLoading}
        label={t(tokens.common.add)}
      />
    </Box>
  );
};

export default AddRetailerForm;

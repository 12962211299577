import { QueryKey, useMutation, useQueryClient } from 'react-query';
import { AxiosInstance } from 'axios';

export const usePost = <U, T = void>(
  axiosInstance: AxiosInstance,
  url: string,
  queryKeys?: QueryKey[],
  getData?: () => T,
  onSuccess?: (data: U) => void,
  updateQuery?: (oldData: U, newData: U) => U
) => {
  const queryClient = useQueryClient();

  const postData = async (data?: T) => {
    const actualData = getData?.() || data;
    const serializedData = actualData ? JSON.stringify(actualData) : {};
    const response = await axiosInstance.post(url, serializedData);
    return response.data as U;
  };

  return useMutation(postData, {
    onSuccess: (newData) => {
      if (updateQuery && queryKeys && queryKeys.length > 0) {
        queryKeys.forEach((queryKey) => {
          queryClient.setQueryData(queryKey, (oldData: U | undefined) => {
            if (!oldData) return newData;
            return updateQuery(oldData, newData);
          });
        });
      } else if (queryKeys && queryKeys.length > 0) {
        // When no updateQuery is given then just flush the query cache, this is the default behavior.
        queryKeys.forEach((queryKey) => queryClient.invalidateQueries(queryKey).then());
      } else if (queryKeys?.length === 0) {
        //
      } else {
        // When no query key is provided then all queries will be invalidated.
        queryClient.invalidateQueries(undefined).then();
      }

      onSuccess?.(newData);
    },
  });
};

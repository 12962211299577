import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { toast } from 'react-hot-toast';
import { ActionIcon } from '../../orders/components/ActionIcon';
import { ThumbUp } from '@mui/icons-material';
import { useStillAcceptReturnProcessingResult } from '../../../../api/automator/returns/useStillAcceptReturnProcessingResult';
import ReturnProcessingResult from '../../../../domain/automator/returns/ReturnProcessingResult';
import { ProcessingResult } from '../../../../domain/automator/returns/ProcessingResult';
import { HandlingResult } from '../../../../domain/automator/returns/HandlingResult';

interface StillAcceptReturnProcessingResultButton {
  returnProcessingResult: ReturnProcessingResult;
}

const StillAcceptReturnProcessingResultButton = ({
  returnProcessingResult,
}: StillAcceptReturnProcessingResultButton) => {
  const { t } = useTranslation();
  const { mutate: stillAcceptReturnProcessingResult, isLoading } =
    useStillAcceptReturnProcessingResult(returnProcessingResult.id);

  const canStilAccept = (returnProcessingResult: ReturnProcessingResult) => {
    if (returnProcessingResult.processingResult == ProcessingResult.CANCELLED) {
      return true;
    }

    const allowedHandlingResults = [
      HandlingResult.EXCHANGE_PRODUCT,
      HandlingResult.RETURN_DOES_NOT_MEET_CONDITIONS,
      HandlingResult.REPAIR_PRODUCT,
      HandlingResult.CUSTOMER_KEEPS_PRODUCT_PAID,
    ];

    return allowedHandlingResults.includes(returnProcessingResult.handlingResult);
  };

  const onSave = () => {
    stillAcceptReturnProcessingResult(undefined, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.returns.actions.processing_result_still_accepted));
      },
    });
  };

  if (!canStilAccept(returnProcessingResult)) {
    return null;
  }

  return (
    <ActionIcon
      tooltip={t(tokens.automator.returns.actions.still_accept)}
      icon={<ThumbUp style={{ color: 'WHITE' }} />}
      isRectangular={true}
      backgroundColor={'GREEN'}
      onClick={() => onSave()}
      isLoading={isLoading}
    />
  );
};

export default StillAcceptReturnProcessingResultButton;

import Stack from '@mui/material/Stack';
import { tokens } from '../../../../locales/translationTokens';
import CurrencyTextField from '../../shared/CurrencyTextField';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { ActionIcon } from './ActionIcon';
import { useTranslation } from 'react-i18next';

interface UpdateOfferPriceStackProps {
  unitPrice: number;
  onUpdateUnitPrice: (cents: number) => void;
}

export const UpdateOfferPriceStack = ({
  unitPrice,
  onUpdateUnitPrice,
}: UpdateOfferPriceStackProps) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      alignItems="center"
    >
      <CurrencyTextField
        value={unitPrice}
        onChange={(cents: number) => onUpdateUnitPrice(cents)}
        textFieldProps={{ variant: 'outlined', size: 'small', style: { width: 100 } }}
      />

      <Stack direction="row">
        <ActionIcon
          iconSize={20}
          icon={<KeyboardDoubleArrowUpIcon />}
          onClick={() => onUpdateUnitPrice(unitPrice + 100)}
          tooltip={t(tokens.automator.orders.dialogs.update_offer.one_euro_up)}
        />
        <ActionIcon
          iconSize={20}
          icon={<KeyboardDoubleArrowDownIcon />}
          onClick={() => onUpdateUnitPrice(unitPrice - 100)}
          tooltip={t(tokens.automator.orders.dialogs.update_offer.one_euro_up)}
        />
      </Stack>
    </Stack>
  );
};

import ReducedPickSession from '../../../../domain/automator/pickSessions/ReducedPickSession';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { usePaths } from '../../../../routes/usePaths';
import { PictureAsPdf } from '@mui/icons-material';
import ActionButton from '../../../../components/ActionButton';

interface NavigateToPickSessionButtonProps {
  pickSession: ReducedPickSession;
}

const NavigateToPickSessionButton = ({ pickSession }: NavigateToPickSessionButtonProps) => {
  const { t } = useTranslation();

  const paths = usePaths();

  return (
    <ActionButton
      icon={<PictureAsPdf />}
      label={t(tokens.automator.pick_sessions.buttons.go_to_pick_session)}
      onClick={() => {
        const url = `${window.location.origin}${paths.automator.pick_sessions}/${pickSession.id}`;
        window.open(url, '_blank');
      }}
      variant="text"
    />
  );
};

export default NavigateToPickSessionButton;

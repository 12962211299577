import { SHIPMENTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';

export const useLoadShipmentEvents = () => {
  const url = useAutomatorApiAccountUrl(SHIPMENTS_PATH + '/' + 'load-events');

  return useAuthorizedPost<undefined, LoadShipmentEventsData>(url);
};

export interface LoadShipmentEventsData {
  shipment_ids: number[];
}

import {SvgIcon, SvgIconProps} from '@mui/material';

export const ReturnToSenderIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 21 21">
      <title>SVG-ReturnToSender</title>
      <path
        id="Shape 21"
        fillRule="evenodd"
        d="m21 15.1c0 1.1-0.7 2-1.6 2h-0.5c0 2.1-1.4 3.9-3.1 3.9-1.8 0-3.2-1.8-3.2-3.9h-4.2c0 2.1-1.4 3.9-3.1 3.9-1.8 0-3.2-1.8-3.2-3.9h-1c-0.6 0-1.1-0.6-1.1-1.3 0-0.8 0.5-1.4 1.1-1.4v-4.7c0-0.7 0.2-1.3 0.6-1.8l2.5-3.2c0.4-0.5 0.9-0.8 1.5-0.8h1.6v-1.9c0-1.1 0.8-2 1.6-2h10.5c0.9 0 1.6 0.9 1.6 2zm-13.7-8.6h-1.6l-2.5 3.2v0.8h4.1zm9.6 11.9c0.3-0.4 0.4-0.9 0.4-1.4 0-0.5-0.1-1-0.4-1.4-0.3-0.4-0.7-0.6-1.1-0.6-0.5 0-0.9 0.2-1.2 0.6-0.3 0.4-0.4 0.9-0.4 1.4 0 0.5 0.1 1 0.4 1.4 0.3 0.3 0.7 0.5 1.2 0.5 0.4 0 0.8-0.2 1.1-0.5zm-12.8 0c0.3 0.3 0.7 0.5 1.2 0.5 0.4 0 0.8-0.2 1.1-0.5 0.3-0.4 0.4-0.9 0.4-1.4 0-0.5-0.1-1-0.4-1.4-0.3-0.4-0.7-0.6-1.1-0.6-0.5 0-0.9 0.2-1.2 0.6-0.3 0.4-0.4 0.9-0.4 1.4 0 0.5 0.1 1 0.4 1.4zm5.8-11.3c-0.3 0.4-0.3 1 0 1.4l2.7 3.3c0.3 0.3 0.8 0.3 1.1 0 0.3-0.4 0.3-1 0-1.4l-1.3-1.6h5.4c0.5 0 0.8-0.5 0.8-1 0-0.6-0.3-1-0.8-1h-5.4l1.3-1.6c0.3-0.4 0.3-1 0-1.4-0.3-0.4-0.8-0.4-1.1 0z"
      />
    </SvgIcon>
  );
};

import { RETURN_REASON_REPORTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { saveAs } from 'file-saver';

export const useDownloadReturnReasonReportCsv = () => {
  const url = useAutomatorApiAccountUrl(RETURN_REASON_REPORTS_PATH);

  const onSuccess = (csv: string) => {
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'return_report.csv');
  };

  return useAuthorizedPost<string, DownloadReturnReasonReportCsvData>(
    url,
    [],
    undefined,
    onSuccess
  );
};

export interface DownloadReturnReasonReportCsvData {
  start_date: string;
  end_date: string;
}

export enum TransportEventCode {
  PRE_ANNOUNCED = 'PRE_ANNOUNCED',
  AT_TRANSPORTER = 'AT_TRANSPORTER',
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERED_AT_NEIGHBOURS = 'DELIVERED_AT_NEIGHBOURS',
  DELIVERED_AT_CUSTOMER = 'DELIVERED_AT_CUSTOMER',
  PICKED_UP_AT_PICK_UP_POINT = 'PICKED_UP_AT_PICK_UP_POINT',
  AT_PICK_UP_POINT = 'AT_PICK_UP_POINT',
  RETURNED_TO_SENDER = 'RETURNED_TO_SENDER',
  INBOUND_COLLECT = 'INBOUND_COLLECT',
}

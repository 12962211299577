export const getDateStringFromDate = (date: Date, withYear: boolean = true) => {
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  if (withYear) {
    return `${year}-${month}-${day}`;
  } else {
    return `${day}-${month}`;
  }
};

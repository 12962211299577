import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/translationTokens';
import { Scrollbar } from '../../../../../../../devias/src/components/scrollbar';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { AdGroupSearchTermPerformanceSearchTermTableRow } from './AdGroupSearchTermPerformanceSearchTermTableRow';
import SearchTerm from '../../../../../../domain/pd/SearchTerm';
import { useFetchAdGroupSearchTermPerformanceSearchTerms } from '../../../../../../api/pd/adPerformances/useFetchAdGroupSearchTermPerformanceSearchTerms';
import getYesterday from '../../../../../../helpers/getYesterday';
import { LatestDays } from '../LatestDaysDropdownSelect';
import getLatestDateByLatestDays from '../../../../../../helpers/getLatestDateByLatestDays';
import { useState } from 'react';
import { useFetchShareOfVoiceAverages } from '../../../../../../api/pd/adPerformances/useFetchShareOfVoiceAverages';

interface AdGroupSearchTermPerformanceSearchTermsListTableProps {
  onSelect: (searchTerm: SearchTerm) => void;
  selected: SearchTerm | undefined;
  campaignId: number;
  adGroupId: number;
  latestDays: LatestDays;
}

export const AdGroupSearchTermPerformanceSearchTermsListTable = ({
  onSelect,
  selected,
  campaignId,
  adGroupId,
  latestDays,
}: AdGroupSearchTermPerformanceSearchTermsListTableProps) => {
  const [showAll, setShowAll] = useState<boolean>(false);

  const { t } = useTranslation();
  const {
    data: adGroupSearchTermPerformanceSearchTerms,
    isLoading: isLoadingAdGroupSearchTermPerformanceSearchTerms,
  } = useFetchAdGroupSearchTermPerformanceSearchTerms(
    adGroupId,
    getLatestDateByLatestDays(latestDays),
    getYesterday()
  );

  const { data: shareOfVoiceSearchTerms, isLoading: isLoadingShareOfVoiceSearchTerms } =
    useFetchShareOfVoiceAverages(campaignId, getLatestDateByLatestDays(latestDays), getYesterday());

  if (isLoadingAdGroupSearchTermPerformanceSearchTerms || isLoadingShareOfVoiceSearchTerms) {
    return <div>Loading...</div>;
  }

  return (
    <Scrollbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              {t(
                tokens.pd.performance.ad_group_search_term_performance_search_terms_table
                  .search_term
              )}
            </TableCell>
            <TableCell>
              {t(
                tokens.pd.performance.ad_group_search_term_performance_search_terms_table
                  .total_impressions
              )}
            </TableCell>

            <TableCell>
              {t(
                tokens.pd.performance.ad_group_search_term_performance_search_terms_table
                  .total_clicks
              )}
            </TableCell>

            <TableCell>
              {t(
                tokens.pd.performance.ad_group_search_term_performance_search_terms_table
                  .impressions_share
              )}
            </TableCell>

            <TableCell>
              {t(
                tokens.pd.performance.ad_group_search_term_performance_search_terms_table
                  .click_share
              )}
            </TableCell>

            <TableCell>
              {t(
                tokens.pd.performance.ad_group_search_term_performance_search_terms_table
                  .average_ctr
              )}
            </TableCell>

            <TableCell>
              {t(
                tokens.pd.performance.ad_group_search_term_performance_search_terms_table
                  .total_conversions
              )}
            </TableCell>

            <TableCell>
              {t(
                tokens.pd.performance.ad_group_search_term_performance_search_terms_table
                  .average_conversion_rate
              )}
            </TableCell>

            <TableCell>
              {t(
                tokens.pd.performance.ad_group_search_term_performance_search_terms_table
                  .total_sales
              )}
            </TableCell>

            <TableCell>
              {t(
                tokens.pd.performance.ad_group_search_term_performance_search_terms_table
                  .total_spent
              )}
            </TableCell>

            <TableCell>
              {t(
                tokens.pd.performance.ad_group_search_term_performance_search_terms_table
                  .average_acos
              )}
            </TableCell>

            <TableCell>
              {t(
                tokens.pd.performance.ad_group_search_term_performance_search_terms_table
                  .average_cpc
              )}
            </TableCell>

            <TableCell>
              {t(
                tokens.pd.performance.ad_group_search_term_performance_search_terms_table
                  .average_winning_bid_average
              )}
            </TableCell>

            <TableCell>
              {t(
                tokens.pd.performance.ad_group_search_term_performance_search_terms_table
                  .total_search_volume
              )}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {adGroupSearchTermPerformanceSearchTerms!.adGroupSearchTermPerformanceSearchTerms
            .filter((adGroupSearchTermPerformanceSearchTerm) => {
              return (
                showAll ||
                adGroupSearchTermPerformanceSearchTerm.totalImpressions >= 50 ||
                adGroupSearchTermPerformanceSearchTerm.totalConversions > 0 ||
                adGroupSearchTermPerformanceSearchTerm.totalSalesInCents > 0 ||
                adGroupSearchTermPerformanceSearchTerm.totalClicks > 0
              );
            })
            .sort((a, b) => {
              return a.totalImpressions > b.totalImpressions ? -1 : 1;
            })
            .map((adGroupSearchTermPerformanceSearchTerm) => {
              return (
                <AdGroupSearchTermPerformanceSearchTermTableRow
                  onSelect={onSelect}
                  key={adGroupSearchTermPerformanceSearchTerm.searchTerm.id}
                  adGroupSearchTermPerformanceSearchTerm={adGroupSearchTermPerformanceSearchTerm}
                  shareOfVoiceAverages={shareOfVoiceSearchTerms?.shareOfVoiceAverages.filter(
                    (shareOfVoiceSearchTerm) =>
                      shareOfVoiceSearchTerm.searchTerm.id ===
                      adGroupSearchTermPerformanceSearchTerm.searchTerm.id
                  )}
                  isSelected={selected?.id === adGroupSearchTermPerformanceSearchTerm.searchTerm.id}
                />
              );
            })}

          {!showAll ? (
            <TableRow
              style={{ cursor: 'pointer', backgroundColor: '#98c9ea', color: 'white' }}
              onClick={() => setShowAll(true)}
            >
              <TableCell colSpan={6}>SHOW ALL</TableCell>
            </TableRow>
          ) : (
            <TableRow
              style={{ cursor: 'pointer', backgroundColor: '#eaaba4', color: 'white' }}
              onClick={() => setShowAll(false)}
            >
              <TableCell colSpan={6}>HIDE</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Scrollbar>
  );
};

import { Table, TableCell, TableHead, TableRow } from '@mui/material';
import Shipment from '../../../../domain/automator/shipments/Shipment';
import { tokens } from '../../../../locales/translationTokens';
import ShipmentRow from './ShipmentRow';
import { TableLoading } from '../../../../components/TableLoading';
import { TableEmpty } from '../../../../components/TableEmpty';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import { t } from 'i18next';
import LoadShipmentEventsReport from '../../../../domain/automator/shipments/LoadShipmentEventsReport';

interface ShipmentsTableProps {
  isLoading: boolean;
  shipments?: Shipment[];
  loadShipmentEventsReport?: LoadShipmentEventsReport;
}

const ShipmentsTable = ({
  shipments,
  loadShipmentEventsReport,
  isLoading,
}: ShipmentsTableProps) => {
  if (isLoading || !shipments || !loadShipmentEventsReport) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (shipments!.length == 0) {
    return <TableEmpty message={t(tokens.automator.shipments.no_items)} />;
  }

  return (
    <Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t(tokens.automator.shipments.customer)}</TableCell>
            <TableCell></TableCell>
            <TableCell>{t(tokens.automator.shipments.internal_reference)}</TableCell>
            <TableCell>{t(tokens.automator.shipments.status.status)}</TableCell>
            <TableCell>{t(tokens.automator.shipments.expectedDeliveryDate)}</TableCell>
            <TableCell>{t(tokens.automator.shipments.transporter)}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {shipments.map((shipment, index) => (
            <ShipmentRow
              key={index}
              loadShipmentEventsReport={loadShipmentEventsReport}
              shipment={shipment}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default ShipmentsTable;

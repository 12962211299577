import { Helmet } from 'react-helmet-async';

interface SeoProps {
  title?: string;
}

export const Seo = ({ title }: SeoProps) => {
  const fullTitle = title ? title + ' | BlueEngine' : 'BlueEngine';

  return (
    <Helmet>
      <title>{fullTitle}</title>
    </Helmet>
  );
};

import { lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';

import { IssuerGuard } from 'devias/src/guards/issuer-guard';
import { GuestGuard } from 'devias/src/guards/guest-guard';
import { AuthLayout as AuthLayout } from 'src/layouts/auth/AuthLayout';
import { Issuer } from 'devias/src/utils/auth';

const LoginPage = lazy(() => import('src/pages/auth/LoginPage'));
const RegistrationPage = lazy(() => import('src/pages/auth/RegistrationPage'));
const RequestNewPasswordPage = lazy(() => import('src/pages/auth/RequestNewPasswordPage'));
const NewPasswordPage = lazy(() => import('src/pages/auth/NewPasswordPage'));

export const authRoutes: RouteObject[] = [
  {
    path: 'auth',
    element: (
      <IssuerGuard issuer={Issuer.JWT}>
        <GuestGuard>
          <AuthLayout>
            <Outlet />
          </AuthLayout>
        </GuestGuard>
      </IssuerGuard>
    ),
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'register',
        element: <RegistrationPage />,
      },
      {
        path: 'request-new-password',
        element: <RequestNewPasswordPage />,
      },
      {
        path: 'new-password',
        element: <NewPasswordPage />,
      },
    ],
  },
];

import * as React from 'react';
import { Widgets } from '@mui/icons-material';
import { tokens } from '../../../../locales/translationTokens';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import SpeedDialMenuItem from '../../shared/speeddial/SpeeddialMenuItem';
import { useFetchOrder } from '../../../../api/automator/orders/useFetchOrder';
import { useFetchOrderShipment } from '../../../../api/automator/orders/useFetchOrderShipment';
import { useFetchOrderReturns } from '../../../../api/automator/orders/useFetchOrderReturns';
import List from '@mui/material/List';

interface CreateReturnMenuProps {
  orderId: number;
}

//NOTE: This component is very similar to the list item in the speed dial, may need to merge the two.
export default function CreateReturnMenu({ orderId }: CreateReturnMenuProps) {
  const openCreateReturnDialog = useOpenDialog(DialogId.CREATE_RETURN);

  const { data: order, isLoading: isOrderLoading } = useFetchOrder(orderId);

  const { data: shipment, isLoading: isShipmentLoading } = useFetchOrderShipment(orderId);

  const { data: returns, isLoading: isReturnsLoading } = useFetchOrderReturns(orderId);

  console.log('nnn???!!');

  if (isOrderLoading || isShipmentLoading || isReturnsLoading) {
    return <></>;
  }

  const hasUnhandledReturns = returns!.some(
    (return_) =>
      //NOTE: rmaId is null if the return is manually created and the rmaId has not been set yet due to bol async processing.
      !return_.returnItems.some((returnItem) => !returnItem.isHandled && returnItem.rmaId != null)
  );

  return shipment && !hasUnhandledReturns ? (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <SpeedDialMenuItem
        icon={<Widgets />}
        label={tokens.automator.returns.dialogs.create_return.create_return}
        onClick={() => openCreateReturnDialog({ order: order! })}
      />
    </List>
  ) : (
    <> </>
  );
}

import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { toast } from 'react-hot-toast';
import { useCreateOfferShippingCosts } from '../../../../../../api/pd/offerPerformances/useCreateOfferShippingCosts';
import { tokens } from '../../../../../../locales/translationTokens';
import ActionButton from '../../../../../../components/ActionButton';

interface CreateOfferShippingCostsFormProps {
  onClick?: () => void;
  offerId: number;
}

export const CreateOfferShippingCostsForm = ({
  onClick,
  offerId,
}: CreateOfferShippingCostsFormProps) => {
  const [shippingCosts, setShippingCosts] = useState(0);

  const { t } = useTranslation();

  const { mutate: create, isLoading } = useCreateOfferShippingCosts();

  const onCreate = () => {
    create(
      {
        offer_id: offerId,
        shipping_costs_in_cents: shippingCosts,
        date: '2020-01-01',
      },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(
            t(tokens.pd.performance.create_offer_shipping_costs_form.shipping_costs_created)
          );
        },
      }
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <TextField
        id="note-field"
        label={t(tokens.pd.performance.create_offer_shipping_costs_form.shipping_costs)}
        variant="filled"
        fullWidth
        style={{ marginBottom: '1em' }}
        value={shippingCosts}
        onChange={(e) => setShippingCosts(Number(e.target.value))}
      />

      <ActionButton
        label={t(tokens.pd.performance.create_offer_shipping_costs_form.create_shipping_costs)}
        onClick={() => onCreate()}
        isLoading={isLoading}
        variant="contained"
        color="primary"
        size="small"
      />
    </form>
  );
};

import { tokens } from '../../../../../../locales/translationTokens';
import { useMemo } from 'react';

export interface FixedSeasonalEvent {
  name: string;
  startDate: Date;
  endDate?: Date;
  icon?: string;
}

export const useFixedSeasonalEvents = () => {
  return useMemo<FixedSeasonalEvent[]>(() => {
    const generateEvent = (
      name: string,
      startMonth: number,
      startDay: number,
      endMonth?: number,
      endDay?: number,
      icon?: string
    ): FixedSeasonalEvent => {
      const getDate = (month: number, day: number) => {
        const year = new Date().getFullYear();
        return new Date(`${year}-${month}-${day}`);
      };

      return {
        name: name,
        startDate: getDate(startMonth, startDay),
        endDate: endMonth && endDay ? getDate(endMonth, endDay) : undefined,
        icon,
      };
    };

    return [
      generateEvent(tokens.pd.performance.timeline_table.seasonals.new_years_day, 1, 1),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.valentines_day, 2, 14),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.international_womens_day, 3, 8),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.saint_patricks_day, 3, 17),
      generateEvent(
        tokens.pd.performance.timeline_table.seasonals.international_day_of_happiness,
        3,
        20
      ),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.april_fools_day, 4, 1),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.ascension_day, 5, 9),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.mothers_day, 5, 12),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.fathers_day, 6, 16),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.liberation_day, 5, 5),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.teachers_day, 10, 5),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.halloween, 10, 31),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.christmas_day, 12, 25),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.new_years_eve, 12, 31),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.saint_nicholas_day, 12, 5),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.good_friday, 3, 29),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.world_animal_day, 4, 10),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.eid_al_fitr, 4, 9),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.carnaval, 11, 2),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.first_pentecost_day, 5, 19),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.second_pentecost_day, 5, 20),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.feast_of_sacrifice, 6, 16),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.black_friday, 11, 29),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.cyber_monday, 12, 2),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.chinese_new_year, 2, 10),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.kings_day, 4, 27),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.secretary_day, 4, 18),
      generateEvent(tokens.pd.performance.timeline_table.seasonals.summer_break, 7, 6, 9, 1),
    ];
  }, []);
};

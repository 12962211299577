import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import { CompetitorProductPerformancesTable } from './tables/CompetitorProductPerformancesTable';
import { useFetchContestedCategories } from '../../../../../api/pd/competitorProductPerformances/useFetchContestedCategories';
import { LatestDays } from './LatestDaysDropdownSelect';
import { CountryCode } from '../../../../../domain/automator/orders/CountryCode';
import { ContestedCategoryDropdownSelect } from './ContestedCategoryDropdownSelect';
import { tokens } from '../../../../../locales/translationTokens';

interface CompetitorProductPerformancesCardProps {
  productId: number;
  latestDays: LatestDays;
  countryCode: CountryCode;
}

export const CompetitorProductPerformancesCard = ({
  productId,
  countryCode,
  latestDays,
}: CompetitorProductPerformancesCardProps) => {
  const { data: contestedCategories, isLoading: isLoadingContestedCategories } =
    useFetchContestedCategories(productId);

  if (isLoadingContestedCategories) {
    return <div>Loading...</div>;
  }

  const activeContestedCategory = contestedCategories?.contestedCategories.find(
    (contestedCategory) => contestedCategory.isActive
  );

  return (
    <Card>
      <Stack
        border={2}
        borderRadius={1}
        margin={1}
        padding={1}
        direction="column"
      >
        <Stack direction="row">
          <ContestedCategoryDropdownSelect productId={productId} />
        </Stack>

        {!activeContestedCategory ? (
          <div>{tokens.pd.performance.competitor_product_performances_table.no_data}</div>
        ) : (
          <CompetitorProductPerformancesTable
            categoryId={activeContestedCategory.category.id}
            countryCode={countryCode}
            latestDays={latestDays}
          />
        )}
      </Stack>
    </Card>
  );
};

import Chip from '@mui/material/Chip';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';

export const CancellationRequestChip = () => {
  const { t } = useTranslation();

  return (
    <Chip
      label={t(tokens.automator.orders.cancellation_request)}
      color="error"
      variant="filled"
      size="small"
    />
  );
};

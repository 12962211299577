import { CircularProgress, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';

import { DialogId } from '../../../../store/dialogs/DialogId';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { useSearchOrder } from '../../../../api/automator/orders/useSearchOrder';

export const OrderSummarySearch = () => {
  const [inputValue, setInputValue] = useState('');
  const { data: order, isLoading } = useSearchOrder(inputValue);
  const openDialog = useOpenDialog(DialogId.ORDER_SUMMARY);
  const theme = useTheme();

  const { t } = useTranslation();

  useEffect(() => {
    if (order) {
      openDialog({ query: inputValue });
      setInputValue('');
    }
  }, [order]);

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter' && order) {
      openDialog({ query: inputValue });
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 40,
          height: 40,
          backgroundColor: theme.palette.primary.main,
        }}
      >
        {isLoading ? (
          <CircularProgress
            size={20}
            color="inherit"
          />
        ) : (
          <SearchIcon style={{ color: 'white' }} />
        )}
      </Box>
      <InputBase
        sx={{
          flex: 1,
          borderColor: theme.palette.primary.main,
          borderWidth: 4,
          borderStyle: 'solid',
          paddingLeft: 1.5,
        }}
        value={inputValue}
        placeholder={t(tokens.automator.order_summary.search_here) as string}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        inputProps={{
          maxLength: 10,
          inputMode: 'numeric',
          pattern: '^[0-9]*$',
        }}
      />
    </Stack>
  );
};

import * as React from 'react';
import { useState } from 'react';
import Popover, { PopoverOrigin } from '@mui/material/Popover';
import { Box } from '@mui/system';

interface PopoverButtonProps {
  id: string;
  button: React.ReactNode;
  content: React.ReactNode;
}

export default function PopoverButton({ id, button, content }: PopoverButtonProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorOrigin, setAnchorOrigin] = useState({ vertical: 'top', horizontal: 'left' });
  const [transformOrigin, setTransformOrigin] = useState({
    vertical: 'bottom',
    horizontal: 'left',
  });

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    const spaceAtBottom = window.innerHeight - event.clientY;
    if (spaceAtBottom < 300) {
      setAnchorOrigin({ vertical: 'top', horizontal: 'left' });
      setTransformOrigin({ vertical: 'bottom', horizontal: 'left' });
    } else {
      setAnchorOrigin({ vertical: 'bottom', horizontal: 'left' });
      setTransformOrigin({ vertical: 'top', horizontal: 'left' });
    }
  };

  const handleClose = (event: React.MouseEvent<any>) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        onClick={(e) => handleClick(e)}
        sx={{ position: 'relative', display: 'inline-block', color: 'transparent' }}
      >
        {button}
      </Box>
      <Popover
        onClick={handleClose}
        id={open ? id : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin as PopoverOrigin}
        transformOrigin={transformOrigin as PopoverOrigin}
      >
        {content}
      </Popover>
    </>
  );
}

import type { ApexOptions } from 'apexcharts';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { useTheme } from '@mui/material/styles';

import { Chart } from 'devias/src/components/chart';

export interface DateLineChartSeries {
  name: string;
  data: (number | null)[];
  invertY?: boolean;
  yMin?: number;
  yMax?: number;
}

interface DateLineChartProps {
  header: string;
  categories: string[];
  chartSeries: DateLineChartSeries[];
}

export const DateLineChart = ({ header, categories, chartSeries }: DateLineChartProps) => {
  const chartOptions = useChartOptions(categories, chartSeries);

  return (
    <Card>
      <CardHeader title={header} />
      <CardContent>
        <Chart
          height={300}
          options={chartOptions}
          series={chartSeries}
          type="line"
        />
      </CardContent>
    </Card>
  );
};

const useChartOptions = (categories: string[], chartSeries: DateLineChartSeries[]): ApexOptions => {
  const theme = useTheme();

  const yaxis = chartSeries.map((series) => {
    return {
      min: series.yMin,
      max: series.yMax,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      reversed: series.invertY || false,
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
      opposite: series.invertY || false,
    };
  });

  return {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    colors: [
      theme.palette.primary.main,
      theme.palette.warning.main,
      'red',
      'blue',
      'green',
      'yellow',
    ],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
      type: 'solid',
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      horizontalAlign: 'right',
      labels: {
        colors: theme.palette.text.secondary,
      },
      position: 'top',
      show: true,
    },
    markers: {
      hover: {
        size: undefined,
        sizeOffset: 2,
      },
      radius: 2,
      shape: 'circle',
      size: 4,
      strokeWidth: 0,
    },
    stroke: {
      curve: 'smooth',
      dashArray: [0, 3],
      lineCap: 'butt',
      width: 3,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: categories,
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: yaxis,
  };
};

import { useAuthorizedPost } from '../../useAuthorizedPost';
import { PRODUCT_REORDER_INTERVAL_PATH } from '../pdApiPaths';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';

export const useCreateProductReorderInterval = () => {
  const url = usePdApiAccountUrl(PRODUCT_REORDER_INTERVAL_PATH);
  return useAuthorizedPost<undefined, CreateProductReorderIntervalData>(url, undefined, undefined);
};

export interface CreateProductReorderIntervalData {
  product_id: number;
  date: string;
  amount_of_days: number;
}

import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import { Redo } from '@mui/icons-material';
import { toast } from 'react-hot-toast';
import { useCreateResend } from '../../../../api/automator/resolutions/useCreateResend';
import * as React from 'react';
import { useDismissProposedActions } from '../../../../api/automator/alerts/useDismissProposedActions';
import { UpdateOrderNoteForm } from './UpdateOrderNoteForm';
import Stack from '@mui/material/Stack';

interface CreateResendFormProps {
  onClick?: () => void;
  orderId: number;
  alertId?: number;
}

export const CreateResendForm = ({ onClick, orderId, alertId }: CreateResendFormProps) => {
  const { t } = useTranslation();

  const { mutate: create, isLoading } = useCreateResend();

  const { mutate: dismissAlert } = useDismissProposedActions();

  const onCreate = () => {
    create(
      {
        order_id: orderId,
      },
      {
        onSuccess: async () => {
          toast.success(t(tokens.automator.resolutions.dialogs.create_resend.resend_created));
          alertId && dismissAlert({ alert_ids: [alertId] }, {});
          onClick?.();
        },
      }
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <Stack gap={1}>
        <UpdateOrderNoteForm orderId={orderId} />

        <ActionButton
          icon={<Redo />}
          label={t(tokens.automator.resolutions.dialogs.create_resend.create_resend)}
          onClick={() => onCreate()}
          isLoading={isLoading}
          variant="contained"
          color="primary"
          size="small"
        />
      </Stack>
    </form>
  );
};

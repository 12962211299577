class JtiStorageService {
  private static JTI = 'jti';

  static setJti(jti: string) {
    localStorage.setItem(this.JTI, jti);
  }

  static getJti() {
    return localStorage.getItem(this.JTI);
  }

  static clearJti() {
    localStorage.removeItem(this.JTI);
  }
}

export default JtiStorageService;

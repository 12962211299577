import * as React from 'react';
import { useState } from 'react';
import { AddCircle } from '@mui/icons-material';
import { ActionIcon } from '../../orders/components/ActionIcon';
import SpeedDialMenu from './SpeedDialMenu';
import { Box } from '@mui/system';
import { colors } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import PopoverButton from '../PopoverButton';

interface SpeedDialActionIconProps {
  orderId: number;
  orderItemId?: number;
  hasNote: boolean;
}

export default function SpeedDialActionIcon({
  orderId,
  orderItemId,
  hasNote: initialHasNote,
}: SpeedDialActionIconProps) {
  const [hasNote, setHasNote] = useState(initialHasNote);

  const { t } = useTranslation();

  return (
    <PopoverButton
      id={'speed-dial'}
      button={
        <Box sx={{ position: 'relative', display: 'inline-block', color: 'transparent' }}>
          <ActionIcon
            icon={<AddCircle />}
            tooltip={t(tokens.automator.speed_dial.speed_dial)}
            color={'primary'}
            iconSize={30}
          />
          {hasNote && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                width: 14,
                height: 14,
                bgcolor: colors.green[400],
                borderRadius: '50%',
                border: '1px solid white',
              }}
            />
          )}
        </Box>
      }
      content={
        <SpeedDialMenu
          orderId={orderId}
          orderItemId={orderItemId}
          onUpdateNote={(note: string) => setHasNote(note != null && note != '')}
        />
      }
    />
  );
}

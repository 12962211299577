import { ORDER_NOTES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import ResolutionDossiers from '../../../domain/automator/resolutionDossiers/ResolutionDossiers';
import { OrderNoteQueryKey } from '../../queryKeys/OrderNoteQueryKey';

export const useUpdateOrderNote = (orderId: number, orderNoteId: number) => {
  const url = useAutomatorApiAccountUrl(ORDER_NOTES_PATH + '/' + orderNoteId);

  return useAuthorizedPost<ResolutionDossiers, UpdateOrderNoteDate>(
    url,
    [OrderNoteQueryKey(orderId)],
    undefined
  );
};

export interface UpdateOrderNoteDate {
  note: string;
}

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';
import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { RangeStatic } from 'quill';

interface HtmlEditorProps {
  content: string;
  onChange: (value: string) => void;
  insertVariable: string | null;
}

const HtmlEditor = ({ content, onChange, insertVariable }: HtmlEditorProps) => {
  const quillRef = useRef<ReactQuill>(null);

  const [value, setValue] = useState<string>(content);

  useEffect(() => {
    setValue(content);
  }, [content]);

  const [currentSelection, setCurrentSelection] = useState<RangeStatic | null>(null);

  useEffect(() => {
    if (insertVariable && quillRef.current != null && currentSelection) {
      const quillInstance = quillRef.current.getEditor();
      quillInstance.focus();
      quillInstance.insertText(currentSelection.index, insertVariable);
      quillInstance.setSelection({
        index: currentSelection.index + insertVariable.length,
        length: 0,
      });
      onChange(quillInstance.root.innerHTML);
    }
  }, [insertVariable]);

  const handleChange = (newValue: string, source: string) => {
    setValue(newValue);

    if (source === 'api' && value != newValue) {
      // this is to prevent an outside change from triggering the onchange
      return;
    }

    if (quillRef.current != null) {
      const quillInstance = quillRef.current.getEditor();
      const newContent = quillInstance.root.innerHTML;
      const sanitizedContent = DOMPurify.sanitize(newContent);
      onChange(sanitizedContent);
    }
  };

  const modules = {
    toolbar: {
      container: [[{ header: [1, 2, 3, false] }], ['bold', 'italic', 'underline']],
    },
  };

  return (
    <Box minHeight={200}>
      <ReactQuill
        style={{ height: '80%' }}
        ref={quillRef}
        value={value}
        modules={modules}
        onChange={(value, _, source) => {
          handleChange(value, source);
        }}
        onChangeSelection={(range) => {
          if (range) {
            setCurrentSelection(range);
          }
        }}
      />
    </Box>
  );
};

export default HtmlEditor;

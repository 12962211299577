import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { TableEmpty } from '../../../../components/TableEmpty';
import EventRule from '../../../../domain/automator/eventRules/EventRule';
import { AutoMailEventRuleTableRow } from './AutoMailEventRuleTableRow';
import groupBy from '../../../../helpers/groupBy';
import { AutoMailTriggerGroupTableRow } from './AutoMailTriggerGroupTableRow';
import EventTrigger from '../../../../domain/automator/eventRules/EventTrigger';
import { EventRuleStatus } from '../../../../domain/automator/eventRules/EventRuleStatus';
import { useState } from 'react';

interface AutoMailEventRulesListTableProps {
  isLoading: boolean;
  eventRules?: EventRule[] | null;
  totalAmountOfProducts?: number | null;
}

export const AutoMailEventRulesListTable = ({
  eventRules,
  isLoading,
  totalAmountOfProducts,
}: AutoMailEventRulesListTableProps) => {
  const [selectedTrigger, setSelectedTrigger] = useState<EventTrigger | null>(null);

  const { t } = useTranslation();

  if (isLoading) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (eventRules!.length == 0) {
    return <TableEmpty message={t(tokens.automator.auto_mail.no_items)} />;
  }

  const groupedEventRules = groupBy(eventRules!, (eventRule) => {
    return eventRule.trigger;
  });

  const determineAmountOfProducts = (eventRules: EventRule[]): number => {
    if (
      eventRules.some(
        (eventRule) =>
          eventRule.products.length == 0 && eventRule.status != EventRuleStatus.DISABLED
      )
    ) {
      return totalAmountOfProducts || 0;
    }

    return eventRules.reduce((acc, eventRule) => {
      return (eventRule.status == EventRuleStatus.DISABLED ? 0 : eventRule.products.length) + acc;
    }, 0);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t(tokens.automator.auto_mail.trigger)}</TableCell>
              <TableCell>{t(tokens.automator.auto_mail.product)}</TableCell>
              <TableCell />
              <TableCell>{t(tokens.automator.auto_mail.conditions)}</TableCell>
              <TableCell>{t(tokens.automator.auto_mail.delay_type.delay)}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.entries(groupedEventRules).map(([trigger, eventRules]) => {
              return (
                <>
                  <AutoMailTriggerGroupTableRow
                    trigger={trigger as EventTrigger}
                    amountOfProducts={determineAmountOfProducts(eventRules)}
                    totalAmountOfProducts={totalAmountOfProducts!}
                    onClick={() =>
                      setSelectedTrigger(
                        trigger === selectedTrigger ? null : (trigger as EventTrigger)
                      )
                    }
                    amountOfActiveEventRules={
                      eventRules.filter((eventRule) => eventRule.status != EventRuleStatus.DISABLED)
                        .length
                    }
                    isSelected={selectedTrigger == trigger}
                  />

                  {selectedTrigger &&
                    selectedTrigger == trigger &&
                    eventRules.map((eventRule) => {
                      return (
                        <AutoMailEventRuleTableRow
                          key={eventRule.id}
                          eventRule={eventRule}
                        />
                      );
                    })}
                </>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};

import { REIMBURSEMENT_REQUESTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import ReimbursementRequest from '../../../domain/automator/reimbursements/ReimbursementRequest';
import { ReimbursementRequestsQueryKey } from '../../queryKeys/ReimbursementRequestsQueryKey';

export const useDismissReimbursementRequest = (reimbursementRequestId: number) => {
  const url = useAutomatorApiAccountUrl(
    REIMBURSEMENT_REQUESTS_PATH + '/' + reimbursementRequestId + '/dismiss'
  );
  return useAuthorizedPost<ReimbursementRequest, undefined>(url, [
    ReimbursementRequestsQueryKey(true),
    ReimbursementRequestsQueryKey(false),
  ]);
};

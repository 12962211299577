import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { PricingPlanIcon } from 'devias/src/sections/pricing/pricing-plan-icon';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../locales/translationTokens';
import { PricingPlan } from './PricingPlan';
import { useFetchAccount } from '../../../api/automator/account/useFetchAccount';

const PricingTablePage = () => {
  const { t } = useTranslation();

  const { data: account } = useFetchAccount();

  if (!account || account.subscription) {
    return <></>;
  }

  return (
    <>
      <Box
        component="main"
        sx={{ flexGrow: 1 }}
      >
        <Box>
          <Container maxWidth="lg">
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                mb: 4,
              }}
            >
              <Typography variant="h3">{t(tokens.automator.pricing.title)}</Typography>
              <Typography
                color="text.secondary"
                sx={{ my: 2 }}
                variant="body1"
              >
                {t(tokens.automator.pricing.subtitle)}
              </Typography>
            </Box>
            <Grid
              container
              spacing={4}
            >
              <Grid
                xs={12}
                md={3}
              >
                <PricingPlan
                  cta={t(tokens.automator.pricing.subscribe)}
                  currency="€"
                  features={[
                    t(tokens.automator.pricing.tier_1.feature_1),
                    t(tokens.automator.pricing.tier_1.feature_2),
                  ]}
                  icon={<PricingPlanIcon name="startup" />}
                  name={t(tokens.automator.pricing.tier_1.title)}
                  price="10,00"
                  sx={{
                    height: '100%',
                    maxWidth: 460,
                    mx: 'auto',
                  }}
                />
              </Grid>
              <Grid
                xs={12}
                md={3}
              >
                <PricingPlan
                  cta={t(tokens.automator.pricing.subscribe)}
                  currency="€"
                  features={[
                    t(tokens.automator.pricing.tier_2.feature_1),
                    t(tokens.automator.pricing.tier_2.feature_2),
                  ]}
                  icon={<PricingPlanIcon name="standard" />}
                  name={t(tokens.automator.pricing.tier_2.title)}
                  price="20,00"
                  sx={{
                    height: '100%',
                    maxWidth: 460,
                    mx: 'auto',
                  }}
                  popular
                />
              </Grid>
              <Grid
                xs={12}
                md={3}
              >
                <PricingPlan
                  cta={t(tokens.automator.pricing.subscribe)}
                  currency="€"
                  features={[
                    t(tokens.automator.pricing.tier_3.feature_1),
                    t(tokens.automator.pricing.tier_3.feature_2),
                  ]}
                  icon={<PricingPlanIcon name="business" />}
                  name={t(tokens.automator.pricing.tier_3.title)}
                  price="30,00"
                  sx={{
                    height: '100%',
                    maxWidth: 460,
                    mx: 'auto',
                  }}
                />
              </Grid>

              <Grid
                xs={12}
                md={3}
              >
                <PricingPlan
                  cta={t(tokens.automator.pricing.subscribe)}
                  currency="€"
                  features={[
                    t(tokens.automator.pricing.tier_4.feature_1),
                    t(tokens.automator.pricing.tier_4.feature_2),
                  ]}
                  icon={<PricingPlanIcon name="business" />}
                  name={t(tokens.automator.pricing.tier_4.title)}
                  price="40,00"
                  sx={{
                    height: '100%',
                    maxWidth: 460,
                    mx: 'auto',
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default PricingTablePage;

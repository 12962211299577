import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

interface TableTabsProps<T> {
  value: T;
  tabOptions: TabOption<T>[];
  onChange: (value: T) => void;
}

export interface TabOption<T> {
  label: string;
  value: T;
  amount?: number;
}

export const TableTabs = <T,>(props: TableTabsProps<T>) => {
  const { value, tabOptions, onChange } = props;

  return (
    <Tabs
      indicatorColor="primary"
      onChange={(_, value) => {
        onChange(value);
      }}
      scrollButtons="auto"
      sx={{ px: 3 }}
      textColor="primary"
      value={value}
      variant="scrollable"
    >
      {tabOptions.map((tab) => (
        <Tab
          key={tab.value as unknown as string}
          label={tab.label + (tab.amount ? ` (${tab.amount})` : '')}
          value={tab.value}
        />
      ))}
    </Tabs>
  );
};

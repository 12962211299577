import { tokens } from '../../../locales/translationTokens';

export enum TransporterCode {
  //vvb
  TNT = 'TNT',
  TNT_BRIEF = 'TNT_BRIEF',
  RJP = 'RJP',
  DHLFORYOU = 'DHLFORYOU',
  BUDBEE = 'BUDBEE',
  // end vvb

  BRIEFPOST = 'BRIEFPOST',
  DHL_SD = 'DHL_SD',
  TNT_EXTRA = 'TNT_EXTRA',
  TNT_EXPRESS = 'TNT_EXPRESS',
  DYL = 'DYL',
  DPD_NL = 'DPD_NL',
  DPD_BE = 'DPD_BE',
  BPOST_BE = 'BPOST_BE',
  BPOST_BRIEF = 'BPOST_BRIEF',
  GLS = 'GLS',
  FEDEX_NL = 'FEDEX_NL',
  FEDEX_BE = 'FEDEX_BE',
  DHL_DE = 'DHL_DE',
  DHL_GLOBAL_MAIL = 'DHL_GLOBAL_MAIL',
  OTHER = 'OTHER',
  TSN = 'TSN',
  TRANSMISSION = 'TRANSMISSION',
  PARCEL_NL = 'PARCEL_NL',
  PACKS = 'PACKS',
  COURIER = 'COURIER',
  TRUNKRS = 'TRUNKRS',
  PES = 'PES',
  CYCLOON = 'CYCLOON',
  UPS = 'UPS',
  UNKNOWN = 'UNKNOWN',
}

export const TransporterCodeTokenMap = {
  undefined: tokens.automator.pick_sessions.transporter_codes.none,
  [TransporterCode.TNT]: tokens.automator.pick_sessions.transporter_codes.tnt,
  [TransporterCode.TNT_BRIEF]: tokens.automator.pick_sessions.transporter_codes.tnt_brief,
  [TransporterCode.RJP]: tokens.automator.pick_sessions.transporter_codes.rjp,
  [TransporterCode.BRIEFPOST]: tokens.automator.pick_sessions.transporter_codes.rjp,
  [TransporterCode.DHLFORYOU]: tokens.automator.pick_sessions.transporter_codes.dhlforyou,
  [TransporterCode.BUDBEE]: tokens.automator.pick_sessions.transporter_codes.budbee,
  [TransporterCode.DHL_SD]: tokens.automator.pick_sessions.transporter_codes.dhl_sd,
  [TransporterCode.TNT_EXTRA]: tokens.automator.pick_sessions.transporter_codes.tnt_extra,
  [TransporterCode.TNT_EXPRESS]: tokens.automator.pick_sessions.transporter_codes.tnt_express,
  [TransporterCode.DYL]: tokens.automator.pick_sessions.transporter_codes.dyl,
  [TransporterCode.DPD_NL]: tokens.automator.pick_sessions.transporter_codes.dpd_nl,
  [TransporterCode.DPD_BE]: tokens.automator.pick_sessions.transporter_codes.dpd_be,
  [TransporterCode.BPOST_BE]: tokens.automator.pick_sessions.transporter_codes.bpost_be,
  [TransporterCode.BPOST_BRIEF]: tokens.automator.pick_sessions.transporter_codes.bpost_brief,
  [TransporterCode.GLS]: tokens.automator.pick_sessions.transporter_codes.gls,
  [TransporterCode.FEDEX_NL]: tokens.automator.pick_sessions.transporter_codes.fedex_nl,
  [TransporterCode.FEDEX_BE]: tokens.automator.pick_sessions.transporter_codes.fedex_be,
  [TransporterCode.DHL_DE]: tokens.automator.pick_sessions.transporter_codes.dhl_de,
  [TransporterCode.DHL_GLOBAL_MAIL]:
    tokens.automator.pick_sessions.transporter_codes.dhl_global_mail,
  [TransporterCode.TSN]: tokens.automator.pick_sessions.transporter_codes.tsn,
  [TransporterCode.TRANSMISSION]: tokens.automator.pick_sessions.transporter_codes.transmission,
  [TransporterCode.PARCEL_NL]: tokens.automator.pick_sessions.transporter_codes.parcel_nl,
  [TransporterCode.PACKS]: tokens.automator.pick_sessions.transporter_codes.packs,
  [TransporterCode.COURIER]: tokens.automator.pick_sessions.transporter_codes.courier,
  [TransporterCode.TRUNKRS]: tokens.automator.pick_sessions.transporter_codes.trunkrs,
  [TransporterCode.PES]: tokens.automator.pick_sessions.transporter_codes.pes,
  [TransporterCode.CYCLOON]: tokens.automator.pick_sessions.transporter_codes.cycloon,
  [TransporterCode.UPS]: tokens.automator.pick_sessions.transporter_codes.ups,
  [TransporterCode.UNKNOWN]: tokens.automator.pick_sessions.transporter_codes.unknown,
  [TransporterCode.OTHER]: tokens.automator.pick_sessions.transporter_codes.other,
};

import { Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { Layout } from '../layouts/automator/Layout';
import PerformancesPage from '../pages/pd/pages/performances/PerformancesPage';
import ProductsPage from '../pages/pd/pages/products/ProductsPage';

export const pdRoutes: RouteObject[] = [
  {
    path: '/pd/accounts/:accountId',
    element: (
      <Layout>
        <Suspense>
          <Outlet />
        </Suspense>
      </Layout>
    ),
    children: [
      {
        path: 'products',
        element: <ProductsPage />,
      },
      {
        path: 'products/:productId/performances',
        element: <PerformancesPage />,
      },
    ],
  },
];

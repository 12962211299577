import OrderNoteTypography from '../../orders/components/OrderNoteTypography';
import { tokens } from '../../../../locales/translationTokens';
import { OrderSummaryCardItem } from './OrderSummaryCardItem';

interface OrderSummaryOrderNoteProps {
  orderId: number;
}

export const OrderSummaryOrderNoteCardItem = ({ orderId }: OrderSummaryOrderNoteProps) => {
  return (
    <OrderSummaryCardItem label={tokens.automator.order_summary.note}>
      <OrderNoteTypography orderId={orderId} />
    </OrderSummaryCardItem>
  );
};

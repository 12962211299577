import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import CustomerPortalSession from '../../../domain/automator/account/CustomerPortalSession';

export const useCreateCustomerPortalSession = () => {
  const url = useAutomatorApiAccountUrl('customer-portal');
  const { mutate } = useAuthorizedPost<CustomerPortalSession>(url, [[EndpointQueryKey.ACCOUNT]]);

  return async () => {
    return new Promise(() => {
      mutate(undefined, {
        onSuccess: (session) => {
          window.location.href = session.url;
        },
      });
    });
  };
};

import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { CreateReturnForm } from '../components/CreateReturnForm';
import ReducedOrder from '../../../../domain/automator/orders/ReducedOrder';

export const CreateReturnDialog = () => {
  const id = DialogId.CREATE_RETURN;
  const { isOpen, data } = useIsDialogOpen(id);
  const closeDialog = useCloseDialog(id);
  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          title={t(tokens.automator.returns.dialogs.create_return.create_return)}
        >
          <CreateReturnForm
            order={data.order}
            onClick={closeDialog}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface CreateReturnDialogData {
  order: ReducedOrder;
}

import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { MANUAL_SEASONAL_EVENTS } from '../pdApiPaths';

export const useCreateManualSeasonalEvent = () => {
  const url = usePdApiAccountUrl(MANUAL_SEASONAL_EVENTS);

  return useAuthorizedPost<undefined, CreateManualSeasonalEventData>(url, undefined, undefined);
};

export interface CreateManualSeasonalEventData {
  name: string;
  start_date: Date;
  end_date?: Date;
}

import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { PERFORMANCE_EVENTS } from '../pdApiPaths';
import { getDateStringFromDate } from '../../../helpers/getDateStringFromDate';
import PerformanceEvents from '../../../domain/pd/PerformanceEvents';
import { PerformanceEventsQueryKey } from '../../queryKeys/PerformanceEventsQueryKey';
import { CountryCode } from '../../../domain/automator/orders/CountryCode';

export const useFetchPerformanceEvents = (
  offerId: number,
  startDate: Date,
  endDate: Date,
  countryCode: CountryCode | null
) => {
  const url = usePdApiAccountUrl(
    PERFORMANCE_EVENTS +
      `?offerId=${offerId}` +
      `&startDate=${getDateStringFromDate(startDate)}` +
      `&endDate=${getDateStringFromDate(endDate)}` +
      (countryCode ? `&countryCode=${countryCode}` : '')
  );
  return useFetchData<PerformanceEvents>(
    url,
    PerformanceEventsQueryKey(offerId, startDate, endDate, countryCode)
  );
};

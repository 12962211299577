import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import SvgIcon from '@mui/material/SvgIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';

interface AccountPopoverMenuItemProps {
  onClick: () => void;
  icon: React.ReactNode;
  label: string;
}

export const AccountPopoverMenuItem = ({onClick, icon, label}: AccountPopoverMenuItemProps) => {
  const {t} = useTranslation();

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <SvgIcon>{icon}</SvgIcon>
      </ListItemIcon>
      <ListItemText
        primary={<Typography variant="subtitle2">{t(label)}</Typography>}
      />
    </MenuItem>
  );
};

import { useTranslation } from 'react-i18next';
import { ReactElement, ReactNode } from 'react';
import ActionButton from './ActionButton';
import Alert from '@mui/material/Alert';
import { tokens } from '../locales/translationTokens';
import Retailer from '../domain/automator/retailers/Retailer';
import { AccountStatus } from '../domain/automator/account/AccountStatus';
import { useFetchRetailers } from '../api/automator/retailers/useFetchRetailers';
import { useFetchAccount } from '../api/automator/account/useFetchAccount';
import { TutorialAlert } from './tutorials/TutorialAlert';
import { WarningAlert } from './alerts/WarningAlert';
import { ErrorAlert } from './alerts/ErrorAlert';
import AutomatorPage from './AutomatorPage';

interface AccountPageProps {
  title: string;
  titleExtra?: ReactNode;
  children: ReactNode;
  alerts?: ReactElement<typeof Alert>[];
  buttons?: ReactElement<typeof ActionButton>[];
  tutorialAlert?: ReactElement<typeof TutorialAlert>;
}

const AccountPage = ({
  title,
  children,
  buttons,
  alerts,
  tutorialAlert,
  titleExtra,
}: AccountPageProps) => {
  const { t } = useTranslation();

  const { data: retailers } = useFetchRetailers();

  const { data: account } = useFetchAccount();

  const isAccountDeactivated = account?.status === AccountStatus.DEACTIVATED;

  const isTrialExpired = account?.status === AccountStatus.TRIAL_EXPIRED;

  const isARetailerBlocked = retailers?.some((retailer: Retailer) => retailer.isApproved === false);

  const topAlerts: ReactElement<typeof Alert>[] = [];
  if (tutorialAlert) topAlerts.push(tutorialAlert as ReactElement<typeof Alert>);

  const bottomAlerts: ReactElement<typeof Alert>[] = alerts ? alerts : [];

  if (isARetailerBlocked) {
    bottomAlerts.push(
      <WarningAlert>{t(tokens.automator.retailers.retailer_blocked_explanation)}</WarningAlert>
    );
  }

  if (isAccountDeactivated) {
    bottomAlerts.push(
      <ErrorAlert>{t(tokens.automator.pricing.subscription_deactivated)}</ErrorAlert>
    );
  }

  if (isTrialExpired) {
    bottomAlerts.push(<WarningAlert>{t(tokens.automator.pricing.trial_expired)}</WarningAlert>);
  }

  return (
    <AutomatorPage
      title={title}
      buttons={buttons}
      topAlerts={topAlerts}
      bottomAlerts={bottomAlerts}
      titleExtra={titleExtra}
    >
      {children}
    </AutomatorPage>
  );
};

export default AccountPage;

import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { PRODUCT_PURCHASE_COSTS_PATH } from '../pdApiPaths';
import ProductPurchaseCostsData from '../../../domain/pd/ProductPurchaseCostsData';
import { ProductPurchaseCostsQuery } from '../../queryKeys/ProductPurchaseCostsQuery';

export const useFetchProductPurchaseCosts = (productId: number) => {
  const url = usePdApiAccountUrl(PRODUCT_PURCHASE_COSTS_PATH + `?productId=${productId}`);
  return useFetchData<ProductPurchaseCostsData>(url, ProductPurchaseCostsQuery(productId));
};

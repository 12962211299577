import { useAuthorizedPost } from '../../useAuthorizedPost';
import { PRODUCT_PURCHASE_VOLUMES_PATH } from '../pdApiPaths';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';

export const useCreateProductPurchaseVolume = () => {
  const url = usePdApiAccountUrl(PRODUCT_PURCHASE_VOLUMES_PATH);
  return useAuthorizedPost<undefined, CreateProductPurchaseVolumeData>(url, undefined, undefined);
};

export interface CreateProductPurchaseVolumeData {
  product_id: number;
  date: string;
  amount_of_units: number;
}

import { QueryClient } from 'react-query';
import { toast } from 'react-hot-toast';
import { tokens } from '../locales/translationTokens';
import { useTranslation } from 'react-i18next';

export const useCreateQueryClient = () => {
  // @ts-ignore
  const staleTime = 1000 * 60 * 60;
  // @ts-ignore
  const cacheTime =
    parseInt(import.meta.env.REACT_QUERY_QUERIES_CACHE_TIME_MS, 10) || 1000 * 60 * 5;
  // @ts-ignore
  const queryRetryCount = parseInt(import.meta.env.REACT_QUERY_QUERIES_RETRY_COUNT, 10) || 2;
  // @ts-ignore
  const mutationRetryCount = parseInt(import.meta.env.REACT_QUERY_MUTATIONS_RETRY_COUNT, 10) || 2;

  const { t } = useTranslation();

  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: queryRetryCount,
        staleTime: staleTime,
        cacheTime: cacheTime,
      },
      mutations: {
        retry: mutationRetryCount,
        onError: () => {
          toast.error(t(tokens.common.action_error));
        },
      },
    },
  });
};

import { useAuthorizedAxios } from './useAuthorizedAxios';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

export const useFetchData = <T>(url: string, queryKey: QueryKey, options?: UseQueryOptions<T>) => {
  const axiosInstance = useAuthorizedAxios();

  const fetchData = async () => {
    const response = await axiosInstance.get(url);

    return response.data as T;
  };

  return useQuery<T>(queryKey, fetchData, options);
};

import { PICK_SESSION_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchDataPolled } from '../../useFetchDataPolled';
import PickOrders from '../../../domain/automator/pickSessions/PickOrders';
import { PickSessionPickOrdersQueryKey } from '../../queryKeys/PickSessionPickOrdersQueryKey';

export const useFetchPickSessionPickOrdersPolled = (pickSessionId: number) => {
  //const queryClient = useQueryClient();

  const url = useAutomatorApiAccountUrl(PICK_SESSION_PATH + `/${pickSessionId}/pick-orders`);

  return useFetchDataPolled<PickOrders>(url, PickSessionPickOrdersQueryKey(pickSessionId), {
    initialPollingInterval: 3500,
    stopCondition: (data: PickOrders | undefined) => {
      if (!data) {
        return false;
      }
      return !data.shouldPoll;
    },
    startCondition: (data: PickOrders | undefined) => {
      if (!data) {
        return false;
      }
      return data.shouldPoll;
    },
    onStopPolling: () => {
      // console.log('onStopPolling');
      // const queryKeys = [
      //   OrdersQueryKey(OrderStatus.OPEN, DistributionParty.BOL),
      //   OrdersQueryKey(OrderStatus.OPEN_LATER, DistributionParty.BOL),
      //   OrdersQueryKey(OrderStatus.SHIPPED, DistributionParty.BOL),
      //   OrdersQueryKey(OrderStatus.PENDING_PICK_SESSION, DistributionParty.BOL),
      //   OrdersQueryKey(OrderStatus.FAILED, DistributionParty.BOL),
      //   OrdersQueryKey(OrderStatus.OPEN, DistributionParty.RETAILER),
      //   OrdersQueryKey(OrderStatus.OPEN_LATER, DistributionParty.RETAILER),
      //   OrdersQueryKey(OrderStatus.SHIPPED, DistributionParty.RETAILER),
      //   OrdersQueryKey(OrderStatus.PENDING_PICK_SESSION, DistributionParty.RETAILER),
      //   OrdersQueryKey(OrderStatus.FAILED, DistributionParty.RETAILER),
      // ];
      //
      // queryKeys.forEach(async (queryKey) => {
      //   await queryClient.invalidateQueries(queryKey).then((_) => {});
      // });
    },
    incrementFactor: 1.1,
  });
};

import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../../../locales/translationTokens';
import ActionButton from '../../../../../../components/ActionButton';
import { useUpdateProductPurchaseCosts } from '../../../../../../api/pd/offerPerformances/useUpdateProductPurchaseCosts';
import ProductPurchaseCosts from '../../../../../../domain/pd/ProductPurchaseCosts';

interface UpdateProductPurchaseCostsFormProps {
  onClick?: () => void;
  productPurchaseCosts: ProductPurchaseCosts;
}

export const UpdateProductPurchaseCostsForm = ({
  onClick,
  productPurchaseCosts,
}: UpdateProductPurchaseCostsFormProps) => {
  const [purchaseCosts, setPurchaseCosts] = useState(productPurchaseCosts.purchaseCostsInCents);

  const { t } = useTranslation();

  const { mutate: update, isLoading } = useUpdateProductPurchaseCosts(productPurchaseCosts.id);

  const onUpdate = () => {
    update(
      {
        purchase_costs_in_cents: purchaseCosts,
        date: '2020-01-01',
      },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(
            t(tokens.pd.performance.update_product_purchase_costs_form.purchase_costs_updated)
          );
        },
      }
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <TextField
        id="note-field"
        label={t(tokens.pd.performance.update_product_purchase_costs_form.purchase_costs)}
        variant="filled"
        fullWidth
        style={{ marginBottom: '1em' }}
        value={purchaseCosts}
        onChange={(e) => setPurchaseCosts(Number(e.target.value))}
      />

      <ActionButton
        label={t(tokens.pd.performance.update_product_purchase_costs_form.update_purchase_costs)}
        onClick={() => onUpdate()}
        isLoading={isLoading}
        variant="contained"
        color="primary"
        size="small"
      />
    </form>
  );
};

import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { AutoMailEventRulesListTable } from './components/AutoMailEventRulesListTable';
import ActionButton from '../../../components/ActionButton';
import { useOpenDialog } from '../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../store/dialogs/DialogId';
import { useTranslation } from 'react-i18next';
import { useFetchEventRules } from '../../../api/automator/eventRules/useFetchEventRules';
import { EventRuleType } from '../../../domain/automator/eventRules/EventRuleType';
import { EventRuleStatus } from '../../../domain/automator/eventRules/EventRuleStatus';
import { useEffect } from 'react';

const AutoMailPage = () => {
  const { data: eventRules, isLoading } = useFetchEventRules(EventRuleType.AUTO_EMAIL, [
    EventRuleStatus.ENABLED,
    EventRuleStatus.DISABLED,
  ]);

  const openDialog = useOpenDialog(DialogId.CREATE_EMAIL_EVENT_RULE);

  const openInfoDialog = useOpenDialog(DialogId.INFO);

  const { t } = useTranslation();

  useEffect(() => {
    if (
      eventRules &&
      !eventRules.eventRules.some((eventRule) => eventRule.status === EventRuleStatus.ENABLED)
    ) {
      openInfoDialog({
        title: tokens.automator.tutorials.auto_mail_tutorial_title,
        message: tokens.automator.tutorials.auto_mail_tutorial_message,
        buttons: [
          {
            label:
              tokens.automator.tutorials.auto_mail_tutorial_activate_parcelshop_mail_button_label,
            onClick: () => {
              window.open(
                'https://blue-engine.tawk.help/article/klantvoorkeur-parcelshop-bij-non-vvb',
                '_blank'
              );
            },
          },
        ],
      });
    }
  }, [eventRules, isLoading]);

  return (
    <AccountPage
      buttons={[
        <ActionButton
          key={1}
          label={t(tokens.automator.auto_mail.form.create_event_rule)}
          onClick={() => openDialog()}
          isLoading={isLoading}
        />,
      ]}
      title={tokens.automator.auto_mail.event_rules}
    >
      <AutoMailEventRulesListTable
        eventRules={eventRules?.eventRules}
        isLoading={isLoading}
        totalAmountOfProducts={eventRules?.totalAmountOfProducts}
      />
    </AccountPage>
  );
};

export default AutoMailPage;

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

interface CustomerInvoiceRequestDataItemProps {
  label: string;
  value: string;
  width?: number;
}

export const CustomerInvoiceRequestDataItem = ({
  label,
  value,
  width,
}: CustomerInvoiceRequestDataItemProps) => {
  return (
    <Grid
      container
      alignItems="center"
      spacing={1}
    >
      <Grid
        width={width}
        item
      >
        <Typography
          variant="subtitle1"
          style={{ fontWeight: 'bold' }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">{value}</Typography>
      </Grid>
    </Grid>
  );
};

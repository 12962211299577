import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { OFFER_SHIPPING_COSTS_PATH } from '../pdApiPaths';
import OfferShippingCostsData from '../../../domain/pd/OfferShippingCostsData';
import { OfferShippingCostsQueryKey } from '../../queryKeys/OfferShippingCostsQueryKey';

export const useFetchOfferShippingCosts = (offerId: number) => {
  const url = usePdApiAccountUrl(OFFER_SHIPPING_COSTS_PATH + `?offerId=${offerId}`);
  return useFetchData<OfferShippingCostsData>(url, OfferShippingCostsQueryKey(offerId));
};

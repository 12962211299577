import { MessageTemplateSelection } from './MessageTemplateSelection';
import { MessageTemplateType } from '../../../../domain/automator/messages/MessageTemplateType';
import { tokens } from '../../../../locales/translationTokens';
import CollapsibleCard from '../../shared/CollapsibleCard';
import { ThreadPhasePopoverButton } from './helpers/ThreadPhasePopoverButton';
import { ThreadPhase } from '../../../../domain/automator/messages/ThreadPhase';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface MessageTemplateSelectionCardProps {
  phase: ThreadPhase;
  onSelect: (messageTemplateId: number) => void;
}

export const MessageTemplateSelectionCard = ({
  phase: initialPhase,
  onSelect,
}: MessageTemplateSelectionCardProps) => {
  const [phase, setPhase] = useState(initialPhase);

  const { t } = useTranslation();

  return (
    <CollapsibleCard
      isOpen={false}
      title={t(tokens.automator.resolutions.dialogs.send_email.email_template)}
      titleComponent={<ThreadPhasePopoverButton setPhase={setPhase} />}
    >
      <MessageTemplateSelection
        type={MessageTemplateType.MANUAL}
        phase={phase}
        recoverability={null}
        selected={null}
        onSelect={onSelect}
      />
    </CollapsibleCard>
  );
};

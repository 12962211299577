import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Orders from '../../../../domain/automator/orders/Orders';
import { FlagIcon } from './FlagIcon';
import { CountryCode } from '../../../../domain/automator/orders/CountryCode';

interface OrdersCountrySelectProps {
  orders?: Orders;
  onClickCountry: (country: CountryCode) => void;
  countries: CountryCode[];
}

const OrdersCountrySelect = ({ orders, onClickCountry, countries }: OrdersCountrySelectProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      key={2}
    >
      <Stack
        alignItems={'center'}
        direction="row"
        gap={1}
      >
        <FlagIcon
          size={20}
          onClick={onClickCountry}
          countryCode={CountryCode.NL}
          isActive={countries.includes(CountryCode.NL)}
        />

        <Typography variant="body1">
          {orders &&
            orders.orders &&
            orders.orders.filter((order) => order.shipmentDetails.countryCode === CountryCode.NL)
              .length}
        </Typography>
      </Stack>

      <Stack
        alignItems={'center'}
        direction="row"
        gap={1}
      >
        <FlagIcon
          size={20}
          onClick={onClickCountry}
          countryCode={CountryCode.BE}
          isActive={countries.includes(CountryCode.BE)}
        />

        <Typography variant="body1">
          {orders &&
            orders.orders &&
            orders.orders.filter((order) => order.shipmentDetails.countryCode === CountryCode.BE)
              .length}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default OrdersCountrySelect;

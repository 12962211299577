import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { Box } from '@mui/system';
import SyncIcon from '@mui/icons-material/Sync';

interface RecurringOrdersButtonsProps {
  recurringBolOrderIds: string[];
  placedDoubleBolOrderIds: string[];
}

export const RecurringOrdersButtons = ({
  recurringBolOrderIds,
  placedDoubleBolOrderIds,
}: RecurringOrdersButtonsProps) => {
  const openDialog = useOpenDialog(DialogId.ORDER_SUMMARY);

  const bolOrderIds = recurringBolOrderIds.concat(placedDoubleBolOrderIds);

  if (bolOrderIds.length === 0) {
    return <></>;
  }

  return (
    <Stack
      direction="row"
      gap={1}
    >
      {bolOrderIds.map((recurringBolOrderId) => {
        return (
          <Box key={recurringBolOrderId}>
            <Button
              onClick={() => openDialog({ query: recurringBolOrderId })}
              key={recurringBolOrderId}
              variant="outlined"
              size="small"
              color="info"
              startIcon={<SyncIcon />}
            >
              {recurringBolOrderId}
            </Button>
          </Box>
        );
      })}
    </Stack>
  );
};

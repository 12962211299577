import { useFetchOrderEvents } from '../../../../api/automator/orders/useFetchOrderEvents';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import Typography from '@mui/material/Typography';
import { getAlphabeticFormatFromDate } from '../../../../helpers/getAlphabeticFormatFromDate';
import Stack from '@mui/material/Stack';
import TableBody from '@mui/material/TableBody';

interface OrderSummaryOrderEventsProps {
  orderId: number;
}

export const OrderSummaryOrderEvents = ({ orderId }: OrderSummaryOrderEventsProps) => {
  const { data: orderEvents, isLoading } = useFetchOrderEvents(orderId);

  if (isLoading) {
    return <>...loading</>;
  }

  const sortedOrderEvents = orderEvents?.sort((a, b) => {
    const dateComparison = new Date(b.date).getTime() - new Date(a.date).getTime();

    if (dateComparison !== 0) {
      return dateComparison;
    } else {
      if (a.time && b.time) {
        const timeA = new Date(`1970-01-01T${a.time}Z`).getTime();
        const timeB = new Date(`1970-01-01T${b.time}Z`).getTime();
        return timeB - timeA;
      } else if (a.time || b.time) {
        return a.time ? -1 : 1;
      } else {
        return 0;
      }
    }
  });

  return (
    <Table>
      <TableBody>
        {sortedOrderEvents?.map((orderEvent, index) => (
          <TableRow key={index}>
            <SlimTableCell>
              <Stack direction="row">
                <Typography
                  noWrap
                  variant="body2"
                  color="textSecondary"
                  width={100}
                >
                  {getAlphabeticFormatFromDate(new Date(orderEvent.date), false)}
                </Typography>

                <Typography
                  noWrap
                  variant="body2"
                  color="textSecondary"
                >
                  {orderEvent.time ? orderEvent.time.slice(0, 5) : ''}
                </Typography>
              </Stack>
            </SlimTableCell>
            <SlimTableCell>
              <Typography
                variant="body2"
                color="textPrimary"
              >
                {orderEvent.type}
              </Typography>
            </SlimTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import CustomerInvoice from '../../../../domain/automator/customerInvoiceRequests/CustomerInvoice';
import { ActionIcon } from '../../orders/components/ActionIcon';
import Download01Icon from '@untitled-ui/icons-react/build/esm/Download01';

interface DownloadCustomerInvoicePdfButtonProps {
  customerInvoice: CustomerInvoice;
}

export const DownloadCustomerInvoicePdfButton = ({
                                                   customerInvoice,
                                                 }: DownloadCustomerInvoicePdfButtonProps) => {
  const {t} = useTranslation();

  return (
    <>
      {customerInvoice.downloadUrl ? (
        <a href={customerInvoice.downloadUrl!}>
          <ActionIcon icon={<Download01Icon/>} tooltip={'download'}/>
        </a>
      ) : (
        <Typography>
          {t(tokens.automator.customer_invoice_requests.customer_invoice_being_created)}
        </Typography>
      )}
    </>
  );
};

import { CompetitorTableCellData } from './CompetitorProductPerformancesTable';
import Typography from '@mui/material/Typography';

interface CompetitorProductPerformanceTableCelContentProps {
  cell: CompetitorTableCellData;
  fontSize: number;
}

export const CompetitorProductPerformanceTableCellContent = ({
  cell,
  fontSize,
}: CompetitorProductPerformanceTableCelContentProps) => {
  const generateContent = (cell: CompetitorTableCellData) => {
    let content = '';

    if (cell.wasSponsored) {
      content += 'S';
    }

    if (cell.isOwnProduct) {
      content += 'O';
    }

    if (cell.isNewcomer) {
      content += 'N';
    }

    if (cell.isDropout) {
      content += 'D';
    }

    return content;
  };

  return <Typography style={{ fontSize: fontSize }}>{generateContent(cell)}</Typography>;
};

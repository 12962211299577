import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { PRODUCT_RANK_CATEGORIES_PATH } from '../pdApiPaths';
import { ProductRanksCategoriesQueryKey } from '../../queryKeys/ProductRanksCategoriesQueryKey';
import ProductRankCategories from '../../../domain/pd/ProductRankCategories';
import { getDateStringFromDate } from '../../../helpers/getDateStringFromDate';
import { CountryCode } from '../../../domain/automator/orders/CountryCode';

export const useFetchProductRankCategories = (
  productId: number,
  startDate: Date,
  endDate: Date,
  countryCode: CountryCode | null
) => {
  const url =
    usePdApiAccountUrl(PRODUCT_RANK_CATEGORIES_PATH + `?productId=${productId}`) +
    `&startDate=${getDateStringFromDate(startDate)}` +
    `&endDate=${getDateStringFromDate(endDate)}` +
    (countryCode ? `&countryCode=${countryCode}` : '');
  return useFetchData<ProductRankCategories>(
    url,
    ProductRanksCategoriesQueryKey(productId, startDate, endDate, countryCode)
  );
};

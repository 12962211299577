import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { EventRulesQueryKey } from '../../queryKeys/EventRulesQueryKey';
import { EVENT_RULE_ACTIONS_PATH } from '../automatorApiPaths';
import { RecipientType } from '../../../domain/automator/messages/RecipientType';

export const useUpdateEventRuleAction = (eventRuleActionId: number) => {
  const url = useAutomatorApiAccountUrl(EVENT_RULE_ACTIONS_PATH + '/' + eventRuleActionId);
  return useAuthorizedPost<undefined, UseUpdateEventRuleActionData>(url, [EventRulesQueryKey()]);
};

export interface UseUpdateEventRuleActionData {
  email_template_id: number;
  recipient_type: RecipientType;
  execute_in_hours: number | null;
  execute_at_time: string | null;
  is_start_with_resolution_case: boolean;
}

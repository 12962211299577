import { EndpointQueryKey } from './EndpointQueryKey';
import { getDateStringFromDate } from '../../helpers/getDateStringFromDate';

export const AdGroupSearchTermPerformancesQueryKey = (
  adGroupId: number,
  searchTermId: number,
  startDate: Date,
  endDate: Date
) => {
  return (
    EndpointQueryKey.AD_GROUP_SEARCH_TERM_PERFORMANCES +
    '_' +
    adGroupId +
    '_' +
    searchTermId +
    '_' +
    getDateStringFromDate(startDate) +
    '_' +
    getDateStringFromDate(endDate)
  );
};

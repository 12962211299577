import Stack from '@mui/material/Stack';
import ResolutionDossier from '../../../../domain/automator/resolutionDossiers/ResolutionDossier';
import { ResolutionCaseSelect } from './ResolutionCaseSelect';
import TableRow from '@mui/material/TableRow';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import { ResolutionDossierStatus } from '../../../../domain/automator/resolutionDossiers/ResolutionDossierStatus';
import { ResolutionCaseStatus } from '../../../../domain/automator/resolutionDossiers/ResolutionCaseStatus';
import { ResolutionDossierCard } from './ResolutionDossierCard';

interface ResolutionDossierInfoTableRowProps {
  resolutionDossier: ResolutionDossier;
  isSelected: boolean;
  setSelectedCaseId: (resolutionCaseId: number | null) => void;
  selectedCaseId: number | null;
  isFirst: boolean;
  isLast: boolean;
  selectedStatus: ResolutionDossierStatus;
}

export const ResolutionDossierInfoTableRow = ({
  resolutionDossier,
  isSelected,
  setSelectedCaseId,
  selectedCaseId,
  isFirst,
  isLast,
  selectedStatus,
}: ResolutionDossierInfoTableRowProps) => {
  const casesFiltered = resolutionDossier.resolutionCases.filter((resolutionCase) => {
    switch (selectedStatus) {
      case ResolutionDossierStatus.OPEN:
        return resolutionCase.status === ResolutionCaseStatus.OPEN;
      case ResolutionDossierStatus.AWAITING_RESPONSE:
        return resolutionCase.status === ResolutionCaseStatus.AWAITING_RESPONSE;
      case ResolutionDossierStatus.HANDLED:
        return resolutionCase.status === ResolutionCaseStatus.HANDLED;
      default:
        return true;
    }
  });

  const casesSorted = casesFiltered.sort((a, b) => {
    return a.hasNewReply === b.hasNewReply ? 0 : a.hasNewReply ? -1 : 1;
  });

  const paddingVertical = resolutionDossier.resolutionCases.length > 1 ? 4 : 12;

  return (
    <TableRow
      hover={true}
      style={{
        cursor: 'pointer',
        border: isSelected ? '2px solid BLUE' : 'none',
      }}
    >
      <SlimTableCell
        onClick={() => setSelectedCaseId(isSelected ? null : casesSorted[0].id)}
        hasBorderTop={!isFirst}
        hasBorderBottom={!isLast}
        paddingVertical={paddingVertical}
      >
        <ResolutionDossierCard resolutionDossier={resolutionDossier} />
      </SlimTableCell>

      <SlimTableCell
        paddingVertical={paddingVertical}
        hasBorderTop={!isFirst}
        hasBorderBottom={!isLast}
      >
        <Stack
          direction="column"
          gap={0.25}
        >
          {casesSorted.map((resolutionCase, index) => (
            <ResolutionCaseSelect
              key={index}
              reducedResolutionCase={resolutionCase}
              dossierHasMultipleCases={casesSorted.length > 1}
              isSelected={isSelected && selectedCaseId == resolutionCase.id}
              setSelectedCaseId={setSelectedCaseId}
            />
          ))}
        </Stack>
      </SlimTableCell>
    </TableRow>
  );
};

export default interface ShareOfVoiceTotal {
  [ShareOfVoiceTotalProperty.IMPRESSION_SHARE_PERCENTAGE]?: number;
  [ShareOfVoiceTotalProperty.CLICK_SHARE_PERCENTAGE]?: number;
  date: string;
}

export enum ShareOfVoiceTotalProperty {
  IMPRESSION_SHARE_PERCENTAGE = 'impressionsSharePercentage',
  CLICK_SHARE_PERCENTAGE = 'clickSharePercentage',
}

import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../../../locales/translationTokens';
import ActionButton from '../../../../../../components/ActionButton';
import { useUpdateOfferPerformanceOverwrite } from '../../../../../../api/pd/offerPerformances/useUpdateOfferPerformanceOverwrite';
import OfferPerformanceOverwrite from '../../../../../../domain/pd/OfferPerformanceOverwrite';

interface UpdateOfferPerformanceOverwriteFormProps {
  onClick?: () => void;
  offerPerformanceOverwrite: OfferPerformanceOverwrite;
}

export const UpdateOfferPerformanceOverwriteForm = ({
  onClick,
  offerPerformanceOverwrite,
}: UpdateOfferPerformanceOverwriteFormProps) => {
  const [visits, setVisits] = useState(offerPerformanceOverwrite.visits);

  const { t } = useTranslation();

  const { mutate: update, isLoading } = useUpdateOfferPerformanceOverwrite(
    offerPerformanceOverwrite.id
  );

  const onUpdate = () => {
    update(
      {
        visits: visits,
      },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(
            t(
              tokens.pd.performance.update_offer_performance_overwrite_form
                .offer_performance_overwrite_updated
            )
          );
        },
      }
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <TextField
        id="note-field"
        label={t(tokens.pd.performance.update_offer_performance_overwrite_form.visits)}
        variant="filled"
        fullWidth
        style={{ marginBottom: '1em' }}
        value={visits}
        onChange={(e) => setVisits(Number(e.target.value))}
      />

      <ActionButton
        label={t(
          tokens.pd.performance.update_offer_performance_overwrite_form
            .update_offer_performance_overwrite
        )}
        onClick={() => onUpdate()}
        isLoading={isLoading}
        variant="contained"
        color="primary"
        size="small"
      />
    </form>
  );
};

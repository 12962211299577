import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { AutomatorProductsAutocomplete } from '../../autoEmail/components/AutomatorProductsAutocomplete';
import Typography from '@mui/material/Typography';
import { VatPercentageToggleButtons } from '../../products/components/VatPercentageToggleButtons';
import { useFetchAutomatorReducedProducts } from '../../../../api/automator/products/useFetchAutomatorReducedProducts';
import { useFetchAccount } from '../../../../api/automator/account/useFetchAccount';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../../components/ActionButton';
import { useBulkUpdateProductVatPercentage } from '../../../../api/automator/products/useBulkUpdateProductVatPercentage';
import { toast } from 'react-hot-toast';
import { useUpdateAccountSettings } from '../../../../api/automator/account/useUpdateAccountSettings';
import { TableLoading } from '../../../../components/TableLoading';

interface SetupVatPercentagesFormProps {
  onSave: () => void;
}

export const SetupVatPercentagesForm = ({ onSave }: SetupVatPercentagesFormProps) => {
  const { t } = useTranslation();

  const { data: products, isLoading: isLoadingProducts } = useFetchAutomatorReducedProducts();

  const { data: account, isLoading: isLoadingAccountSettings } = useFetchAccount();

  const [product0VatPercentageIds, setProduct0VatPercentageIds] = useState<number[]>([]);

  const [product9VatPercentageIds, setProduct9VatPercentageIds] = useState<number[]>([]);

  const [product21VatPercentageIds, setProduct21VatPercentageIds] = useState<number[]>([]);

  const [defaultVatPercentage, setDefaultVatPercentage] = useState<number | null>(null);

  const { mutate: updateBulk, isLoading: isLoadingBulkUpdate } =
    useBulkUpdateProductVatPercentage();

  const { mutate: updateSettings, isLoading } = useUpdateAccountSettings(
    account?.settings?.id || 0
  );

  useEffect(() => {
    if (products) {
      setProduct0VatPercentageIds(
        products.products
          .filter((product) => product.vatPercentage === 0)
          .map((product) => product.id)
      );

      setProduct9VatPercentageIds(
        products.products
          .filter((product) => product.vatPercentage === 9)
          .map((product) => product.id)
      );

      setProduct21VatPercentageIds(
        products.products
          .filter((product) => product.vatPercentage === 21)
          .map((product) => product.id)
      );
    }
  }, [products]);

  useEffect(() => {
    if (account) {
      setDefaultVatPercentage(account.settings.defaultVatPercentage);
    }
  }, [account]);

  const onClickSave = () => {
    if (defaultVatPercentage == null) {
      return;
    }

    const dataSettings = {
      automatically_approve_cancellation_requests:
        account!.settings.automaticallyApproveCancellationRequests,
      ignore_latest_handover_date: account!.settings.ignoreLatestHandoverDate,
      forward_auto_mail_replies: account!.settings.forwardAutoMailReplies,
      forward_customer_question_replies: account!.settings.forwardCustomerQuestionReplies,
      automatically_send_customer_invoices: account!.settings.automaticallySendCustomerInvoices,
      default_vat_percentage: defaultVatPercentage,
      customer_invoice_information: account!.settings.customerInvoiceInformation,
      initial_customer_invoice_numbering_method:
        account!.settings.initialCustomerInvoiceNumberingMethod,
      customer_invoice_import_start_date: account!.settings.customerInvoiceImportStartDate,
      customer_invoice_auto_icp_method: account!.settings.customerInvoiceAutoICPMethod,
    };

    updateSettings(dataSettings, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.settings.settings_saved));
      },
    });

    const data = {
      updates: [
        {
          product_ids: product0VatPercentageIds,
          vat_percentage: 0,
        },
        {
          product_ids: product9VatPercentageIds,
          vat_percentage: 9,
        },
        {
          product_ids: product21VatPercentageIds,
          vat_percentage: 21,
        },
      ],
    };

    updateBulk(data, {
      onSuccess: () => {
        toast.success(t(tokens.automator.settings.default_vat_percentage));
        onSave();
      },
    });
  };

  if (isLoadingProducts || isLoadingAccountSettings) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  return (
    <Stack
      direction="column"
      gap={3}
    >
      {defaultVatPercentage != null && (
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
        >
          <Typography variant="h6">
            {t(tokens.automator.settings.default_vat_percentage)}
          </Typography>

          <VatPercentageToggleButtons
            onSelect={setDefaultVatPercentage}
            value={defaultVatPercentage}
          />
        </Stack>
      )}

      {defaultVatPercentage != 0 && (
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          width="100%"
        >
          <Typography variant="h6">0%</Typography>
          <AutomatorProductsAutocomplete
            productIds={product0VatPercentageIds}
            onSelect={(productIds) => setProduct0VatPercentageIds(productIds)}
          />
        </Stack>
      )}

      {defaultVatPercentage != 9 && (
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          width="100%"
        >
          <Typography variant="h6">9%</Typography>
          <AutomatorProductsAutocomplete
            productIds={product9VatPercentageIds}
            onSelect={(productIds) => setProduct9VatPercentageIds(productIds)}
          />
        </Stack>
      )}

      {defaultVatPercentage != 21 && (
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          width="100%"
        >
          <Typography variant="h6">21%</Typography>
          <AutomatorProductsAutocomplete
            productIds={product21VatPercentageIds}
            onSelect={(productIds) => setProduct21VatPercentageIds(productIds)}
          />
        </Stack>
      )}

      <ActionButton
        label={t(tokens.automator.settings.save_settings)}
        onClick={onClickSave}
        color="primary"
        isLoading={isLoadingBulkUpdate || isLoading}
      />
    </Stack>
  );
};

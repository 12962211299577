import Box from '@mui/material/Box';
import { LatestDays } from '../LatestDaysDropdownSelect';
import getLatestDateByLatestDays from '../../../../../../helpers/getLatestDateByLatestDays';
import getYesterday from '../../../../../../helpers/getYesterday';
import { tokens } from '../../../../../../locales/translationTokens';
import { useFetchAdGroupPerformances } from '../../../../../../api/pd/adPerformances/useFetchAdGroupPerformances';
import { AdGroupPerformanceProperty } from '../../../../../../domain/pd/AdGroupPerformance';
import { useTranslation } from 'react-i18next';
import { ChartProperty, PerformancesDateLineChart } from './PerformancesDateLineChart';
import { useFetchShareOfVoiceTotals } from '../../../../../../api/pd/adPerformances/useFetchShareOfVoiceTotals';
import { ShareOfVoiceTotalProperty } from '../../../../../../domain/pd/ShareOfVoiceTotal';

interface AdGroupPerformancesChartProps {
  campaignId: number;
  adGroupId: number;
  latestDays: LatestDays;
}

export const AdGroupPerformancesChart = ({
  campaignId,
  adGroupId,
  latestDays,
}: AdGroupPerformancesChartProps) => {
  const { data: adGroupPerformances, isLoading: isLoadingAdGroupPerformances } =
    useFetchAdGroupPerformances(adGroupId, getLatestDateByLatestDays(latestDays), getYesterday());

  const { data: shareOfVoiceTotals, isLoading: isLoadingShareOfVoiceTotals } =
    useFetchShareOfVoiceTotals(campaignId, getLatestDateByLatestDays(latestDays), getYesterday());

  const { t } = useTranslation();

  if (isLoadingAdGroupPerformances || isLoadingShareOfVoiceTotals) {
    return <Box>Loading...</Box>;
  }

  const availableChartProperties: ChartProperty[] = [
    {
      label: tokens.pd.performance.ad_group_performance_chart.impressions,
      property: AdGroupPerformanceProperty.IMPRESSIONS,
    },
    {
      label: tokens.pd.performance.ad_group_performance_chart.clicks,
      property: AdGroupPerformanceProperty.CLICKS,
    },
    {
      label: tokens.pd.performance.ad_group_performance_chart.conversions,
      property: AdGroupPerformanceProperty.CONVERSIONS,
    },
    {
      label: tokens.pd.performance.ad_group_performance_chart.sales,
      property: AdGroupPerformanceProperty.SALES,
    },
    {
      label: tokens.pd.performance.ad_group_performance_chart.spent,
      property: AdGroupPerformanceProperty.SPENT,
    },
    {
      label: tokens.pd.performance.ad_group_performance_chart.impression_share,
      property: ShareOfVoiceTotalProperty.IMPRESSION_SHARE_PERCENTAGE,
    },
    {
      label: tokens.pd.performance.ad_group_performance_chart.click_share,
      property: ShareOfVoiceTotalProperty.CLICK_SHARE_PERCENTAGE,
    },
  ];

  const chartData = adGroupPerformances!.adGroupPerformances.map((adGroupPerformance) => {
    const shareOfVoice = shareOfVoiceTotals!.shareOfVoiceTotals.find(
      (shareOfVoiceTotal) => shareOfVoiceTotal.date === adGroupPerformance.date
    );

    return {
      date: adGroupPerformance.date,
      [AdGroupPerformanceProperty.IMPRESSIONS]: adGroupPerformance.impressions,
      [AdGroupPerformanceProperty.CLICKS]: adGroupPerformance.clicks,
      [AdGroupPerformanceProperty.CONVERSIONS]: adGroupPerformance.conversions,
      [AdGroupPerformanceProperty.SALES]: adGroupPerformance[AdGroupPerformanceProperty.SALES],
      [AdGroupPerformanceProperty.SPENT]: adGroupPerformance[AdGroupPerformanceProperty.SPENT],
      [ShareOfVoiceTotalProperty.IMPRESSION_SHARE_PERCENTAGE]:
        shareOfVoice?.impressionsSharePercentage || 0,
      [ShareOfVoiceTotalProperty.CLICK_SHARE_PERCENTAGE]: shareOfVoice?.clickSharePercentage || 0,
    };
  });

  return (
    <PerformancesDateLineChart
      label={t(tokens.pd.performance.ad_group_performance_chart.ad_group_performance)}
      chartProperties={availableChartProperties}
      data={chartData}
    />
  );
};

import { EndpointQueryKey } from './EndpointQueryKey';
import { getDateStringFromDate } from '../../helpers/getDateStringFromDate';

export const AdPerformancesQueryKey = (
  offerId: number,
  startDate: Date,
  endDate: Date,
  rollingAverageDays: number
) => {
  return (
    EndpointQueryKey.AD_PERFORMANCES +
    '_' +
    offerId +
    '_' +
    getDateStringFromDate(startDate) +
    '_' +
    getDateStringFromDate(endDate) +
    '_' +
    rollingAverageDays
  );
};

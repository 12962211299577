import { useTranslation } from 'react-i18next';
import { ActionIcon } from '../../orders/components/ActionIcon';
import { tokens } from '../../../../locales/translationTokens';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { toast } from 'react-hot-toast';
import { useDismissReimbursement } from '../../../../api/automator/reimbursements/useDismissReimbursement';
import { useDismissReimbursementRequest } from '../../../../api/automator/reimbursements/useDismissReimbursementRequest';

interface DismissReimbursementActionIconProps {
  reimbursementId: number | null;
  reimbursementRequestId: number | null;
}

export const DismissReimbursementActionIcon = ({
  reimbursementId,
  reimbursementRequestId,
}: DismissReimbursementActionIconProps) => {
  const { t } = useTranslation();

  const { mutate: dismissReimbursement, isLoading: isLoadingDismissReimbursement } =
    useDismissReimbursement(reimbursementId || 0);

  const { mutate: dismissReimbursementRequest, isLoading: isLoadingDismissReimbursementRequest } =
    useDismissReimbursementRequest(reimbursementRequestId || 0);

  const onDismiss = () => {
    if (reimbursementId) {
      dismissReimbursement(undefined, {
        onSuccess: () => {
          toast.success(t(tokens.automator.reimbursements.dismissed));
        },
      });
    }

    if (reimbursementRequestId) {
      dismissReimbursementRequest(undefined, {
        onSuccess: () => {
          toast.success(t(tokens.automator.reimbursements.dismissed));
        },
      });
    }
  };

  return (
    <ActionIcon
      iconSize={30}
      icon={<CancelOutlinedIcon />}
      tooltip={t(tokens.automator.reimbursements.dismiss)}
      isLoading={isLoadingDismissReimbursement || isLoadingDismissReimbursementRequest}
      onClick={onDismiss}
    />
  );
};

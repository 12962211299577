import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { RetailerListTable } from './components/RetailerListTable';
import ActionButton from '../../../components/ActionButton';
import { useOpenDialog } from '../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../store/dialogs/DialogId';
import { useFetchRetailers } from '../../../api/automator/retailers/useFetchRetailers';
import { useEffect } from 'react';

const RetailersPage = () => {
  const { data: retailers, isLoading: isLoadingRetailers } = useFetchRetailers();

  const openAddRetailerDialog = useOpenDialog(DialogId.ADD_RETAILER);

  const openInfoDialog = useOpenDialog(DialogId.INFO);

  useEffect(() => {
    if (!isLoadingRetailers && retailers!.length === 0) {
      openInfoDialog({
        title: tokens.automator.tutorials.retailer_tutorial_title,
        message: tokens.automator.tutorials.retailer_tutorial_message,
        buttons: [
          {
            label: tokens.automator.tutorials.retailer_tutorial_button,
            onClick: () => {
              window.open(
                'https://partnerplatform.bol.com/nl/hulp-nodig/automatiseren/hoe-krijg-ik-toegang-tot-de-api',
                '_blank'
              );
            },
          },
        ],
      });
    }
  }, [retailers, isLoadingRetailers]);

  return (
    <AccountPage
      title={tokens.automator.retailers.retailers}
      buttons={[
        <ActionButton
          key={1}
          label={tokens.automator.retailers.add_retailer.add_retailer}
          onClick={() => openAddRetailerDialog()}
        />,
      ]}
    >
      <RetailerListTable retailers={retailers} />
    </AccountPage>
  );
};

export default RetailersPage;

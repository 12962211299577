import { OrderSummaryCard } from './OrderSummaryCard';
import { OrderSummaryReturnsCardItem } from './OrderSummaryReturnsCardItem';
import { useFetchOrderReturns } from '../../../../api/automator/orders/useFetchOrderReturns';

interface OrderSummaryReturnsCardProps {
  orderId: number;
}

export const OrderSummaryReturnsCard = ({ orderId }: OrderSummaryReturnsCardProps) => {
  const { data: returns, isLoading: isLoadingReturns } = useFetchOrderReturns(orderId);

  if (isLoadingReturns) {
    return <>...loading</>;
  }

  if (returns!.length === 0) {
    return <></>;
  }

  return (
    <OrderSummaryCard>
      <OrderSummaryReturnsCardItem returns={returns!} />
    </OrderSummaryCard>
  );
};

import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { ReturnReasonStack } from '../../returns/components/ReturnReasonStack';
import ReturnItemButtons from '../../returns/components/ReturnItemButtons';
import AcceptOrRejectReturnItemButtons from '../../returns/components/AcceptOrRejectReturnItemButtons';
import { ReturnProcessingResultListTable } from '../../returns/components/ReturnProcessingResultListTable';
import { ReturnItemTransporterChip } from '../../returns/components/ReturnItemTransporterChip';
import * as React from 'react';
import { ReturnItemFilter } from '../../../../domain/automator/returns/ReturnItemFilter';
import { OrderSummaryCardItem } from './OrderSummaryCardItem';
import Return from '../../../../domain/automator/returns/Return';

interface OrderSummaryReturnViewProps {
  returns: Return[];
}

export const OrderSummaryReturnsCardItem = ({ returns }: OrderSummaryReturnViewProps) => {
  const { t } = useTranslation();

  const returnItems = returns!.flatMap((return_) => {
    return return_.returnItems;
  });

  return (
    <OrderSummaryCardItem label={t(tokens.automator.order_summary.returns)}>
      <Stack
        direction="column"
        gap={3}
      >
        {returnItems.map((returnItem, index) => {
          return !returnItem.isHandled ? (
            <Stack
              direction="column"
              gap={1}
            >
              <Stack
                key={index}
                direction="row"
                gap={2}
                alignItems="center"
              >
                <ReturnItemButtons
                  returnItem={returnItem}
                  currentTab={ReturnItemFilter.OVERVIEW}
                />

                <AcceptOrRejectReturnItemButtons returnItem={returnItem} />

                {returnItem.transporterInfos.map((info) => {
                  return (
                    info.trackAndTraceUrl && <ReturnItemTransporterChip transporterInfo={info} />
                  );
                })}
              </Stack>

              <ReturnReasonStack returnItem={returnItem} />
            </Stack>
          ) : (
            <ReturnProcessingResultListTable returnItem={returnItem} />
          );
        })}
      </Stack>
    </OrderSummaryCardItem>
  );
};

import { Tooltip } from '@mui/material';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';

interface DoublePlaceOrderChipProps {
  bolOrderId: string;
}

const DoublePlaceOrderChip = ({ bolOrderId }: DoublePlaceOrderChipProps) => {
  const { t } = useTranslation();

  const openDialog = useOpenDialog(DialogId.ORDER_SUMMARY);

  return (
    <Tooltip
      enterDelay={2000}
      enterNextDelay={2000}
      title={t(tokens.automator.orders.double_placed_order_tooltip)}
      placement={'right'}
    >
      <Chip
        onClick={() => openDialog({ query: bolOrderId })}
        size="small"
        label={t(tokens.automator.orders.double_placed_order)}
        color="warning"
        variant="filled"
      />
    </Tooltip>
  );
};

export default DoublePlaceOrderChip;

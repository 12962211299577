import { tokens } from '../../../../../locales/translationTokens';
import IconCopyButton from '../../../shared/IconCopyButton';

interface CopyInternalReferenceButtonProps {
  aggregateId: number;
  internalReference: string;
  quantity: number;
}

const CopyInternalReferenceButton = ({
  aggregateId,
  internalReference,
  quantity,
}: CopyInternalReferenceButtonProps) => {
  const value = internalReference + (quantity > 1 ? ` ${quantity}X` : '');

  return (
    <IconCopyButton
      value={value}
      label={tokens.automator.orders.buttons.copy.copy_internal_reference}
      toastMessage={tokens.automator.orders.buttons.copy.internal_reference_copied}
      activeCopyButtonState={{
        activeAggregateId: aggregateId,
        activeButtonId: aggregateId + internalReference,
      }}
    />
  );
};

export default CopyInternalReferenceButton;

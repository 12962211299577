import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface OfferPerformanceTotalProps {
  label: string;
  value: number | string;
}

export const OfferPerformanceTotal = ({ label, value }: OfferPerformanceTotalProps) => {
  const { t } = useTranslation();

  return (
    <Stack
      border={1}
      direction="column"
      padding={1}
    >
      <Typography>{t(label)}</Typography>
      <Box>{value}</Box>
    </Stack>
  );
};

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import BundlePrice from '../../../../../domain/pd/BundlePrice';
import { tokens } from '../../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { convertCentsToEuros } from '../../../helpers/convertCentsToEuros';

interface VolumeDiscountIndicatorProps {
  bundlePrices?: BundlePrice[];
}

export const VolumeDiscountIndicator = ({ bundlePrices }: VolumeDiscountIndicatorProps) => {
  const { t } = useTranslation();

  const determineIndicator = (bundlePrices?: BundlePrice[]) => {
    if (!bundlePrices) {
      return tokens.pd.performance.volume_discount_indicator.price_unknown;
    } else if (bundlePrices.length > 1) {
      return tokens.pd.performance.volume_discount_indicator.yes;
    } else {
      return tokens.pd.performance.volume_discount_indicator.no;
    }
  };

  const prices = bundlePrices?.map((price) => (
    <Typography
      key={price.quantity}
      variant="body2"
    >
      {price.quantity + 'x - ' + convertCentsToEuros(price.unitPriceInCents)}
    </Typography>
  ));

  return (
    <Tooltip title={<Stack direction="column">{prices}</Stack>}>
      <Stack direction={'column'}>
        <Typography variant="body1">
          {t(tokens.pd.performance.volume_discount_indicator.volume_discount)}
        </Typography>
        <Typography variant="body2">{t(determineIndicator(bundlePrices))}</Typography>
      </Stack>
    </Tooltip>
  );
};

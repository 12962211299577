import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { SEARCH_TERM_VOLUMES } from '../pdApiPaths';
import SearchTermVolumes from '../../../domain/pd/SearchTermVolumes';
import { SearchTermVolumesQueryKey } from '../../queryKeys/SearchTermVolumesQueryKey';
import { getDateStringFromDate } from '../../../helpers/getDateStringFromDate';
import { CountryCode } from '../../../domain/automator/orders/CountryCode';

export const useFetchSearchTermVolumes = (
  searchTermId: number,
  startDate: Date,
  endDate: Date,
  countryCode: CountryCode | null
) => {
  const url = usePdApiAccountUrl(
    SEARCH_TERM_VOLUMES +
      `?searchTermId=${searchTermId}` +
      `&startDate=${getDateStringFromDate(startDate)}` +
      `&endDate=${getDateStringFromDate(endDate)}` +
      (countryCode ? `&countryCode=${countryCode}` : '')
  );
  return useFetchData<SearchTermVolumes>(
    url,
    SearchTermVolumesQueryKey(searchTermId, startDate, endDate, countryCode)
  );
};

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { OrderRecipient } from '../../orders/components/OrderRecipient';
import { ShipmentDetailsAddress } from '../../orders/components/ShipmentDetailsAddress';
import ActionButton from '../../../../components/ActionButton';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../locales/translationTokens';
import Typography from '@mui/material/Typography';
import RetailerLooksIcon from '../../retailers/components/RetailerLooksIcon';
import OrderNoteTypography from '../../orders/components/OrderNoteTypography';
import { OrderItemProductInfo } from '../../orders/components/OrderItemProductInfo';
import { useActiveCopyButton } from '../../../../store/activeCopyButton/useActiveCopyButton';
import SpeedDialActionIcon from '../../shared/speeddial/SpeedDialActionIcon';
import Order from '../../../../domain/automator/orders/Order';
import { useUnpinOrder } from '../../../../api/automator/orders/useUnpinOrder';

interface PinnedOrderTableRowProps {
  order: Order;
}

export const PinnedOrderTableRow = ({ order }: PinnedOrderTableRowProps) => {
  const { t } = useTranslation();

  const { activeAggregateId } = useActiveCopyButton();

  const { mutate: unpin } = useUnpinOrder(order.id);

  const onHandleUnpin = () => {
    unpin(undefined, {
      onSuccess: () => {
        toast.success(t(tokens.automator.pinned_orders.unpinned));
      },
    });
  };

  return (
    <TableRow style={{ backgroundColor: activeAggregateId == order.id ? '#ece3e3' : undefined }}>
      <TableCell>
        <Stack direction="column">
          {order.items.map((orderItem) => (
            <OrderItemProductInfo
              key={orderItem.id}
              orderStatus={order.status}
              orderItemStatus={orderItem.status}
              quantity={orderItem.quantity}
              offer={orderItem.offer}
            />
          ))}
        </Stack>
      </TableCell>

      <TableCell>
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
        >
          <Typography variant={'body1'}>{order.bolOrderId}</Typography>
          <RetailerLooksIcon retailerId={order.retailerId} />
          <SpeedDialActionIcon
            orderId={order.id}
            hasNote={order.hasNote}
          />
        </Stack>
      </TableCell>

      <TableCell>
        <Stack
          display={'flex'}
          flexDirection={'column'}
          gap={'0.1rem'}
        >
          <OrderRecipient
            shipmentDetails={order.shipmentDetails}
            doubleOrderBolOrderIds={order.doublePlacedBolOrderIds}
            isDoublePlaceOrdersACancelled={order.doublePlaceOrdersAreCancelled || false}
            repeatCustomerBolOrderIds={order.repeatCustomerBolOrderIds}
          />
          <ShipmentDetailsAddress
            pickupPoint={order.pickupPoint}
            shipmentDetails={order.shipmentDetails}
          />
        </Stack>
      </TableCell>

      <TableCell>
        <OrderNoteTypography orderId={order.id} />
      </TableCell>

      <TableCell>
        <ActionButton
          size="small"
          label={t(tokens.automator.pinned_orders.unpin)}
          onClick={onHandleUnpin}
        />
      </TableCell>
    </TableRow>
  );
};

import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import PickSession from '../../../../domain/automator/pickSessions/PickSession';
import { ActionIcon } from '../../orders/components/ActionIcon';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ActionButton from '../../../../components/ActionButton';

interface PickSessionTableRowProps {
  pickSession: PickSession;
}

export const PickSessionDownloads = ({ pickSession }: PickSessionTableRowProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      <a href={pickSession.downloadPickListUrl ? pickSession.downloadPickListUrl : '#'}>
        <ActionButton
          label={t(tokens.automator.pick_sessions.buttons.download_pick_list)}
          isDisabled={pickSession.downloadPickListUrl == null}
          variant="text"
          size="small"
        />
      </a>
      <ActionIcon
        icon={<InsertDriveFileIcon style={{ color: '#0000EE' }} />}
        tooltip={t(tokens.automator.pick_sessions.buttons.download_clean_labels)}
        tooltipDisabled={t(tokens.automator.pick_sessions.buttons.download_clean_labels_disabled)}
        disabled={pickSession.downloadCleanLabelsUrl == null}
        href={pickSession.downloadCleanLabelsUrl ? pickSession.downloadCleanLabelsUrl : '#'}
      />

      <a href={pickSession.downloadPickSessionCsvUrl ? pickSession.downloadPickSessionCsvUrl : '#'}>
        <ActionButton
          label={t(tokens.automator.pick_sessions.buttons.download_pick_session_csv)}
          isDisabled={pickSession.downloadPickSessionCsvUrl == null}
          variant="text"
          size="small"
        />
      </a>

      <ActionIcon
        icon={<PictureAsPdfIcon style={{ color: '#0000EE' }} />}
        tooltip={t(tokens.automator.pick_sessions.buttons.download_enriched_labels)}
        tooltipDisabled={t(
          tokens.automator.pick_sessions.buttons.download_enriched_labels_disabled
        )}
        disabled={pickSession.downloadEnrichedLabelsUrl == null}
        href={pickSession.downloadEnrichedLabelsUrl ? pickSession.downloadEnrichedLabelsUrl : '#'}
      />
    </Box>
  );
};

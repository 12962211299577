import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { RETURN_ITEMS_PATH } from '../automatorApiPaths';
import ReturnItem from '../../../domain/automator/returns/ReturnItem';
import { ReturnItemQueryKey } from '../../queryKeys/ReturnItemQueryKey';

export const useFetchReturnItem = (returnItemId: number) => {
  const url = useAutomatorApiAccountUrl(RETURN_ITEMS_PATH + '/' + returnItemId);
  return useFetchData<ReturnItem>(url, ReturnItemQueryKey(returnItemId));
};

import { useEffect, useState } from 'react';
import SearchTerm from '../../../../../domain/pd/SearchTerm';
import Stack from '@mui/material/Stack';
import { LatestDays } from './LatestDaysDropdownSelect';
import { AdGroupSearchTermPerformanceSearchTermsListTable } from './tables/AdGroupSearchTermPerformanceSearchTermsListTable';
import { AdGroupSearchTermPerformanceChart } from './charts/AdGroupSearchTermPerformanceChart';
import Campaign from '../../../../../domain/pd/Campaign';
import AdGroup from '../../../../../domain/pd/AdGroup';
import { CampaignDropdownSelect } from './CampaignDropdownSelect';
import { AdGroupDropdownSelect } from './AdGroupDropdownSelect';
import getLatestDateByLatestDays from '../../../../../helpers/getLatestDateByLatestDays';
import { AdGroupPerformancesChart } from './charts/AdGroupPerformancesChart';
import { UpdatePerformanceNoteForm } from './forms/UpdatePerformanceNoteForm';
import { PerformanceNoteType } from '../../../../../domain/pd/PerformanceNoteType';
import { useFetchPerformanceNotes } from '../../../../../api/pd/notes/useFetchPerformanceNotes';
import Offer from '../../../../../domain/pd/Offer';

interface AdGroupPerformanceTabProps {
  offer: Offer;
  latestDays: LatestDays;
}

export const AdGroupPerformancesTab = ({ offer, latestDays }: AdGroupPerformanceTabProps) => {
  const [campaign, setCampaign] = useState<Campaign | undefined>(undefined);
  const [adGroup, setAdGroup] = useState<AdGroup | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<SearchTerm | undefined>(undefined);

  const { data: performanceNotes, isLoading: isLoadingPerformanceNotes } = useFetchPerformanceNotes(
    offer.productId
  );

  useEffect(() => {
    if (campaign && !adGroup && campaign?.adGroups.length > 0) {
      setAdGroup(campaign.adGroups[0]);
    }
  }, [campaign]);

  if (isLoadingPerformanceNotes) {
    return <Stack>Loading...</Stack>;
  }

  const onSelectSearchTerm = (selectedSearchTerm: SearchTerm) => {
    if (selectedSearchTerm.id == searchTerm?.id) {
      setSearchTerm(undefined);
    } else {
      setSearchTerm(selectedSearchTerm);
    }
  };

  return (
    <Stack
      direction="column"
      gap={1}
    >
      <CampaignDropdownSelect
        startDate={getLatestDateByLatestDays(latestDays)}
        offerId={offer.id}
        onSelect={setCampaign}
        campaign={campaign}
      />

      {campaign && campaign.adGroups.length > 1 && (
        <AdGroupDropdownSelect
          adGroups={campaign.adGroups}
          onSelect={setAdGroup}
          adGroup={adGroup}
        />
      )}

      {!searchTerm && adGroup && (
        <AdGroupPerformancesChart
          campaignId={campaign!.id}
          adGroupId={adGroup.id}
          latestDays={latestDays}
        />
      )}

      {searchTerm && adGroup && (
        <AdGroupSearchTermPerformanceChart
          adGroupId={adGroup.id}
          campaignId={campaign!.id}
          searchTermId={searchTerm.id}
          latestDays={latestDays}
        />
      )}

      <UpdatePerformanceNoteForm
        performanceNote={
          performanceNotes!.performanceNotes.find(
            (note) => note.type === PerformanceNoteType.AD_GROUP_SEARCH_TERM_PERFORMANCES
          )!
        }
      />

      {adGroup && (
        <AdGroupSearchTermPerformanceSearchTermsListTable
          onSelect={onSelectSearchTerm}
          selected={searchTerm}
          campaignId={campaign!.id}
          adGroupId={adGroup.id}
          latestDays={latestDays}
        />
      )}
    </Stack>
  );
};

import ReturnItem from '../../../../domain/automator/returns/ReturnItem';
import { useTranslation } from 'react-i18next';
import { useAcceptReturnItem } from '../../../../api/automator/returns/useAcceptReturnItem';
import { tokens } from '../../../../locales/translationTokens';
import { toast } from 'react-hot-toast';
import { ActionIcon } from '../../orders/components/ActionIcon';
import { ThumbUp } from '@mui/icons-material';

interface AcceptReturnItemButtonProps {
  returnItem: ReturnItem;
  quantityReturned: number;
  onClick?: () => void;
}

const AcceptReturnItemButton = ({
  returnItem,
  quantityReturned,
  onClick,
}: AcceptReturnItemButtonProps) => {
  const { t } = useTranslation();
  const { mutate: acceptReturnItem, isLoading } = useAcceptReturnItem(returnItem.id);

  const onSave = () => {
    acceptReturnItem(
      { quantity_returned: quantityReturned },
      {
        onSuccess: async () => {
          toast.success(t(tokens.automator.returns.actions.return_item_accepted));
          onClick && onClick();
        },
      }
    );
  };

  return (
    <ActionIcon
      tooltip={t(tokens.automator.returns.actions.accept)}
      icon={<ThumbUp style={{ color: 'WHITE' }} />}
      isRectangular={true}
      backgroundColor={'#1976d2'}
      onClick={() => onSave()}
      isLoading={isLoading}
    />
  );
};

export default AcceptReturnItemButton;

import { DropdownOption, DropdownSelect } from '../../../../../automator/shared/DropdownSelect';
import { tokens } from '../../../../../../locales/translationTokens';

export enum DeviationChartType {
  SEARCH_TERM_VOLUME = 'SEARCH_TERM_VOLUME',
  ASSORTMENT_IMPRESSIONS = 'ASSORTMENT_IMPRESSIONS',
  ASSORTMENT_VISITS = 'ASSORTMENT_VISITS',
}

interface DeviationChartTypeDropdownProps {
  selectedChartType: DeviationChartType | undefined;
  onSelect: (chartType: DeviationChartType) => void;
}

export const DeviationChartTypeDropdown = ({
  selectedChartType,
  onSelect,
}: DeviationChartTypeDropdownProps) => {
  const options: DropdownOption<DeviationChartType, DeviationChartType>[] = [
    {
      key: DeviationChartType.SEARCH_TERM_VOLUME,
      value: DeviationChartType.SEARCH_TERM_VOLUME,
    },
    {
      key: DeviationChartType.ASSORTMENT_IMPRESSIONS,
      value: DeviationChartType.ASSORTMENT_IMPRESSIONS,
    },
    {
      key: DeviationChartType.ASSORTMENT_VISITS,
      value: DeviationChartType.ASSORTMENT_VISITS,
    },
  ];

  return (
    <DropdownSelect
      options={options}
      onSelect={(key) => {
        onSelect(key);
      }}
      selected={selectedChartType}
      label={tokens.pd.deviation_chart_type_dropdown_select.label}
    />
  );
};

import { DialogId } from '../../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../../../../../store/dialogs/useCloseDialog';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../locales/translationTokens';
import { ApplicationDialog } from '../../../../../components/ApplicationDialog';
import ProductPurchaseVolume from '../../../../../domain/pd/ProductPurchaseVolume';
import { UpdateProductPurchaseVolumeForm } from '../components/forms/UpdateProductPurchaseVolumeForm';

export const UpdateProductPurchaseVolumeDialog = () => {
  const id = DialogId.UPDATE_PRODUCT_PURCHASE_VOLUME;
  const { isOpen, data } = useIsDialogOpen(id);
  const closeDialog = useCloseDialog(id);
  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          title={t(
            tokens.pd.performance.update_product_purchase_volume_form.update_purchase_volume
          )}
        >
          <UpdateProductPurchaseVolumeForm
            productPurchaseVolume={data.productPurchaseVolume}
            onClick={closeDialog}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface UpdateProductPurchaseVolumeDialogData {
  productPurchaseVolume: ProductPurchaseVolume;
}

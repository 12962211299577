import { ThreadPhase } from '../../../../domain/automator/messages/ThreadPhase';
import Box from '@mui/material/Box';
import { EmailThreadOrderItemSelection } from './EmailThreadOrderItemSelection';
import { EmailThreadShipmentSelection } from './EmailThreadShipmentSelection';
import { EmailThreadReturnItemSelection } from './EmailThreadReturnItemSelection';
import Stack from '@mui/material/Stack';
import { useFetchOrder } from '../../../../api/automator/orders/useFetchOrder';
import { useFetchOrderShipment } from '../../../../api/automator/orders/useFetchOrderShipment';
import { useFetchOrderReturns } from '../../../../api/automator/orders/useFetchOrderReturns';
import { useEffect } from 'react';

interface EmailThreadAggregateSelectionProps {
  orderId: number;
  aggregateId: number | null;
  setAggregateId: (aggregateId: number) => void;
}

export const EmailThreadAggregateSelection = ({
  orderId,
  aggregateId,
  setAggregateId,
}: EmailThreadAggregateSelectionProps) => {
  const { data: order, isLoading: isLoadingOrder } = useFetchOrder(orderId);

  // NOTE: not really efficient to fetch all the returns and shipments for every order as we already know the current phase as thus what aggregates to use
  const { data: shipment, isLoading: isLoadingOrderShipment } = useFetchOrderShipment(orderId);
  const { data: returns, isLoading: isLoadingOrderReturns } = useFetchOrderReturns(orderId);

  useEffect(() => {
    if (!isLoadingOrder && !isLoadingOrderShipment && !isLoadingOrderReturns) {
      switch (order!.currentPhase) {
        case ThreadPhase.ORDER:
          if (order!.items.length === 1) {
            setAggregateId(order!.items[0].id);
          }
          return;

        case ThreadPhase.SHIPMENT:
          setAggregateId(shipment!.id);
          return;

        case ThreadPhase.RETURN:
          if (returns!.length === 1) {
            const returnItems = returns![0].returnItems;
            if (returnItems.length === 1) {
              setAggregateId(returnItems[0].id);
            }
          }
          return;
      }
    }
  }, [isLoadingOrder, isLoadingOrderShipment, isLoadingOrderReturns]);

  if (isLoadingOrder || isLoadingOrderShipment || isLoadingOrderReturns) {
    return <div>Loading...</div>;
  }

  // const determineSelectablePhases = (returns: Return[], shipment?: Shipment | null) => {
  //   const selectablePhases = [ThreadPhase.ORDER];
  //
  //   if (shipment) {
  //     selectablePhases.push(ThreadPhase.SHIPMENT);
  //   }
  //
  //   if (returns.length > 0) {
  //     selectablePhases.push(ThreadPhase.RETURN);
  //   }
  //
  //   return selectablePhases;
  // };
  //

  return (
    <Stack
      direction="column"
      gap={1.5}
    >
      {/*{phase == null && (*/}
      {/*  <EmailPhaseToggleButtonGroup*/}
      {/*    onSelect={onUpdatePhase}*/}
      {/*    value={phase}*/}
      {/*    selectablePhases={determineSelectablePhases(returns!, shipment) || []}*/}
      {/*  />*/}
      {/*)}*/}

      <Box>
        {order!.currentPhase == ThreadPhase.ORDER && (
          <EmailThreadOrderItemSelection
            order={order!}
            setAggregateId={setAggregateId}
            selectedAggregateId={aggregateId}
          />
        )}

        {order!.currentPhase == ThreadPhase.SHIPMENT && (
          <EmailThreadShipmentSelection
            shipment={shipment!}
            setAggregateId={setAggregateId}
            selectedAggregateId={aggregateId}
          />
        )}

        {order!.currentPhase == ThreadPhase.RETURN && (
          <EmailThreadReturnItemSelection
            returns={returns!}
            selectedAggregateId={aggregateId}
            setAggregateId={setAggregateId}
          />
        )}
      </Box>
    </Stack>
  );
};

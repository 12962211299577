import { REIMBURSEMENT_REQUESTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { ReimbursementRequestsQueryKey } from '../../queryKeys/ReimbursementRequestsQueryKey';
import ReimbursementRequests from '../../../domain/automator/reimbursements/ReimbursementRequests';

export const useFetchReimbursementRequests = (isDismissed: boolean) => {
  const url = useAutomatorApiAccountUrl(
    `${REIMBURSEMENT_REQUESTS_PATH}?isDismissed=${isDismissed}`
  );
  return useFetchData<ReimbursementRequests>(url, ReimbursementRequestsQueryKey(isDismissed));
};

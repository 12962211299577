import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../locales/translationTokens';
import { TableLoading } from '../../../../../components/TableLoading';
import { useFetchOfferShippingCosts } from '../../../../../api/pd/offerPerformances/useFetchOfferShippingCosts';
import { useFetchProductPurchaseCosts } from '../../../../../api/pd/offerPerformances/useFetchProductPurchaseCosts';
import Offer from '../../../../../domain/pd/Offer';
import Stack from '@mui/material/Stack';
import { useOpenDialog } from '../../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../../store/dialogs/DialogId';
import ActionButton from '../../../../../components/ActionButton';
import { useFetchProductPurchaseVolume } from '../../../../../api/pd/offerPerformances/useFetchProductPurchaseVolume';

interface ProductAndOfferActionButtonsProps {
  offer: Offer;
}

export const ProductAndOfferActionButtons = ({ offer }: ProductAndOfferActionButtonsProps) => {
  const { t } = useTranslation();

  const openCreateOfferShippingCostsDialog = useOpenDialog(DialogId.CREATE_OFFER_SHIPPING_COSTS);
  const openCreateProductPurchaseCostsDialog = useOpenDialog(
    DialogId.CREATE_PRODUCT_PURCHASE_COSTS
  );
  const openUpdateProductPurchaseCostsDialog = useOpenDialog(
    DialogId.UPDATE_PRODUCT_PURCHASE_COSTS
  );

  const openCreateProductPurchaseVolumeDialog = useOpenDialog(
    DialogId.CREATE_PRODUCT_PURCHASE_VOLUME
  );
  const openUpdateProductPurchaseVolumeDialog = useOpenDialog(
    DialogId.UPDATE_PRODUCT_PURCHASE_VOLUME
  );

  const openUpdateOfferShippingCostsDialog = useOpenDialog(DialogId.UPDATE_OFFER_SHIPPING_COSTS);

  const { data: offerShippingCosts, isLoading: isLoadingOfferShippingCosts } =
    useFetchOfferShippingCosts(offer.id);

  const { data: productPurchaseCosts, isLoading: isLoadingProductPurchaseCosts } =
    useFetchProductPurchaseCosts(offer.productId);

  const { data: productPurchaseVolume, isLoading: isLoadingProductPurchaseVolume } =
    useFetchProductPurchaseVolume(offer.productId);

  if (
    isLoadingOfferShippingCosts ||
    isLoadingProductPurchaseCosts ||
    isLoadingProductPurchaseVolume
  ) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  return (
    <Stack direction="row">
      {offerShippingCosts!.offerShippingCosts.length === 0 ? (
        <ActionButton
          label={t(tokens.pd.performance.create_offer_shipping_costs_form.create_shipping_costs)}
          onClick={() => openCreateOfferShippingCostsDialog({ offerId: offer.id })}
        />
      ) : (
        <ActionButton
          label={t(tokens.pd.performance.update_offer_shipping_costs_form.update_shipping_costs)}
          onClick={() =>
            openUpdateOfferShippingCostsDialog({
              offerShippingCosts: offerShippingCosts!.offerShippingCosts[0],
            })
          }
        />
      )}

      {productPurchaseCosts!.productPurchaseCosts.length === 0 ? (
        <ActionButton
          label={t(tokens.pd.performance.create_product_purchase_costs_form.create_purchase_costs)}
          onClick={() => openCreateProductPurchaseCostsDialog({ productId: offer.productId })}
        />
      ) : (
        <ActionButton
          label={t(tokens.pd.performance.update_product_purchase_costs_form.update_purchase_costs)}
          onClick={() =>
            openUpdateProductPurchaseCostsDialog({
              productPurchaseCosts: productPurchaseCosts!.productPurchaseCosts[0],
            })
          }
        />
      )}

      {productPurchaseVolume!.productPurchaseVolumes.length === 0 ? (
        <ActionButton
          label={t(
            tokens.pd.performance.create_product_purchase_volume_form.create_purchase_volume
          )}
          onClick={() => openCreateProductPurchaseVolumeDialog({ productId: offer.productId })}
        />
      ) : (
        <ActionButton
          label={t(
            tokens.pd.performance.update_product_purchase_volume_form.update_purchase_volume
          )}
          onClick={() =>
            openUpdateProductPurchaseVolumeDialog({
              productPurchaseVolume: productPurchaseVolume!.productPurchaseVolumes[0],
            })
          }
        />
      )}
    </Stack>
  );
};

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { TableBodyPlaceholder } from './TableBodyPlaceholder';

interface TableNotFoundProps {
  message: string;
}

export const TableEmpty = ({ message }: TableNotFoundProps) => {
  return (
    <TableBodyPlaceholder>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="15px"
      >
        <Typography variant="h5">{message}</Typography>
      </Box>
    </TableBodyPlaceholder>
  );
};

import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { ActionIcon } from '../../orders/components/ActionIcon';
import AssignmentReturnedOutlinedIcon from '@mui/icons-material/AssignmentReturnedOutlined';

const HasReturnedToSenderIndicator = () => {
  const { t } = useTranslation();

  return (
    <ActionIcon
      icon={<AssignmentReturnedOutlinedIcon />}
      tooltip={t(tokens.automator.shipments.has_returned_to_sender)}
    />
  );
};

export default HasReturnedToSenderIndicator;

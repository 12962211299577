import { DropdownOption, DropdownSelect } from '../../../../automator/shared/DropdownSelect';
import { tokens } from '../../../../../locales/translationTokens';
import Campaign from '../../../../../domain/pd/Campaign';
import { useFetchCampaigns } from '../../../../../api/pd/adPerformances/useFetchCampaigns';

interface CampaignDropdownSelectProps {
  offerId: number;
  startDate: Date;
  campaign?: Campaign;
  onSelect: (campaign: Campaign) => void;
}

export const CampaignDropdownSelect = ({
  offerId,
  startDate,
  campaign,
  onSelect,
}: CampaignDropdownSelectProps) => {
  const { data: campaigns, isLoading } = useFetchCampaigns(offerId, startDate);

  const options: DropdownOption<number, string>[] = [];

  if (campaigns && !isLoading) {
    campaigns.campaigns.forEach((campaign) => {
      options.push({
        key: campaign.id,
        value: campaign.name,
      });
    });
  }

  return (
    <DropdownSelect
      options={options}
      onSelect={(key) => {
        campaign = campaigns?.campaigns.find((campaign) => campaign.id === key) as Campaign;
        onSelect(campaign);
      }}
      selected={campaign?.id}
      label={tokens.pd.campaign_dropdown_select.label}
    />
  );
};

import { tokens } from '../../../../../locales/translationTokens';
import IconCopyButton from '../../../shared/IconCopyButton';

interface CopyEmailAddressButtonProps {
  aggregateId?: number;
  emailAddress: string;
}

const CopyEmailAddressButton = ({ emailAddress, aggregateId }: CopyEmailAddressButtonProps) => {
  return (
    <IconCopyButton
      value={emailAddress}
      label={tokens.automator.shipments.copy_email}
      toastMessage={tokens.automator.shipments.email_copied}
      activeCopyButtonState={
        aggregateId
          ? {
              activeAggregateId: aggregateId,
              activeButtonId: aggregateId + emailAddress,
            }
          : undefined
      }
    />
  );
};

export default CopyEmailAddressButton;

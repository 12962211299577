import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { DeliveryCode } from '../../../../../domain/pd/DeliveryCode';
import { tokens } from '../../../../../locales/translationTokens';

export const useGetDeliveryCodeOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        key: DeliveryCode.A24UURS_23,
        value: t(tokens.automator.orders.delivery_code.a24uurs_23),
      },
      {
        key: DeliveryCode.A24UURS_22,
        value: t(tokens.automator.orders.delivery_code.a24uurs_22),
      },
      {
        key: DeliveryCode.A24UURS_21,
        value: t(tokens.automator.orders.delivery_code.a24uurs_21),
      },
      {
        key: DeliveryCode.A24UURS_20,
        value: t(tokens.automator.orders.delivery_code.a24uurs_20),
      },
      {
        key: DeliveryCode.A24UURS_19,
        value: t(tokens.automator.orders.delivery_code.a24uurs_19),
      },
      {
        key: DeliveryCode.A24UURS_18,
        value: t(tokens.automator.orders.delivery_code.a24uurs_18),
      },
      {
        key: DeliveryCode.A24UURS_17,
        value: t(tokens.automator.orders.delivery_code.a24uurs_17),
      },
      {
        key: DeliveryCode.A24UURS_16,
        value: t(tokens.automator.orders.delivery_code.a24uurs_16),
      },
      {
        key: DeliveryCode.A24UURS_15,
        value: t(tokens.automator.orders.delivery_code.a24uurs_15),
      },
      {
        key: DeliveryCode.A24UURS_14,
        value: t(tokens.automator.orders.delivery_code.a24uurs_14),
      },
      {
        key: DeliveryCode.A24UURS_13,
        value: t(tokens.automator.orders.delivery_code.a24uurs_13),
      },
      {
        key: DeliveryCode.A24UURS_12,
        value: t(tokens.automator.orders.delivery_code.a24uurs_12),
      },
      {
        key: DeliveryCode.A1_2D,
        value: t(tokens.automator.orders.delivery_code.a1_2d),
      },
      {
        key: DeliveryCode.A2_3D,
        value: t(tokens.automator.orders.delivery_code.a2_3d),
      },
      {
        key: DeliveryCode.A3_5D,
        value: t(tokens.automator.orders.delivery_code.a3_5d),
      },
      {
        key: DeliveryCode.A4_8D,
        value: t(tokens.automator.orders.delivery_code.a4_8d),
      },
      {
        key: DeliveryCode.A1_8D,
        value: t(tokens.automator.orders.delivery_code.a1_8d),
      },
      {
        key: DeliveryCode.MIJNLEVERBELOFTE,
        value: t(tokens.automator.orders.delivery_code.mijnleverbelofte),
      },
      {
        key: DeliveryCode.VVB,
        value: t(tokens.automator.orders.delivery_code.vvb),
      },
    ],
    [t]
  );
};

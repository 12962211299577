import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { ReimbursementsListTable } from './components/ReimbursementsListTable';
import { useFetchReimbursements } from '../../../api/automator/reimbursements/useFetchReimbursements';
import { useFetchReimbursementRequests } from '../../../api/automator/reimbursements/useFetchReimbursementRequests';

const ReimbursementsPage = () => {
  const { data: reimbursements, isLoading: isLoadingReimbursements } =
    useFetchReimbursements(false);
  const { data: reimbursementRequests, isLoading: isLoadingReimbursementRequests } =
    useFetchReimbursementRequests(false);

  return (
    <AccountPage title={tokens.automator.reimbursements.reimbursements}>
      <ReimbursementsListTable
        reimbursements={reimbursements?.reimbursements}
        reimbursementRequests={reimbursementRequests?.reimbursementRequests}
        isLoading={isLoadingReimbursements || isLoadingReimbursementRequests}
      />
    </AccountPage>
  );
};

export default ReimbursementsPage;

import Chip from '@mui/material/Chip';
import Retailer from '../../../../domain/automator/retailers/Retailer';

interface RetailerChipProps {
  retailer: Retailer;
  onClick?: (retailerId: number) => void;
  isSelected?: boolean;
}

const RetailerChip = ({ retailer, onClick, isSelected }: RetailerChipProps) => {
  return (
    <Chip
      label={retailer.name}
      onClick={onClick ? () => onClick?.(retailer.id) : undefined}
      variant={'filled'}
      color={isSelected ? 'success' : 'default'}
    />
  );
};

export default RetailerChip;

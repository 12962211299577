import { ReimbursementType } from '../../../../domain/automator/reimbursements/ReimbursementType';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { ToggleButtonGroup } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';

interface ReimbursementTypeToggleButtonsProps {
  value: ReimbursementType;
  onSelect: (reimbursementType: ReimbursementType) => void;
  selectable: ReimbursementType[];
}

export const ReimbursementTypeToggleButtons = ({
  value,
  onSelect,
  selectable,
}: ReimbursementTypeToggleButtonsProps) => {
  const { t } = useTranslation();

  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      onChange={(_, value) => {
        if (!value) return;
        onSelect(value as ReimbursementType);
      }}
      aria-label="Platform"
    >
      {selectable.includes(ReimbursementType.LIM) ? (
        <ToggleButton value={ReimbursementType.LIM}>
          {t(tokens.automator.reimbursements.type.lim)}
        </ToggleButton>
      ) : null}

      {selectable.includes(ReimbursementType.RLIM) ? (
        <ToggleButton value={ReimbursementType.RLIM}>
          {t(tokens.automator.reimbursements.type.rlim)}
        </ToggleButton>
      ) : null}

      {selectable.includes(ReimbursementType.RETURN_LABEL) ? (
        <ToggleButton value={ReimbursementType.RETURN_LABEL}>
          {t(tokens.automator.reimbursements.type.return_label)}
        </ToggleButton>
      ) : null}

      {selectable.includes(ReimbursementType.SHIPPING_LABEL) ? (
        <ToggleButton value={ReimbursementType.SHIPPING_LABEL}>
          {t(tokens.automator.reimbursements.type.shipping_label)}
        </ToggleButton>
      ) : null}
    </ToggleButtonGroup>
  );
};

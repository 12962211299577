import { useTranslation } from 'react-i18next';
import { TableTabs, TabOption } from '../../../../components/TableTabs';
import { tokens } from '../../../../locales/translationTokens';
import { ShipmentsRequestType } from '../../../../api/queryKeys/ShipmentsQueryKey';
import ShipmentTotals from '../../../../domain/automator/shipments/ShipmentTotals';

interface ShipmentTabsProps {
  currentTab: ShipmentsRequestType;
  setCurrentTab: (status: ShipmentsRequestType) => void;
  totals?: ShipmentTotals;
}

const ShipmentTabs = ({ currentTab, setCurrentTab, totals }: ShipmentTabsProps) => {
  const { t } = useTranslation();

  const tabOptions: TabOption<ShipmentsRequestType>[] = [];
  tabOptions.push(
    ...[
      {
        label: t(tokens.automator.shipments.tabs.all),
        value: ShipmentsRequestType.ALL,
      },
      {
        label: t(tokens.automator.shipments.tabs.at_pick_up_point),
        value: ShipmentsRequestType.AT_PICK_UP_POINT,
        amount: totals?.totalAtPickUpPoint,
      },
      {
        label: t(tokens.automator.shipments.tabs.overdue),
        value: ShipmentsRequestType.OVERDUE,
        amount: totals?.totalOverdue,
      },
      {
        label: t(tokens.automator.shipments.tabs.returned_to_sender),
        value: ShipmentsRequestType.RETURNED_TO_SENDER,
        amount: totals?.totalReturnedToSender,
      },
    ]
  );

  return (
    <TableTabs
      value={currentTab}
      onChange={(status: ShipmentsRequestType) => {
        setCurrentTab(status);
      }}
      tabOptions={tabOptions}
    />
  );
};

export default ShipmentTabs;

import { tokens } from '../locales/translationTokens';

const getDayOfTheWeek = (date: string) => {
  const daysOfWeek = [
    tokens.common.days_of_the_week.sunday,
    tokens.common.days_of_the_week.monday,
    tokens.common.days_of_the_week.tuesday,
    tokens.common.days_of_the_week.wednesday,
    tokens.common.days_of_the_week.thursday,
    tokens.common.days_of_the_week.friday,
    tokens.common.days_of_the_week.saturday,
  ];

  const dateObject = new Date(date);
  return daysOfWeek[dateObject.getDay()];
};

export default getDayOfTheWeek;

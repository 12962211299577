import Typography from '@mui/material/Typography';
import ResolutionCase from '../../../../domain/automator/resolutionDossiers/ResolutionCase';
import DistributionPartyChip from '../../shipments/components/DistributionPartyChip';
import { getDateStringFromDate } from '../../../../helpers/getDateStringFromDate';
import ShipmentTransporterInfo from '../../shipments/components/ShipmentTransporterInfo';
import { useFetchOrder } from '../../../../api/automator/orders/useFetchOrder';
import { useFetchOrderShipment } from '../../../../api/automator/orders/useFetchOrderShipment';
import * as React from 'react';
import { ReturnResolutionCaseInfo } from './ReturnResolutionCaseInfo';
import { useFetchOrderEvents } from '../../../../api/automator/orders/useFetchOrderEvents';
import { OrderEventType } from '../../../../domain/automator/orders/OrderEventType';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import getWeekNumberOfYear from '../../../../helpers/getWeekNumberOfYear';
import CollapsibleCard from '../../shared/CollapsibleCard';

interface OrderResolutionCaseInfoCardProps {
  resolutionCase: ResolutionCase;
}

export const OrderResolutionCaseInfoCard = ({
  resolutionCase,
}: OrderResolutionCaseInfoCardProps) => {
  const hasBolCaseNumber = resolutionCase.bolCaseNumber !== null;

  const { data: order, isLoading: isLoadingOrder } = useFetchOrder(
    resolutionCase.messageThread.orderId!
  );

  const { data: orderEvents, isLoading: isLoadingOrderEvents } = useFetchOrderEvents(
    resolutionCase.messageThread.orderId!
  );

  const { data: shipment, isLoading: isLoadingShipment } = useFetchOrderShipment(
    resolutionCase.messageThread.orderId!
  );

  const { t } = useTranslation();

  if (isLoadingOrder || isLoadingShipment || isLoadingOrderEvents) {
    return <div>Loading...</div>;
  }

  const latestOrderEvent = orderEvents!
    .filter((event) => event.type != OrderEventType.EXPECTED_DELIVERY_DATE)
    .sort((a, b) => {
      const timeA = new Date(`${a.date}T${a.time}Z`).getTime();
      const timeB = new Date(`${b.date}T${b.time}Z`).getTime();
      return timeB - timeA;
    })[0];

  return (
    <CollapsibleCard
      title={t(tokens.automator.resolutions.resolution_dossier_table.order_info)}
      isOpen={true}
    >
      <List>
        {hasBolCaseNumber && (
          <ListItem>
            <ListItemText
              primary={t(tokens.automator.resolutions.resolution_dossier_table.case_number)}
            />
            <Typography variant="body2">{resolutionCase.bolCaseNumber}</Typography>
          </ListItem>
        )}

        <ListItem>
          <ListItemText primary={t(tokens.automator.auto_mail.distribution_party)} />
          <DistributionPartyChip distributionParty={order!.distributionParty} />
        </ListItem>

        <ListItem>
          <ListItemText primary={t(tokens.automator.order_summary.order_event_type)} />
          <Typography
            variant="body2"
            color="textPrimary"
          >
            {latestOrderEvent.type}
          </Typography>
        </ListItem>

        <ListItem>
          <ListItemText primary={t(tokens.automator.orders.order_placed_date)} />
          <Typography variant="body2">
            {getDateStringFromDate(new Date(order!.orderPlacedDate), false) +
              ' (W' +
              getWeekNumberOfYear(order!.orderPlacedDate) +
              ')'}
          </Typography>
        </ListItem>

        {shipment && (
          <ListItem>
            <ListItemText primary={t(tokens.automator.orders.expected_delivery_date)} />
            <Typography variant="body2">
              {getDateStringFromDate(new Date(shipment.expectedDeliveryDate), false) +
                ' (W' +
                getWeekNumberOfYear(shipment.expectedDeliveryDate) +
                ')'}
            </Typography>
          </ListItem>
        )}

        {shipment && (
          <ListItem>
            <ListItemText primary={t(tokens.automator.shipments.shipment)} />
            <ShipmentTransporterInfo shipment={shipment!} />
          </ListItem>
        )}

        {resolutionCase.messageThread.returnItemId && (
          <ListItem>
            <ListItemText primary={t(tokens.automator.returns.return)} />
            <ReturnResolutionCaseInfo returnItemId={resolutionCase.messageThread.returnItemId} />
          </ListItem>
        )}
      </List>
    </CollapsibleCard>
  );
};

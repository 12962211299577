import Stack from '@mui/material/Stack';
import { TooltippedProductThumbnail } from '../../orders/components/TooltippedProductThumbnail';
import Typography from '@mui/material/Typography';
import RetailerLooksIcon from '../../retailers/components/RetailerLooksIcon';
import { CustomerInvoiceRecipient } from './CustomerInvoiceRecipient';
import BillingDetails from '../../../../domain/automator/orders/BillingDetails';
import CustomerInvoiceLine from '../../../../domain/automator/customerInvoiceRequests/CustomerInvoiceLine';

interface CustomerInvoiceViewProps {
  retailerId: number;
  bolOrderId: string;
  billingDetails: BillingDetails;
  customerInvoiceLines: CustomerInvoiceLine[];
}

export const CustomerInvoiceView = ({
  retailerId,
  billingDetails,
  bolOrderId,
  customerInvoiceLines,
}: CustomerInvoiceViewProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={0.5}
      justifyContent="space-between"
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={0.5}
      >
        {customerInvoiceLines.map((item, index) => {
          return (
            <TooltippedProductThumbnail
              key={index}
              productTitle={item.offer.product.title}
              height={60}
              thumbnailUrl={item.offer.product.thumbnailUrl}
              quantity={item.quantity}
            />
          );
        })}

        <Stack direction="column">
          <Stack
            direction="row"
            gap={1}
          >
            <CustomerInvoiceRecipient billingDetails={billingDetails} />
          </Stack>

          <Stack
            direction="row"
            gap={0.5}
          >
            <RetailerLooksIcon retailerId={retailerId} />
            <Typography variant="body2">{bolOrderId}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

import { CUSTOMER_INVOICES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { CustomerInvoiceRequestsQueryKey } from '../../queryKeys/CustomerInvoiceRequestsQueryKey';
import CustomerInvoiceRequests from '../../../domain/automator/customerInvoiceRequests/CustomerInvoiceRequests';
import { useUpdateData } from '../../useUpdateData';
import { useCustomerInvoiceRequestQueryKeysUpdater } from './useCustomerInvoiceRequestQueryKeysUpdater';

export const useRequestCustomerInvoiceInformation = (customerInvoiceId: number) => {
  const url = useAutomatorApiAccountUrl(
    CUSTOMER_INVOICES_PATH + '/' + customerInvoiceId + `/request-information`
  );

  const updater = useCustomerInvoiceRequestQueryKeysUpdater();
  const updateData = useUpdateData(updater, 'customerInvoiceRequests');

  const onSuccess = (customerInvoiceRequests: CustomerInvoiceRequests) => {
    updateData(customerInvoiceRequests);
  };

  return useAuthorizedPost<CustomerInvoiceRequests, RequestCustomerInvoiceInformationData>(
    url,
    [CustomerInvoiceRequestsQueryKey()],
    undefined,
    onSuccess
  );
};

interface RequestCustomerInvoiceInformationData {
  subject: string;
  body: string;
}

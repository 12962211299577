import { useAuthorizedPost } from '../../useAuthorizedPost';
import { OFFER_PERFORMANCE_OVERWRITE_PATH } from '../pdApiPaths';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';

export const useCreateOfferPerformanceOverwrite = () => {
  const url = usePdApiAccountUrl(OFFER_PERFORMANCE_OVERWRITE_PATH);
  return useAuthorizedPost<undefined, CreateOfferPerformanceOverwriteData>(
    url,
    undefined,
    undefined
  );
};

export interface CreateOfferPerformanceOverwriteData {
  offer_id: number;
  date: string;
  visits: number;
}

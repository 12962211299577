import TableRow from '@mui/material/TableRow';
import ProposedAction from '../../../../domain/automator/alerts/ProposedAction';
import { CondensedOrderView } from '../../orderSummaries/components/CondensedOrderView';
import { AlertActions } from './AlertActions';
import { useTranslation } from 'react-i18next';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import { Box } from '@mui/system';
import Chip from '@mui/material/Chip';
import { tokens } from '../../../../locales/translationTokens';

interface AlertsListTableRowProps {
  proposedActions: ProposedAction[];
  isLastRow: boolean;
}

export const AlertsListTableRow = ({ proposedActions, isLastRow }: AlertsListTableRowProps) => {
  const { t } = useTranslation();

  const firstAlert = proposedActions[0];
  const order = firstAlert.order;

  return (
    <TableRow>
      <SlimTableCell hasBorderBottom={!isLastRow}>
        <CondensedOrderView
          showDistributionParty={true}
          order={order}
        />
      </SlimTableCell>

      <SlimTableCell hasBorderBottom={!isLastRow}>
        <>
          {!firstAlert.isSeen && (
            <Box>
              <Chip
                size="small"
                label={t(tokens.common.is_new)}
                variant={'filled'}
                color={'warning'}
              />
            </Box>
          )}
        </>
      </SlimTableCell>

      <SlimTableCell hasBorderBottom={!isLastRow}>
        <></>
      </SlimTableCell>

      <SlimTableCell hasBorderBottom={!isLastRow}>
        <AlertActions proposedActions={proposedActions} />
      </SlimTableCell>
    </TableRow>
  );
};

import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { PRODUCTS } from '../pdApiPaths';
import Products from '../../../domain/pd/Products';
import { ProductRanksQueryKey } from '../../queryKeys/ProductsQueryKey';

export const useFetchPerformanceProducts = (categoryId?: number) => {
  const url = usePdApiAccountUrl(PRODUCTS + (categoryId ? `?categoryId=${categoryId}` : ''));
  return useFetchData<Products>(url, ProductRanksQueryKey(categoryId));
};

interface StepperStraightLineProps {
  color: string;
  length: number;
}

const StepperStraightLine = ({ color, length }: StepperStraightLineProps) => {
  return <hr style={{ width: `${length}%`, borderTop: `1px solid ${color}` }} />;
};

export default StepperStraightLine;

import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useApproveCancellationRequests } from '../../../../api/automator/orders/useApproveCancellationRequests';
import ActionButton from '../../../../components/ActionButton';
import { tokens } from '../../../../locales/translationTokens';

interface ApprovePendingCancellationRequestsButtonProps {
  totalCancellationRequests: number;
}

const ApprovePendingCancellationRequestsButton = ({
  totalCancellationRequests,
}: ApprovePendingCancellationRequestsButtonProps) => {
  const { t } = useTranslation();
  const { mutate: approveCancellationRequests, isLoading } = useApproveCancellationRequests();

  return (
    <ActionButton
      label={
        t(tokens.automator.orders.approve_customer_cancellations) +
        `  (${totalCancellationRequests})`
      }
      onClick={() =>
        approveCancellationRequests(undefined, {
          onSuccess: async () => {
            toast.success(t(tokens.automator.orders.cancellation_requests_approved));
          },
        })
      }
      isLoading={isLoading}
    />
  );
};

export default ApprovePendingCancellationRequestsButton;

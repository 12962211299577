import { DialogId } from '../../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../../../../../store/dialogs/useCloseDialog';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../locales/translationTokens';
import { ApplicationDialog } from '../../../../../components/ApplicationDialog';
import { CreateManualSeasonalEventForm } from '../components/forms/CreateManualSeasonalEventForm';

export const CreateManualSeasonalEventDialog = () => {
  const id = DialogId.CREATE_MANUAL_SEASONAL_EVENT;
  const { isOpen } = useIsDialogOpen(id);
  const closeDialog = useCloseDialog(id);
  const { t } = useTranslation();

  return (
    <>
      {isOpen && (
        <ApplicationDialog
          dialogId={id}
          title={t(
            tokens.pd.performance.create_manual_seasonal_event_form.create_manual_seasonal_event
          )}
        >
          <CreateManualSeasonalEventForm onClick={closeDialog} />
        </ApplicationDialog>
      )}
    </>
  );
};

import { ReimbursementType } from '../../../../domain/automator/reimbursements/ReimbursementType';
import Chip from '@mui/material/Chip';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';

interface ReimbursementTypeChipProps {
  type: ReimbursementType | null;
}

export const ReimbursementTypeChip = ({ type }: ReimbursementTypeChipProps) => {
  const { t } = useTranslation();

  const determineLabel = () => {
    switch (type) {
      case ReimbursementType.LIM:
        return t(tokens.automator.reimbursements.type.lim);
      case ReimbursementType.RLIM:
        return t(tokens.automator.reimbursements.type.rlim);
      case ReimbursementType.RETURN_LABEL:
        return t(tokens.automator.reimbursements.type.return_label);
      case ReimbursementType.SHIPPING_LABEL:
        return t(tokens.automator.reimbursements.type.shipping_label);
      default:
        return t(tokens.automator.reimbursements.type.unknown);
    }
  };

  return (
    <Chip
      style={{ borderRadius: 4 }}
      size="small"
      label={determineLabel()}
      variant="outlined"
      color={'info'}
    />
  );
};

import SvgIcon from '@mui/material/SvgIcon';
import Box from '@mui/material/Box';
import { ReactNode } from 'react';
import Typography from '@mui/material/Typography';

interface StepperNumberBoxProps {
  color: string;
  number?: number;
  icon: ReactNode;
}

const StepperNumberBox = ({ color, number, icon }: StepperNumberBoxProps) => {
  const hasValue = number && number > 0;

  return (
    <Box
      width={52}
      border={`1px solid ${color}`}
      display="flex"
      flexDirection="row"
      paddingLeft="3px"
      paddingRight="3px"
      alignItems="center"
      justifyContent="space-between"
      borderRadius={0.5}
      bgcolor={hasValue ? color : 'WHITE'}
    >
      <SvgIcon sx={{ color: hasValue ? 'WHITE' : color, fontSize: 15 }}>{icon}</SvgIcon>
      <Typography
        variant="body1"
        color={hasValue ? 'WHITE' : color}
        style={{ fontWeight: hasValue ? 'normal' : 'lighter' }}
      >
        {number}
      </Typography>
    </Box>
  );
};

export default StepperNumberBox;

import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { ReturnRecoverability } from '../../../../domain/automator/returns/ReturnRecoverability';
import { ThreadPhase } from '../../../../domain/automator/messages/ThreadPhase';
import { CreateMessageTemplateForm } from '../components/CreateMessageTemplateForm';
import { MessageTemplateType } from '../../../../domain/automator/messages/MessageTemplateType';

export const CreateMessageTemplateDialog = () => {
  const id = DialogId.CREATE_MESSAGE_TEMPLATE;
  const { isOpen, data } = useIsDialogOpen(id);
  const closeDialog = useCloseDialog(id);
  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          title={t(tokens.automator.resolutions.dialogs.create_email_template.dialog_title)}
          maxWidth={'md'}
        >
          <CreateMessageTemplateForm
            type={data.type}
            phase={data.phase}
            recoverability={data.recoverability}
            onCreate={(messageTemplateId: number) => {
              data.onCreate(messageTemplateId);
              closeDialog();
            }}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface CreateMessageTemplateDialogData {
  type: MessageTemplateType;
  phase: ThreadPhase | null;
  recoverability: ReturnRecoverability | null;
  onCreate: (messageTemplateId: number) => void;
}

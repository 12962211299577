import { PRODUCTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import AutomatorProducts from '../../../domain/automator/products/AutomatorProducts';
import { AutomatorProductsQueryKey } from '../../queryKeys/AutomatorProductsQueryKey';

export const useFetchAutomatorProducts = () => {
  const url = useAutomatorApiAccountUrl(`${PRODUCTS_PATH}`);
  return useFetchData<AutomatorProducts>(url, AutomatorProductsQueryKey());
};

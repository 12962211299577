import { tokens } from '../../../../../locales/translationTokens';
import IconCopyButton from '../../../shared/IconCopyButton';

interface CopyCustomerNameButtonProps {
  aggregateId?: number;
  customerName: string;
}

const CopyCustomerNameButton = ({ aggregateId, customerName }: CopyCustomerNameButtonProps) => {
  return (
    <IconCopyButton
      value={customerName}
      label={tokens.automator.orders.buttons.copy.copy_customer_name}
      toastMessage={tokens.automator.orders.buttons.copy.customer_name_copied}
      activeCopyButtonState={
        aggregateId
          ? {
              activeAggregateId: aggregateId,
              activeButtonId: aggregateId + customerName,
            }
          : undefined
      }
      maxLength={19}
    />
  );
};

export default CopyCustomerNameButton;

import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import ShipmentsDayReportRow from './ShipmentsDayReportRow';
import Shipments from '../../../../domain/automator/shipments/Shipments';
import ShipmentsReport from '../../../../domain/automator/shipments/ShipmentsReport';
import { TableLoading } from '../../../../components/TableLoading';
import { t } from 'i18next';
import { tokens } from '../../../../locales/translationTokens';
import { getLastDays } from '../../../../helpers/getLastDays';

interface ShipmentsPerDayTableProps {
  selectedDate: string | null;
  setSelectedDate: (date: string | null) => void;
  shipments?: Shipments;
  shipmentsReport?: ShipmentsReport;
  isLoading: boolean;
}

const ShipmentsPerDayTable = ({
  selectedDate,
  setSelectedDate,
  shipments,
  shipmentsReport,
  isLoading,
}: ShipmentsPerDayTableProps) => {
  if (isLoading) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableBody>
          {getLastDays(14).map((date) => (
            <ShipmentsDayReportRow
              key={date}
              date={date}
              shipments={shipments?.shipments}
              isLoading={isLoading}
              shipmentDayReport={
                shipmentsReport &&
                shipmentsReport.dayReports.find((report) => report.date === date)!
              }
              loadShipmentEventsReport={shipmentsReport && shipmentsReport.loadEventsReport}
              isExpanded={selectedDate === date}
              onExpand={(newDate) => setSelectedDate(selectedDate === newDate ? null : newDate)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ShipmentsPerDayTable;

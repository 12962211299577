import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import { HandlingResult } from '../../../../domain/automator/returns/HandlingResult';
import { toast } from 'react-hot-toast';
import { useCreateReturn } from '../../../../api/automator/returns/useCreateReturn';
import Stack from '@mui/material/Stack';
import ReducedOrder from '../../../../domain/automator/orders/ReducedOrder';
import ReturnOrderItemSelect from './ReturnOrderItemSelect';
import ReducedOrderItem from '../../../../domain/automator/orders/ReducedOrderItem';

interface CreateReturnFormProps {
  order: ReducedOrder;
  onClick?: () => void;
}

interface CreateReturnOrderItem {
  isSelected: boolean;
  orderItem: ReducedOrderItem;
  quantity: number;
  handlingResult: HandlingResult;
}

export const CreateReturnForm = ({ onClick, order }: CreateReturnFormProps) => {
  const determineInitialCreateReturnOrderItems = (order: ReducedOrder): CreateReturnOrderItem[] => {
    return order.items.map((orderItem) => {
      return {
        isSelected: order.items.length == 1,
        orderItem: orderItem,
        quantity: 1,
        handlingResult: HandlingResult.RETURN_RECEIVED,
      };
    });
  };

  const [createReturnOrderItems, setCreateReturnOrderItems] = useState<CreateReturnOrderItem[]>(
    determineInitialCreateReturnOrderItems(order)
  );

  const { t } = useTranslation();

  const { mutate: createReturn, isLoading } = useCreateReturn();

  const onCreate = () => {
    createReturnOrderItems.forEach((createReturnOrderItem) => {
      createReturn(
        {
          order_item_id: createReturnOrderItem.orderItem.id,
          handling_result: createReturnOrderItem.handlingResult,
          quantity: createReturnOrderItem.quantity,
        },
        {
          onSuccess: async () => {
            onClick?.();
            toast.success(t(tokens.automator.returns.dialogs.create_return.return_created));
          },
        }
      );
    });
  };

  const handleSelect = (orderItem: ReducedOrderItem) => {
    setCreateReturnOrderItems(
      createReturnOrderItems.map((createReturnOrderItem) => {
        if (createReturnOrderItem.orderItem.id === orderItem.id) {
          return {
            ...createReturnOrderItem,
            isSelected: !createReturnOrderItem.isSelected,
          };
        }
        return createReturnOrderItem;
      })
    );
  };

  const handleChangeQuantity = (orderItem: ReducedOrderItem, quantity: number) => {
    setCreateReturnOrderItems(
      createReturnOrderItems.map((createReturnOrderItem) => {
        if (createReturnOrderItem.orderItem.id === orderItem.id) {
          return {
            ...createReturnOrderItem,
            quantity: quantity,
          };
        }
        return createReturnOrderItem;
      })
    );
  };

  const handleChangeHandlingResult = (
    orderItem: ReducedOrderItem,
    handlingResult: HandlingResult
  ) => {
    setCreateReturnOrderItems(
      createReturnOrderItems.map((createReturnOrderItem) => {
        if (createReturnOrderItem.orderItem.id == orderItem.id) {
          console.log('handlingResult', handlingResult);
          return {
            ...createReturnOrderItem,
            handlingResult: handlingResult,
          };
        }
        return createReturnOrderItem;
      })
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <Stack
        direction="column"
        gap={3}
      >
        <Stack
          direction="column"
          gap={2}
        >
          {createReturnOrderItems.map((createReturnOrderItem) => {
            return (
              <ReturnOrderItemSelect
                showSelect={order.items.length > 1}
                key={createReturnOrderItem.orderItem.id}
                orderItem={createReturnOrderItem.orderItem}
                quantity={createReturnOrderItem.quantity}
                handlingResult={createReturnOrderItem.handlingResult}
                isSelected={createReturnOrderItem.isSelected}
                setIsSelected={handleSelect}
                setQuantity={handleChangeQuantity}
                setHandlingResult={handleChangeHandlingResult}
              />
            );
          })}
        </Stack>

        <ActionButton
          label={t(tokens.automator.returns.dialogs.create_return.create_return)}
          onClick={() => onCreate()}
          isLoading={isLoading}
          isDisabled={createReturnOrderItems.every(
            (createReturnOrderItem) => !createReturnOrderItem.isSelected
          )}
          variant="contained"
          color="primary"
          size="small"
        />
      </Stack>
    </form>
  );
};

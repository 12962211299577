import { useTranslation } from 'react-i18next';
import ProposedAction from '../../../../domain/automator/alerts/ProposedAction';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { EventRuleActionType } from '../../../../domain/automator/eventRules/EventRuleActionType';
import { tokens } from '../../../../locales/translationTokens';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import EventRuleAction from '../../../../domain/automator/eventRules/EventRuleAction';
import { RecipientType } from '../../../../domain/automator/messages/RecipientType';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { Box } from '@mui/system';
import ActionButton from '../../../../components/ActionButton';
import { DismissAlertsActionIcon } from './DismissAlertsActionIcon';
import { toast } from 'react-hot-toast';
import { useExecuteProposedActions } from '../../../../api/automator/alerts/useExecuteProposedActions';
import { MessageTemplateType } from '../../../../domain/automator/messages/MessageTemplateType';
import { ThreadPhase } from '../../../../domain/automator/messages/ThreadPhase';
import { convertCentsToEuros } from '../../../pd/helpers/convertCentsToEuros';

interface ProposedActionViewProps {
  proposedAction: ProposedAction;
}

export const ProposedActionView = ({ proposedAction }: ProposedActionViewProps) => {
  const { t } = useTranslation();

  const openCreateEmailThreadDialog = useOpenDialog(DialogId.CREATE_EMAIL_THREAD);

  const openCreateResendDialog = useOpenDialog(DialogId.CREATE_RESEND);

  const { mutate: executeProposedActions, isLoading: isLoadingProposedActions } =
    useExecuteProposedActions();

  const determineActionName = (eventRuleAction: EventRuleAction) => {
    switch (eventRuleAction.type) {
      case EventRuleActionType.SEND_EMAIL:
        if (RecipientType.CUSTOMER) {
          return t(tokens.automator.alerts.event_rule_action_type.send_customer_email);
        } else if (RecipientType.BOL) {
          return t(tokens.automator.alerts.event_rule_action_type.send_bol_email);
        } else if (RecipientType.TRANSPORTER) {
          return t(tokens.automator.alerts.event_rule_action_type.send_transporter_email);
        } else {
          return '';
        }
      case EventRuleActionType.REQUEST_REIMBURSEMENT:
        return t(tokens.automator.alerts.event_rule_action_type.send_request_refund_email);

      case EventRuleActionType.CREATE_RESEND:
        return t(tokens.automator.alerts.event_rule_action_type.create_resend);
      case EventRuleActionType.MONITOR_RETURN_TO_SENDER:
        return t(tokens.automator.alerts.event_rule_action_type.monitor_return_to_sender);

      case EventRuleActionType.CLICK_URL:
        return t(tokens.automator.alerts.event_rule_action_type.click_url);
    }
  };

  const onExecuteWithAdjustments = () => {
    switch (proposedAction.eventRuleAction.type) {
      case EventRuleActionType.SEND_EMAIL:
      case EventRuleActionType.REQUEST_REIMBURSEMENT: {
        const determineAggregateId = (proposedAction: ProposedAction) => {
          switch (proposedAction.eventRule.phase) {
            case ThreadPhase.ORDER:
              return proposedAction.orderItemId
                ? proposedAction.orderItemId
                : proposedAction.order.items.length == 1
                ? proposedAction.order.items[0].id
                : null;
            case ThreadPhase.SHIPMENT:
              return proposedAction.shipmentId;
            case ThreadPhase.RETURN:
              return proposedAction.returnItemId ? proposedAction.returnItemId : null;
          }
        };

        openCreateEmailThreadDialog({
          proposedActionId: proposedAction.id,
          aggregateId: determineAggregateId(proposedAction) ?? null,
          orderId: proposedAction.order.id,
          phase: proposedAction.order.currentPhase,
          messageTemplateId: proposedAction.eventRuleAction.emailAction!.messageTemplate.id,
          recipientType: proposedAction.eventRuleAction.emailAction!.recipientType,
          body: null,
          messageTemplateType: MessageTemplateType.MANUAL,
        });
        break;
      }
      case EventRuleActionType.CREATE_RESEND:
        openCreateResendDialog({
          orderId: proposedAction.order.id,
          alertId: proposedAction.id,
        });
        break;
      case EventRuleActionType.MONITOR_RETURN_TO_SENDER:
        break;
      case EventRuleActionType.CLICK_URL: {
        const url = `${proposedAction.eventRuleAction.url}`;
        window.open(url, '_blank');
        break;
      }
    }
  };

  const onExecute = () => {
    executeProposedActions(
      { proposed_action_ids: [proposedAction.id] },
      {
        onSuccess: () => {
          toast.success(t(tokens.automator.alerts.executed));
        },
      }
    );
  };

  return (
    <Stack
      gap={3}
      direction="row"
      alignItems={'center'}
    >
      {proposedAction.amountInCents && (
        <Chip
          style={{ borderRadius: 4, fontSize: 15 }}
          label={'€ ' + convertCentsToEuros(proposedAction.amountInCents)}
          variant="outlined"
          color={'warning'}
        />
      )}

      <Box width={220}>
        <Chip
          style={{ borderRadius: 4, fontSize: 15 }}
          label={determineActionName(proposedAction.eventRuleAction)}
          variant="outlined"
          color={!proposedAction.isExecuted ? 'primary' : 'success'}
          onClick={!proposedAction.isExecuted ? onExecuteWithAdjustments : undefined}
        />
      </Box>

      <ActionButton
        icon={<PlayCircleOutlineIcon />}
        label={
          !proposedAction.isExecuted
            ? t(tokens.automator.alerts.execute)
            : t(tokens.automator.alerts.executed)
        }
        onClick={!proposedAction.isExecuted ? onExecute : undefined}
        isLoading={isLoadingProposedActions}
        size={'small'}
        variant={!proposedAction.isExecuted ? 'contained' : 'outlined'}
        color={!proposedAction.isExecuted ? 'primary' : 'success'}
        isDisabled={proposedAction.isExecuted}
      />

      {!proposedAction.isDismissed && <DismissAlertsActionIcon alertIds={[proposedAction.id]} />}
    </Stack>
  );
};

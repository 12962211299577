import { alpha } from '@mui/material/styles';
import type { Theme } from '@mui/material';
import { Box, IconButton, Stack, SvgIcon, useMediaQuery } from '@mui/material';
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';
import { SIDE_NAV_WIDTH } from './SideNav';
import { tss } from 'tss-react/mui-compat';
import { AccountButton } from '../../account-button/AccountButton';
import { OrderSummarySearch } from '../../../pages/automator/orderSummaries/components/OrderSummarySearch';
import ActionButton from '../../../components/ActionButton';
import { tokens } from '../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';

export const TOP_NAV_HEIGHT: number = 64;

interface TopNavProps {
  onMobileNavOpen?: () => void;
}

export const TopNav = ({ onMobileNavOpen }: TopNavProps) => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const { cx, classes } = useStyles();

  const { t } = useTranslation();

  return (
    <Box
      className={cx(classes.topNav)}
      component="header"
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        className={cx(classes.topNavContent)}
      >
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
          paddingRight={2}
        >
          <ActionButton
            label={t(tokens.automator.tutorials.tutorial)}
            variant="text"
            size="small"
            onClick={() => window.open('https://blue-engine.tawk.help/', '_blank')}
          />

          <Box paddingLeft={2}>
            <OrderSummarySearch />
          </Box>

          {/*<Button*/}
          {/*  href="mailto:info@blueengine.nl"*/}
          {/*  variant="outlined"*/}
          {/*>*/}
          {/*  {t(tokens.nav.support)}*/}
          {/*</Button>*/}
          {/*<DarkModeToggleButton />*/}
          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  );
};

const useStyles = tss.create(({ theme }) => ({
  topNav: {
    backdropFilter: 'blur(6px)',
    backgroundColor: alpha(theme.palette.background.default, 0.8),
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.up('lg')]: {
      left: `${SIDE_NAV_WIDTH}px`,
      width: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
    },
  },
  topNavContent: {
    minHeight: TOP_NAV_HEIGHT,
    px: 2,
  },
}));

import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import Retailer from '../../../domain/automator/retailers/Retailer';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';
import { RETAILER_SETTINGS_PATH } from '../automatorApiPaths';

export const useUpdateRetailerSettings = (retailerSettingsId: number) => {
  const url = useAutomatorApiAccountUrl(RETAILER_SETTINGS_PATH + '/' + retailerSettingsId);
  return useAuthorizedPost<Retailer, UpdateRetailerSettingsData>(url, [
    EndpointQueryKey.RETAILER_SETTINGS,
  ]);
};

export interface UpdateRetailerSettingsData {
  initial_customer_invoice_numbers: InitialCustomerInvoiceNumberUpdateData[];
}

export interface InitialCustomerInvoiceNumberUpdateData {
  year: number;
  customer_invoice_number: number;
}

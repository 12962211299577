import { tokens } from '../../../../locales/translationTokens';
import { DateChipAndText } from './DateChipAndText';

interface ExpectedDeliveryDateTextProps {
  expectedDeliveryDate: string;
}

export const ExpectedDeliveryDateText = ({
  expectedDeliveryDate,
}: ExpectedDeliveryDateTextProps) => {
  return (
    <DateChipAndText
      chip={'E'}
      date={expectedDeliveryDate}
      tooltip={tokens.automator.orders.expected_delivery_date_tooltip}
    />
  );
};

import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import Orders from '../../../../domain/automator/orders/Orders';
import OrdersDayReportRow from './OrdersDayReportRow';
import Account from '../../../../domain/automator/account/Account';
import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';
import { CountryCode } from '../../../../domain/automator/orders/CountryCode';
import { TableLoading } from '../../../../components/TableLoading';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { getLastDays } from '../../../../helpers/getLastDays';
import { OrdersMode } from '../OrdersPage';

interface OrdersPerDayTableProps {
  account: Account;
  selectedStartDate: string | undefined;
  selectedEndDate: string | undefined;
  setSelectedEndDate: (endDate: string | undefined) => void;
  setSelectedStartDate: (startDate: string | undefined) => void;
  orders?: Orders;
  isLoading: boolean;
  distributionParty: DistributionParty;
  selectedCountries: CountryCode[];
  mode: OrdersMode;
}

const OrdersPerDayTable = ({
  selectedStartDate,
  setSelectedEndDate,
  setSelectedStartDate,
  orders,
  isLoading,
  account,
  distributionParty,
  selectedCountries,
  mode,
}: OrdersPerDayTableProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableBody>
          {getLastDays(14).map((date) => (
            <OrdersDayReportRow
              mode={mode}
              selectedCountries={selectedCountries}
              key={date}
              account={account}
              orders={orders?.orders}
              ordersDayReport={orders?.dayReports.find((dayReport) => dayReport.date === date)}
              startDate={date}
              endDate={date}
              onExpand={(startDate: string, endDate: string) => {
                if (selectedStartDate === startDate) {
                  setSelectedStartDate(undefined);
                  setSelectedEndDate(undefined);
                } else {
                  setSelectedStartDate(startDate);
                  setSelectedEndDate(endDate);
                }
              }}
              isExpanded={selectedStartDate === date}
              isLoading={isLoading}
              distributionParty={distributionParty}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrdersPerDayTable;

export enum HandlingResult {
  RETURN_RECEIVED = 'RETURN_RECEIVED',
  EXCHANGE_PRODUCT = 'EXCHANGE_PRODUCT',
  RETURN_DOES_NOT_MEET_CONDITIONS = 'RETURN_DOES_NOT_MEET_CONDITIONS',
  REPAIR_PRODUCT = 'REPAIR_PRODUCT',
  CUSTOMER_KEEPS_PRODUCT_PAID = 'CUSTOMER_KEEPS_PRODUCT_PAID',
  STILL_APPROVED = 'STILL_APPROVED',
  CUSTOMER_KEEPS_PRODUCT_FREE_OF_CHARGE = 'CUSTOMER_KEEPS_PRODUCT_FREE_OF_CHARGE',
  RETURN_ITEM_LOST = 'RETURN_ITEM_LOST',
  EXPIRED = 'EXPIRED',
  EXCESSIVE_RETURN = 'EXCESSIVE_RETURN',
  STILL_RECEIVED = 'STILL_RECEIVED',
  CANCELLED_BY_CUSTOMER = 'CANCELLED_BY_CUSTOMER',
  FAILED_TO_CREATE_SHIPPING_LABEL = 'FAILED_TO_CREATE_SHIPPING_LABEL',
  UNDER_INVESTIGATION = 'UNDER_INVESTIGATION',
  FAILED_TO_COLLECT_BY_TRANSPORTER = 'FAILED_TO_COLLECT_BY_TRANSPORTER',
}

const getWeekNumberOfYear = (date: string) => {
  const dateObj = new Date(date);
  const dateUtc = new Date(Date.UTC(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate()));
  dateUtc.setUTCDate(dateUtc.getUTCDate() + 4 - (dateUtc.getUTCDay() || 7));
  const yearStart = new Date(Date.UTC(dateUtc.getUTCFullYear(), 0, 1));
  // Use getTime method to get timestamp of dates before subtracting
  return Math.ceil(((dateUtc.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
};

export default getWeekNumberOfYear;

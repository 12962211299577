import { PROPOSED_ACTIONS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import ProposedActions from '../../../domain/automator/alerts/ProposedActions';
import { ProposedActionsQueryKey } from '../../queryKeys/ProposedActionsQueryKey';
import { GetProposedActionsStatus } from '../../../domain/automator/alerts/GetProposedActionsStatus';

export const useFetchProposedActions = (status: GetProposedActionsStatus, date: string | null) => {
  const url =
    useAutomatorApiAccountUrl(PROPOSED_ACTIONS_PATH) +
    '?' +
    `status=${status}` +
    (date ? `&date=${date}` : '');

  return useFetchData<ProposedActions>(url, ProposedActionsQueryKey(status, date));
};

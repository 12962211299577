import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { AD_GROUP_PERFORMANCES } from '../pdApiPaths';
import { getDateStringFromDate } from '../../../helpers/getDateStringFromDate';
import AdGroupPerformances from '../../../domain/pd/AdGroupPerformances';
import { AdGroupPerformancesQueryKey } from '../../queryKeys/AdGroupPerformancesQueryKey';

export const useFetchAdGroupPerformances = (adGroupId: number, startDate: Date, endDate: Date) => {
  const url = usePdApiAccountUrl(
    AD_GROUP_PERFORMANCES +
      `?adGroupId=${adGroupId}&startDate=${getDateStringFromDate(
        startDate
      )}&endDate=${getDateStringFromDate(endDate)}`
  );
  return useFetchData<AdGroupPerformances>(
    url,
    AdGroupPerformancesQueryKey(adGroupId, startDate, endDate)
  );
};

import { DialogId } from '../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../../../store/dialogs/useCloseDialog';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../components/ApplicationDialog';
import ActionButton from '../../../components/ActionButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { tokens } from '../../../locales/translationTokens';

export const WarningDialog = () => {
  const dialogId = DialogId.WARNING;

  const { isOpen, data } = useIsDialogOpen(dialogId);
  const closeDialog = useCloseDialog(dialogId);
  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={dialogId}
          title={t(data.title)}
        >
          <Stack
            direction="column"
            gap={3}
          >
            <Typography variant="body1">{t(data.message)}</Typography>

            <Stack
              direction="row"
              gap={1}
            >
              <ActionButton
                color={'warning'}
                label={t(data.buttonLabel)}
                onClick={() => {
                  data.onConfirm();
                  closeDialog();
                }}
              />

              <ActionButton
                variant={'outlined'}
                label={t(tokens.common.cancel)}
                onClick={() => {
                  closeDialog();
                }}
              />
            </Stack>
          </Stack>
        </ApplicationDialog>
      )}
    </>
  );
};

export interface WarningDialogData {
  title: string;
  message: string;
  buttonLabel: string;
  onConfirm: () => void;
}

export enum OrderEventType {
  ORDER_PLACED = 'ORDER_PLACED',
  ORDER_SHIPPED = 'ORDER_SHIPPED',
  LABEL_CREATED = 'LABEL_CREATED',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_ITEM_CANCELLED = 'ORDER_ITEM_CANCELLED',
  SHIPMENT_PRE_ANNOUNCED = 'SHIPMENT_PRE_ANNOUNCED',
  SHIPMENT_AT_TRANSPORTER = 'SHIPMENT_AT_TRANSPORTER',
  SHIPMENT_IN_TRANSIT = 'SHIPMENT_IN_TRANSIT',
  SHIPMENT_DELIVERED_AT_NEIGHBOURS = 'SHIPMENT_DELIVERED_AT_NEIGHBOURS',
  SHIPMENT_DELIVERED_AT_CUSTOMER = 'SHIPMENT_DELIVERED_AT_CUSTOMER',
  SHIPMENT_PICKED_UP_AT_PICK_UP_POINT = 'SHIPMENT_PICKED_UP_AT_PICK_UP_POINT',
  SHIPMENT_AT_PICK_UP_POINT = 'SHIPMENT_AT_PICK_UP_POINT',
  SHIPMENT_RETURNED_TO_SENDER = 'SHIPMENT_RETURNED_TO_SENDER',
  SHIPMENT_INBOUND_COLLECT = 'SHIPMENT_INBOUND_COLLECT',
  SHIPMENT_RETURN_TO_SENDER_SCANNED = 'SHIPMENT_RETURN_TO_SENDER_SCANNED',
  RETURN_REGISTERED = 'RETURN_REGISTERED',
  RETURN_ITEM_SCANNED = 'RETURN_ITEM_SCANNED',
  RETURN_ITEM_IN_REPAIR = 'RETURN_ITEM_IN_REPAIR',
  RETURN_ITEM_IN_EXCHANGE = 'RETURN_ITEM_IN_EXCHANGE',
  RETURN_ITEM_ACCEPTED = 'RETURN_ITEM_ACCEPTED',
  RETURN_ITEM_REJECTED = 'RETURN_ITEM_REJECTED',
  RETURN_ITEM_CANCELED = 'RETURN_ITEM_CANCELED',
  RETURN_ITEM_LOST = 'RETURN_ITEM_LOST',
  RESEND_CREATED = 'RESEND_CREATED',
  RESEND_HANDLED = 'RESEND_HANDLED',
  EXPECTED_DELIVERY_DATE = 'EXPECTED_DELIVERY_DATE',
  REIMBURSEMENT_REQUESTED = 'REIMBURSEMENT_REQUESTED',
  REIMBURSEMENT_CREATED = 'REIMBURSEMENT_CREATED',
}

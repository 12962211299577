import EventTrigger from '../../../../domain/automator/eventRules/EventTrigger';
import { tokens } from '../../../../locales/translationTokens';
import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import Typography from '@mui/material/Typography';
import { determineEventTriggerLabel } from '../../resolutions/components/determineEventTriggerLabel';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';

import TimerIcon from '@mui/icons-material/Timer';
import HandymanIcon from '@mui/icons-material/Handyman';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import IconButton from '@mui/material/IconButton';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';

import { DoubleOrderIcon } from '../../../../../public/assets/icons/DoubleOrderIcon';
import { ReturnToSenderIcon } from '../../../../../public/assets/icons/ReturnToSenderIcon';
import { ParcelShopAlmostExpiredIcon } from '../../../../../public/assets/icons/ParcelShopAlmostExpiredIcon';
import { ParcelShopReturnToSenderIcon } from '../../../../../public/assets/icons/ParcelShopReturnToSenderIcon';
import InfoIcon from '@mui/icons-material/Info';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';

import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import RecommendIcon from '@mui/icons-material/Recommend';
import UTurnLeftIcon from '@mui/icons-material/UTurnLeft';

interface EventRuleInfoButtonProps {
  eventTrigger: EventTrigger;
  distributionParty: DistributionParty | null;
  showInfo: boolean;
}

export const EventRuleInfoButton = ({
  eventTrigger,
  distributionParty,
  showInfo,
}: EventRuleInfoButtonProps) => {
  const openDialog = useOpenDialog(DialogId.INFO);

  const { t } = useTranslation();

  const determineMessage = () => {
    switch (eventTrigger) {
      case EventTrigger.RECOVERABILITY_UNCLEAR_RETURN_ITEM_REGISTERED:
        return tokens.automator.alerts.event_rule_info;
      case EventTrigger.ORDER_IS_PLACED_DOUBLE:
        return tokens.automator.alerts.event_rule_info.order_is_placed_double;
      case EventTrigger.PICKUP_POINT_WILL_RETURN_TO_SENDER:
        return tokens.automator.alerts.event_rule_info.order_is_placed_double;
      case EventTrigger.SHIPMENT_RETURNED_TO_SENDER_BY_TRANSPORTER:
        return distributionParty == DistributionParty.BOL
          ? tokens.automator.alerts.event_rule_info.vvb_shipment_returned_to_sender_by_transporter
          : tokens.automator.alerts.event_rule_info
              .non_vvb_shipment_returned_to_sender_by_transporter;
      case EventTrigger.ORDER_OVERDUE:
        return tokens.automator.alerts.event_rule_info.order_overdue;
      case EventTrigger.SHIPMENT_RETURNED_TO_SENDER_BY_PICKUP_POINT:
        return tokens.automator.alerts.event_rule_info.shipment_returned_to_sender_by_pickup_point;
      case EventTrigger.RETURN_ITEM_REJECTED:
        return tokens.automator.alerts.event_rule_info.return_item_rejected;
      case EventTrigger.SHIPMENT_OVERDUE:
        return distributionParty == DistributionParty.BOL
          ? tokens.automator.alerts.event_rule_info.vvb_shipment_overdue
          : tokens.automator.alerts.event_rule_info.non_vvb_shipment_overdue;
      case EventTrigger.RECOVERABLE_RETURN_ITEM_REGISTERED:
        return tokens.automator.alerts.event_rule_info.recoverable_return_item_registered;
      case EventTrigger.RETURN_ITEM_EXPIRED:
        return tokens.automator.alerts.event_rule_info.return_item_expired;
      case EventTrigger.SURCHARGE_CREATED:
        return tokens.automator.alerts.event_rule_info.surcharge_created;
    }
  };

  const determineIcon = () => {
    switch (eventTrigger) {
      case EventTrigger.RECOVERABILITY_UNCLEAR_RETURN_ITEM_REGISTERED:
        return <QuestionMarkIcon />;
      case EventTrigger.ORDER_IS_PLACED_DOUBLE:
        return <DoubleOrderIcon />;
      case EventTrigger.PICKUP_POINT_WILL_RETURN_TO_SENDER:
        return <ReturnToSenderIcon />;
      case EventTrigger.SHIPMENT_RETURNED_TO_SENDER_BY_TRANSPORTER:
        return <ParcelShopAlmostExpiredIcon />;
      case EventTrigger.ORDER_OVERDUE:
        return <DepartureBoardIcon />;
      case EventTrigger.SHIPMENT_RETURNED_TO_SENDER_BY_PICKUP_POINT:
        return <ParcelShopReturnToSenderIcon />;
      case EventTrigger.RETURN_ITEM_REJECTED:
        return <ThumbDownOffAltIcon />;
      case EventTrigger.SHIPMENT_OVERDUE:
        return <TimerIcon />;
      case EventTrigger.RECOVERABLE_RETURN_ITEM_REGISTERED:
        return <HandymanIcon />;
      case EventTrigger.RETURN_ITEM_EXPIRED:
        return <TimerIcon />;
      case EventTrigger.NEW_ORDER:
        return <LocalOfferIcon />;
      case EventTrigger.CUSTOMER_IS_RECURRING:
        return <InfoIcon />;
      case EventTrigger.SHIPMENT_SHIPPED:
        return <InfoIcon />;
      case EventTrigger.SHIPMENT_TRACK_AND_TRACE_UPLOADED:
        return <InfoIcon />;
      case EventTrigger.SHIPMENT_DELIVERED:
        return <InfoIcon />;
      case EventTrigger.SHIPMENT_DELIVERED_AT_PICKUP_POINT:
        return <InfoIcon />;
      case EventTrigger.SHIPMENT_RETURNED_TO_SENDER:
        return <ReturnToSenderIcon />;
      case EventTrigger.EXPECTED_DELIVERY_DATE_REACHED:
        return <RecommendIcon />;
      case EventTrigger.RETURN_REGISTERED:
        return <UTurnLeftIcon />;
      case EventTrigger.NOT_RECOVERABLE_RETURN_ITEM_REGISTERED:
        return <InfoIcon />;
      case EventTrigger.RETURN_HANDLED:
        return <InfoIcon />;
      case EventTrigger.SURCHARGE_CREATED:
        return <NotificationImportantIcon />;
    }
  };

  return (
    <Stack
      direction="row"
      gap={1.5}
      style={{ cursor: 'pointer' }}
      alignItems="center"
      onClick={
        showInfo
          ? (event) => {
              openDialog({
                title: tokens.automator.alerts.event_rule_info.info,
                message: determineMessage() as string,
              });
              event.stopPropagation();
            }
          : undefined
      }
    >
      <IconButton color="primary">{determineIcon()}</IconButton>

      <Typography
        fontSize={16}
        fontWeight={500}
        variant="body2"
      >
        {t(determineEventTriggerLabel(eventTrigger))}
      </Typography>
    </Stack>
  );
};

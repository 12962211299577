import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { tokens } from '../../../../../../locales/translationTokens';
import { Scrollbar } from '../../../../../../../devias/src/components/scrollbar';
import {
  PerformanceHeaderTableCell,
  PerformanceHeaderTableCellColor,
} from '../PerformanceHeaderTableCell';
import { AdPerformancesTableRow } from './AdPerformancesTableRow';
import AdPerformances from '../../../../../../domain/pd/AdPerformances';

interface AdPerformancesTableProps {
  adPerformances: AdPerformances;
  rollingAverageDays: number;
}

export const AdPerformancesTable = ({
  adPerformances,
  rollingAverageDays,
}: AdPerformancesTableProps) => {
  const adPerformancesSorted = adPerformances.adPerformances!.sort((a, b) =>
    new Date(a.date).getTime() - new Date(b.date).getTime() > 0 ? -1 : 1
  );

  const backgroundColor = PerformanceHeaderTableCellColor.LIGHTBLUE;

  return (
    <Scrollbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <PerformanceHeaderTableCell
              value={tokens.pd.performance.ad_performances_table.ad_performances}
              backgroundColor={backgroundColor}
              colSpan={8}
            />
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <PerformanceHeaderTableCell
              value={tokens.pd.performance.ad_performances_table.clicks}
              backgroundColor={backgroundColor}
              rollingAverageDays={rollingAverageDays}
            />

            <PerformanceHeaderTableCell
              value={tokens.pd.performance.ad_performances_table.sales}
              backgroundColor={backgroundColor}
              rollingAverageDays={rollingAverageDays}
            />

            <PerformanceHeaderTableCell
              value={tokens.pd.performance.ad_performances_table.conversions}
              backgroundColor={backgroundColor}
              rollingAverageDays={rollingAverageDays}
            />

            <PerformanceHeaderTableCell
              value={tokens.pd.performance.ad_performances_table.cpc}
              backgroundColor={backgroundColor}
              rollingAverageDays={rollingAverageDays}
            />

            <PerformanceHeaderTableCell
              value={tokens.pd.performance.ad_performances_table.spent}
              backgroundColor={backgroundColor}
              rollingAverageDays={rollingAverageDays}
            />

            <PerformanceHeaderTableCell
              value={tokens.pd.performance.ad_performances_table.impressions}
              backgroundColor={backgroundColor}
              rollingAverageDays={rollingAverageDays}
            />

            <PerformanceHeaderTableCell
              value={tokens.pd.performance.ad_performances_table.acos}
              backgroundColor={backgroundColor}
              rollingAverageDays={rollingAverageDays}
            />

            <PerformanceHeaderTableCell
              value={tokens.pd.performance.ad_performances_table.ctr}
              backgroundColor={backgroundColor}
              rollingAverageDays={rollingAverageDays}
            />
          </TableRow>
        </TableHead>

        <TableBody>
          {adPerformancesSorted.map((adPerformance, index) => {
            return (
              <AdPerformancesTableRow
                key={index}
                adPerformance={adPerformance}
                adPerformanceMinMaxReports={adPerformances.minMaxReports}
              />
            );
          })}
        </TableBody>
      </Table>
    </Scrollbar>
  );
};

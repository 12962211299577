import Chip from '@mui/material/Chip';
import ProductGroup from '../../../../domain/automator/products/ProductGroup';

interface ProductGroupChipProps {
  productGroup: ProductGroup;
  onDelete?: (productGroupId: number) => void;
  onClick: (productGroupId: number) => void;
  isSelected?: boolean;
}

export const ProductGroupChip = ({
  productGroup,
  onDelete,
  onClick,
  isSelected,
}: ProductGroupChipProps) => {
  function stringToPastelColour(str: string): string {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const hue = hash % 360; // Hue value needs to be between 0 and 359
    return `hsl(${hue}, 100%, 85%)`; // Saturation is set to 100%, Lightness to 85% to create pastel colors
  }

  const determineBackgroundColor = (name: string): string | undefined => {
    if (isSelected == null || isSelected) {
      return stringToPastelColour(name);
    } else {
      return undefined;
    }
  };
  return (
    <Chip
      style={{ backgroundColor: determineBackgroundColor(productGroup.name), color: 'BLACK' }}
      label={productGroup.name}
      onClick={() => onClick(productGroup.id)}
      onDelete={onDelete ? () => onDelete?.(productGroup.id) : undefined}
      variant={'filled'}
      color={isSelected ? 'success' : 'default'}
    />
  );
};

import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import { Box } from '@mui/material';
import { WarningSharp } from '@mui/icons-material';

interface CanNotProcessBoxProps {
  message: string;
}

export const CanNotProcessBox = ({ message }: CanNotProcessBoxProps) => {
  const { t } = useTranslation();

  return (
    <Box
      display={'flex'}
      gap={'0.5rem'}
      flexDirection={'row'}
    >
      <SvgIcon sx={{ fontSize: 16, color: 'red', marginTop: '3.5px' }}>
        <WarningSharp />
      </SvgIcon>
      <Typography
        variant="h6"
        color="error"
      >
        {t(message)}
      </Typography>
    </Box>
  );
};

import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tss } from 'tss-react/mui-compat';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { getAlphabeticFormatFromDate } from '../../../../helpers/getAlphabeticFormatFromDate';

interface LatestHandoverDateTextProps {
  chip: string;
  date: string;
  tooltip: string;
  onClick?: () => void;
  icon?: React.ReactNode;
}

export const DateChipAndText = ({
  chip,
  date,
  tooltip,
  onClick,
  icon,
}: LatestHandoverDateTextProps) => {
  const { t } = useTranslation();

  const { classes, cx } = useStyles();

  return (
    <Tooltip
      title={t(tooltip)}
      placement={'right'}
      enterDelay={2000}
      enterNextDelay={2000}
    >
      <Stack
        direction="row"
        gap={0.5}
        onClick={onClick}
      >
        <Chip
          style={{ borderRadius: '0.25rem' }}
          size="small"
          label={chip}
          variant="outlined"
        />
        <Typography
          className={onClick && cx(classes.chipDateAndText)}
          variant={'subtitle2'}
          noWrap
        >
          {getAlphabeticFormatFromDate(new Date(date))}
        </Typography>
        {icon && <span className={cx(classes.chipDateAndTextIcon)}>{icon}</span>}
      </Stack>
    </Tooltip>
  );
};

const useStyles = tss.create(() => ({
  chipDateAndText: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.45rem',
  },
  chipDateAndTextIcon: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

import TableCell from '@mui/material/TableCell';
import { useTranslation } from 'react-i18next';

export enum PerformanceHeaderTableCellColor {
  LIGHTYELLOW = '#eed684',
  LIGHTGREEN = '#c0ccbb',
  LIGHTBLUE = '#bec5f1',
  LIGHT_PINK = '#C763A8',
}

interface PerformanceHeaderTableCellProps {
  children?: React.ReactNode;
  backgroundColor?: PerformanceHeaderTableCellColor;
  value?: string;
  rollingAverageDays?: number;
  colSpan?: number;
}

export const PerformanceHeaderTableCell = ({
  children,
  backgroundColor,
  value,
  rollingAverageDays,
  colSpan,
}: PerformanceHeaderTableCellProps) => {
  const { t } = useTranslation();

  const getValue = (value?: string, rollingAverage?: number) => {
    if (!value) {
      return '';
    } else if (rollingAverage) {
      return `${t(value)} (${rollingAverage}D)`;
    } else {
      return t(value);
    }
  };

  return (
    <TableCell
      width={1}
      height="75px"
      colSpan={colSpan}
      style={{ backgroundColor: backgroundColor }}
    >
      {children ? children : getValue(value, rollingAverageDays)}
    </TableCell>
  );
};

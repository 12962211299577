import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import AddRetailerForm from '../../orders/components/AddRetailerForm';

export const AddRetailerDialog = () => {
  const id = DialogId.ADD_RETAILER;
  const { isOpen } = useIsDialogOpen(id);
  const { t } = useTranslation();
  const closeDialog = useCloseDialog(id);

  return (
    <>
      {isOpen && (
        <ApplicationDialog
          dialogId={id}
          title={t(tokens.automator.retailers.add_retailer.add_retailer)}
        >
          <AddRetailerForm onClick={closeDialog} />
        </ApplicationDialog>
      )}
    </>
  );
};

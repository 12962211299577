import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { ASSORTMENT_VISITS } from '../pdApiPaths';
import AssortmentVisits from '../../../domain/pd/AssortmentVisits';
import { AssortmentVisitsQueryKey } from '../../queryKeys/AssortmentVisitsQueryKey';
import { getDateStringFromDate } from '../../../helpers/getDateStringFromDate';

export const useFetchAssortmentVisits = (storeId: number, startDate: Date, endDate: Date) => {
  const url = usePdApiAccountUrl(
    ASSORTMENT_VISITS +
      `?storeId=${storeId}&startDate=${getDateStringFromDate(
        startDate
      )}&endDate=${getDateStringFromDate(endDate)}`
  );
  return useFetchData<AssortmentVisits>(url, AssortmentVisitsQueryKey(storeId, startDate, endDate));
};

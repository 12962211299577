import jwtDecode from 'jwt-decode';

interface DecodedToken {
  sub: string;
  account_id: number;
  exp: number;
  nbf: number;
}

class JwtService {
  static convertUnixTimestampToDate(unixTimestamp: number): Date {
    return new Date(unixTimestamp * 1000);
  }

  static decodeToken(jwt: string): DecodedToken {
    return jwtDecode(jwt) as DecodedToken;
  }

  static getTokenExpirationDate(jwt: string): Date {
    const decodedToken = this.decodeToken(jwt);
    return this.convertUnixTimestampToDate(decodedToken!.exp);
  }

  static getTokenNotBeforeDate(jwt: string): Date {
    const decodedToken = this.decodeToken(jwt);
    return this.convertUnixTimestampToDate(decodedToken!.nbf);
  }

  static getUserIdFromToken(jwt: string): number {
    const decodedToken = this.decodeToken(jwt);
    return Number(decodedToken!.sub);
  }

  static getAccountIdFromToken(jwt: string): number {
    const decodedToken = this.decodeToken(jwt);
    return decodedToken!.account_id;
  }
}

export default JwtService;

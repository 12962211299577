import { useLocation } from 'react-router-dom'; // Custom hook to get the value of a query parameter

// Custom hook to get the value of a query parameter
function useQueryParam<T = string>(key: string): T | null {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryValue = queryParams.get(key);

  // Add a type assertion, you may need to adjust this depending on your logic
  return (queryValue as unknown as T) || null;
}

export default useQueryParam;

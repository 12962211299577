import type { ReactNode } from 'react';

import { useMenu } from './useMenu';
import { VerticalLayout } from './vertical-layout/VerticalLayout';

interface LayoutProps {
  children?: ReactNode;
}

export const Layout = (props: LayoutProps) => {
  const sections = useMenu();
  return (
    <VerticalLayout
      sections={sections}
      navColor={'evident'}
      {...props}
    />
  );
};

import { RecipientType } from '../../../../../domain/automator/messages/RecipientType';

const determineDefaultEmailMessageBody = (newRecipientType: RecipientType) => {
  switch (newRecipientType) {
    case RecipientType.TRANSPORTER:
      return `Beste [[TRANSPORTER_NAME]],<br><br>Het gaat om zending: [[SHIPMENT_TRACKING_LINK]]<br><br>Met vriendelijke groet,<br><br>Team [[STORE_NAME]]<br>Partner van Bol.com`;
    case RecipientType.CUSTOMER:
      return `Beste [[CUSTOMER_NAME]],<br><br><br><br>Met vriendelijke groet,<br><br>Team [[STORE_NAME]]<br>Partner van Bol.com`;
    case RecipientType.BOL:
      return `Beste Bol,<br><br><br><br>Met vriendelijke groet,<br><br>Team [[STORE_NAME]]<br>Winkelnummer: [[BOL_RETAILER_ID]]`;
    default:
      return '';
  }
};

export default determineDefaultEmailMessageBody;

import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import Stack from '@mui/material/Stack';
import { useCreateProductGroup } from '../../../../api/automator/products/useCreateProductGroup';
import { toast } from 'react-hot-toast';
import TextField from '@mui/material/TextField';
import ActionButton from '../../../../components/ActionButton';
import { useState } from 'react';

interface CreateProductGroupFormProps {
  onSave?: () => void;
}

export const CreateProductGroupForm = ({ onSave }: CreateProductGroupFormProps) => {
  const [name, setName] = useState('');

  const { t } = useTranslation();

  const { mutate: createProductGroup, isLoading } = useCreateProductGroup();

  const doCreateProductGroup = () => {
    const data = {
      name: name,
    };

    createProductGroup(data, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.products.dialogs.create_group.group_created));
        onSave?.();
      },
    });
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      gap={4}
    >
      <TextField
        label={t(tokens.automator.products.dialogs.create_group.name)}
        size="small"
        variant="outlined"
        value={name}
        style={{ width: 200 }}
        onChange={(e) => setName(e.target.value)}
      />

      <ActionButton
        onClick={doCreateProductGroup}
        isLoading={isLoading}
        label={t(tokens.automator.products.dialogs.create_group.create_group)}
        fullWidth
      />
    </Stack>
  );
};

import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { ReturnsQueryKey } from '../../queryKeys/ReturnsQueryKey';
import { RETURNS_PATH } from '../automatorApiPaths';
import Returns from '../../../domain/automator/returns/Returns';
import { ReturnItemFilter } from '../../../domain/automator/returns/ReturnItemFilter';

export const useFetchReturns = (filter: ReturnItemFilter) => {
  const url = useAutomatorApiAccountUrl(RETURNS_PATH) + ('?filter=' + filter);
  return useFetchData<Returns>(url, ReturnsQueryKey(filter));
};

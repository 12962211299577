import { EndpointQueryKey } from './EndpointQueryKey';
import { getDateStringFromDate } from '../../helpers/getDateStringFromDate';

export const AssortmentVisitsQueryKey = (storeId: number, startDate: Date, endDate: Date) => {
  return (
    EndpointQueryKey.ASSORTMENT_VISITS +
    '_' +
    storeId +
    '_' +
    getDateStringFromDate(startDate) +
    '_' +
    getDateStringFromDate(endDate)
  );
};

import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { OFFER_PERFORMANCES_PATH } from '../pdApiPaths';
import { OfferPerformancesQueryKey } from '../../queryKeys/OfferPerformancesQueryKey';
import OfferPerformances from '../../../domain/pd/OfferPerformances';
import { getDateStringFromDate } from '../../../helpers/getDateStringFromDate';
import { CountryCode } from '../../../domain/automator/orders/CountryCode';

export const useFetchOfferPerformances = (
  offerId: number,
  startDate: Date,
  endDate: Date,
  rollingAverageDays: number,
  countryCode: CountryCode | null
) => {
  const url = usePdApiAccountUrl(
    OFFER_PERFORMANCES_PATH +
      `?offerId=${offerId}` +
      `&startDate=${getDateStringFromDate(startDate)}` +
      `&endDate=${getDateStringFromDate(endDate)}` +
      `&rollingAverageDays=${rollingAverageDays}` +
      (countryCode ? `&countryCode=${countryCode}` : '')
  );
  return useFetchData<OfferPerformances>(
    url,
    OfferPerformancesQueryKey(offerId, startDate, endDate, rollingAverageDays, countryCode)
  );
};

import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { CUSTOMER_INVOICES_PATH } from '../automatorApiPaths';
import CustomerInvoiceRequests from '../../../domain/automator/customerInvoiceRequests/CustomerInvoiceRequests';
import { CustomerInvoiceRequestsQueryKey } from '../../queryKeys/CustomerInvoiceRequestsQueryKey';
import { useCustomerInvoiceRequestQueryKeysUpdater } from './useCustomerInvoiceRequestQueryKeysUpdater';
import { useUpdateData } from '../../useUpdateData';

export const useUploadCustomerInvoice = (customerInvoiceId: number) => {
  const url = useAutomatorApiAccountUrl(
    `${CUSTOMER_INVOICES_PATH}/` + customerInvoiceId + '/upload'
  );

  const updater = useCustomerInvoiceRequestQueryKeysUpdater();
  const updateData = useUpdateData(updater, 'customerInvoiceRequests');

  const onSuccess = (customerInvoiceRequests: CustomerInvoiceRequests) => {
    updateData(customerInvoiceRequests);
  };

  return useAuthorizedPost<CustomerInvoiceRequests, undefined>(
    url,
    [CustomerInvoiceRequestsQueryKey()],
    undefined,
    onSuccess
  );
};

import { AutomatorProductsQueryKey } from '../../../queryKeys/AutomatorProductsQueryKey';
import { QueryKeysUpdater } from '../../../useUpdateData';
import AutomatorProduct from '../../../../domain/automator/products/AutomatorProduct';

export const useAutomatorProductQueryKeysUpdater = (): QueryKeysUpdater<AutomatorProduct> => {
  return {
    [AutomatorProductsQueryKey()]: productUpdater(),
  };
};

const productUpdater = () => {
  return {
    addCriteria: (_: AutomatorProduct) => {
      return true;
    },
    sort: undefined,
  };
};

import { initializeAction } from './authSlice';
import { useDispatch } from 'react-redux';

export const useInitializeAuth = () => {
  const dispatch = useDispatch();

  return () => {
    dispatch(initializeAction({ isAuthenticated: false, user: null }));
  };
};

import ProposedAction from '../../../../domain/automator/alerts/ProposedAction';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { toast } from 'react-hot-toast';
import { useExecuteProposedActions } from '../../../../api/automator/alerts/useExecuteProposedActions';

interface ExecuteAllAlertActionsButtonProps {
  proposedActions: ProposedAction[];
}

export const ExecuteAllAlertActionsButton = ({
  proposedActions,
}: ExecuteAllAlertActionsButtonProps) => {
  const { t } = useTranslation();

  const { mutate: executeAlerts, isLoading: isLoadingExecuteAlerts } = useExecuteProposedActions();

  const onExecuteAll = () => {
    executeAlerts(
      {
        proposed_action_ids: proposedActions
          .filter((alert) => {
            return !alert.isExecuted;
          })
          .map((alert) => alert.id),
      },
      {
        onSuccess: () => {
          toast.success(t(tokens.automator.alerts.dismissed));
        },
      }
    );
  };

  const allAreExecuted = proposedActions.every((alert) => alert.isExecuted);

  return (
    <ActionButton
      icon={<PlayCircleOutlineIcon />}
      label={t(tokens.automator.alerts.execute_all)}
      onClick={allAreExecuted ? undefined : onExecuteAll}
      size={'small'}
      color={allAreExecuted ? 'success' : 'primary'}
      variant={allAreExecuted ? 'outlined' : 'contained'}
      isLoading={isLoadingExecuteAlerts}
      isDisabled={allAreExecuted}
    />
  );
};

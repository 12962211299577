import { useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useAuth } from '../../store/auth/useAuth';
import { useRouter } from '../../../devias/src/hooks/use-router';
import { usePaths } from '../../routes/usePaths';
import Divider from '@mui/material/Divider';
import Settings01Icon from '@untitled-ui/icons-react/build/esm/Settings01';
import { tokens } from '../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { AccountPopoverMenuItem } from './AccountPopoverMenuItem';
import { AccountStatus } from '../../domain/automator/account/AccountStatus';
import { useCreateCustomerPortalSession } from '../../api/automator/account/useCreateCustomerPortalSession';
import { useFetchRetailers } from '../../api/automator/retailers/useFetchRetailers';
import { useLogout } from '../../api/automator/auth/useLogout';
import { useFetchAccount } from '../../api/automator/account/useFetchAccount';
import { useFetchMe } from '../../api/automator/users/useFetchMe';
import { Building07 } from '@untitled-ui/icons-react';

interface AccountPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}

export const AccountPopover = (props: AccountPopoverProps) => {
  const { anchorEl, onClose, open, ...other } = props;
  const router = useRouter();
  const auth = useAuth();
  const { t } = useTranslation();
  const paths = usePaths();

  const logout = useLogout();

  const createCustomerPortalSession = useCreateCustomerPortalSession();
  const { data: retailers } = useFetchRetailers();
  const { data: account } = useFetchAccount();
  const { data: user } = useFetchMe();

  const handleLogout = useCallback(async (): Promise<void> => {
    onClose?.();
    logout();
    router.push(paths.auth.login);
  }, [auth, router, onClose]);

  if (!user || !retailers || !account) {
    return <></>;
  }

  const retailerNames = retailers.map((retailer) => retailer.name).join('|');

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 300 } }}
      {...other}
    >
      <Box>
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2">{user.emailAddress}</Typography>
          <Typography
            color="text.secondary"
            variant="subtitle2"
          >
            {retailerNames}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <AccountPopoverMenuItem
            onClick={() => {
              router.push(paths.automator.settings);
              onClose?.();
            }}
            icon={<Settings01Icon />}
            label={tokens.nav.settings}
          />

          {account.status == AccountStatus.ACTIVE && (
            <AccountPopoverMenuItem
              onClick={() => createCustomerPortalSession()}
              icon={<Building07 />}
              label={tokens.nav.customer_portal}
            />
          )}
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            fullWidth
            variant="outlined"
            onClick={handleLogout}
          >
            {t(tokens.nav.logout)}
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

import { useAuthorizedPost } from '../../useAuthorizedPost';
import { OFFER_PERFORMANCE_OVERWRITE_PATH } from '../pdApiPaths';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';

export const useUpdateOfferPerformanceOverwrite = (offerPerformanceOverwriteId: number) => {
  const url = usePdApiAccountUrl(
    OFFER_PERFORMANCE_OVERWRITE_PATH + '/' + offerPerformanceOverwriteId
  );

  return useAuthorizedPost<undefined, UpdateOfferPerformanceOverwriteData>(
    url,
    undefined,
    undefined
  );
};

export interface UpdateOfferPerformanceOverwriteData {
  visits: number;
}

import { PRODUCTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';

export const useUpdateProductInternalReference = (productId: number) => {
  const url = useAutomatorApiAccountUrl(
    PRODUCTS_PATH + '/' + productId + `/update-internal-reference`
  );
  return useAuthorizedPost<undefined, UpdateProductInternalReferenceData>(
    url,
    undefined,
    undefined
  );
};

export interface UpdateProductInternalReferenceData {
  internal_reference: string;
}

import { ORDERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import CustomerInvoice from '../../../domain/automator/customerInvoiceRequests/CustomerInvoice';
import { OrderCustomerInvoicesQueryKey } from '../../queryKeys/OrderCustomerInvoicesQueryKey';

export const useFetchOrderCustomerInvoices = (orderId: number) => {
  const url = useAutomatorApiAccountUrl(ORDERS_PATH + '/' + orderId + '/invoices');
  return useFetchData<CustomerInvoice[]>(url, OrderCustomerInvoicesQueryKey(orderId));
};

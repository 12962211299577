import TableRow from '@mui/material/TableRow';
import { getDateStringFromDate } from '../../../../../../helpers/getDateStringFromDate';
import { generateDateRangeList } from '../services/generateDateList';
import { CompetitorProductPerformanceTableCell } from './CompetitorProductPerformanceTableCell';
import { CompetitorTableCellData } from './CompetitorProductPerformancesTable';
import Stack from '@mui/material/Stack';
import { SlimTableCell } from '../../../../../../components/SlimTableCell';
import Typography from '@mui/material/Typography';
import uniqueBy from '../../../../helpers/uniqueBy';
import { CompetitorProductLegendItem } from './ComepetitorProductLegendItem';

interface CompetitorProductPerformanceTableRowProps {
  position: number;
  startDate: Date;
  endDate: Date;
  cellData: CompetitorTableCellData[];
  onHover: (productId: number | null) => void;
}

export const CompetitorProductPerformanceTableRow = ({
  position,
  startDate,
  endDate,
  cellData,
  onHover,
}: CompetitorProductPerformanceTableRowProps) => {
  const dateList = generateDateRangeList(startDate, endDate, false);

  const totalImpressions = cellData.reduce((total, cell) => total + cell.impressions, 0);
  //const knownDays = uniqueBy(cellData, (cell) => cell.date).length;
  const averageImpressions = Math.round(totalImpressions / cellData.length);

  const initialCells = uniqueBy(
    cellData.filter((cell) => cell.initialPosition == position),
    (cell) => cell.productId
  );

  return (
    <TableRow>
      <SlimTableCell isSticky>
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
        >
          <Typography
            variant="body1"
            minWidth="20px"
            minHeight="25px"
          >
            {position}
          </Typography>

          <Typography
            variant="body1"
            minWidth="75px"
          >
            {averageImpressions ? averageImpressions + ' / ' + totalImpressions : ''}
          </Typography>

          <CompetitorProductLegendItem
            cells={initialCells}
            onHover={onHover}
          />
        </Stack>
      </SlimTableCell>

      <>
        {dateList.map((date) => {
          const cellsByDate = cellData.filter((cell) => cell.date == getDateStringFromDate(date));

          return (
            <CompetitorProductPerformanceTableCell
              key={date.toISOString()}
              cells={cellsByDate}
              onHover={onHover}
            />
          );
        })}
      </>
    </TableRow>
  );
};

import Stack from '@mui/material/Stack';

interface OrderSummaryCardProps {
  children?: React.ReactNode;
}

export const OrderSummaryCard = ({ children }: OrderSummaryCardProps) => {
  return (
    <Stack
      border="1px solid #008ab8"
      borderRadius={2}
      padding={2}
      gap={4}
    >
      {children}
    </Stack>
  );
};

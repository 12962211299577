import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { useHandleResolutionCase } from '../../../../api/automator/resolutions/useHandleResolutionCase';
import { toast } from 'react-hot-toast';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { ActionIcon } from '../../orders/components/ActionIcon';
import ReducedResolutionCase from '../../../../domain/automator/resolutionDossiers/ReducedResolutionCase';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useNavigate } from 'react-router-dom';
import { usePaths } from '../../../../routes/usePaths';
import { TriggerType } from '../../../../domain/automator/messages/TriggerType';

interface HandleResolutionCaseButtonProps {
  resolutionCase: ReducedResolutionCase;
}

export const HandleResolutionCaseButton = ({ resolutionCase }: HandleResolutionCaseButtonProps) => {
  const { mutate, isLoading } = useHandleResolutionCase(resolutionCase.id);

  const { t } = useTranslation();

  const openWarningDialog = useOpenDialog(DialogId.WARNING);

  const navigate = useNavigate();
  const paths = usePaths();

  const onClick = () => {
    mutate(undefined, {
      onSuccess: () => {
        toast.success(t(tokens.automator.resolutions.actions.case_handled));
        navigate(paths.automator.resolution_dossiers);
      },
    });
  };

  return (
    <ActionIcon
      tooltip={t(tokens.automator.resolutions.actions.handle_case)}
      iconSize={22}
      onClick={() =>
        resolutionCase.isAWaitingResponseOnReply &&
        resolutionCase.triggerType == TriggerType.CUSTOMER_QUESTION
          ? openWarningDialog({
              title: t(
                tokens.automator.resolutions.dialogs.handle_unanswered_customer_question_email
                  .dialog_title
              ),
              message: t(
                tokens.automator.resolutions.dialogs.handle_unanswered_customer_question_email
                  .message
              ),
              buttonLabel: t(
                tokens.automator.resolutions.dialogs.handle_unanswered_customer_question_email
                  .button_label
              ),
              onConfirm: onClick,
            })
          : onClick()
      }
      isLoading={isLoading}
      icon={
        <AssignmentTurnedInIcon
          style={{
            color:
              resolutionCase.isAWaitingResponseOnReply &&
              resolutionCase.triggerType == TriggerType.CUSTOMER_QUESTION
                ? 'ORANGE'
                : undefined,
          }}
        />
      }
      color="primary"
    />
  );
};

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import { useState } from 'react';
import AccountSettings from '../../../../domain/automator/account/AccountSettings';
import { useUpdateAccountSettings } from '../../../../api/automator/account/useUpdateAccountSettings';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../locales/translationTokens';
import Tooltip from '@mui/material/Tooltip';
import { VatPercentageToggleButtons } from '../../products/components/VatPercentageToggleButtons';
import ActionButton from '../../../../components/ActionButton';
import CustomerInvoiceInformation from '../../../../domain/automator/account/CustomerInvoiceInformation';
import { CountryCode } from '../../../../domain/automator/orders/CountryCode';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@mui/material/TextField';
import RetailerSetting from '../../../../domain/automator/retailers/RetailerSetting';
import { InitialCustomerInvoiceNumberingMethod } from '../../../../domain/automator/retailers/InitialCustomerInvoiceNumberingMethod';
import { InitialCustomerInvoiceNumberView } from './InitialCustomerInvoiceNumberView';
import Typography from '@mui/material/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { CustomerInvoiceAutoICPMethod } from '../../../../domain/automator/account/CustomerInvoiceAutoICPMethod';

interface CustomerInvoiceSettingsTabProps {
  settings: AccountSettings;
  retailerSettings: RetailerSetting[];
  onClick?: () => void;
}

interface FormInputs {
  zipCode: string;
  streetName: string;
  houseNumber: string;
  houseNumberExtension: string | null;
  city: string;
  countryCode: CountryCode;
  emailAddress: string | null;
  phoneNumber: string | null;
}

export const CustomerInvoiceSettingsTab = ({
  settings,
  retailerSettings,
  onClick,
}: CustomerInvoiceSettingsTabProps) => {
  const [defaultVatPercentage, setDefaultVatPercentage] = useState(settings.defaultVatPercentage);

  const [automaticallySendCustomerInvoices, setAutomaticallySendCustomerInvoices] = useState(
    settings.automaticallySendCustomerInvoices
  );

  const [initialCustomerInvoiceNumberingMethod, setInitialCustomerInvoiceNumberingMethod] =
    useState(settings.initialCustomerInvoiceNumberingMethod);

  const [startCustomerInvoiceImportToday, setStartCustomerInvoiceImportToday] = useState(false);

  const [customerInvoiceICPMethod, setCustomerInvoiceICPMethod] = useState(
    settings.customerInvoiceAutoICPMethod
  );

  const { t } = useTranslation();

  const { mutate: updateSettings, isLoading } = useUpdateAccountSettings(settings.id);

  const zipNLRegex = new RegExp('^\\d{4} ?[a-zA-Z]{2}$');
  const zipBERegex = new RegExp('^\\d{4}$');

  const determineCountryCode = (zipCode: string) => {
    if (zipNLRegex.test(zipCode)) {
      return CountryCode.NL;
    } else if (zipBERegex.test(zipCode)) {
      return CountryCode.BE;
    }
  };

  const onSubmit = (data: FormInputs) => {
    const customerInvoiceInformation: CustomerInvoiceInformation = {
      emailAddress: data.emailAddress?.trim() || null,
      phoneNumber: data.phoneNumber?.trim() || null,
      address: {
        streetName: data.streetName.trim(),
        houseNumber: data.houseNumber.trim(),
        houseNumberExtension: data.houseNumberExtension?.trim() || null,
        zipCode: data.zipCode.trim().toUpperCase(),
        city: data.city.trim(),
        countryCode: determineCountryCode(data.zipCode) as CountryCode,
      },
    };

    doUpdate(customerInvoiceInformation);
  };

  const validationSchema = Yup.object({
    zipCode: Yup.string()
      .required(t(tokens.common.required) as string)
      .matches(/^(\d{4}[a-zA-Z]{2}|\d{4})$/, t(tokens.common.incorrect) as string),
    streetName: Yup.string().required(t(tokens.common.required) as string),
    houseNumber: Yup.string()
      .required(t(tokens.common.required) as string)
      .matches(/^\d+$/, t(tokens.common.incorrect) as string),
    houseNumberExtension: Yup.string().nullable().defined().default(null),
    city: Yup.string().required(t(tokens.common.required) as string),
    emailAddress: Yup.string()
      .email(t(tokens.common.incorrect) as string)
      .nullable(),
    phoneNumber: Yup.string().nullable(),
  });

  const determineInitialValues = () => {
    return {
      emailAddress: settings.customerInvoiceInformation?.emailAddress,
      phoneNumber: settings.customerInvoiceInformation?.phoneNumber,
      streetName: settings.customerInvoiceInformation?.address.streetName,
      houseNumber: settings.customerInvoiceInformation?.address.houseNumber,
      houseNumberExtension: settings.customerInvoiceInformation?.address.houseNumberExtension,
      zipCode: settings.customerInvoiceInformation?.address.zipCode,
      city: settings.customerInvoiceInformation?.address.city,
      countryCode: settings.customerInvoiceInformation?.address.countryCode,
    };
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    // @ts-ignore
    resolver: yupResolver(validationSchema),
    defaultValues: determineInitialValues(),
  });

  const doUpdate = (customerInvoiceInformation: CustomerInvoiceInformation) => {
    const data = {
      automatically_approve_cancellation_requests:
        settings.automaticallyApproveCancellationRequests,
      ignore_latest_handover_date: settings.ignoreLatestHandoverDate,
      forward_auto_mail_replies: settings.forwardAutoMailReplies,
      forward_customer_question_replies: settings.forwardCustomerQuestionReplies,
      automatically_send_customer_invoices: automaticallySendCustomerInvoices,
      default_vat_percentage: defaultVatPercentage,
      customer_invoice_information: customerInvoiceInformation,
      initial_customer_invoice_numbering_method:
        initialCustomerInvoiceNumberingMethod || InitialCustomerInvoiceNumberingMethod.AUTOMATIC,
      customer_invoice_import_start_date: startCustomerInvoiceImportToday
        ? new Date().toISOString()
        : new Date(new Date().getFullYear() + '-01-01').toISOString(),
      customer_invoice_auto_icp_method: customerInvoiceICPMethod,
    };

    updateSettings(data, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.settings.settings_saved));

        onClick?.();
      },
    });
  };

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack
        paddingTop={2}
        paddingX={5}
        paddingBottom={3}
        gap={1}
      >
        <Stack
          spacing={3}
          width={'50%'}
        >
          <Stack
            direction={'row'}
            gap={1}
          >
            <Controller
              name="emailAddress"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t(tokens.automator.settings.customer_invoice_information.email_address)}
                  error={Boolean(errors.emailAddress)}
                  helperText={errors.emailAddress?.message}
                />
              )}
            />

            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t(tokens.automator.settings.customer_invoice_information.phone_number)}
                  error={Boolean(errors.phoneNumber)}
                  helperText={errors.phoneNumber?.message}
                  onChange={(event) => field.onChange(event.target.value.trim())}
                />
              )}
            />
          </Stack>

          <Stack
            direction={'row'}
            gap={1}
          >
            <Controller
              name="streetName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t(tokens.common.address.street_name)}
                  error={Boolean(errors.streetName)}
                  helperText={errors.streetName?.message}
                />
              )}
            />
            <Controller
              name="houseNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t(tokens.common.address.house_number)}
                  error={Boolean(errors.houseNumber)}
                  helperText={errors.houseNumber?.message}
                  onChange={(event) => field.onChange(event.target.value.trim())}
                />
              )}
            />
            <Controller
              name="houseNumberExtension"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t(tokens.common.address.house_number_extension)}
                  error={Boolean(errors.houseNumberExtension)}
                  helperText={errors.houseNumberExtension?.message}
                />
              )}
            />
          </Stack>

          <Stack
            direction="row"
            gap={1}
          >
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t(tokens.common.address.city)}
                  error={Boolean(errors.city)}
                  helperText={errors.city?.message}
                />
              )}
            />
            <Controller
              name="zipCode"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t(tokens.common.address.zip_code)}
                  error={Boolean(errors.zipCode)}
                  helperText={errors.zipCode?.message}
                  onChange={(event) => field.onChange(event.target.value.trim())}
                />
              )}
            />
          </Stack>
        </Stack>

        <Stack
          sx={{ p: 3 }}
          spacing={3}
        >
          {settings.customerInvoiceImportStartDate == null ? (
            <Box>
              <Tooltip
                enterDelay={500}
                enterNextDelay={500}
                title={t(tokens.automator.settings.start_customer_invoice_today_tooltip)}
                placement={'right'}
              >
                <FormControlLabel
                  control={
                    <Switch
                      disabled={settings.customerInvoiceImportStartDate != null}
                      checked={startCustomerInvoiceImportToday}
                      onChange={() =>
                        setStartCustomerInvoiceImportToday(!startCustomerInvoiceImportToday)
                      }
                    />
                  }
                  label={t(tokens.automator.settings.start_customer_invoice_today)}
                />
              </Tooltip>
            </Box>
          ) : (
            <Stack
              direction="row"
              gap={1}
            >
              <Typography>{t(tokens.automator.settings.start_customer_invoices_date)}</Typography>
              <Typography>
                {new Date(settings.customerInvoiceImportStartDate!).toLocaleDateString()}
              </Typography>
            </Stack>
          )}

          <Box>
            <Tooltip
              enterDelay={500}
              enterNextDelay={500}
              title={t(tokens.automator.settings.automatically_upload_customer_invoices_tooltip)}
              placement={'right'}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={automaticallySendCustomerInvoices}
                    onChange={(_, checked) => setAutomaticallySendCustomerInvoices(checked)}
                  />
                }
                label={t(tokens.automator.settings.automatically_upload_customer_invoices)}
              />
            </Tooltip>
          </Box>

          <Box>
            <Tooltip
              enterDelay={500}
              enterNextDelay={500}
              title={t(tokens.automator.settings.default_vat_percentage_tooltip)}
              placement={'right'}
            >
              <FormControlLabel
                control={
                  <VatPercentageToggleButtons
                    onSelect={setDefaultVatPercentage}
                    value={defaultVatPercentage}
                  />
                }
                label={t(tokens.automator.settings.default_vat_percentage)}
                labelPlacement="end"
              />
            </Tooltip>
          </Box>

          <Stack>
            <Box>
              <Tooltip
                enterDelay={500}
                enterNextDelay={500}
                title={t(
                  tokens.automator.settings.initial_customer_invoice_numbering_method_tooltip
                )}
                placement={'right'}
              >
                <FormControlLabel
                  control={
                    <Switch
                      disabled={settings.initialCustomerInvoiceNumberingMethod != null}
                      checked={
                        initialCustomerInvoiceNumberingMethod ==
                        InitialCustomerInvoiceNumberingMethod.MANUAL
                      }
                      onChange={(_, checked) =>
                        setInitialCustomerInvoiceNumberingMethod(
                          checked
                            ? InitialCustomerInvoiceNumberingMethod.MANUAL
                            : InitialCustomerInvoiceNumberingMethod.AUTOMATIC
                        )
                      }
                    />
                  }
                  label={t(tokens.automator.settings.initial_customer_invoice_numbering_method)}
                />
              </Tooltip>
            </Box>

            <Stack
              direction="row"
              alignItems="center"
              gap={2}
            >
              <Typography>
                {t(
                  tokens.automator.settings.customer_invoice_auto_icp_method
                    .customer_invoice_auto_icp_method
                )}
              </Typography>
              <ToggleButtonGroup
                disabled={settings.customerInvoiceAutoICPMethod != null}
                value={customerInvoiceICPMethod}
                exclusive
                onChange={(_, method) => setCustomerInvoiceICPMethod(method)}
              >
                <ToggleButton value={CustomerInvoiceAutoICPMethod.NONE}>
                  {t(tokens.automator.settings.customer_invoice_auto_icp_method.none)}
                </ToggleButton>
                <ToggleButton value={CustomerInvoiceAutoICPMethod.REQUESTS_ONLY}>
                  {t(tokens.automator.settings.customer_invoice_auto_icp_method.requests_only)}
                </ToggleButton>
                <ToggleButton value={CustomerInvoiceAutoICPMethod.ALL}>
                  {t(tokens.automator.settings.customer_invoice_auto_icp_method.all)}
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>

            {initialCustomerInvoiceNumberingMethod ===
              InitialCustomerInvoiceNumberingMethod.MANUAL && (
              <Stack
                direction="column"
                gap={1}
              >
                {retailerSettings.map((retailerSetting) => {
                  return (
                    <InitialCustomerInvoiceNumberView
                      key={retailerSetting.id}
                      retailerSetting={retailerSetting}
                    />
                  );
                })}
              </Stack>
            )}
          </Stack>
        </Stack>

        <Box>
          <ActionButton
            variant="contained"
            label={t(tokens.automator.settings.save_settings)}
            isLoading={isLoading}
            type="submit"
          />
        </Box>
      </Stack>
    </form>
  );
};

import { useMemo } from 'react';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { Scrollbar } from 'devias/src/components/scrollbar';
import { usePathname } from 'devias/src/hooks/use-pathname';
import type { NavColor } from 'devias/src/types/settings';

import type { Section } from '../useMenu';
import { SideNavHeader } from './SideNavHeader';
import { SideNavSection } from './SideNavSection';

export const SIDE_NAV_WIDTH = 280;

interface SideNavProps {
  color?: NavColor;
  sections?: Section[];
  onClose?: any;
  open?: boolean | undefined;
}

export const SideNav = ({
  color = 'evident',
  sections = [],
  onClose,
  open = true,
}: SideNavProps) => {
  const pathname = usePathname();
  const cssVars = useCssVars(color);

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: 'var(--nav-bg)',
          color: 'var(--nav-color)',
          width: SIDE_NAV_WIDTH,
        },
      }}
      variant={onClose ? 'temporary' : 'permanent'}
    >
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%',
          },
          '& .simplebar-scrollbar:before': {
            background: 'var(--nav-scrollbar-color)',
          },
        }}
      >
        <Stack sx={{ height: '100%' }}>
          <SideNavHeader />
          <Stack
            component="nav"
            spacing={2}
            sx={{
              flexGrow: 1,
              px: 2,
            }}
          >
            {sections.map((section, index) => (
              <SideNavSection
                items={section.items}
                key={index}
                pathname={pathname}
                subheader={section.subheader}
              />
            ))}
          </Stack>
        </Stack>
      </Scrollbar>
    </Drawer>
  );
};

const useCssVars = (color: NavColor): Record<string, string> => {
  const theme = useTheme();

  return useMemo((): Record<string, string> => {
    switch (color) {
      // case 'blend-in':
      //   if (theme.palette.mode === 'dark') {
      //     return {
      //       '--nav-bg': theme.palette.background.default,
      //       '--nav-color': theme.palette.neutral[100],
      //       '--nav-border-color': theme.palette.neutral[700],
      //       '--nav-logo-border': theme.palette.neutral[700],
      //       '--nav-section-title-color': theme.palette.neutral[400],
      //       '--nav-item-color': theme.palette.neutral[400],
      //       '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
      //       '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
      //       '--nav-item-active-color': theme.palette.text.primary,
      //       '--nav-item-disabled-color': theme.palette.neutral[600],
      //       '--nav-item-icon-color': theme.palette.neutral[500],
      //       '--nav-item-icon-active-color': theme.palette.primary.main,
      //       '--nav-item-icon-disabled-color': theme.palette.neutral[700],
      //       '--nav-item-chevron-color': theme.palette.neutral[700],
      //       '--nav-scrollbar-color': theme.palette.neutral[400],
      //     };
      //   } else {
      //     return {
      //       '--nav-bg': theme.palette.background.default,
      //       '--nav-color': theme.palette.text.primary,
      //       '--nav-border-color': theme.palette.neutral[100],
      //       '--nav-logo-border': theme.palette.neutral[100],
      //       '--nav-section-title-color': theme.palette.neutral[400],
      //       '--nav-item-color': theme.palette.text.secondary,
      //       '--nav-item-hover-bg': theme.palette.action.hover,
      //       '--nav-item-active-bg': theme.palette.action.selected,
      //       '--nav-item-active-color': theme.palette.text.primary,
      //       '--nav-item-disabled-color': theme.palette.neutral[400],
      //       '--nav-item-icon-color': theme.palette.neutral[400],
      //       '--nav-item-icon-active-color': theme.palette.primary.main,
      //       '--nav-item-icon-disabled-color': theme.palette.neutral[400],
      //       '--nav-item-chevron-color': theme.palette.neutral[400],
      //       '--nav-scrollbar-color': theme.palette.neutral[900],
      //     };
      //   }
      //
      // case 'discrete':
      //   if (theme.palette.mode === 'dark') {
      //     return {
      //       '--nav-bg': theme.palette.neutral[900],
      //       '--nav-color': theme.palette.neutral[100],
      //       '--nav-border-color': theme.palette.neutral[700],
      //       '--nav-logo-border': theme.palette.neutral[700],
      //       '--nav-section-title-color': theme.palette.neutral[400],
      //       '--nav-item-color': theme.palette.neutral[400],
      //       '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
      //       '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
      //       '--nav-item-active-color': theme.palette.text.primary,
      //       '--nav-item-disabled-color': theme.palette.neutral[600],
      //       '--nav-item-icon-color': theme.palette.neutral[500],
      //       '--nav-item-icon-active-color': theme.palette.primary.main,
      //       '--nav-item-icon-disabled-color': theme.palette.neutral[700],
      //       '--nav-item-chevron-color': theme.palette.neutral[700],
      //       '--nav-scrollbar-color': theme.palette.neutral[400],
      //     };
      //   } else {
      //     return {
      //       '--nav-bg': theme.palette.neutral[50],
      //       '--nav-color': theme.palette.text.primary,
      //       '--nav-border-color': theme.palette.divider,
      //       '--nav-logo-border': theme.palette.neutral[200],
      //       '--nav-section-title-color': theme.palette.neutral[500],
      //       '--nav-item-color': theme.palette.neutral[500],
      //       '--nav-item-hover-bg': theme.palette.action.hover,
      //       '--nav-item-active-bg': theme.palette.action.selected,
      //       '--nav-item-active-color': theme.palette.text.primary,
      //       '--nav-item-disabled-color': theme.palette.neutral[400],
      //       '--nav-item-icon-color': theme.palette.neutral[400],
      //       '--nav-item-icon-active-color': theme.palette.primary.main,
      //       '--nav-item-icon-disabled-color': theme.palette.neutral[400],
      //       '--nav-item-chevron-color': theme.palette.neutral[400],
      //       '--nav-scrollbar-color': theme.palette.neutral[900],
      //     };
      //   }

      case 'evident':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.neutral[800],
            '--nav-color': theme.palette.common.white,
            '--nav-border-color': 'transparent',
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.common.white,
            '--nav-item-disabled-color': theme.palette.neutral[500],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[500],
            '--nav-item-chevron-color': theme.palette.neutral[600],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          };
        } else {
          return {
            '--nav-bg': theme.palette.neutral[800],
            '--nav-color': theme.palette.common.white,
            '--nav-border-color': 'transparent',
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.common.white,
            '--nav-item-disabled-color': theme.palette.neutral[500],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[500],
            '--nav-item-chevron-color': theme.palette.neutral[600],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          };
        }

      default:
        return {};
    }
  }, [theme, color]);
};

import { Typography } from '@mui/material';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useFetchOrderNote } from '../../../../api/automator/orders/useFetchOrderNote';
import { ActionIcon } from './ActionIcon';
import CommentOutlined from '@mui/icons-material/CommentOutlined';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';

interface OrderNoteTypographyProps {
  orderId: number;
}

const OrderNoteTypography = ({ orderId }: OrderNoteTypographyProps) => {
  const { data: orderNote, isLoading } = useFetchOrderNote(orderId);

  const { t } = useTranslation();

  const openDialog = useOpenDialog(DialogId.UPDATE_ORDER_NOTE_DIALOG);

  if (isLoading) {
    return <>Loading...</>;
  }

  if (orderNote!.note == '') {
    return (
      <ActionIcon
        icon={<CommentOutlined />}
        onClick={() => openDialog({ orderId: orderId })}
        tooltip={t(tokens.automator.orders.dialogs.update_order_note.note)}
      />
    );
  }

  return (
    <Stack
      direction="row"
      gap={1.5}
      display="flex"
      alignItems="center"
      border={1}
      borderRadius={1}
      borderColor="grey.300"
      padding={1}
      onClick={() => {
        isLoading || openDialog({ orderId: orderId });
      }}
      style={{ cursor: 'pointer' }}
    >
      <CommentOutlined />

      <Typography
        lineHeight={1.1}
        minWidth={200}
        variant={'body1'}
      >
        {orderNote && !isLoading ? orderNote.note : '-'}
      </Typography>
    </Stack>
  );
};

export default OrderNoteTypography;

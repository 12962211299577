import TableRow from '@mui/material/TableRow';
import { PerformanceDateType, PerformanceTableCell } from '../PerformanceTableCell';
import { getDateStringFromDate } from '../../../../../../helpers/getDateStringFromDate';
import { SlimTableCell } from '../../../../../../components/SlimTableCell';
import { TimelineEvent, TimeLineEventMarker } from '../TimeLineEventMarker';
import Stack from '@mui/material/Stack';

interface TimelineTableRowProps {
  date: Date;
  timelineEventsLeft: TimelineEvent[];
  timelineEventsRight: TimelineEvent[];
}

export const TimelineTableRow = ({
  date,
  timelineEventsLeft,
  timelineEventsRight,
}: TimelineTableRowProps) => {
  return (
    <TableRow key={date.toISOString()}>
      <SlimTableCell
        style={{
          width: '5px',
          whiteSpace: 'nowrap',
        }}
      >
        <Stack direction="row">
          {timelineEventsLeft.map((timelineEvent, index) => {
            return (
              <TimeLineEventMarker
                key={index}
                timelineEvent={timelineEvent}
              />
            );
          })}
        </Stack>
      </SlimTableCell>
      <PerformanceTableCell
        width={'10px'}
        value={getDateStringFromDate(date)}
        type={PerformanceDateType.DATE}
      />
      <SlimTableCell
        style={{
          width: '5px',
          whiteSpace: 'nowrap',
        }}
      >
        <Stack direction="row">
          {timelineEventsRight.map((timelineEvent, index) => {
            return (
              <TimeLineEventMarker
                key={index}
                timelineEvent={timelineEvent}
              />
            );
          })}
        </Stack>
      </SlimTableCell>
    </TableRow>
  );
};

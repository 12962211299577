import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { MANUAL_PERFORMANCE_EVENTS } from '../pdApiPaths';

export const useCreateManualPerformanceEvent = () => {
  const url = usePdApiAccountUrl(MANUAL_PERFORMANCE_EVENTS);

  return useAuthorizedPost<undefined, CreateManualPerformanceEventData>(url, undefined, undefined);
};

export interface CreateManualPerformanceEventData {
  offer_id: number;
  note: string;
  date: Date;
}

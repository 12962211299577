import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ActiveCopyButtonState {
  activeButtonId: string | null;
  activeAggregateId: number | null;
}

const initialState: ActiveCopyButtonState = {
  activeButtonId: null,
  activeAggregateId: null,
};

export const activeCopyButtonsSlice = createSlice({
  name: 'button',
  initialState,
  reducers: {
    setActiveButtonId: (state, action: PayloadAction<ActiveCopyButtonState>) => {
      state.activeButtonId = action.payload.activeButtonId;
      state.activeAggregateId = action.payload.activeAggregateId;
    },
  },
});

export const { setActiveButtonId } = activeCopyButtonsSlice.actions;
export const copyButtonsReducer = activeCopyButtonsSlice.reducer;

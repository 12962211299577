import { ProductThumbnail } from '../../orders/components/ProductThumbnail';
import ResolutionDossier from '../../../../domain/automator/resolutionDossiers/ResolutionDossier';
import Badge from '@mui/material/Badge';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { TriggerType } from '../../../../domain/automator/messages/TriggerType';

interface ResolutionDossierProductThumbnailProps {
  resolutionDossier: ResolutionDossier;
}

export const ResolutionDossierProductThumbnail = ({
  resolutionDossier,
}: ResolutionDossierProductThumbnailProps) => {
  const { t } = useTranslation();

  const isAwaitingResponseOnReply = (resolutionDossier: ResolutionDossier) => {
    return resolutionDossier.resolutionCases.some((resolutionCase) => {
      return resolutionCase.isAWaitingResponseOnReply;
    });
  };

  const hasNewReply = (resolutionDossier: ResolutionDossier) => {
    if (isAwaitingResponseOnReply(resolutionDossier)) {
      return false;
    }

    if (!hasUnreadMessages(resolutionDossier)) {
      return false;
    }

    return resolutionDossier.resolutionCases.some((resolutionCase) => {
      return resolutionCase.latestReplyDate != null;
    });
  };

  const hasUnreadMessages = (resolutionDossier: ResolutionDossier) => {
    return resolutionDossier.resolutionCases.some((resolutionCase) => {
      return resolutionCase.hasUnreadMessages;
    });
  };

  const isNew = (resolutionDossier: ResolutionDossier) => {
    return (
      hasUnreadMessages(resolutionDossier) &&
      resolutionDossier.resolutionCases.some((resolutionCase) => {
        return (
          resolutionCase.amountOfMessages == 1 &&
          resolutionCase.triggerType == TriggerType.CUSTOMER_QUESTION
        );
      })
    );
  };

  return (
    <Badge
      color="warning"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      invisible={!resolutionDossier.isOld}
      badgeContent={t(tokens.automator.resolutions.resolution_dossier_table.is_old)}
    >
      <Badge
        color={'success'}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        invisible={
          !hasNewReply(resolutionDossier) /*&& !isAwaitingResponseOnReply(resolutionDossier)*/
        }
        badgeContent={t(tokens.automator.resolutions.resolution_dossier_table.new_reply)}
        // badgeContent={
        //   hasNewReply(resolutionDossier)
        //     ? t(tokens.automator.resolutions.resolution_dossier_table.new_reply)
        //     : t(tokens.automator.resolutions.resolution_dossier_table.awaiting_reply_response)
        // }
      >
        <Badge
          color={'success'}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          invisible={!isNew(resolutionDossier)}
          badgeContent={t(tokens.automator.resolutions.resolution_dossier_table.new_thread)}
        >
          <ProductThumbnail
            height={50}
            thumbnailUrl={
              resolutionDossier.order
                ? resolutionDossier.order.items[0].offer.product.thumbnailUrl
                : resolutionDossier.product!.thumbnailUrl
            }
            quantity={resolutionDossier.order ? resolutionDossier.order!.items[0].quantity : 1}
          />
        </Badge>
      </Badge>
    </Badge>
  );
};

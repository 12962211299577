import AutomatorProduct from '../../../../domain/automator/products/AutomatorProduct';
import Chip from '@mui/material/Chip';
import Popover from '@mui/material/Popover';
import { useState } from 'react';
import { AddProductGroupToProductList } from './AddProductGroupToProductList';
import { useAddGroupToProduct } from '../../../../api/automator/products/useAddGroupToProduct';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

interface AddProductGroupToProductChipProps {
  product: AutomatorProduct;
}

export const AddProductGroupToProductChip = ({ product }: AddProductGroupToProductChipProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { mutate: addGroupToProduct, isLoading } = useAddGroupToProduct(product.id);

  const { t } = useTranslation();

  const open = Boolean(anchorEl);
  const id = open ? 'add-group-to-product' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const doAddGroupToProduct = (productGroupId: number) => {
    const data = {
      product_group_id: productGroupId,
    };

    handleClose();

    addGroupToProduct(data, {
      onSuccess: () => {
        toast.success(t(tokens.automator.products.groups));
      },
    });
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Chip
        label={'+'}
        variant="outlined"
        onClick={(e) => handleClick(e)}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <AddProductGroupToProductList
          onClick={(productGroupId) => doAddGroupToProduct(productGroupId)}
          product={product}
        />
      </Popover>
    </>
  );
};

import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { UpdateBillingDetailsForm } from '../components/UpdateBillingDetailsForm';
import CustomerInvoice from '../../../../domain/automator/customerInvoiceRequests/CustomerInvoice';
import { useUpdateCustomerInvoiceBillingDetails } from '../../../../api/automator/customerInvoiceRequests/useUpdateCustomerInvoiceBillingDetails';
import BillingDetails from '../../../../domain/automator/orders/BillingDetails';
import { toast } from 'react-hot-toast';

export const UpdateUpdatedBillingDetailsDialog = () => {
  const id = DialogId.UPDATE_UPDATED_BILLING_DETAILS;

  const { isOpen, data } = useIsDialogOpen(id);

  const closeDialog = useCloseDialog(id);

  const { t } = useTranslation();

  const { mutate: updateUpdatedBillingDetails, isLoading } = useUpdateCustomerInvoiceBillingDetails(
    data?.customerInvoice.id || 0
  );

  const doUpdate = (data: BillingDetails) => {
    updateUpdatedBillingDetails(
      { billing_details: data },
      {
        onSuccess: async () => {
          toast.success(
            t(tokens.automator.customer_invoice_requests.details.billing_details_updated)
          );
          closeDialog();
        },
      }
    );
  };

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          maxWidth={'md'}
          title={t(tokens.automator.customer_invoice_requests.details.billing_details)}
        >
          <UpdateBillingDetailsForm
            onSave={doUpdate}
            billingDetails={data.customerInvoice.billingDetails}
            isLoading={isLoading}
            isKvkNumberRequired={false}
            isVatNumberRequired={false}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface UpdateUpdatedBillingDetailsDialogData {
  customerInvoice: CustomerInvoice;
}

import { styled } from '@mui/material/styles';
import type { NavColor } from 'devias/src/types/settings';
import type { Section } from '../useMenu';
import { SideNav } from './SideNav';
import { TopNav } from './TopNav';
import { ReactNode } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/system';
import { useMobileNav } from '../../../../devias/src/layouts/dashboard/vertical-layout/use-mobile-nav';

const SIDE_NAV_WIDTH = 280;

interface VerticalLayoutProps {
  children?: ReactNode;
  navColor: NavColor;
  sections: Section[];
}

export const VerticalLayout = (props: VerticalLayoutProps) => {
  const { children, sections, navColor } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const mobileNav = useMobileNav();

  //
  // {
  //   lgUp && <SideNav color={navColor} sections={sections}/>
  // }
  // {
  //   !lgUp && <SideNav color={navColor} onClose={mobileNav.handleClose} open={mobileNav.open}
  //                     sections={sections}/>
  // }

  return (
    <>
      <TopNav onMobileNavOpen={mobileNav.handleOpen} />
      <SideNav
        color={navColor}
        sections={sections}
        open={!lgUp ? mobileNav.open : undefined}
        onClose={!lgUp ? mobileNav.handleClose : null}
      />
      <VerticalLayoutRoot>
        <VerticalLayoutContainer>{children}</VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );
};

const VerticalLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: SIDE_NAV_WIDTH,
  },
}));

const VerticalLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%',
});

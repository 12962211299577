import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { TemplatesAndVariablesToggle } from './TemplatesAndVariablesToggle';
import MessageThread from '../../../../domain/automator/messages/MessageThread';
import { SendMessageThreadReplyHtmlEditor } from './SendMessageThreadReplyHtmlEditor';

interface SendMessageThreadReplyFormProps {
  onClick?: () => void;
  messageThread: MessageThread;
  messageTemplateId: number | null;
  resolutionCaseId: number;
}

export const SendMessageThreadReplyForm = ({
  onClick,
  messageThread,
  messageTemplateId: initialMessageTemplateId,
  resolutionCaseId,
}: SendMessageThreadReplyFormProps) => {
  const [messageTemplateId, setMessageTemplateId] = useState<number | null>(
    initialMessageTemplateId
  );
  const [insertVariable, setInsertVariable] = useState<string | null>(null);

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <Stack
        direction="row"
        gap={2}
      >
        <SendMessageThreadReplyHtmlEditor
          resolutionCaseId={resolutionCaseId}
          onClick={onClick}
          messageThread={messageThread}
          messageTemplateId={messageTemplateId}
          insertVariable={insertVariable}
        />

        <Box width="30%">
          <TemplatesAndVariablesToggle
            phase={messageThread.phase}
            onSelectTemplate={setMessageTemplateId}
            selectedTemplateId={messageTemplateId}
            onSelectVariable={setInsertVariable}
          />
        </Box>
      </Stack>
    </form>
  );
};

import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { CreateProductGroupForm } from '../components/CreateProductGroupForm';

export const CreateProductGroupDialog = () => {
  const id = DialogId.CREATE_PRODUCT_GROUP;

  const { isOpen } = useIsDialogOpen(id);

  const closeDialog = useCloseDialog(id);

  const { t } = useTranslation();

  return (
    <>
      {isOpen && (
        <ApplicationDialog
          dialogId={id}
          maxWidth={'xs'}
          title={t(tokens.automator.products.dialogs.create_group.create_group)}
        >
          <CreateProductGroupForm onSave={closeDialog} />
        </ApplicationDialog>
      )}
    </>
  );
};

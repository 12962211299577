import { Typography } from '@mui/material';
import { useFetchOffers } from '../../../../api/automator/offers/useFetchOffers';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { UpdateOfferForm } from './UpdateOfferForm';

type UpdateOffersFormProps = {
  productId: number;
};

export const UpdateOffersForm = ({ productId }: UpdateOffersFormProps) => {
  const { data: offers, isLoading } = useFetchOffers(productId);

  const { t } = useTranslation();

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Stack gap={4}>
      <Stack
        direction="row"
        alignItems="flex-start"
        gap={7}
      >
        <Stack
          direction="column"
          justifyContent="space-evenly"
          alignItems="flex-start"
          gap={4.8}
          paddingY={2}
        >
          <Box />

          <Chip
            style={{ width: 100 }}
            label={t(tokens.automator.orders.dialogs.update_offer.price)}
          />
          <Chip
            style={{ width: 100 }}
            label={t(tokens.automator.orders.dialogs.update_offer.stock)}
          />
          <Chip
            style={{ width: 100 }}
            label={t(tokens.automator.orders.dialogs.update_offer.delivery_code)}
          />
          <Chip
            style={{ width: 100 }}
            label={t(tokens.automator.orders.dialogs.update_offer.listing)}
          />
        </Stack>

        {offers!.offers
          .sort((a, b) => {
            return a.retailer.id > b.retailer.id ? 1 : -1;
          })
          .map((offer) => {
            return (
              <UpdateOfferForm
                productId={productId}
                offer={offer}
                key={offer.id}
              />
            );
          })}
      </Stack>
    </Stack>
  );
};

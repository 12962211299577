import { tokens } from '../../../../../locales/translationTokens';
import IconCopyButton from '../../../shared/IconCopyButton';

interface CopyCityButtonProps {
  aggregateId: number;
  city: string;
}

const CopyCityButton = ({ aggregateId, city }: CopyCityButtonProps) => {
  return (
    <IconCopyButton
      value={city}
      label={tokens.automator.orders.buttons.copy.copy_city}
      toastMessage={tokens.automator.orders.buttons.copy.city_copied}
      activeCopyButtonState={{
        activeButtonId: aggregateId + city,
        activeAggregateId: aggregateId,
      }}
    />
  );
};

export default CopyCityButton;

import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { ToggleButtonGroup } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import { ResolutionCaseStatus } from '../../../../domain/automator/resolutionDossiers/ResolutionCaseStatus';

interface ResolutionCaseStatusToggleButtonProps {
  onSelect: (selected: ResolutionCaseStatus) => void;
  value: ResolutionCaseStatus;
}

export const ResolutionCaseStatusToggleButton = ({
  onSelect,
  value,
}: ResolutionCaseStatusToggleButtonProps) => {
  const { t } = useTranslation();

  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      onChange={(_, value) => onSelect(value as ResolutionCaseStatus)}
      aria-label="Platform"
    >
      <ToggleButton value={ResolutionCaseStatus.OPEN}>
        {t(tokens.automator.resolutions.resolution_case_status.open)}
      </ToggleButton>
      <ToggleButton value={ResolutionCaseStatus.AWAITING_RESPONSE}>
        {t(tokens.automator.resolutions.resolution_case_status.awaiting_response)}
      </ToggleButton>
      <ToggleButton value={ResolutionCaseStatus.HANDLED}>
        {t(tokens.automator.resolutions.resolution_case_status.handled)}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export enum DeliveryCode {
  A24UURS_23 = 'A24UURS_23',
  A24UURS_22 = 'A24UURS_22',
  A24UURS_21 = 'A24UURS_21',
  A24UURS_20 = 'A24UURS_20',
  A24UURS_19 = 'A24UURS_19',
  A24UURS_18 = 'A24UURS_18',
  A24UURS_17 = 'A24UURS_17',
  A24UURS_16 = 'A24UURS_16',
  A24UURS_15 = 'A24UURS_15',
  A24UURS_14 = 'A24UURS_14',
  A24UURS_13 = 'A24UURS_13',
  A24UURS_12 = 'A24UURS_12',
  A1_2D = 'A1_2D',
  A2_3D = 'A2_3D',
  A3_5D = 'A3_5D',
  A4_8D = 'A4_8D',
  A1_8D = 'A1_8D',
  MIJNLEVERBELOFTE = 'MIJNLEVERBELOFTE',
  VVB = 'VVB',
}

import ProposedAction from '../../../../domain/automator/alerts/ProposedAction';
import Stack from '@mui/material/Stack';
import { ProposedActionView } from './ProposedActionView';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box } from '@mui/system';
import { DismissAlertsActionIcon } from './DismissAlertsActionIcon';
import { ExecuteAllAlertActionsButton } from './ExecuteAllAlertActionsButton';

interface AlertActionsProps {
  proposedActions: ProposedAction[];
}

export const AlertActions = ({ proposedActions }: AlertActionsProps) => {
  const [isActionsHidden, setIsActionsHidden] = useState(proposedActions.length > 1);

  const { t } = useTranslation();

  return (
    <Stack
      direction="column"
      gap={1}
      alignItems="flex-end"
    >
      {proposedActions.length > 1 && (
        <Stack
          gap={3}
          direction={'row'}
        >
          <Box width={190}>
            <Button
              onClick={() => setIsActionsHidden(!isActionsHidden)}
              color="primary"
              variant="outlined"
              size="small"
            >
              {proposedActions.length + ' ' + t(tokens.automator.alerts.actions)}
              {isActionsHidden ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </Button>
          </Box>

          <ExecuteAllAlertActionsButton proposedActions={proposedActions} />

          {!proposedActions[0].isDismissed && (
            <DismissAlertsActionIcon alertIds={proposedActions.map((alert) => alert.id)} />
          )}
        </Stack>
      )}

      {!isActionsHidden &&
        proposedActions.map((alert) => (
          <ProposedActionView
            key={alert.id}
            proposedAction={alert}
          />
        ))}
    </Stack>
  );
};

import { PRODUCTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { useAutomatorProductQueryKeysUpdater } from './updaters/useAutomatorProductQueryKeysUpdater';
import { useUpdateData } from '../../useUpdateData';
import AutomatorProducts from '../../../domain/automator/products/AutomatorProducts';
import { CustomerInvoiceRequestsQueryKey } from '../../queryKeys/CustomerInvoiceRequestsQueryKey';

export const useUpdateProductVatPercentage = (productId: number) => {
  const url = useAutomatorApiAccountUrl(`${PRODUCTS_PATH}/${productId}/update-vat-percentage`);

  const updater = useAutomatorProductQueryKeysUpdater();
  const updateData = useUpdateData(updater, 'products');

  const onSuccess = (products: AutomatorProducts) => {
    updateData(products);
  };

  return useAuthorizedPost<AutomatorProducts, UseUpdateProductVatPercentageData>(
    url,
    [CustomerInvoiceRequestsQueryKey()],
    undefined,
    onSuccess
  );
};

export interface UseUpdateProductVatPercentageData {
  vat_percentage: number;
}

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import ProductTableRow from './ProductTableRow';
import { TableLoading } from '../../../../components/TableLoading';
import { TableEmpty } from '../../../../components/TableEmpty';
import AutomatorProduct from '../../../../domain/automator/products/AutomatorProduct';
import ProductGroup from '../../../../domain/automator/products/ProductGroup';

interface ProductListTableProps {
  products?: AutomatorProduct[];
  productGroups?: ProductGroup[];
  isLoading: boolean;
}

export const ProductListTable = ({ products, productGroups, isLoading }: ProductListTableProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (products!.length == 0) {
    return <TableEmpty message={t(tokens.common.no_items)} />;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t(tokens.automator.products.product)}</TableCell>
              <TableCell>{t(tokens.automator.products.label_type)}</TableCell>
              <TableCell>{t(tokens.automator.products.more_than)}</TableCell>
              <TableCell>{t(tokens.automator.products.label_notes)}</TableCell>
              <TableCell>{t(tokens.automator.products.groups)}</TableCell>
              <TableCell>{t(tokens.automator.products.vat_percentage)}</TableCell>
              <TableCell>{t(tokens.automator.products.offers)}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {products!.map((product) => {
              return (
                <ProductTableRow
                  key={product.id}
                  productGroups={productGroups!}
                  product={product}
                />
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { tokens } from '../../../../../../locales/translationTokens';
import { Scrollbar } from '../../../../../../../devias/src/components/scrollbar';
import {
  PerformanceHeaderTableCell,
  PerformanceHeaderTableCellColor,
} from '../PerformanceHeaderTableCell';
import { OfferPerformanceMetricsTableRow } from './OfferPerformanceMetricsTableRow';
import OfferPerformances from '../../../../../../domain/pd/OfferPerformances';

interface OfferPerformanceMetricsTableProps {
  offerPerformances: OfferPerformances;
  rollingAverageDays: number;
}

export const OfferPerformanceMetricsTable = ({
  offerPerformances,
  rollingAverageDays,
}: OfferPerformanceMetricsTableProps) => {
  const offerPerformancesSorted = offerPerformances.offerPerformances.sort((a, b) =>
    new Date(a.date).getTime() - new Date(b.date).getTime() > 0 ? -1 : 1
  );

  const backgroundColor = PerformanceHeaderTableCellColor.LIGHTGREEN;

  return (
    <Scrollbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <PerformanceHeaderTableCell
              value={tokens.pd.performance.offer_performance_metrics_table.metrics}
              backgroundColor={backgroundColor}
              colSpan={3}
            />
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <PerformanceHeaderTableCell
              value={tokens.pd.performance.offer_performance_metrics_table.daily_profit}
              backgroundColor={backgroundColor}
              rollingAverageDays={rollingAverageDays}
            />

            <PerformanceHeaderTableCell
              value={tokens.pd.performance.offer_performance_metrics_table.yearly_profit}
              backgroundColor={backgroundColor}
              rollingAverageDays={rollingAverageDays}
            />

            <PerformanceHeaderTableCell
              value={tokens.pd.performance.offer_performance_metrics_table.ROIDE}
              backgroundColor={backgroundColor}
              rollingAverageDays={rollingAverageDays}
            />
          </TableRow>
        </TableHead>

        <TableBody>
          {offerPerformancesSorted.map((offerPerformance, index) => {
            return (
              <OfferPerformanceMetricsTableRow
                key={index}
                offerPerformance={offerPerformance}
                offerPerformancesMinMaxReport={offerPerformances.minMaxReports}
              />
            );
          })}
        </TableBody>
      </Table>
    </Scrollbar>
  );
};

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import GB from 'country-flag-icons/react/3x2/GB';
import NL from 'country-flag-icons/react/3x2/NL';
import { Language } from '../../../locales/i18n';
import { useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../locales/translationTokens';
import FormControl from '@mui/material/FormControl';

export const LanguageRadioGroup = () => {
  const { t, i18n } = useTranslation();

  const handleChange = useCallback(
    async (language: Language): Promise<void> => {
      await i18n.changeLanguage(language);
      toast.success(t(tokens.common.language_changed));
    },
    [i18n, t]
  );

  return (
    <FormControl component="fieldset">
      <RadioGroup
        value={i18n.language}
        onChange={(event) => handleChange(event.target.value as Language)}
        row
      >
        <FormControlLabel
          value={Language.NL}
          control={<Radio />}
          label={
            <Box width="3rem">
              <NL />
            </Box>
          }
        />
        <FormControlLabel
          value={Language.EN}
          control={<Radio />}
          label={
            <Box width="3rem">
              <GB />
            </Box>
          }
        />
      </RadioGroup>
    </FormControl>
  );
};

import { EndpointQueryKey } from './EndpointQueryKey';
import { getDateStringFromDate } from '../../helpers/getDateStringFromDate';

export const ManualSeasonalEventsQueryKey = (startDate: Date, endDate: Date) => {
  return (
    EndpointQueryKey.MANUAL_SEASONAL_EVENTS +
    '_' +
    getDateStringFromDate(startDate) +
    '_' +
    getDateStringFromDate(endDate)
  );
};

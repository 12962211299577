import { tokens } from '../../../../../locales/translationTokens';
import IconCopyButton from '../../../shared/IconCopyButton';

interface CopyHouseNumberExtensionButtonProps {
  aggregateId: number;
  houseNumberExtension: string;
}

const CopyHouseNumberExtensionButton = ({
  aggregateId,
  houseNumberExtension,
}: CopyHouseNumberExtensionButtonProps) => {
  return (
    <IconCopyButton
      value={
        houseNumberExtension.charAt(0) == '-' ? houseNumberExtension : `-${houseNumberExtension}`
      }
      label={tokens.automator.orders.buttons.copy.copy_house_number_extension}
      toastMessage={tokens.automator.orders.buttons.copy.house_number_extension_copied}
      activeCopyButtonState={{
        activeButtonId: aggregateId + houseNumberExtension,
        activeAggregateId: aggregateId,
      }}
    />
  );
};

export default CopyHouseNumberExtensionButton;

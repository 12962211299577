import { usePost } from './usePost';
import { useNotAuthorizedAxios } from './useNotAuthorizedAxios';
import { QueryKey } from 'react-query';

export const useUnAuthorizedPost = <U, T = void>(
  url: string,
  queryKey?: QueryKey[],
  getData?: () => T,
  onSuccess?: (data: U) => void
) => {
  const axiosInstance = useNotAuthorizedAxios();
  return usePost<U, T>(axiosInstance, url, queryKey, getData, onSuccess);
};

import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Input from '@mui/material/Input';
import { ChangeEvent, useState } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileUpload from '../../shared/FileUpload';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface FileUploadProps {
  onChange: (files: FileUpload[]) => void;
  multiple?: boolean;
}

const HiddenInput = styled(Input)({
  display: 'none',
});

const FileUploadForm = ({ onChange, multiple }: FileUploadProps) => {
  const [fileNames, setFileNames] = useState<string[]>([]);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filePromises = Array.from(event.target.files).map(
        (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              const result = reader.result as string;
              return resolve({
                name: file.name,
                base64content: result.split(',')[1],
                type: result.split(';')[0].split(':')[1],
              });
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          })
      );

      const files = await Promise.all(filePromises);

      setFileNames(Array.from(event.target.files).map((file) => file.name));
      onChange(files as FileUpload[]);
    }
  };

  return (
    <Stack
      direction="row"
      gap={2}
    >
      <Box
        display="flex"
        alignItems="center"
        width="5%"
      >
        <IconButton
          color="primary"
          component="label"
        >
          <AttachFileIcon style={{ color: 'BLACK' }} />
          <HiddenInput
            id="document-upload"
            type="file"
            inputProps={{ multiple: multiple }}
            onChange={handleFileChange}
          />
        </IconButton>
      </Box>
      <Stack>
        {fileNames.map((fileName, index) => (
          <Typography key={index}>{fileName}</Typography>
        ))}
      </Stack>
    </Stack>
  );
};

export default FileUploadForm;

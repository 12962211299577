export const PD_API_URL = import.meta.env.VITE_PD_API_URL;

export const ACCOUNTS_PATH = 'accounts';
export const STORES_PATH = 'stores';
export const OFFERS_PATH = 'offers';
export const OFFER_PERFORMANCES_PATH = 'offer-performances';

export const OFFER_SHIPPING_COSTS_PATH = 'offer-shipping-costs';
export const OFFER_PERFORMANCE_OVERWRITE_PATH = 'offer-performance-overwrites';

export const PRODUCT_PURCHASE_VOLUMES_PATH = 'product-purchase-volumes';

export const PRODUCT_PURCHASE_COSTS_PATH = 'product-purchase-costs';
export const PRODUCT_REORDER_INTERVAL_PATH = 'product-reorder-intervals';

export const PRODUCT_RANK_SEARCH_TERMS_PATH = 'product-ranks/search-terms';
export const PRODUCT_RANK_CATEGORIES_PATH = 'product-ranks/categories';
export const PRODUCT_RANKS = 'product-ranks';

export const PRODUCT_PERFORMANCES = 'product-performances';

export const SEARCH_TERM_VOLUMES = 'search-term-volumes';

export const ASSORTMENT_IMPRESSIONS = 'assortment/impressions';
export const ASSORTMENT_VISITS = 'assortment/visits';

export const CAMPAIGNS_PATH = 'campaigns';

export const AD_GROUP_SEARCH_TERM_PERFORMANCE_SEARCH_TERMS =
  'ad-group-search-term-performances/search-terms';

export const AD_GROUP_SEARCH_TERM_PERFORMANCES = 'ad-group-search-term-performances';

export const AD_GROUP_PERFORMANCES = 'ad-group-performances';

export const AD_PERFORMANCES = 'ad-performances';

export const PRODUCTS = 'products';

export const PERFORMANCE_NOTES = 'performance-notes';

export const PRODUCT_SEARCH_TERM_SETTINGS = 'product-search-term-settings';

export const SHARE_OF_VOICES = 'share-of-voices';

export const SHARE_OF_VOICE_AVERAGES = 'share-of-voices/averages';

export const SHARE_OF_VOICE_TOTALS = 'share-of-voices/totals';

export const PERFORMANCE_EVENTS = 'performance-events';
export const MANUAL_PERFORMANCE_EVENTS = 'manual-performance-events';

export const MANUAL_SEASONAL_EVENTS = 'manual-seasonal-events';

export const CONTESTED_CATEGORIES = 'contested-categories';

export enum PerformanceEventType {
  OFFER_PRICE = 'OFFER_PRICE',
  OFFER_VOLUME_DISCOUNT = 'OFFER_VOLUME_DISCOUNT',
  OFFER_DELIVERY_CODE = 'OFFER_DELIVERY_CODE',
  CAMPAIGN_ACOS_PERCENTAGE = 'CAMPAIGN_ACOS_PERCENTAGE',
  CAMPAIGN_TARGET_CHANNELS = 'CAMPAIGN_TARGET_CHANNELS',
  CAMPAIGN_DAILY_BUDGET = 'CAMPAIGN_DAILY_BUDGET',
  CAMPAIGN_TOTAL_BUDGET = 'CAMPAIGN_TOTAL_BUDGET',
  PRODUCT_RATING = 'PRODUCT_RATING',
  COMPETITOR_PRODUCT_IS_IN_STOCK = 'COMPETITOR_PRODUCT_IS_IN_STOCK',
  COMPETITOR_PRODUCT_PRICE = 'COMPETITOR_PRODUCT_PRICE',
}

import { useAuthorizedPost } from '../../useAuthorizedPost';
import { PRODUCT_PURCHASE_VOLUMES_PATH } from '../pdApiPaths';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';

export const useUpdateProductPurchaseVolume = (productPurchaseVolumeId: number) => {
  const url = usePdApiAccountUrl(PRODUCT_PURCHASE_VOLUMES_PATH + '/' + productPurchaseVolumeId);
  return useAuthorizedPost<undefined, UpdateProductPurchaseVolumeData>(url, undefined, undefined);
};

export interface UpdateProductPurchaseVolumeData {
  date: string;
  amount_of_units: number;
}

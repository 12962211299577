import { DialogId } from '../../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../../../../../store/dialogs/useCloseDialog';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../locales/translationTokens';
import { ApplicationDialog } from '../../../../../components/ApplicationDialog';
import { CreateManualPerformanceEventForm } from '../components/forms/CreateManualPerformanceEventForm';

export const CreateManualPerformanceEventDialog = () => {
  const id = DialogId.CREATE_MANUAL_PERFORMANCE_EVENT;
  const { isOpen, data } = useIsDialogOpen(id);
  const closeDialog = useCloseDialog(id);
  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          title={t(
            tokens.pd.performance.create_manual_performance_event_form
              .create_manual_performance_event
          )}
        >
          <CreateManualPerformanceEventForm
            offerId={data.offerId}
            onClick={closeDialog}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface CreateManualPerformanceEventDialogData {
  offerId: number;
}

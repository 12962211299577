import { DropdownSelect } from '../../../../automator/shared/DropdownSelect';
import { tokens } from '../../../../../locales/translationTokens';
import { useFetchContestedCategories } from '../../../../../api/pd/competitorProductPerformances/useFetchContestedCategories';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { useActivateContestedCategory } from '../../../../../api/pd/competitorProductPerformances/useActivateContestedCategory';

interface ContestedCategoryDropdownSelectProps {
  productId: number;
}

export const ContestedCategoryDropdownSelect = ({
  productId,
}: ContestedCategoryDropdownSelectProps) => {
  const { data: contestedCategories, isLoading: isLoadingContestedCategories } =
    useFetchContestedCategories(productId);

  const { mutate: activateContestedCategory } = useActivateContestedCategory();

  const { t } = useTranslation();

  const doUpdate = (categoryId: number) => {
    const data = {
      product_id: productId,
      category_id: categoryId,
    };
    activateContestedCategory(data, {
      onSuccess: async () => {
        toast.success(t(tokens.pd.contested_category_dropdown_select.contested_category_activated));
      },
    });
  };

  if (isLoadingContestedCategories) {
    return <div>Loading...</div>;
  }

  const options = contestedCategories!.contestedCategories.map((contestedCategory) => ({
    key: contestedCategory.category.id,
    value: contestedCategory.category.name,
    label: contestedCategory.category.name,
  }));

  return (
    <DropdownSelect
      options={options}
      onSelect={(key) => {
        doUpdate(key);
      }}
      selected={contestedCategories!.contestedCategories.find((c) => c.isActive)?.category.id}
      label={tokens.pd.contested_category_dropdown_select.label}
    />
  );
};

import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { ReturnScanPhotosCarousel } from '../components/ReturnScanPhotosCarousel';

export const ViewReturnScanPhotosDialog = () => {
  const id = DialogId.VIEW_RETURN_SCAN_PHOTOS;

  const { isOpen, data } = useIsDialogOpen(id);

  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          maxWidth={'md'}
          title={t(tokens.automator.return_scans.view_photos)}
        >
          <ReturnScanPhotosCarousel returnScanId={data.returnScanId} />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface ViewReturnScanPhotosDialogData {
  returnScanId: number;
}

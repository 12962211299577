import TextField from '@mui/material/TextField';
import { DeliveryCode } from '../../../../domain/pd/DeliveryCode';
import { useGetDeliveryCodeOptions } from './hooks/useGetDeliveryCodeOptions';

interface DeliveryCodeDropdownProps {
  selected: DeliveryCode | null;
  onSelect: (deliveryCode: DeliveryCode) => void;
}

export const DeliveryCodeDropdown = ({ onSelect, selected }: DeliveryCodeDropdownProps) => {
  const options = useGetDeliveryCodeOptions();

  return (
    <TextField
      size="small"
      variant="outlined"
      select
      value={selected}
      SelectProps={{ native: true }}
      onChange={(e) => onSelect(e.target.value as DeliveryCode)}
    >
      {options.map((option) => (
        <option
          key={option.key}
          value={option.key}
        >
          {option.value}
        </option>
      ))}
    </TextField>
  );
};

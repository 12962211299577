import { tokens } from '../../../../locales/translationTokens';
import { OrderSummaryCardItem } from './OrderSummaryCardItem';
import { RecurringOrdersButtons } from './RecurringOrdersButtons';

interface OrderSummaryRecurringOrdersCardItemProps {
  recurringBolOrderIds: string[];
  placedDoubleBolOrderIds: string[];
}

export const OrderSummaryRecurringOrdersCardItem = ({
  recurringBolOrderIds,
  placedDoubleBolOrderIds,
}: OrderSummaryRecurringOrdersCardItemProps) => {
  const bolOrderIds = recurringBolOrderIds.concat(placedDoubleBolOrderIds);

  if (bolOrderIds.length === 0) {
    return <></>;
  }

  return (
    <OrderSummaryCardItem label={tokens.automator.order_summary.recurring_orders}>
      <RecurringOrdersButtons
        recurringBolOrderIds={recurringBolOrderIds}
        placedDoubleBolOrderIds={placedDoubleBolOrderIds}
      />
    </OrderSummaryCardItem>
  );
};

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import CustomerInvoicesBundle from '../../../../domain/automator/customerInvoiceRequests/CustomerInvoicesBundle';
import ActionButton from '../../../../components/ActionButton';
import { tokens } from '../../../../locales/translationTokens';
import Typography from '@mui/material/Typography';
import { CustomerInvoicesBundleStatus } from '../../../../domain/automator/customerInvoiceRequests/CustomerInvoicesBundleStatus';
// eslint-disable-next-line import/no-named-as-default
import getShortenedAlphabeticFormatFromDate from '../../../../helpers/getShortendAlphabeticFormatFromDate';

interface CustomerInvoicesBundleTableRowProps {
  customerInvoicesBundle: CustomerInvoicesBundle;
}

export const CustomerInvoicesBundleTableRow = ({
  customerInvoicesBundle,
}: CustomerInvoicesBundleTableRowProps) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell>{customerInvoicesBundle.retailerInvoice?.bolRetailerInvoiceId}</TableCell>
      <TableCell>{customerInvoicesBundle.type}</TableCell>
      <TableCell>
        <Typography>
          {getShortenedAlphabeticFormatFromDate(new Date(customerInvoicesBundle.startDate), false) +
            ' t/m ' +
            getShortenedAlphabeticFormatFromDate(new Date(customerInvoicesBundle.endDate), false) +
            ' ' +
            new Date(customerInvoicesBundle.startDate).getFullYear()}
        </Typography>
      </TableCell>
      <TableCell>{customerInvoicesBundle.amountOfDebitInvoices}</TableCell>
      <TableCell>{customerInvoicesBundle.amountOfCreditInvoices}</TableCell>
      <TableCell align={'right'}>
        <>
          {customerInvoicesBundle.downloadUrl && (
            <a href={customerInvoicesBundle.downloadUrl}>
              <ActionButton
                label={t(tokens.automator.customer_invoices_bundles.download)}
                variant="outlined"
                size="small"
              />
            </a>
          )}
          {customerInvoicesBundle.status == CustomerInvoicesBundleStatus.INVALIDATED ||
            (customerInvoicesBundle.status == CustomerInvoicesBundleStatus.CREATED && (
              <Typography>
                {t(tokens.automator.customer_invoices_bundles.being_regenerated)}
              </Typography>
            ))}
        </>
      </TableCell>
    </TableRow>
  );
};

import { useState } from 'react';
import { Box, CircularProgress, IconButton, Paper } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useFetchReturnScanDocuments } from '../../../../api/automator/returnScans/useFetchReturnScanDocuments';

interface ReturnScanPhotosCarouselProps {
  returnScanId: number;
}

export const ReturnScanPhotosCarousel = ({ returnScanId }: ReturnScanPhotosCarouselProps) => {
  const { data: returnScanDocuments, isLoading } = useFetchReturnScanDocuments(returnScanId);
  const [currentIndex, setCurrentIndex] = useState(0);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!returnScanDocuments || returnScanDocuments.returnScanDocuments.length === 0) {
    return <Box>No documents found.</Box>;
  }

  const documents = returnScanDocuments.returnScanDocuments;

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + documents.length) % documents.length);
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % documents.length);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
    >
      <IconButton onClick={handlePrevClick}>
        <ArrowBackIos />
      </IconButton>
      <Paper
        elevation={3}
        sx={{ p: 2, minWidth: 300, textAlign: 'center' }}
      >
        <img
          src={documents[currentIndex].url}
          alt={documents[currentIndex].name}
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </Paper>
      <IconButton onClick={handleNextClick}>
        <ArrowForwardIos />
      </IconButton>
    </Box>
  );
};

import TableRow from '@mui/material/TableRow';
import OfferPerformance, {
  OfferPerformanceProperty,
} from '../../../../../../domain/pd/OfferPerformance';
import { useOpenDialog } from '../../../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../../../store/dialogs/DialogId';
import {
  ArticlesOrderOption,
  OrganicNonOrganicOption,
} from './OfferPerformancesOrganicNonOrganicTable';
import { ColorScale, PerformanceDateType, PerformanceTableCell } from '../PerformanceTableCell';
import OfferPerformancesMinMaxReport from '../../../../../../domain/pd/OfferPerformancesMinMaxReport';

interface OfferPerformanceOrganicNonOrganicTableRowProps {
  offerPerformance: OfferPerformance;
  offerPerformancesMinMaxReport: OfferPerformancesMinMaxReport;
  organicNonOrganicOption: OrganicNonOrganicOption;
  articlesOrdersOption: ArticlesOrderOption;
}

export const OfferPerformanceOrganicNonOrganicTableRow = ({
  offerPerformance,
  offerPerformancesMinMaxReport,
  organicNonOrganicOption,
  articlesOrdersOption,
}: OfferPerformanceOrganicNonOrganicTableRowProps) => {
  const openCreateOfferPerformanceOverwriteDialog = useOpenDialog(
    DialogId.CREATE_OFFER_PERFORMANCE_OVERWRITE
  );
  const openUpdateOfferPerformanceOverwriteDialog = useOpenDialog(
    DialogId.UPDATE_OFFER_PERFORMANCE_OVERWRITE
  );

  const onClickVisits = () => {
    if (offerPerformance.overwrite == null) {
      openCreateOfferPerformanceOverwriteDialog({
        offerId: offerPerformance.offerId,
        date: offerPerformance.date,
      });
    } else {
      openUpdateOfferPerformanceOverwriteDialog({
        offerPerformanceOverwrite: offerPerformance.overwrite,
      });
    }
  };

  const getVisits = (offerPerformance: OfferPerformance) => {
    switch (organicNonOrganicOption) {
      case OrganicNonOrganicOption.ALL:
        return offerPerformance.visits;
      case OrganicNonOrganicOption.ORGANIC:
        return offerPerformance.organicVisits;
      case OrganicNonOrganicOption.NONE_ORGANIC:
        return offerPerformance.nonOrganicVisits;
    }
  };

  const getVisitsMinMax = (offerPerformancesMinMaxReport: OfferPerformancesMinMaxReport) => {
    switch (organicNonOrganicOption) {
      case OrganicNonOrganicOption.ALL:
        return offerPerformancesMinMaxReport.visits;
      case OrganicNonOrganicOption.ORGANIC:
        return offerPerformancesMinMaxReport.organicVisits;
      case OrganicNonOrganicOption.NONE_ORGANIC:
        return offerPerformancesMinMaxReport.nonOrganicVisits;
    }
  };

  const getArticlesSold = (offerPerformance: OfferPerformance) => {
    switch (organicNonOrganicOption) {
      case OrganicNonOrganicOption.ALL:
        return offerPerformance.articlesSold;
      case OrganicNonOrganicOption.ORGANIC:
        return offerPerformance.organicArticlesSold;
      case OrganicNonOrganicOption.NONE_ORGANIC:
        return offerPerformance.nonOrganicArticlesSold;
    }
  };

  const getArticlesSoldMinMax = (offerPerformancesMinMaxReport: OfferPerformancesMinMaxReport) => {
    switch (organicNonOrganicOption) {
      case OrganicNonOrganicOption.ALL:
        return offerPerformancesMinMaxReport.articlesSold;
      case OrganicNonOrganicOption.ORGANIC:
        return offerPerformancesMinMaxReport.organicArticlesSold;
      case OrganicNonOrganicOption.NONE_ORGANIC:
        return offerPerformancesMinMaxReport.nonOrganicArticlesSold;
    }
  };

  const getConversionRate = (offerPerformance: OfferPerformance) => {
    switch (organicNonOrganicOption) {
      case OrganicNonOrganicOption.ALL:
        return offerPerformance.conversionRate;
      case OrganicNonOrganicOption.ORGANIC:
        return offerPerformance.organicConversionRate;
      case OrganicNonOrganicOption.NONE_ORGANIC:
        return offerPerformance[OfferPerformanceProperty.NON_ORGANIC_CONVERSIONS_RATE];
    }
  };

  const getConversionRateMinMax = (
    offerPerformancesMinMaxReport: OfferPerformancesMinMaxReport
  ) => {
    switch (organicNonOrganicOption) {
      case OrganicNonOrganicOption.ALL:
        return offerPerformancesMinMaxReport.conversionRate;
      case OrganicNonOrganicOption.ORGANIC:
        return offerPerformancesMinMaxReport.organicConversionRate;
      case OrganicNonOrganicOption.NONE_ORGANIC:
        return offerPerformancesMinMaxReport[OfferPerformanceProperty.NON_ORGANIC_CONVERSIONS_RATE];
    }
  };

  return (
    <TableRow>
      <PerformanceTableCell
        value={getVisits(offerPerformance)}
        type={PerformanceDateType.ROUNDED}
        onClick={onClickVisits}
        minMaxReport={getVisitsMinMax(offerPerformancesMinMaxReport)}
        colorScale={ColorScale.GREEN}
      />

      {articlesOrdersOption === ArticlesOrderOption.ARTICLES ? (
        <>
          <PerformanceTableCell
            value={getArticlesSold(offerPerformance)}
            type={PerformanceDateType.ROUNDED}
            minMaxReport={getArticlesSoldMinMax(offerPerformancesMinMaxReport)}
            colorScale={ColorScale.GREEN}
          />

          <PerformanceTableCell
            value={getConversionRate(offerPerformance)}
            type={PerformanceDateType.PERCENTAGE}
            minMaxReport={getConversionRateMinMax(offerPerformancesMinMaxReport)}
            colorScale={ColorScale.GREEN}
          />
        </>
      ) : (
        <>
          <PerformanceTableCell
            value={offerPerformance.amountOfOrders}
            type={PerformanceDateType.ROUNDED}
            minMaxReport={offerPerformancesMinMaxReport.amountOfOrders}
            colorScale={ColorScale.GREEN}
          />

          <PerformanceTableCell
            value={offerPerformance.ordersConversionRate}
            type={PerformanceDateType.PERCENTAGE}
            minMaxReport={offerPerformancesMinMaxReport.ordersConversionRate}
            colorScale={ColorScale.GREEN}
          />
        </>
      )}
    </TableRow>
  );
};

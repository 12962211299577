import ThreadMessage from '../../../../domain/automator/messages/ThreadMessage'; // @ts-ignore
import DOMPurify from 'dompurify';
import parse from 'react-html-parser';
import { tss } from 'tss-react/mui-compat';
import { getTimeStringFromDate } from '../../../../helpers/getTimeStringFromDate';
import { colors } from '@mui/material';
import SenderTypeChip from './SenderTypeChip';
import { SenderType } from '../../../../domain/automator/messages/SenderType';
import Badge from '@mui/material/Badge';
import { Box } from '@mui/system';
// eslint-disable-next-line import/no-named-as-default
import getShortenedAlphabeticFormatFromDate from '../../../../helpers/getShortendAlphabeticFormatFromDate';

interface ThreadMessageViewProps {
  message: ThreadMessage;
}

export const ThreadMessageView = ({ message }: ThreadMessageViewProps) => {
  const { classes, cx } = useStyles();

  const determineMailBackgroundColor = (senderType: SenderType) => {
    switch (senderType) {
      case SenderType.SELLER:
        return colors.green[50];
      case SenderType.CUSTOMER:
        return colors.cyan[50];
      case SenderType.BOL:
        return colors.blue[50];
      case SenderType.TRANSPORTER:
        return colors.lightBlue[50];
      default:
        return colors.grey[50];
    }
  };

  const date = new Date(message.sentDateTime || message.createdDateTime);

  return (
    <Box width="100%">
      <Badge
        badgeContent={<SenderTypeChip senderType={message.senderType} />}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ width: '100%' }}
      >
        <div
          style={{ width: '100%' }}
          className={cx(classes.emailMessage)}
        >
          {parse(
            DOMPurify.sanitize(`
                <div style="font-family: Arial, sans-serif; font-size: 14px; line-height: 1.0; border-radius: 25px; color: #333; padding: 35px 35px 15px 35px; width: 100%; margin: 0 0; background-color: ${determineMailBackgroundColor(
                  message.senderType
                )};">
                  <div>${message.body}</div>
                  <div style="width: 100%; text-align: right; padding-top: 10px; font-size: 11px"> ${
                    getShortenedAlphabeticFormatFromDate(date) + ' ' + getTimeStringFromDate(date)
                  }
                  </div>
                </div>
              `)
          )}
        </div>
      </Badge>
    </Box>
  );
};

const useStyles = tss.create(() => ({
  emailMessage: {
    '& p': {
      lineHeight: '1.3',
      margin: '0 0',
    },
    '& br': {
      lineHeight: '1',
    },
  },
}));

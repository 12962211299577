// slices/buttonSlice.js
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderStatus } from '../../domain/automator/orders/OrderStatus'; // Define a type for the slice state

// Define a type for the slice state
export interface OrderTabsState {
  orderTab: OrderStatus;
}

// Initial state
const initialState: OrderTabsState = {
  orderTab: OrderStatus.OPEN,
};

export const orderTabsSlice = createSlice({
  name: 'order_tabs',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<OrderTabsState>) => {
      state.orderTab = action.payload.orderTab;
    },
  },
});

export const { setActiveTab } = orderTabsSlice.actions;

export const orderTabsReducer = orderTabsSlice.reducer;

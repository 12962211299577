import { useEffect, useRef, useState } from 'react';
import { useFetchData } from './useFetchData';
import { QueryKey, UseQueryOptions } from 'react-query';

interface UsePollingFetchOptions<T> extends UseQueryOptions<T> {
  initialPollingInterval: number;
  stopCondition?: (data: T | undefined) => boolean;
  startCondition?: (data: T | undefined) => boolean;
  onStopPolling?: () => void;
  incrementFactor?: number;
  maxPollAttempts?: number;
  updateState?: (data: T | undefined) => void;
}

export const useFetchDataPolled = <T>(
  url: string,
  queryKey: QueryKey,
  options: UsePollingFetchOptions<T>
) => {
  const {
    initialPollingInterval,
    stopCondition,
    startCondition,
    incrementFactor = 1.5,
    updateState,
    keepPreviousData,
    maxPollAttempts = 150,
    onStopPolling,
    //...fetchOptions
  } = options;

  const [pollingInterval, setPollingInterval] = useState(initialPollingInterval);
  const [isPolling, setDoPoll] = useState(true);
  const [pollAttempts, setPollAttempts] = useState(0);

  const previousQueryKey = useRef(queryKey.toString());

  useEffect(() => {
    if (queryKey != previousQueryKey.current) {
      setDoPoll(true);
      setPollingInterval(initialPollingInterval);
      setPollAttempts(0);
    }
    previousQueryKey.current = queryKey.toString();
  }, [queryKey, initialPollingInterval]);

  const {
    data,
    isLoading: isLoadingQuery,
    isFetching,
    isStale,
    ...queryInfo
  } = useFetchData<T>(url, queryKey, {
    refetchInterval: pollingInterval,
    enabled: isPolling,
    onSuccess: (newData) => {
      setPollAttempts((attempts) => attempts + 1);
      if ((stopCondition && stopCondition(newData)) || pollAttempts > maxPollAttempts) {
        setDoPoll(false);
        onStopPolling && onStopPolling();
      } else if (startCondition && startCondition(newData)) {
        setDoPoll(true);
      } else {
        setPollingInterval((interval) => interval * incrementFactor);
      }

      updateState && updateState(data);
    },
    onError: (_) => {
      setDoPoll(false);
    },
    keepPreviousData,
  });

  useEffect(() => {
    if (isStale) {
      setDoPoll(true);
      setPollingInterval(initialPollingInterval);
      setPollAttempts(0);
    }
    previousQueryKey.current = queryKey.toString();
  }, [isStale]);

  const isLoading = pollAttempts === 0 && (isLoadingQuery || isFetching);

  return { data, isLoading, ...queryInfo, setDoPoll };
};

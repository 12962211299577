import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import AdGroupSearchTermPerformanceSearchTerm from '../../../../../../domain/pd/AdGroupSearchTermPerformanceSearchTerm';
import SearchTerm from '../../../../../../domain/pd/SearchTerm';
import { convertCentsToEuros } from '../../../../helpers/convertCentsToEuros';
import convertRatioToPercentage from '../../../../helpers/convertRatioToPercentage';
import ShareOfVoiceAverage from '../../../../../../domain/pd/ShareOfVoiceAverage';
import roundDownTo1Decimal from '../../../../helpers/roundDownTo1Decimal';

interface AdGroupSearchTermPerformanceSearchTermTableRowProps {
  adGroupSearchTermPerformanceSearchTerm: AdGroupSearchTermPerformanceSearchTerm;
  // These can be multiple because sometimes both search term and category have the same name
  // And the ad group search term performance has clustered them together
  // So we take the average in that case
  shareOfVoiceAverages: ShareOfVoiceAverage[] | undefined;
  onSelect: (searchTerm: SearchTerm) => void;
  isSelected: boolean;
}

export const AdGroupSearchTermPerformanceSearchTermTableRow = ({
  adGroupSearchTermPerformanceSearchTerm,
  shareOfVoiceAverages,
  onSelect,
  isSelected,
}: AdGroupSearchTermPerformanceSearchTermTableRowProps) => {
  const impressionsSharePercentage = shareOfVoiceAverages
    ? shareOfVoiceAverages.reduce((a, b) => a + (b.impressionsSharePercentage ?? 0), 0) /
      (shareOfVoiceAverages.length || 1)
    : null;

  const clickSharePercentage = shareOfVoiceAverages
    ? shareOfVoiceAverages.reduce((a, b) => a + (b.clickSharePercentage ?? 0), 0) /
      (shareOfVoiceAverages.length || 1)
    : null;

  return (
    <TableRow
      style={{
        backgroundColor: isSelected ? '#ff0' : '#fff',
        cursor: 'pointer',
      }}
      onClick={() => onSelect(adGroupSearchTermPerformanceSearchTerm.searchTerm)}
    >
      <TableCell>{adGroupSearchTermPerformanceSearchTerm.searchTerm.searchTerm}</TableCell>
      <TableCell>{adGroupSearchTermPerformanceSearchTerm.totalImpressions}</TableCell>
      <TableCell>{adGroupSearchTermPerformanceSearchTerm.totalClicks}</TableCell>

      <TableCell>
        {roundDownTo1Decimal(impressionsSharePercentage) + (impressionsSharePercentage ? '%' : '')}
      </TableCell>
      <TableCell>
        {roundDownTo1Decimal(clickSharePercentage) + (clickSharePercentage ? '%' : '')}
      </TableCell>

      <TableCell>
        {convertRatioToPercentage(adGroupSearchTermPerformanceSearchTerm.averageCtr)}
      </TableCell>

      <TableCell>{adGroupSearchTermPerformanceSearchTerm.totalConversions}</TableCell>
      <TableCell>
        {convertRatioToPercentage(adGroupSearchTermPerformanceSearchTerm.averageConversionRate)}
      </TableCell>
      <TableCell>
        {convertCentsToEuros(adGroupSearchTermPerformanceSearchTerm.totalSalesInCents)}
      </TableCell>
      <TableCell>
        {convertCentsToEuros(adGroupSearchTermPerformanceSearchTerm.totalSpentInCents)}
      </TableCell>
      <TableCell>{adGroupSearchTermPerformanceSearchTerm.averageAcos}</TableCell>
      <TableCell>
        {convertCentsToEuros(adGroupSearchTermPerformanceSearchTerm.averageCpcInCents)}
      </TableCell>

      <TableCell>
        {convertCentsToEuros(
          adGroupSearchTermPerformanceSearchTerm.averageWinningBidAverageInCents
        )}
      </TableCell>

      <TableCell>{adGroupSearchTermPerformanceSearchTerm.totalSearchVolume}</TableCell>
    </TableRow>
  );
};

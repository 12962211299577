import { tokens } from '../../../../locales/translationTokens';
import { DateChipAndText } from './DateChipAndText';

interface OrderPlacedDateTextProps {
  orderPlacedDate: string;
}

export const OrderPlacedDateText = ({ orderPlacedDate }: OrderPlacedDateTextProps) => {
  return (
    <DateChipAndText
      chip={'B'}
      date={orderPlacedDate}
      tooltip={tokens.automator.orders.order_placed_date_tooltip}
    />
  );
};

import { EndpointQueryKey } from './EndpointQueryKey';
import { getDateStringFromDate } from '../../helpers/getDateStringFromDate';

export const AdGroupSearchTermPerformanceSearchTermsQueryKey = (
  adGroupId: number,
  startDate: Date,
  endDate: Date
) => {
  return (
    EndpointQueryKey.AD_GROUP_SEARCH_TERM_PERFORMANCE_SEARCH_TERMS +
    '_' +
    adGroupId +
    '_' +
    getDateStringFromDate(startDate) +
    '_' +
    getDateStringFromDate(endDate)
  );
};

import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { UpdateProductsForm } from '../components/UpdateProductsForm';
import AutomatorProduct from '../../../../domain/automator/products/AutomatorProduct';

export const UpdateProductsDialog = () => {
  const id = DialogId.UPDATE_PRODUCTS;

  const { isOpen, data } = useIsDialogOpen(id);

  const closeDialog = useCloseDialog(id);

  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          maxWidth={false}
          title={t(tokens.automator.products.dialogs.update_products.update_products)}
        >
          <UpdateProductsForm
            products={data.products}
            onSave={closeDialog}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface UpdateProductsDialogData {
  products: AutomatorProduct[];
}

import { useQueryClient } from 'react-query';
import { Aggregate } from '../domain/Aggregate';

type addCriteriaCriteria<T> = (data: T) => boolean;
type SortFunction<T> = (a: T, b: T) => number;

// Defining the type for QueryKeyFunctionMapping
export type QueryKeysUpdater<T> = {
  [queryKey: string]: {
    addCriteria: addCriteriaCriteria<T>;
    sort?: SortFunction<T>;
  };
};

export const useUpdateData = <T, U extends Aggregate>(
  functionMap: QueryKeysUpdater<U>,
  aggregateKey: keyof T
) => {
  const queryClient = useQueryClient();

  return (newData: T) => {
    const newAggregates = newData[aggregateKey] as U[];

    Object.keys(functionMap).forEach((queryKey) => {
      const oldData = queryClient.getQueryData<T>(queryKey);

      if (!oldData) return;

      const oldAggregates = oldData[aggregateKey] as U[];

      const oldAggregateMap = new Map(
        oldAggregates.map((item, index) => [item.id, { item, index }])
      );

      const { addCriteria, sort } = functionMap[queryKey];
      const modifiedAggregates: U[] = [];

      // First pass: Remove or replace items in old aggregates if they are in new aggregates and matches addCriteria
      oldAggregates.forEach((oldAggregate) => {
        const newAggregate = newAggregates.find((newItem) => newItem.id === oldAggregate.id);

        if (newAggregate && addCriteria(newAggregate)) {
          modifiedAggregates.push(newAggregate);
        } else if (!newAggregate) {
          modifiedAggregates.push(oldAggregate);
        }
      });

      // Second pass: Add items from new aggregates at bottom if they are not in old aggregates
      newAggregates.forEach((newAggregate) => {
        if (!oldAggregateMap.has(newAggregate.id) && addCriteria(newAggregate)) {
          // Add at bottom
          modifiedAggregates.push(newAggregate);
        }
      });

      if (sort) {
        modifiedAggregates.sort(sort);
      }

      // Get all non-aggregate attributes of oldData not present in newData
      // It doesn't affect attributes of newData that already exist in oldData
      const additionalAttributes = Object.fromEntries(
        // @ts-ignore
        Object.entries(oldData).filter(([key]) => key !== aggregateKey && !(key in newData))
      );

      const updatedData = { ...newData, ...additionalAttributes };

      // @ts-ignore
      updatedData[aggregateKey] = modifiedAggregates;

      queryClient.setQueryData(queryKey, updatedData);
    });
  };
};

import { DropdownSelect } from '../../../../automator/shared/DropdownSelect';
import { tokens } from '../../../../../locales/translationTokens';

export enum LatestDays {
  LATEST_14_DAYS = 'LATEST_14_DAYS',
  LATEST_30_DAYS = 'LATEST_30_DAYS',
  LATEST_60_DAYS = 'LATEST_60_DAYS',
  LATEST_90_DAYS = 'LATEST_90_DAYS',
  LATEST_150_DAYS = 'LATEST_150_DAYS',
}

interface LatestDaysDropdownSelectProps {
  onSelect: (dateRange: LatestDays) => void;
  selected?: LatestDays;
}

export const LatestDaysDropdownSelect = ({ selected, onSelect }: LatestDaysDropdownSelectProps) => {
  const options = [
    {
      key: LatestDays.LATEST_14_DAYS,
      value: LatestDays.LATEST_14_DAYS,
      label: tokens.pd.latest_days_dropdown_select.latest_14_days,
    },
    {
      key: LatestDays.LATEST_30_DAYS,
      value: LatestDays.LATEST_30_DAYS,
      label: tokens.pd.latest_days_dropdown_select.latest_30_days,
    },
    {
      key: LatestDays.LATEST_60_DAYS,
      value: LatestDays.LATEST_60_DAYS,
      label: tokens.pd.latest_days_dropdown_select.latest_60_days,
    },
    {
      key: LatestDays.LATEST_90_DAYS,
      value: LatestDays.LATEST_90_DAYS,
      label: tokens.pd.latest_days_dropdown_select.latest_90_days,
    },
    {
      key: LatestDays.LATEST_150_DAYS,
      value: LatestDays.LATEST_150_DAYS,
      label: tokens.pd.latest_days_dropdown_select.latest_150_days,
    },
  ];

  return (
    <DropdownSelect
      options={options}
      onSelect={(key) => {
        onSelect(key);
      }}
      selected={selected}
      label={tokens.pd.latest_days_dropdown_select.label}
    />
  );
};

import { PRODUCTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { useAutomatorProductQueryKeysUpdater } from './updaters/useAutomatorProductQueryKeysUpdater';
import { useUpdateData } from '../../useUpdateData';
import AutomatorProducts from '../../../domain/automator/products/AutomatorProducts';

export interface RemoveGroupFromProductData {
  product_group_id: number;
}

export const useRemoveGroupFromProduct = (productId: number) => {
  const url = useAutomatorApiAccountUrl(`${PRODUCTS_PATH}/${productId}/remove-product-group`);

  const updater = useAutomatorProductQueryKeysUpdater();
  const updateData = useUpdateData(updater, 'products');

  const onSuccess = (products: AutomatorProducts) => {
    updateData(products);
  };

  return useAuthorizedPost<AutomatorProducts, RemoveGroupFromProductData>(
    url,
    [],
    undefined,
    onSuccess
  );
};

import { useAuthorizedPost } from '../../useAuthorizedPost';
import { PERFORMANCE_NOTES } from '../pdApiPaths';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';

export const useUpdatePerformanceNote = (performanceNoteId: number) => {
  const url = usePdApiAccountUrl(PERFORMANCE_NOTES + `/` + performanceNoteId);
  return useAuthorizedPost<undefined, UpdatePerformanceNoteData>(url, undefined, undefined);
};

export interface UpdatePerformanceNoteData {
  note: string;
}

import { useAuthorizedPost } from '../../useAuthorizedPost';
import { OFFER_SHIPPING_COSTS_PATH } from '../pdApiPaths';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';

export const useCreateOfferShippingCosts = () => {
  const url = usePdApiAccountUrl(OFFER_SHIPPING_COSTS_PATH);
  return useAuthorizedPost<undefined, CreateOfferShippingCostsData>(url, undefined, undefined);
};

export interface CreateOfferShippingCostsData {
  offer_id: number;
  date: string;
  shipping_costs_in_cents: number;
}

import { REIMBURSEMENTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { ReimbursementsQueryKey } from '../../queryKeys/ReimbursementsQueryKey';
import Reimbursements from '../../../domain/automator/reimbursements/Reimbursements';

export const useFetchReimbursements = (isDismissed: boolean) => {
  const url = useAutomatorApiAccountUrl(`${REIMBURSEMENTS_PATH}?isDismissed=${isDismissed}`);
  return useFetchData<Reimbursements>(url, ReimbursementsQueryKey(isDismissed));
};

import Stack from '@mui/material/Stack';
import { DeliveryCode } from '../../../../domain/pd/DeliveryCode';
import { DeliveryCodeDropdown } from '../../orders/components/DeliveryCodeDropdown';
import { ActionIcon } from '../../orders/components/ActionIcon';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { useGetDeliveryCodeOptions } from '../../orders/components/hooks/useGetDeliveryCodeOptions';

interface UpdateDeliveryCodeStackProps {
  value?: DeliveryCode;
  onChange: (deliveryCode: DeliveryCode) => void;
}

export const UpdateDeliveryCodeStack = ({ value, onChange }: UpdateDeliveryCodeStackProps) => {
  const options = useGetDeliveryCodeOptions();

  const determineDeliveryCodeDown = () => {
    const index = options.findIndex((option) => option.key === value);
    if (index + 1 == options.length) {
      return options[0].key;
    } else {
      return options[index + 1]?.key ?? value;
    }
  };

  const determineDeliveryCodeUp = () => {
    const index = options.findIndex((option) => option.key === value);
    if (index - 1 == -1) {
      return options[options.length - 1].key;
    } else {
      return options[index - 1]?.key ?? value;
    }
  };

  return (
    <Stack direction="row">
      <DeliveryCodeDropdown
        selected={value ?? DeliveryCode.MIJNLEVERBELOFTE}
        onSelect={(deliveryCode) => onChange(deliveryCode)}
      />
      <Stack direction="row">
        <ActionIcon
          iconSize={20}
          icon={<KeyboardDoubleArrowUpIcon />}
          onClick={() => onChange(determineDeliveryCodeUp())}
          tooltip={'+'}
        />
        <ActionIcon
          iconSize={20}
          icon={<KeyboardDoubleArrowDownIcon />}
          onClick={() => onChange(determineDeliveryCodeDown())}
          tooltip={'-'}
        />
      </Stack>
    </Stack>
  );
};

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { TableEmpty } from '../../../../components/TableEmpty';
import { useFetchCustomerInvoicesBundles } from '../../../../api/automator/customerInvoiceRequests/useFetchCustomerInvoicesBundles';
import { CustomerInvoicesBundleTableRow } from './CustomerInvoicesBundleTableRow';
import CustomerInvoicesBundle from '../../../../domain/automator/customerInvoiceRequests/CustomerInvoicesBundle';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import RetailerLooksIcon from '../../retailers/components/RetailerLooksIcon';

export const CustomerInvoicesBundlesListTable = () => {
  const { t } = useTranslation();
  const { data: customerInvoiceBundles, isLoading } = useFetchCustomerInvoicesBundles();
  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>({});
  const [expandedRetailers, setExpandedRetailers] = useState<Record<string, boolean>>({});

  if (isLoading) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  const customerInvoicesBundles = customerInvoiceBundles!.customerInvoicesBundles
    .filter((bundle) => bundle.amountOfCreditInvoices !== 0 || bundle.amountOfDebitInvoices !== 0)
    .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());

  if (!customerInvoicesBundles || customerInvoicesBundles.length === 0) {
    return <TableEmpty message={t(tokens.common.no_items)} />;
  }

  const groupedCustomerInvoicesBundles = customerInvoicesBundles.reduce<
    Record<string, Record<string, CustomerInvoicesBundle[]>>
  >((acc, customerInvoicesBundle) => {
    const dateKey = customerInvoicesBundle.startDate + ' - ' + customerInvoicesBundle.endDate;
    const retailerKey = customerInvoicesBundle.retailerId;

    if (!acc[dateKey]) {
      acc[dateKey] = {};
    }
    if (!acc[dateKey][retailerKey]) {
      acc[dateKey][retailerKey] = [];
    }
    acc[dateKey][retailerKey].push(customerInvoicesBundle);
    return acc;
  }, {});

  const toggleGroupVisibility = (key: string) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const toggleRetailerVisibility = (key: string) => {
    setExpandedRetailers((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  return (
    <Scrollbar>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t(tokens.automator.customer_invoices_bundles.invoice_number)}</TableCell>
            <TableCell>{t(tokens.automator.customer_invoices_bundles.type)}</TableCell>
            <TableCell>{t(tokens.automator.customer_invoices_bundles.period)}</TableCell>
            <TableCell>{t(tokens.automator.customer_invoices_bundles.debit_invoices)}</TableCell>
            <TableCell>{t(tokens.automator.customer_invoices_bundles.credit_invoices)}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.entries(groupedCustomerInvoicesBundles).map(([dateKey, retailerGroups]) => (
            <>
              <TableRow
                key={`${dateKey}-header`}
                onClick={() => toggleGroupVisibility(dateKey)}
                style={{ cursor: 'pointer', backgroundColor: '#f5f5f5' }}
              >
                <TableCell colSpan={5}>
                  <Typography fontWeight={500}>{dateKey}</Typography>
                </TableCell>
                <TableCell align={'right'}>{expandedGroups[dateKey] ? '-' : '+'}</TableCell>
              </TableRow>

              {expandedGroups[dateKey] &&
                Object.entries(retailerGroups).map(([retailerKey, bundles]) => (
                  <>
                    <TableRow
                      key={`${retailerKey}-header`}
                      onClick={() => toggleRetailerVisibility(retailerKey)}
                      style={{ cursor: 'pointer', backgroundColor: '#e0e0e0' }}
                    >
                      <TableCell
                        colSpan={5}
                        style={{ paddingLeft: '2em' }}
                      >
                        <RetailerLooksIcon
                          withName={true}
                          retailerId={retailerKey as unknown as number}
                        />
                      </TableCell>
                      <TableCell align="right">
                        {expandedRetailers[retailerKey] ? '-' : '+'}
                      </TableCell>
                    </TableRow>

                    {expandedRetailers[retailerKey] &&
                      bundles.map((customerInvoicesBundle) => (
                        <CustomerInvoicesBundleTableRow
                          key={customerInvoicesBundle.id}
                          customerInvoicesBundle={customerInvoicesBundle}
                        />
                      ))}
                  </>
                ))}
            </>
          ))}
        </TableBody>
      </Table>
    </Scrollbar>
  );
};

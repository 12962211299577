import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { useFetchPinnedOrders } from '../../../api/automator/orders/useFetchPinnedOrders';
import { PinnedOrdersListTable } from './components/PinnedOrdersListTable';

const PinnedOrdersPage = () => {
  const { data: orders, isLoading } = useFetchPinnedOrders();

  return (
    <AccountPage title={tokens.automator.pinned_orders.pinned_orders}>
      <PinnedOrdersListTable
        orders={orders}
        isLoading={isLoading}
      />
    </AccountPage>
  );
};

export default PinnedOrdersPage;

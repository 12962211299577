import Chip from '@mui/material/Chip';
import * as React from 'react';
import { Box } from '@mui/system';

interface ProductThumbnailProps {
  thumbnailUrl?: string;
  height: number;
  onClick?: () => void;
  quantity?: number;
}

export const ProductThumbnail = ({
  thumbnailUrl,
  height,
  onClick,
  quantity,
}: ProductThumbnailProps) => {
  const placeholderThumbnail = 'https://imageholdr.com/250x200/transparent/757575/fa-barcode';

  const width = height * 1.14583333333;

  return (
    <Box position="relative">
      {quantity && quantity > 1 ? (
        <Chip
          size="small"
          sx={{
            height: '20px',
            width: '40px',
            position: 'absolute',
            top: 0.2,
            zIndex: 1,
          }}
          variant="filled"
          color="primary"
          label={<strong>{quantity}X</strong>}
        />
      ) : (
        <></>
      )}

      <img
        onClick={onClick}
        style={{
          width: width + 'px',
          height: height + 'px',
          cursor: onClick ? 'pointer' : undefined,
        }}
        src={thumbnailUrl ? thumbnailUrl : placeholderThumbnail}
        onError={(e: any) => {
          e.target.onerror = null;
          e.target.src = placeholderThumbnail;
        }}
        alt=""
      />
    </Box>
  );
};

import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { toast } from 'react-hot-toast';
import { useAwaitingResponseResolutionCase } from '../../../../api/automator/resolutions/useAwaitingResponseResolutionCase';
import { ActionIcon } from '../../orders/components/ActionIcon';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import { useNavigate } from 'react-router-dom';
import { usePaths } from '../../../../routes/usePaths';

interface AwaitingResponseResolutionCaseButtonProps {
  resolutionCaseId: number;
}

export const AwaitingResponseResolutionCaseButton = ({
  resolutionCaseId,
}: AwaitingResponseResolutionCaseButtonProps) => {
  const { mutate, isLoading } = useAwaitingResponseResolutionCase(resolutionCaseId);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const paths = usePaths();

  const onClick = () => {
    mutate(undefined, {
      onSuccess: () => {
        toast.success(t(tokens.automator.resolutions.actions.case_set_to_awaiting_response));
        navigate(paths.automator.resolution_dossiers);
      },
    });
  };

  return (
    <ActionIcon
      iconSize={22}
      icon={<AlarmOnIcon />}
      tooltip={t(tokens.automator.resolutions.actions.case_awaiting_response)}
      onClick={() => onClick()}
      isLoading={isLoading}
      color="primary"
    />
  );
};

import { RESOLUTION_CASES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { ResolutionCaseQueryKey } from './ResolutionCaseQueryKey';
import ResolutionDossiers from '../../../domain/automator/resolutionDossiers/ResolutionDossiers';
import { OrderMessageThreadsQueryKey } from '../../queryKeys/OrderMessageThreadsQueryKey';
import { ResolutionDossiersQueryKey } from './ResolutionDossiersQueryKey';
import { ResolutionDossiersReportQueryKey } from './ResolutionDossiersReportQueryKey';

export const useHandleResolutionCase = (resolutionCaseId: number) => {
  const url = useAutomatorApiAccountUrl(RESOLUTION_CASES_PATH + '/' + resolutionCaseId + `/handle`);

  return useAuthorizedPost<ResolutionDossiers, undefined>(url, [
    OrderMessageThreadsQueryKey(undefined),
    ResolutionCaseQueryKey(resolutionCaseId),
    ResolutionDossiersQueryKey(undefined, undefined),
    ResolutionDossiersReportQueryKey(),
  ]);
};

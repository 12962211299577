import { MESSAGE_TEMPLATES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { ThreadPhase } from '../../../domain/automator/messages/ThreadPhase';
import { ReturnRecoverability } from '../../../domain/automator/returns/ReturnRecoverability';
import FileUpload from '../../../pages/automator/shared/FileUpload';
import { MessageTemplatesQueryKey } from '../../queryKeys/MessageTemplatesQueryKey';
import { MessageTemplateType } from '../../../domain/automator/messages/MessageTemplateType';
import MessageTemplate from '../../../domain/automator/messages/MessageTemplate';

export const useCreateMessageTemplate = () => {
  const url = useAutomatorApiAccountUrl(MESSAGE_TEMPLATES_PATH);

  return useAuthorizedPost<MessageTemplate, CreateMessageTemplateData>(
    url,
    [MessageTemplatesQueryKey()],
    undefined
  );
};

export interface CreateMessageTemplateData {
  name: string;
  type: MessageTemplateType;
  subject: string;
  body: string;
  phase: ThreadPhase;
  return_recoverability: ReturnRecoverability | null;
  files: FileUpload[];
}

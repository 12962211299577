export default interface MinMaxReport {
  min: number;
  max: number;
  type: MinMaxType;
}

export enum MinMaxType {
  MIN_BETTER = 'MIN_BETTER',
  MAX_BETTER = 'MAX_BETTER',
}

import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { TableBodyPlaceholder } from './TableBodyPlaceholder';

interface TableLoadingProps {
  message: string;
}

export const TableLoading = ({ message }: TableLoadingProps) => {
  return (
    <TableBodyPlaceholder>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="15px"
      >
        <CircularProgress />
        <Typography variant="body1">{message}</Typography>
      </Box>
    </TableBodyPlaceholder>
  );
};

import Badge from '@mui/material/Badge';

interface AmountBadgeProps {
  amount: number;
}

export const AmountBadge = ({ amount }: AmountBadgeProps) => {
  if (amount === 0) {
    return '';
  }

  return (
    <Badge
      badgeContent={'+' + amount}
      color="primary"
    ></Badge>
  );
};

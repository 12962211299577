import { DropdownOption, DropdownSelect } from '../../../../automator/shared/DropdownSelect';
import { tokens } from '../../../../../locales/translationTokens';
import Store from '../../../../../domain/pd/Store';

interface StoreDropdownSelectProps {
  store?: Store;
  stores?: Store[];
  onSelect: (store: Store) => void;
}

export const StoreDropdownSelect = ({ store, onSelect, stores }: StoreDropdownSelectProps) => {
  const options: DropdownOption<number, string>[] = [];

  if (stores) {
    stores.forEach((store) => {
      options.push({
        key: store.id,
        value: store.name,
      });
    });
  }

  return (
    <DropdownSelect
      options={options}
      onSelect={(key) => {
        store = stores?.find((store) => store.id === key) as Store;
        onSelect(store);
      }}
      selected={store?.id}
      label={tokens.pd.store_dropdown_select.label}
    />
  );
};

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { tokens } from '../../../../../../locales/translationTokens';
import { useEffect, useState } from 'react';
import {
  PerformanceHeaderTableCell,
  PerformanceHeaderTableCellColor,
} from '../PerformanceHeaderTableCell';
import { OrganicNonOrganicOptionsButton } from '../OrganicNonOrganicOptionsButton';
import { OfferPerformanceOrganicNonOrganicTableRow } from './OfferPerformanceOrganicNonOrganicTableRow';
import OfferPerformances from '../../../../../../domain/pd/OfferPerformances';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Stack from '@mui/material/Stack';

interface OfferPerformancesOrganicOrNonOrganicTableProps {
  offerPerformances: OfferPerformances;
  rollingAverageDays: number;
}

export enum OrganicNonOrganicOption {
  ALL = 'ALL',
  ORGANIC = 'ORGANIC',
  NONE_ORGANIC = 'NONE_ORGANIC',
}

export enum ArticlesOrderOption {
  ARTICLES = 'ARTICLES',
  ORDERS = 'ORDERS',
}

export const OfferPerformancesOrganicNonOrganicTable = ({
  offerPerformances,
  rollingAverageDays,
}: OfferPerformancesOrganicOrNonOrganicTableProps) => {
  const [organicNonOrganicOption, setOrganicNonOrganicOption] = useState<OrganicNonOrganicOption>(
    OrganicNonOrganicOption.ALL
  );
  const [articlesOrdersOption, setArticlesOrdersOption] = useState<ArticlesOrderOption>(
    ArticlesOrderOption.ARTICLES
  );

  const offerPerformancesSorted = offerPerformances.offerPerformances.sort((a, b) =>
    new Date(a.date).getTime() - new Date(b.date).getTime() > 0 ? -1 : 1
  );

  const backgroundColor = PerformanceHeaderTableCellColor.LIGHTYELLOW;

  const offerPerformancesMinMaxReport = offerPerformances.minMaxReports;

  useEffect(() => {
    if (articlesOrdersOption == ArticlesOrderOption.ORDERS) {
      setOrganicNonOrganicOption(OrganicNonOrganicOption.ALL);
    }
  }, [articlesOrdersOption]);

  return (
    <Table
      stickyHeader
      size="small"
    >
      <TableHead>
        <TableRow>
          <PerformanceHeaderTableCell
            value={tokens.pd.performance.organic_non_organic_table.organic_vs_non_organic}
            backgroundColor={backgroundColor}
          />
          <PerformanceHeaderTableCell
            colSpan={5}
            backgroundColor={backgroundColor}
          >
            <Stack
              direction="row"
              spacing={1}
            >
              {articlesOrdersOption === ArticlesOrderOption.ARTICLES && (
                <OrganicNonOrganicOptionsButton
                  option={organicNonOrganicOption}
                  setOption={setOrganicNonOrganicOption}
                />
              )}

              <SwapHorizIcon
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  setArticlesOrdersOption(
                    articlesOrdersOption == ArticlesOrderOption.ORDERS
                      ? ArticlesOrderOption.ARTICLES
                      : ArticlesOrderOption.ORDERS
                  )
                }
              />
            </Stack>
          </PerformanceHeaderTableCell>
        </TableRow>
      </TableHead>

      <TableHead>
        <TableRow>
          <PerformanceHeaderTableCell
            value={tokens.pd.performance.organic_non_organic_table.visits}
            backgroundColor={backgroundColor}
            rollingAverageDays={rollingAverageDays}
          />

          {articlesOrdersOption === ArticlesOrderOption.ARTICLES ? (
            <>
              <PerformanceHeaderTableCell
                value={tokens.pd.performance.organic_non_organic_table.articles}
                backgroundColor={backgroundColor}
                rollingAverageDays={rollingAverageDays}
              />

              <PerformanceHeaderTableCell
                value={tokens.pd.performance.organic_non_organic_table.conversion_rate}
                backgroundColor={backgroundColor}
                rollingAverageDays={rollingAverageDays}
              />
            </>
          ) : (
            <>
              <PerformanceHeaderTableCell
                value={tokens.pd.performance.organic_non_organic_table.orders}
                backgroundColor={backgroundColor}
                rollingAverageDays={rollingAverageDays}
              />

              <PerformanceHeaderTableCell
                value={tokens.pd.performance.organic_non_organic_table.orders_conversion_rate}
                backgroundColor={backgroundColor}
                rollingAverageDays={rollingAverageDays}
              />
            </>
          )}
        </TableRow>
      </TableHead>

      <TableBody>
        {offerPerformancesSorted.map((offerPerformance, index) => {
          return (
            <OfferPerformanceOrganicNonOrganicTableRow
              key={index}
              offerPerformance={offerPerformance}
              articlesOrdersOption={articlesOrdersOption}
              offerPerformancesMinMaxReport={offerPerformancesMinMaxReport}
              organicNonOrganicOption={organicNonOrganicOption}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

import { CANCELLATION_BLOCKS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import CancellationBlock from '../../../domain/automator/cancellationBlocks/CancellationBlock';
import { CancellationBlocksQueryKey } from '../../queryKeys/CancellationBlocksQueryKey';

export const useFetchCancellationBlocks = () => {
  const url = useAutomatorApiAccountUrl(CANCELLATION_BLOCKS_PATH);
  return useFetchData<CancellationBlock[]>(url, CancellationBlocksQueryKey());
};

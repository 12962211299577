import { RecipientType } from '../../../../../domain/automator/messages/RecipientType';

const determineDefaultEmailMessageSubject = (newRecipientType: RecipientType) => {
  switch (newRecipientType) {
    case RecipientType.TRANSPORTER:
      return '[[SHIPMENT_TRACKING_CODE]] ';
    case RecipientType.BOL:
      return '[[BOL_ORDER_ID]] ';
    default:
      return '';
  }
};

export default determineDefaultEmailMessageSubject;

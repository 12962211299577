import SvgIcon from '@mui/material/SvgIcon';
import { Store } from '@mui/icons-material';

export const PickupPointIcon = () => {
  return (
    <SvgIcon fontSize="small">
      <Store />
    </SvgIcon>
  );
};

import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';
import { tokens } from '../../../../locales/translationTokens';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';

interface DoublePlaceOrderCancelledChipProps {
  bolOrderId: string;
}

const DoublePlaceOrderCancelledChip = ({ bolOrderId }: DoublePlaceOrderCancelledChipProps) => {
  const { t } = useTranslation();

  const openDialog = useOpenDialog(DialogId.ORDER_SUMMARY);

  return (
    <Chip
      style={{ borderRadius: 4 }}
      onClick={() => openDialog({ query: bolOrderId })}
      size="small"
      label={t(tokens.automator.orders.double_placed_order_cancelled)}
      variant="outlined"
      color={'warning'}
    />
  );
};

export default DoublePlaceOrderCancelledChip;

import { DialogId } from '../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../ApplicationDialog';
import { tokens } from '../../locales/translationTokens';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { usePaths } from '../../routes/usePaths';
import { useRouter } from '../../../devias/src/hooks/use-router';
import Box from '@mui/material/Box';
import { useCloseDialog } from '../../store/dialogs/useCloseDialog';
import { useQueryClient } from 'react-query';
import { OrdersQueryKey } from '../../api/queryKeys/OrdersQueryKey';
import { OrderStatus } from '../../domain/automator/orders/OrderStatus';
import { DistributionParty } from '../../domain/automator/orders/DistributionParty';

export const FirstRetailerCreatedTutorialDialog = () => {
  const id = DialogId.FIRST_RETAILER_CREATED;
  const { isOpen } = useIsDialogOpen(id);
  const closeDialog = useCloseDialog(id);
  const { t } = useTranslation();

  const paths = usePaths();
  const router = useRouter();

  const queryClient = useQueryClient();

  return (
    <>
      {isOpen && (
        <ApplicationDialog
          dialogId={id}
          title={t(tokens.automator.retailers.tutorials.with_retailer_dialog_title)}
        >
          <Stack spacing={3}>
            <Box>{t(tokens.automator.retailers.tutorials.with_retailer_dialog_message)}</Box>
            <Button
              sx={{ width: '300px' }}
              color="success"
              size="small"
              variant="contained"
              onClick={async () => {
                closeDialog();
                await queryClient.invalidateQueries(
                  OrdersQueryKey(OrderStatus.OPEN, DistributionParty.BOL)
                );
                await queryClient.invalidateQueries(
                  OrdersQueryKey(OrderStatus.OPEN, DistributionParty.RETAILER)
                );
                router.push(paths.automator.vvb_orders);
              }}
            >
              {t(tokens.automator.retailers.tutorials.with_retailer_dialog_button)}
            </Button>
          </Stack>
        </ApplicationDialog>
      )}
    </>
  );
};

import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { ASSORTMENT_IMPRESSIONS } from '../pdApiPaths';
import AssortmentImpressions from '../../../domain/pd/AssortmentImpressions';
import { AssortmentImpressionsQueryKey } from '../../queryKeys/AssortmentImpressionsQueryKey';
import { getDateStringFromDate } from '../../../helpers/getDateStringFromDate';

export const useFetchAssortmentImpressions = (storeId: number, startDate: Date, endDate: Date) => {
  const url = usePdApiAccountUrl(
    ASSORTMENT_IMPRESSIONS +
      `?storeId=${storeId}&startDate=${getDateStringFromDate(
        startDate
      )}&endDate=${getDateStringFromDate(endDate)}`
  );
  return useFetchData<AssortmentImpressions>(
    url,
    AssortmentImpressionsQueryKey(storeId, startDate, endDate)
  );
};

import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../../../locales/translationTokens';
import ActionButton from '../../../../../../components/ActionButton';
import { useCreateManualSeasonalEvent } from '../../../../../../api/pd/timeline/useCreateManualSeasonalEvent';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';

interface CreateManualSeasonalEventFormProps {
  onClick?: () => void;
}

export const CreateManualSeasonalEventForm = ({ onClick }: CreateManualSeasonalEventFormProps) => {
  const [name, setName] = useState('');
  const [date, setDate] = useState<Date>(new Date());

  const { t } = useTranslation();

  const { mutate: create, isLoading } = useCreateManualSeasonalEvent();

  const onCreate = () => {
    create(
      {
        name: name,
        start_date: date,
        end_date: undefined,
      },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(
            t(tokens.pd.performance.create_manual_seasonal_event_form.create_manual_seasonal_event)
          );
        },
      }
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <Stack
        direction="column"
        spacing={2}
        style={{ marginBottom: '1em' }}
      >
        <TextField
          id="note-field"
          label={t(tokens.pd.performance.create_manual_seasonal_event_form.name)}
          variant="filled"
          fullWidth
          style={{ marginBottom: '1em' }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <DatePicker
          label={t(tokens.pd.performance.create_manual_seasonal_event_form.date)}
          value={date}
          onChange={(newValue) => {
            if (newValue) {
              setDate(newValue);
            }
          }}
        />

        <ActionButton
          label={t(
            tokens.pd.performance.create_manual_seasonal_event_form.create_manual_seasonal_event
          )}
          onClick={() => onCreate()}
          isLoading={isLoading}
          variant="contained"
          color="primary"
          size="small"
        />
      </Stack>
    </form>
  );
};

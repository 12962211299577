import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { HandlingResult } from '../../../../domain/automator/returns/HandlingResult';
import { DropdownSelect } from '../../shared/DropdownSelect';

interface HandlingResultDropdownProps {
  value: HandlingResult;
  onSelect: (handlingResult: HandlingResult) => void;
}

export const HandlingResultDropdown = ({ value, onSelect }: HandlingResultDropdownProps) => {
  const { t } = useTranslation();

  const options = [
    {
      key: HandlingResult.RETURN_RECEIVED,
      value: t(tokens.automator.returns.handling_result.return_received),
    },
    {
      key: HandlingResult.EXCHANGE_PRODUCT,
      value: t(tokens.automator.returns.handling_result.exchange_product),
    },
    {
      key: HandlingResult.RETURN_DOES_NOT_MEET_CONDITIONS,
      value: t(tokens.automator.returns.handling_result.return_does_not_meet_conditions),
    },
    {
      key: HandlingResult.REPAIR_PRODUCT,
      value: t(tokens.automator.returns.handling_result.repair_product),
    },
    {
      key: HandlingResult.CUSTOMER_KEEPS_PRODUCT_PAID,
      value: t(tokens.automator.returns.handling_result.customer_keeps_product_paid),
    },
  ];

  return (
    <DropdownSelect
      selected={value}
      options={options}
      onSelect={onSelect}
      label={tokens.automator.returns.dialogs.create_return.handling_result}
    />
  );
};

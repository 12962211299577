import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import BillingDetails from '../../../../domain/automator/orders/BillingDetails';
import { truncateString } from '../../../../helpers/truncateString';
import { FlagIcon } from '../../orders/components/FlagIcon';
import { CountryCode } from '../../../../domain/automator/orders/CountryCode';

interface CustomerInvoiceRecipientProps {
  billingDetails: BillingDetails | null;
}

export const CustomerInvoiceRecipient = ({ billingDetails }: CustomerInvoiceRecipientProps) => {
  return (
    <Stack
      direction={'row'}
      gap={1}
      alignItems={'center'}
    >
      <Typography
        variant="subtitle1"
        display={'flex'}
        gap={0.2}
        noWrap
      >
        {truncateString(
          billingDetails?.company || billingDetails?.firstName + ' ' + billingDetails?.surname,
          25
        )}
      </Typography>

      {billingDetails?.countryCode == CountryCode.BE && (
        <FlagIcon
          countryCode={CountryCode.BE}
          size={16}
        />
      )}

      {/*{billingDetails.kvkNumber && (*/}
      {/*  <Typography*/}
      {/*    variant="subtitle1"*/}
      {/*    display={'flex'}*/}
      {/*    gap={0.2}*/}
      {/*    noWrap*/}
      {/*  >*/}
      {/*    {billingDetails.kvkNumber}*/}
      {/*  </Typography>*/}
      {/*)}*/}

      {/*{billingDetails.vatNumber && (*/}
      {/*  <Typography*/}
      {/*    variant="subtitle1"*/}
      {/*    display={'flex'}*/}
      {/*    gap={0.2}*/}
      {/*    noWrap*/}
      {/*  >*/}
      {/*    {billingDetails.vatNumber}*/}
      {/*  </Typography>*/}
      {/*)}*/}
    </Stack>
  );
};

import { MESSAGE_TEMPLATES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { MessageTemplatesQueryKey } from '../../queryKeys/MessageTemplatesQueryKey';

export const useArchiveMessageTemplate = (messageTemplateId: number) => {
  const url = useAutomatorApiAccountUrl(
    MESSAGE_TEMPLATES_PATH + '/' + messageTemplateId + '/archive'
  );

  return useAuthorizedPost<undefined, undefined>(url, [MessageTemplatesQueryKey()], undefined);
};

import * as React from 'react';
import { HubOutlined, Toll, Widgets } from '@mui/icons-material';
import { tokens } from '../../../../locales/translationTokens';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import SpeedDialMenuItem from './SpeeddialMenuItem';
import CommentOutlined from '@mui/icons-material/CommentOutlined';
import { useFetchOrder } from '../../../../api/automator/orders/useFetchOrder';
import List from '@mui/material/List';
import { RecipientType } from '../../../../domain/automator/messages/RecipientType';
import Mail01Icon from '@untitled-ui/icons-react/build/esm/Mail01';
import { useFetchOrderShipment } from '../../../../api/automator/orders/useFetchOrderShipment';
import ReplayIcon from '@mui/icons-material/Replay';
import { ThreadPhase } from '../../../../domain/automator/messages/ThreadPhase';
import { useFetchOrderReturns } from '../../../../api/automator/orders/useFetchOrderReturns';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useCancelOrderItem } from '../../../../api/automator/orders/useCancelOrderItem';
import { CancellationReason } from '../../../../domain/automator/orders/CancellationReason';
import { toast } from 'react-hot-toast';
import { OrderItemStatus } from '../../../../domain/automator/orders/OrderItemStatus';
import { usePinOrder } from '../../../../api/automator/orders/usePinOrder';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import { MessageTemplateType } from '../../../../domain/automator/messages/MessageTemplateType';

interface SpeedDialMenuProps {
  orderId: number;
  orderItemId?: number;
  onUpdateNote: (string: string) => void;
}

export default function SpeedDialMenu({ orderId, orderItemId, onUpdateNote }: SpeedDialMenuProps) {
  const openViewOrderSummaryDialog = useOpenDialog(DialogId.ORDER_SUMMARY);
  const openUpdateOrderNoteDialog = useOpenDialog(DialogId.UPDATE_ORDER_NOTE_DIALOG);
  const openUpdateOffersDialog = useOpenDialog(DialogId.UPDATE_OFFERS);
  const openCreateEmailThreadDialog = useOpenDialog(DialogId.CREATE_EMAIL_THREAD);
  const openCreateResendDialog = useOpenDialog(DialogId.CREATE_RESEND);
  const openCreateReturnDialog = useOpenDialog(DialogId.CREATE_RETURN);
  const openCancelOrderItemDialog = useOpenDialog(DialogId.CANCEL_ORDER_ITEM);

  const { data: order, isLoading: isOrderLoading } = useFetchOrder(orderId);

  const { data: shipment, isLoading: isShipmentLoading } = useFetchOrderShipment(orderId);

  const { data: returns, isLoading: isReturnsLoading } = useFetchOrderReturns(orderId);

  const { mutate: cancelOrderItem, isLoading: isLoadingCancelOrderItem } = useCancelOrderItem(
    orderItemId || 0
  );

  const { mutate: pinOrder, isLoading: isLoadingPinOrder } = usePinOrder(orderId);

  const onCancel = () => {
    cancelOrderItem(
      { cancellation_reason: CancellationReason.REQUESTED_BY_CUSTOMER },
      {
        onSuccess: async () => {
          toast.success(tokens.automator.orders.order_item_cancelled);
        },
      }
    );
  };

  const onPin = () => {
    pinOrder(undefined, {
      onSuccess: async () => {
        toast.success(tokens.automator.pinned_orders.pinned);
      },
    });
  };

  if (isOrderLoading || isShipmentLoading || isReturnsLoading) {
    return <></>;
  }

  const hasUnhandledReturns = returns!.some(
    (return_) =>
      //NOTE: rmaId is null if the return is manually created and the rmaId has not been set yet due to bol async processing.
      !return_.returnItems.some((returnItem) => !returnItem.isHandled && returnItem.rmaId != null)
  );

  const orderItem = order!.items.find((item) => item.id === orderItemId);

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <SpeedDialMenuItem
        icon={<HubOutlined />}
        label={tokens.automator.order_summary.order_summary}
        onClick={() => openViewOrderSummaryDialog({ query: order!.bolOrderId })}
      />

      {orderItem && orderItem.status == OrderItemStatus.OPEN && (
        <SpeedDialMenuItem
          icon={<CancelOutlinedIcon />}
          label={tokens.automator.orders.cancel}
          onClick={() => {
            orderItem.cancellationRequest
              ? onCancel()
              : openCancelOrderItemDialog({ orderItem: orderItem });
          }}
          isLoading={isLoadingCancelOrderItem}
        />
      )}

      {shipment && (
        <SpeedDialMenuItem
          icon={<ReplayIcon />}
          label={tokens.automator.resolutions.dialogs.create_resend.create_resend}
          onClick={() => openCreateResendDialog({ orderId })}
        />
      )}

      {orderItemId && (
        <SpeedDialMenuItem
          icon={<PriceChangeIcon />}
          label={tokens.automator.orders.dialogs.update_offer.update_offers}
          onClick={() =>
            openUpdateOffersDialog({
              productId: order!.items.find((item) => item.id === orderItemId)!.offer.product.id,
            })
          }
        />
      )}

      {shipment && !hasUnhandledReturns && (
        <SpeedDialMenuItem
          icon={<Widgets />}
          label={tokens.automator.returns.dialogs.create_return.create_return}
          onClick={() => openCreateReturnDialog({ order: order! })}
        />
      )}

      <SpeedDialMenuItem
        icon={<Mail01Icon />}
        label={tokens.automator.resolutions.dialogs.send_email.dialog_title}
        onClick={() =>
          openCreateEmailThreadDialog({
            orderId: orderId,
            phase: order!.currentPhase,
            aggregateId: null,
            body: null,
            messageTemplateId: null,
            recipientType: RecipientType.CUSTOMER,
            messageTemplateType: MessageTemplateType.MANUAL,
          })
        }
      />

      <SpeedDialMenuItem
        icon={<Toll />}
        label={tokens.automator.reimbursements.create_reimbursement_request}
        onClick={() =>
          openCreateEmailThreadDialog({
            orderId: orderId,
            phase: ThreadPhase.SHIPMENT,
            aggregateId: null,
            body: null,
            messageTemplateId: null,
            recipientType: RecipientType.BOL,
            messageTemplateType: MessageTemplateType.REIMBURSEMENT_REQUEST,
          })
        }
      />

      <SpeedDialMenuItem
        icon={<CommentOutlined />}
        label={tokens.automator.orders.dialogs.update_order_note.note}
        onClick={() => openUpdateOrderNoteDialog({ orderId, onUpdate: onUpdateNote })}
      />

      {order!.pinnedDateTime == null && (
        <SpeedDialMenuItem
          icon={<FmdBadIcon />}
          label={tokens.automator.pinned_orders.pin}
          onClick={onPin}
          isLoading={isLoadingPinOrder}
        />
      )}
    </List>
  );
}

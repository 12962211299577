import { SHIPMENTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { useUpdateData } from '../../useUpdateData';
import Shipments, { AGGREGATE_KEY } from '../../../domain/automator/shipments/Shipments';
import { useShipmentQueryKeysUpdater } from './useShipmentsQueryKeysUpdater';

export const useAddShipmentTrackAndTrace = (shipmentId: number) => {
  const url = useAutomatorApiAccountUrl(SHIPMENTS_PATH + '/' + shipmentId + `/add-track-and-trace`);

  const updater = useShipmentQueryKeysUpdater();
  const updateData = useUpdateData(updater, AGGREGATE_KEY);
  const onSuccess = (data: Shipments) => {
    updateData(data);
  };

  return useAuthorizedPost<Shipments, AddShipmentTrackAndTraceData>(url, [], undefined, onSuccess);
};

export interface AddShipmentTrackAndTraceData {
  track_and_trace: string;
}

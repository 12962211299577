import Chip from '@mui/material/Chip';
import { TriggerType } from '../../../../domain/automator/messages/TriggerType';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import Badge from '@mui/material/Badge';

interface TriggerTypeChipProps {
  triggerType: TriggerType;
  minutesAfterMessageRequiringReply: number | null;
}

const TriggerTypeChip = ({
  triggerType,
  minutesAfterMessageRequiringReply,
}: TriggerTypeChipProps) => {
  const { t } = useTranslation();

  const triggerTypeTokens = {
    [TriggerType.MANUAL]: tokens.automator.resolutions.trigger_type.manual,
    [TriggerType.ALERT]: tokens.automator.resolutions.trigger_type.manual,
    [TriggerType.AUTO_MAIL]: tokens.automator.resolutions.trigger_type.auto_mail,
    [TriggerType.CUSTOMER_QUESTION]: tokens.automator.resolutions.trigger_type.customer_question,
  };

  const convertMinutesToTime = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (hours >= 24) {
      return `24:00+`;
    } else {
      return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;
    }
  };

  //   <Box minWidth={150}>
  //     {reducedResolutionCase.triggerType == TriggerType.CUSTOMER_QUESTION && (
  //       {reducedResolutionCase.minutesAfterMessageRequiringReply && (
  //           <Chip
  //             size="small"
  //             label={}
  //             color="primary"
  //             style={{
  //               borderRadius: 4,
  //               backgroundColor: Colors.PRIMARY_BOL_BLUE,
  //               color: 'WHITE',
  //             }}
  //           />
  //         )}
  //       </Stack>
  //       )}
  // </Box>

  const determineColor = (triggerType: TriggerType) => {
    switch (triggerType) {
      case TriggerType.MANUAL:
        return 'info';
      case TriggerType.ALERT:
        return 'secondary';
      case TriggerType.AUTO_MAIL:
        return 'info';
      case TriggerType.CUSTOMER_QUESTION:
        return 'primary';
    }
  };

  return (
    <Badge
      color="primary"
      style={{ borderRadius: 4 }}
      badgeContent={
        minutesAfterMessageRequiringReply
          ? convertMinutesToTime(minutesAfterMessageRequiringReply)
          : null
      }
    >
      <Chip
        style={{ borderRadius: 4 }}
        size="small"
        label={t(triggerTypeTokens[triggerType])}
        color={determineColor(triggerType)}
        variant="outlined"
      />
    </Badge>
  );
};

export default TriggerTypeChip;

import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { ReturnListTable } from './components/ReturnListTable';
import ReturnItemTabs from './components/ReturnItemTabs';
import { useState } from 'react';
import { HandledReturnListTable } from './components/HandledReturnListTable';
import { ReturnListOverviewTable } from './components/ReturnListOverviewTable';
import { useFetchReturns } from '../../../api/automator/returns/useFetchReturns';
import { ReturnItemFilter } from '../../../domain/automator/returns/ReturnItemFilter';

const ReturnsPage = () => {
  const [currentTab, setCurrentTab] = useState(ReturnItemFilter.OVERVIEW);

  const { data: returns, isLoading } = useFetchReturns(currentTab);

  const determineTable = () => {
    switch (currentTab) {
      case ReturnItemFilter.HANDLED:
        return (
          <HandledReturnListTable
            returns={returns && returns.returns}
            isLoading={isLoading}
          />
        );
      case ReturnItemFilter.OVERVIEW:
        return (
          <ReturnListOverviewTable
            currentTab={currentTab}
            isLoading={isLoading}
            returns={returns && returns.returns}
          />
        );
      default:
        return (
          <ReturnListTable
            currentTab={currentTab}
            returns={returns && returns.returns}
            isLoading={isLoading}
          />
        );
    }
  };

  return (
    <AccountPage title={tokens.automator.returns.returns}>
      <ReturnItemTabs
        report={returns && returns.report}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />

      {determineTable()}
    </AccountPage>
  );
};

export default ReturnsPage;

import { LatestDays } from '../pages/pd/pages/performances/components/LatestDaysDropdownSelect';

const getLatestDateByLatestDays = (latestDays: LatestDays) => {
  const subtractDays = (days: number) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date;
  };

  switch (latestDays) {
    case LatestDays.LATEST_14_DAYS:
      return subtractDays(14);
    case LatestDays.LATEST_30_DAYS:
      return subtractDays(30);
    case LatestDays.LATEST_60_DAYS:
      return subtractDays(60);
    case LatestDays.LATEST_90_DAYS:
      return subtractDays(90);
    case LatestDays.LATEST_150_DAYS:
      return subtractDays(150);
  }
};

export default getLatestDateByLatestDays;

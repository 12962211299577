import { PRODUCTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import AutomatorProducts from '../../../domain/automator/products/AutomatorProducts';
import { AutomatorProductsQueryKey } from '../../queryKeys/AutomatorProductsQueryKey';

export const useBulkUpdateProductVatPercentage = () => {
  const url = useAutomatorApiAccountUrl(`${PRODUCTS_PATH}/update-vat-percentages`);

  return useAuthorizedPost<AutomatorProducts, BulkUpdateProductVatPercentageData>(url, [
    AutomatorProductsQueryKey(),
  ]);
};

export interface BulkUpdateProductVatPercentageData {
  updates: {
    product_ids: number[];
    vat_percentage: number;
  }[];
}

import { useAuthorizedPost } from '../../useAuthorizedPost';
import { CONTESTED_CATEGORIES } from '../pdApiPaths';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';

export const useActivateContestedCategory = () => {
  const url = usePdApiAccountUrl(CONTESTED_CATEGORIES + `/activate`);
  return useAuthorizedPost<undefined, ActivateContestedCategoryData>(url, undefined, undefined);
};

export interface ActivateContestedCategoryData {
  product_id: number;
  category_id: number;
}

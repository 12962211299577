import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { ChartProperty } from './PerformancesDateLineChart';

interface DataAttributeButtonsProps {
  availableProperties: ChartProperty[];
  selectedProperties: ChartProperty[];
  onSelect: (selectedAttributes: ChartProperty) => void;
}

export const ChartPropertyButtons = ({
  availableProperties,
  selectedProperties,
  onSelect,
}: DataAttributeButtonsProps) => {
  const { t } = useTranslation();

  return (
    <Stack direction={'column'}>
      {availableProperties.map((chartProperty) => {
        const isSelected = selectedProperties.find(
          (selectedAttribute) => selectedAttribute.property === chartProperty.property
        );
        return (
          <Button
            key={chartProperty.property}
            variant={isSelected ? 'contained' : 'outlined'}
            onClick={() => onSelect(chartProperty)}
          >
            {t(chartProperty.label)}
          </Button>
        );
      })}
    </Stack>
  );
};

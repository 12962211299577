import { useAuthorizedAxios } from './useAuthorizedAxios';
import { usePost } from './usePost';
import { QueryKey } from 'react-query';

export const useAuthorizedPost = <U, T = void>(
  url: string,
  queryKeys?: QueryKey[],
  getData?: () => T,
  onSuccess?: (data: U) => void,
  updateQuery?: (oldData: U, newData: U) => U
) => {
  const axiosInstance = useAuthorizedAxios();
  return usePost<U, T>(axiosInstance, url, queryKeys, getData, onSuccess, updateQuery);
};

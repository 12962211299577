import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CustomerInvoiceRequest from '../../../../domain/automator/customerInvoiceRequests/CustomerInvoiceRequest';
import Stack from '@mui/material/Stack';
import { getDateOnly } from '../../../../helpers/getDateOnly';
import { UploadCustomerInvoiceButton } from './UploadCustomerInvoiceButton';
import ActionButton from '../../../../components/ActionButton';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { CustomerInvoiceRequestsListTable } from './CustomerInvoiceRequestDetails';
import { CustomerInvoiceRequestStatus } from '../../../../domain/automator/customerInvoiceRequests/CustomerInvoiceRequestStatus';
import { CustomerInvoiceView } from './CustomerInvoiceView';
import { useRequestCustomerInvoiceInformation } from '../../../../api/automator/customerInvoiceRequests/useRequestCustomerInvoiceInformation';
import { toast } from 'react-hot-toast';
import { useMailCustomerInvoice } from '../../../../api/automator/customerInvoiceRequests/useMailCustomerInvoice';
import Typography from '@mui/material/Typography';
import { DownloadCustomerInvoicePdfButton } from './DownloadCustomerInvoicePdfButton';

interface CustomerInvoiceRequestsTableRowProps {
  customerInvoiceRequest: CustomerInvoiceRequest;
}

export const CustomerInvoiceRequestsTableRow = ({
  customerInvoiceRequest,
}: CustomerInvoiceRequestsTableRowProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false); // Add this

  const { mutate: requestCustomerInvoiceInformation, isLoading } =
    useRequestCustomerInvoiceInformation(customerInvoiceRequest.customerInvoice?.id || 0);

  const { mutate: mailCustomerInvoice, isLoading: isLoadingMailCustomerInvoice } =
    useMailCustomerInvoice(customerInvoiceRequest.customerInvoice?.id || 0);

  const onRequestInformation = () => {
    requestCustomerInvoiceInformation(
      {
        subject: 'Informatie nodig om factuur te maken',
        body: '',
      },
      {
        onSuccess: () => {
          toast.success(t(tokens.automator.customer_invoice_requests.information_requested));
        },
      }
    );
  };

  const onMail = () => {
    mailCustomerInvoice(undefined, {
      onSuccess: () => {
        toast.success(t(tokens.automator.customer_invoice_requests.invoice_mailed));
      },
    });
  };

  const onOpen = () => {
    if (!customerInvoiceRequest.customerInvoice) {
      return;
    }
    setOpen(!open);
  };

  return (
    <>
      <TableRow
        style={{
          cursor: customerInvoiceRequest.customerInvoice ? 'pointer' : undefined,
        }}
      >
        <TableCell onClick={onOpen}>
          <Stack
            direction="row"
            gap={2}
            alignItems="space-between"
          >
            <CustomerInvoiceView
              retailerId={customerInvoiceRequest.retailerId}
              bolOrderId={customerInvoiceRequest.bolOrderId}
              billingDetails={
                customerInvoiceRequest.customerInvoice
                  ? customerInvoiceRequest.customerInvoice.billingDetails
                  : customerInvoiceRequest.billingDetails
              }
              customerInvoiceLines={
                customerInvoiceRequest.customerInvoice
                  ? customerInvoiceRequest.customerInvoice.customerInvoiceLines
                  : []
              }
            />

            {!customerInvoiceRequest.customerInvoice && (
              <Typography
                variant="body2"
                color="textSecondary"
              >
                {t(tokens.automator.customer_invoice_requests.customer_invoice_being_created)}
              </Typography>
            )}
          </Stack>
        </TableCell>

        <TableCell onClick={onOpen}>
          {customerInvoiceRequest.orderPlacedDate
            ? getDateOnly(customerInvoiceRequest.orderPlacedDate)
            : '-'}
        </TableCell>
        <TableCell onClick={onOpen}>{customerInvoiceRequest.status}</TableCell>
        <TableCell onClick={onOpen}>
          {customerInvoiceRequest.customerInvoice?.customerInvoiceInformationRequest != null &&
            (customerInvoiceRequest.customerInvoice?.customerInvoiceInformationRequest.isUsed ? (
              <Typography fontWeight={'bold'}>
                {t(tokens.automator.customer_invoice_requests.billing_details_updated)}
              </Typography>
            ) : (
              <Typography fontWeight={'bold'}>
                {t(tokens.automator.customer_invoice_requests.information_requested)}
              </Typography>
            ))}
        </TableCell>
        <TableCell align="right">
          <Stack
            direction="row"
            gap={2}
            justifyContent="flex-end"
          >
            {customerInvoiceRequest.customerInvoice != null &&
              customerInvoiceRequest.billingDetails.email != null &&
              customerInvoiceRequest.customerInvoice?.customerInvoiceInformationRequest == null && (
                <ActionButton
                  label={t(tokens.automator.customer_invoice_requests.request_information)}
                  variant="outlined"
                  size="small"
                  onClick={onRequestInformation}
                  isLoading={isLoading}
                />
              )}

            {customerInvoiceRequest.customerInvoice != null &&
              customerInvoiceRequest.billingDetails.email != null && (
                <ActionButton
                  label={t(tokens.automator.customer_invoice_requests.mail_invoice)}
                  variant="outlined"
                  size="small"
                  onClick={onMail}
                  isLoading={isLoadingMailCustomerInvoice}
                />
              )}

            {customerInvoiceRequest.customerInvoice?.downloadUrl &&
              customerInvoiceRequest.status == CustomerInvoiceRequestStatus.INVOICE_REQUESTED && (
                <UploadCustomerInvoiceButton
                  customerInvoiceId={customerInvoiceRequest.customerInvoice.id}
                />
              )}

            {customerInvoiceRequest.customerInvoice && (
              <DownloadCustomerInvoicePdfButton
                customerInvoice={customerInvoiceRequest.customerInvoice}
              />
            )}
          </Stack>
        </TableCell>

        <TableCell
          align="right"
          onClick={onOpen}
        >
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <CustomerInvoiceRequestsListTable customerInvoiceRequest={customerInvoiceRequest} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

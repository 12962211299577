import { RETURN_ITEMS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { useUpdateData } from '../../useUpdateData';
import { useReturnsQueryKeysUpdater } from './useReturnsQueryKeysUpdater';
import Returns, { AGGREGATE_KEY } from '../../../domain/automator/returns/Returns';
import { ReturnItemQueryKey } from '../../queryKeys/ReturnItemQueryKey';
import { useQueryClient } from 'react-query';
import { ReturnScansQueryKey } from '../../queryKeys/ReturnScansQueryKey';

export const useReturnItemSetToNew = (returnItemId: number) => {
  const url = useAutomatorApiAccountUrl(RETURN_ITEMS_PATH + '/' + returnItemId + `/new`);

  const updater = useReturnsQueryKeysUpdater();
  const updateData = useUpdateData(updater, AGGREGATE_KEY);

  const queryClient = useQueryClient();

  const onSuccess = (returns: Returns) => {
    updateData(returns);
    queryClient
      .invalidateQueries('order_summary', {
        refetchActive: true,
        refetchInactive: true,
        exact: false,
      })
      .then((_) => {});
  };

  return useAuthorizedPost<Returns>(
    url,
    [ReturnItemQueryKey(returnItemId), ReturnScansQueryKey()],
    undefined,
    onSuccess
  );
};

import { tokens } from '../../../../locales/translationTokens';
import AccountPage from '../../../../components/AccountPage';
import { useEffect, useState } from 'react';
import Store from '../../../../domain/pd/Store';
import PerformancesTypeTabs, { PerformanceType } from './components/PerformancesTypeTabs';
import { StoreDropdownSelect } from './components/StoreDropdownSelect';
import { OfferPerformancesTab } from './components/OfferPerformancesTab';
import { ProductPerformancesTab } from './components/ProductPerformancesTab';
import { LatestDays, LatestDaysDropdownSelect } from './components/LatestDaysDropdownSelect';
import CountryCodeSelect from './components/CountrySelect';
import { CountryCode } from '../../../../domain/automator/orders/CountryCode';
import { AdGroupPerformancesTab } from './components/AdGroupPerformancesTab';
import RollingAverageDaySelect from './components/RollingAverageDaySelect';
import { useIdFromUrl } from '../../../../api/useIdFromUrl';
import Box from '@mui/material/Box';
import Offer from '../../../../domain/pd/Offer';
import { useFetchPerformanceProduct } from '../../../../api/pd/products/useFetchPerformanceProduct';
import { useTranslation } from 'react-i18next';

const PerformancesPage = () => {
  const productId = useIdFromUrl('products');

  const { t } = useTranslation();

  const { data: product, isLoading: isLoadingProduct } = useFetchPerformanceProduct(
    productId as unknown as number
  );

  const [store, setStore] = useState<Store | undefined>(undefined);
  const [currentTab, setCurrentTab] = useState<PerformanceType>(PerformanceType.OFFER_PERFORMANCE);

  const [countryCode, setCountryCode] = useState<CountryCode | null>(null);

  const [rollingAverageDays, setRollingAverageDays] = useState<number>(7);

  const [latestDays, setLatestDays] = useState<LatestDays>(LatestDays.LATEST_14_DAYS);

  const onSelectCountry = (selectedCountry: CountryCode) => {
    if (countryCode != null) {
      setCountryCode(null);
    } else {
      setCountryCode(selectedCountry);
    }
  };

  useEffect(() => {
    if (product && !store) {
      setStore(product.offers[0].store);
    }
  }, [product]);

  if (isLoadingProduct) {
    return <Box>Loading...</Box>;
  }

  const titleExtra = (
    <CountryCodeSelect
      onSelect={onSelectCountry}
      countryCode={countryCode}
    />
  );

  const offer = product!.offers.find((offer) => offer.store.id == store?.id)!;

  return (
    <AccountPage
      titleExtra={titleExtra}
      title={t(tokens.pd.performance.performances) + ' ' + product?.offers[0].title}
    >
      {product && product.offers.length > 1 && (
        <StoreDropdownSelect
          stores={product.offers.map((offer: Offer) => offer.store)}
          onSelect={setStore}
          store={store}
        />
      )}

      <LatestDaysDropdownSelect
        onSelect={setLatestDays}
        selected={latestDays}
      />
      <RollingAverageDaySelect
        onSelect={setRollingAverageDays}
        rollingAverageDays={rollingAverageDays}
      />

      <PerformancesTypeTabs
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
      {currentTab === PerformanceType.OFFER_PERFORMANCE && store && (
        <OfferPerformancesTab
          offer={offer}
          latestDays={latestDays}
          countryCode={countryCode}
          rollingAverageDays={rollingAverageDays}
        />
      )}
      {currentTab === PerformanceType.PRODUCT_PERFORMANCE && store && (
        <ProductPerformancesTab
          storeId={store!.id}
          latestDays={latestDays}
          productId={product!.id}
          countryCode={countryCode}
        />
      )}
      {currentTab === PerformanceType.AD_GROUP_PERFORMANCE && store && (
        <AdGroupPerformancesTab
          latestDays={latestDays}
          offer={offer}
        />
      )}
    </AccountPage>
  );
};

export default PerformancesPage;

import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import Offers from '../../../domain/automator/offers/Offers';
import { DeliveryCode } from '../../../domain/pd/DeliveryCode';
import BundlePrice from '../../../domain/automator/offers/BundlePrice';
import { OFFERS_PATH } from '../automatorApiPaths';
import { OffersQueryKey } from '../../queryKeys/OffersQueryKey';
import { AutomatorProductsQueryKey } from '../../queryKeys/AutomatorProductsQueryKey';

export const useUpdateOffer = (offerId: number, productId: number) => {
  const url = useAutomatorApiAccountUrl(`${OFFERS_PATH}/${offerId}`);
  return useAuthorizedPost<Offers, UpdateOfferData>(url, [
    OffersQueryKey(productId),
    AutomatorProductsQueryKey(),
  ]);
};

export interface UpdateOfferData {
  is_on_hold_by_retailer: boolean | null;
  delivery_code: DeliveryCode | null;
  stock_amount: number | null;
  bundle_prices: BundlePrice[] | null;
}

import { useDispatch } from '../Store';
import { DialogId } from './DialogId';
import { openDialog } from './dialogsSlice';
import { DialogIdDataTypeMap } from './DialogIdDataMap';

export const useOpenDialog = <T extends DialogId>(id: T) => {
  const dispatch = useDispatch();

  return (data?: DialogIdDataTypeMap[T]) => {
    dispatch(openDialog({ id, data }));
  };
};

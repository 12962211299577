import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { UpdateProductInternalReferenceForm } from '../components/UpdateProductInternalReferenceForm';

export const UpdateOfferInternalReferenceDialog = () => {
  const id = DialogId.UPDATE_OFFER_INTERNAL_REFERENCE;
  const { isOpen, data } = useIsDialogOpen(id);
  const closeDialog = useCloseDialog(id);
  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          title={t(tokens.automator.orders.dialogs.update_internal_reference.dialog_title)}
        >
          <UpdateProductInternalReferenceForm
            productId={data.productId}
            initialInternalReference={data.internalReference}
            onClick={closeDialog}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface UpdateOfferInternalReferenceDialogData {
  productId: number;
  internalReference: string;
}

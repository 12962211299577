import { QueryKeysUpdater } from '../../useUpdateData';
import ProposedAction from '../../../domain/automator/alerts/ProposedAction';
import { ProposedActionsQueryKey } from '../../queryKeys/ProposedActionsQueryKey';
import { GetProposedActionsStatus } from '../../../domain/automator/alerts/GetProposedActionsStatus';

export const useProposedActionQueryKeysUpdater = (): QueryKeysUpdater<ProposedAction> => {
  return {
    [ProposedActionsQueryKey(GetProposedActionsStatus.OPEN, null)]: alertUpdater(
      GetProposedActionsStatus.OPEN
    ),
  };
};

const alertUpdater = (status: GetProposedActionsStatus) => {
  return {
    addCriteria: (data: ProposedAction) => {
      if (status == GetProposedActionsStatus.OPEN) {
        return !data.isDismissed;
      } else {
        return data.isExecuted;
      }
    },
  };
};

import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../../../locales/translationTokens';
import ActionButton from '../../../../../../components/ActionButton';
import { useCreateManualPerformanceEvent } from '../../../../../../api/pd/timeline/useCreateManualPerformanceEvent';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';

interface CreateManualPerformanceEventFormProps {
  offerId: number;
  onClick?: () => void;
}

export const CreateManualPerformanceEventForm = ({
  offerId,
  onClick,
}: CreateManualPerformanceEventFormProps) => {
  const [note, setNote] = useState('');
  const [date, setDate] = useState<Date>(new Date());

  const { t } = useTranslation();

  const { mutate: create, isLoading } = useCreateManualPerformanceEvent();

  const onCreate = () => {
    create(
      {
        offer_id: offerId,
        note: note,
        date: date,
      },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(
            t(
              tokens.pd.performance.create_manual_performance_event_form
                .create_manual_performance_event
            )
          );
        },
      }
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <Stack
        direction="column"
        spacing={2}
        style={{ marginBottom: '1em' }}
      >
        <TextField
          id="note-field"
          label={t(tokens.pd.performance.create_manual_performance_event_form.note)}
          variant="filled"
          fullWidth
          style={{ marginBottom: '1em' }}
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />

        <DatePicker
          label={t(tokens.pd.performance.create_manual_performance_event_form.date)}
          value={date}
          onChange={(newValue) => {
            if (newValue) {
              setDate(newValue);
            }
          }}
        />

        <ActionButton
          label={t(
            tokens.pd.performance.create_manual_performance_event_form
              .create_manual_performance_event
          )}
          onClick={() => onCreate()}
          isLoading={isLoading}
          variant="contained"
          color="primary"
          size="small"
        />
      </Stack>
    </form>
  );
};

import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { PRODUCT_PERFORMANCES } from '../pdApiPaths';
import { getDateStringFromDate } from '../../../helpers/getDateStringFromDate';
import ProductPerformances from '../../../domain/pd/ProductPerformances';
import { ProductPerformanceQueryKey } from '../../queryKeys/ProductPerformanceQueryKey';

export const useFetchProductPerformances = (
  productId: number,
  startDate: Date,
  endDate: Date,
  rollingAverageDays: number
) => {
  const url = usePdApiAccountUrl(
    PRODUCT_PERFORMANCES +
      `?productId=${productId}&startDate=${getDateStringFromDate(
        startDate
      )}&endDate=${getDateStringFromDate(endDate)}&rollingAverageDays=${rollingAverageDays}`
  );
  return useFetchData<ProductPerformances>(
    url,
    ProductPerformanceQueryKey(productId, startDate, endDate, rollingAverageDays)
  );
};

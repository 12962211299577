import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useIdFromUrl = (pathPart: string) => {
  const location = useLocation();
  return useMemo(() => {
    const paths = location.pathname.split('/');
    const accountIdIndex = paths.findIndex((path) => path === pathPart) + 1;
    return paths[accountIdIndex];
  }, [location]);
};

import SvgIcon, {SvgIconProps} from "@mui/material/SvgIcon";

export function DoubleOrderIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 21 21" sx={{fill: props.color ? props.color : 'currentcolor'}}>
      <title>SVG-DoubleOrder</title>
      <path
        id="Shape 8"
        fillRule="evenodd"
        d="m9 3.3c0 0.3 0.3 0.6 0.6 0.6h1.8c0.3 0 0.6-0.3 0.6-0.6v-3.3h1.4c1 0 1.8 0.9 1.8 2v4.6c0 1.4-1.1 2.6-2.4 2.6h-4.6c-1.3 0-2.4-1.2-2.4-2.6v-4.6c0-1.1 0.8-2 1.8-2h1.4zm-5.2 7.2v3.3c0 0.4 0.3 0.7 0.6 0.7h1.7c0.3 0 0.6-0.3 0.6-0.7v-3.3h1.5c1.3 0 2.3 1.2 2.3 2.6v5.3c0 1.4-1 2.6-2.3 2.6h-5.9c-1.3 0-2.3-1.2-2.3-2.6v-5.3c0-1.4 1-2.6 2.3-2.6zm7.6 10q0.3-0.7 0.3-1.5v-6.5q0-0.8-0.3-1.4c0.4-0.4 0.9-0.6 1.5-0.6h1.4v3.3c0 0.4 0.3 0.7 0.6 0.7h1.8c0.3 0 0.6-0.3 0.6-0.7v-3.3h1.4c1.3 0 2.3 1.2 2.3 2.6v5.3c0 1.4-1 2.6-2.3 2.6h-5.8c-0.6 0-1.1-0.2-1.5-0.5z"
      />
    </SvgIcon>
  );
}

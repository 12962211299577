import { MESSAGE_TEMPLATES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import MessageTemplate from '../../../domain/automator/messages/MessageTemplate';
import { MessageTemplateQueryKey } from '../../queryKeys/MessageTemplateQueryKey';
import { useFetchData } from '../../useFetchData';

export const useFetchMessageTemplate = (messageTemplateId?: number | null) => {
  const url = useAutomatorApiAccountUrl(`${MESSAGE_TEMPLATES_PATH}/${messageTemplateId}`);

  return useFetchData<MessageTemplate>(url, MessageTemplateQueryKey(messageTemplateId ?? 0), {
    enabled: messageTemplateId != null,
  });
};

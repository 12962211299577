import { useTranslation } from 'react-i18next';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import TableCell from '@mui/material/TableCell';

interface TableListDividerProps {
  label: string;
  chipWidth?: number;
  onClick?: () => void;
}

export const TableListDivider = ({ label, chipWidth, onClick }: TableListDividerProps) => {
  const { t } = useTranslation();

  return (
    <TableRow
      hover={onClick != null}
      onClick={onClick}
      style={{
        cursor: onClick ? 'pointer' : 'default',
      }}
    >
      <TableCell
        align="center"
        colSpan={6}
        style={{
          borderTop: 'none',
          borderBottom: 'none',
          paddingBottom: 0,
          margin: 0,
          paddingLeft: 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Chip
            label={t(label)}
            variant="filled"
            size="small"
            style={{
              width: chipWidth,
              borderRadius: '0px 15px 15px 0px',
            }}
          />

          <hr style={{ flexGrow: 17, marginLeft: '15px' }} />
        </div>
      </TableCell>
    </TableRow>
  );
};

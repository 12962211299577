import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import DistributionPartyChip from '../../shipments/components/DistributionPartyChip';
import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';
import { FlagIcon } from '../../orders/components/FlagIcon';
import { CountryCode } from '../../../../domain/automator/orders/CountryCode';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { Switch } from '@mui/material';
import { TooltippedProductThumbnail } from '../../orders/components/TooltippedProductThumbnail';
import EventRule from '../../../../domain/automator/eventRules/EventRule';
import { useEnableEventRuleAction } from '../../../../api/automator/eventRules/useEnableEventRuleAction';
import EventRuleAction from '../../../../domain/automator/eventRules/EventRuleAction';
import { useDisableEventRuleAction } from '../../../../api/automator/eventRules/useDisableEventRuleAction';
import { EmailActionReportView } from './EmailActionReportView';
import { Box } from '@mui/system';
import { ActionIcon } from '../../orders/components/ActionIcon';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { DeleteForever, EmailOutlined } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import { truncateString } from '../../../../helpers/truncateString';
import { EventRuleStatus } from '../../../../domain/automator/eventRules/EventRuleStatus';
import { useArchiveEventRuleAction } from '../../../../api/automator/eventRules/useArchiveEventRuleAction';

interface AutoMailEventRuleTableRowProps {
  eventRule: EventRule;
}

export const AutoMailEventRuleTableRow = ({ eventRule }: AutoMailEventRuleTableRowProps) => {
  const eventRuleAction = eventRule.eventRuleActions[0]!;

  const { mutate: enable } = useEnableEventRuleAction(eventRuleAction.id);
  const { mutate: disable } = useDisableEventRuleAction(eventRuleAction.id);
  const { mutate: archive } = useArchiveEventRuleAction(eventRuleAction.id);

  const openUpdateMessageTemplateDialog = useOpenDialog(DialogId.UPDATE_MESSAGE_TEMPLATE);

  const openUpdateEventRule = useOpenDialog(DialogId.UPDATE_AUTO_MAIL_EVENT_RULE);

  const openWarningDialog = useOpenDialog(DialogId.WARNING);

  const { t } = useTranslation();

  const handleEnable = () => {
    enable(undefined, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.auto_mail.enabled));
      },
    });
  };

  const handleDisable = () => {
    disable(undefined, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.auto_mail.disabled));
      },
    });
  };

  const handleArchive = () => {
    archive(undefined, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.auto_mail.archived));
      },
    });
  };

  const onArchiveClick = () => {
    openWarningDialog({
      title: t(tokens.automator.auto_mail.archive),
      message: t(tokens.automator.auto_mail.archive_warning_message),
      buttonLabel: t(tokens.automator.auto_mail.archive),
      onConfirm: handleArchive,
    });
  };

  const renderDelay = (eventRuleAction: EventRuleAction) => {
    if (eventRuleAction.executeAtTime) {
      return eventRuleAction.executeAtTime.slice(0, 5);
    } else if (eventRuleAction.executeInHours) {
      if (eventRuleAction.executeInHours % 24 === 0) {
        return `${eventRuleAction.executeInHours / 24} ${t(
          tokens.automator.auto_mail.delay_type.days
        )}`;
      } else {
        return `${eventRuleAction.executeInHours} ${t(
          tokens.automator.auto_mail.delay_type.hours
        )}`;
      }
    } else {
      return '';
    }
  };

  return (
    <TableRow key={eventRule.id}>
      <TableCell>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(6, 1fr)',
            alignItems: 'center',
            gap: 0.2,
          }}
        >
          {eventRule.products.length === 0 && (
            <Chip
              size="small"
              label={t(tokens.automator.auto_mail.all_products)}
              color="info"
              variant={'outlined'}
            />
          )}

          {eventRule.products.slice(0, 5).map((product, index) => (
            <TooltippedProductThumbnail
              key={index}
              productTitle={product.title}
              height={20}
              thumbnailUrl={product.thumbnailUrl}
            />
          ))}

          {eventRule.products.length > 5 && (
            <Badge
              badgeContent={'+' + (eventRule.products.length - 5)}
              color="primary"
            />
          )}
        </Box>
      </TableCell>

      <TableCell>
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
        >
          <ActionIcon
            icon={<SettingsIcon style={{ color: 'blue' }} />}
            tooltip={t(tokens.automator.auto_mail.update_event_rule)}
            onClick={() => openUpdateEventRule({ eventRule: eventRule })}
          />

          <Chip
            icon={<EmailOutlined />}
            label={truncateString(eventRuleAction.emailAction?.messageTemplate.name || '', 25)}
            onClick={() =>
              openUpdateMessageTemplateDialog({
                messageTemplateId: eventRuleAction.emailAction!.messageTemplate.id,
              })
            }
          />

          {eventRuleAction.report?.amountOfEmailTasksQueued &&
          eventRuleAction.report?.amountOfEmailTasksQueued > 0 ? (
            <Badge
              badgeContent={eventRuleAction.report?.amountOfEmailTasksQueued}
              color="secondary"
            >
              <AccessAlarmOutlinedIcon color="action" />
            </Badge>
          ) : (
            <></>
          )}
        </Stack>
      </TableCell>

      <TableCell>
        <Stack
          direction="row"
          gap={1}
          justifyContent="space-between"
          alignItems="center"
        >
          {eventRule.distributionParty ? (
            <DistributionPartyChip distributionParty={eventRule.distributionParty!} />
          ) : (
            <Stack
              direction="row"
              gap={1}
            >
              <DistributionPartyChip distributionParty={DistributionParty.BOL} />
              <DistributionPartyChip distributionParty={DistributionParty.RETAILER} />
            </Stack>
          )}

          <Box marginTop={1}>
            {eventRule.countryCode ? (
              <FlagIcon
                size={30}
                countryCode={eventRule.countryCode!}
              />
            ) : (
              <Stack
                direction="row"
                gap={1}
              >
                <FlagIcon
                  size={30}
                  countryCode={CountryCode.NL}
                />
                <FlagIcon
                  size={30}
                  countryCode={CountryCode.BE}
                />
              </Stack>
            )}
          </Box>
        </Stack>
      </TableCell>

      <TableCell>{renderDelay(eventRuleAction)}</TableCell>

      <TableCell>
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            {eventRuleAction.report && eventRuleAction.report.amountOfEmailMessagesSent > 0 && (
              <EmailActionReportView emailActionReport={eventRuleAction.report!} />
            )}
          </Box>

          <Stack
            direction="row"
            alignItems="center"
          >
            <Switch
              checked={eventRule.status != EventRuleStatus.DISABLED}
              onChange={eventRule.status == EventRuleStatus.DISABLED ? handleEnable : handleDisable}
              color="primary"
              name="enabled"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />

            <ActionIcon
              icon={<DeleteForever style={{ color: 'orange' }} />}
              tooltip={t(tokens.automator.auto_mail.archive)}
              onClick={onArchiveClick}
            />
          </Stack>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export enum CancellationReason {
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  REQUESTED_BY_CUSTOMER = 'REQUESTED_BY_CUSTOMER',
  BAD_CONDITION = 'BAD_CONDITION',
  HIGHER_SHIPCOST = 'HIGHER_SHIPCOST',
  INCORRECT_PRICE = 'INCORRECT_PRICE',
  NOT_AVAIL_IN_TIME = 'NOT_AVAIL_IN_TIME',
  NO_BOL_GUARANTEE = 'NO_BOL_GUARANTEE',
  ORDERED_TWICE = 'ORDERED_TWICE',
  RETAIN_ITEM = 'RETAIN_ITEM',
  TECH_ISSUE = 'TECH_ISSUE',
  UNFINDABLE_ITEM = 'UNFINDABLE_ITEM',
  OTHER = 'OTHER',
}

import { EndpointQueryKey } from './EndpointQueryKey';
import { MessageTemplateType } from '../../domain/automator/messages/MessageTemplateType';
import { ThreadPhase } from '../../domain/automator/messages/ThreadPhase';
import { ReturnRecoverability } from '../../domain/automator/returns/ReturnRecoverability';

export const MessageTemplatesQueryKey = (
  type?: MessageTemplateType,
  phase?: ThreadPhase | null,
  recoverability?: ReturnRecoverability | null
) => {
  if (!type) return [EndpointQueryKey.MESSAGE_TEMPLATE];

  return [EndpointQueryKey.MESSAGE_TEMPLATE, { type, phase, recoverability }];
};

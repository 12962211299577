import Stack from '@mui/material/Stack';
import { TableTabs } from '../../../../../components/TableTabs';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../locales/translationTokens';

export enum PerformanceType {
  OFFER_PERFORMANCE = 'OFFER_PERFORMANCE',
  PRODUCT_PERFORMANCE = 'PRODUCT_PERFORMANCE',
  AD_GROUP_PERFORMANCE = 'AD_GROUP_PERFORMANCE',
}

interface PerformancesTypeTabsProps {
  currentTab: PerformanceType;
  setCurrentTab: (tab: PerformanceType) => void;
}

const PerformancesTypeTabs = ({ currentTab, setCurrentTab }: PerformancesTypeTabsProps) => {
  const { t } = useTranslation();

  const tabOptions = [
    {
      label: t(tokens.pd.performance.tabs.offer_performance),
      value: PerformanceType.OFFER_PERFORMANCE,
    },
    {
      label: t(tokens.pd.performance.tabs.product_performance),
      value: PerformanceType.PRODUCT_PERFORMANCE,
    },
    {
      label: t(tokens.pd.performance.tabs.ad_search_term_performance),
      value: PerformanceType.AD_GROUP_PERFORMANCE,
    },
  ];

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
    >
      <TableTabs
        value={currentTab}
        onChange={(tab: PerformanceType) => {
          setCurrentTab(tab);
        }}
        tabOptions={tabOptions}
      />
    </Stack>
  );
};

export default PerformancesTypeTabs;

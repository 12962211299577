import { EndpointQueryKey } from './EndpointQueryKey';
import { EventRuleType } from '../../domain/automator/eventRules/EventRuleType';
import { EventRuleStatus } from '../../domain/automator/eventRules/EventRuleStatus';

export const EventRulesQueryKey = (type?: EventRuleType, status?: EventRuleStatus[]) => {
  if (!type) {
    return [EndpointQueryKey.EVENT_RULES];
  }

  return [EndpointQueryKey.EVENT_RULES, { type, status }];
};

import { EVENT_RULES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { DistributionParty } from '../../../domain/automator/orders/DistributionParty';
import { CountryCode } from '../../../domain/automator/orders/CountryCode';
import EventTrigger from '../../../domain/automator/eventRules/EventTrigger';
import { RecipientType } from '../../../domain/automator/messages/RecipientType';
import EventRules from '../../../domain/automator/eventRules/EventRules';
import { EventRulesQueryKey } from '../../queryKeys/EventRulesQueryKey';

export const useCreateEventRule = () => {
  const url = useAutomatorApiAccountUrl(EVENT_RULES_PATH);
  return useAuthorizedPost<EventRules, CreateEventRuleData>(url, [EventRulesQueryKey()]);
};

export interface CreateEventRuleData {
  product_ids: number[];
  email_template_id: number;
  distribution_party: DistributionParty | null;
  country_code: CountryCode | null;
  recipient_type: RecipientType;
  trigger: EventTrigger;
  execute_at_time: string | null;
  execute_in_hours: number | null;
  is_start_with_resolution_case: boolean;
}

import { RESENDS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { ResendsQueryKey } from './ResendsQueryKey';
import { ResendStatus } from '../../../domain/automator/resends/ResendStatus';
import Resends from '../../../domain/automator/resends/Resends';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';

export const useHandleResend = (resendId: number) => {
  const url = useAutomatorApiAccountUrl(RESENDS_PATH + '/' + resendId + `/handle`);
  return useAuthorizedPost<Resends, undefined>(
    url,
    [
      ResendsQueryKey(ResendStatus.HANDLED),
      ResendsQueryKey(ResendStatus.OPEN),
      [EndpointQueryKey.ACCOUNT],
    ],
    undefined
  );
};

import { RETURN_SCANS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { ReturnScanDocumentsQueryKey } from '../../queryKeys/ReturnScanDocumentsQueryKey';
import ReturnScanDocuments from '../../../domain/automator/services/ReturnScanDocuments';

export const useFetchReturnScanDocuments = (returnScanId: number) => {
  const url = useAutomatorApiAccountUrl(RETURN_SCANS_PATH + `/${returnScanId}` + '/photos');
  return useFetchData<ReturnScanDocuments>(url, ReturnScanDocumentsQueryKey(returnScanId));
};

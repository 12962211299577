import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import { Email } from '@mui/icons-material';
import { useSendMessageThreadReply } from '../../../../api/automator/emails/useSendMessageThreadReply';
import { toast } from 'react-hot-toast';
import Stack from '@mui/material/Stack';
import HtmlEditor from '../../autoEmail/components/HtmlEditor';
import MessageThread from '../../../../domain/automator/messages/MessageThread';
import { useFetchMessageTemplate } from '../../../../api/automator/emails/useFetchMessageTemplate';
import { EmailDocumentsList } from './EmailDocumentsList';
import Box from '@mui/material/Box';
import FileUploadForm from './FileUploadForm';
import FileUpload from '../../shared/FileUpload';
import { ResolutionCaseStatus } from '../../../../domain/automator/resolutionDossiers/ResolutionCaseStatus';
import { ResolutionCaseStatusToggleButton } from './ResolutionCaseStatusToggleButton';
import { useHandleResolutionCase } from '../../../../api/automator/resolutions/useHandleResolutionCase';
import { useAwaitingResponseResolutionCase } from '../../../../api/automator/resolutions/useAwaitingResponseResolutionCase';
import { useNavigate } from 'react-router-dom';
import { usePaths } from '../../../../routes/usePaths';
import determineDefaultEmailMessageBody from './helpers/determineDefaultEmailMessageBody';

interface SendMessageThreadReplyHtmlEditorProps {
  resolutionCaseId: number;
  onClick?: () => void;
  messageThread: MessageThread;
  messageTemplateId: number | null;
  insertVariable: string | null;
}

export const SendMessageThreadReplyHtmlEditor = ({
  onClick,
  messageThread,
  messageTemplateId,
  insertVariable,
  resolutionCaseId,
}: SendMessageThreadReplyHtmlEditorProps) => {
  const [resolutionCaseStatus, setResolutionCaseStatus] = useState<ResolutionCaseStatus>(
    ResolutionCaseStatus.HANDLED
  );

  const { data: messageTemplate, isLoading: isLoadingMessageTemplate } =
    useFetchMessageTemplate(messageTemplateId);

  const { mutate: sendReply, isLoading } = useSendMessageThreadReply(messageThread.id);

  const { mutate: handleResolutionCase, isLoading: isLoadingHandleResolutionCase } =
    useHandleResolutionCase(resolutionCaseId);

  const { mutate: handleAwaitingResolutionCase, isLoading: isLoadingAwaitingResolutionCase } =
    useAwaitingResponseResolutionCase(resolutionCaseId);

  useEffect(() => {
    if (messageTemplate !== null) {
      setBody(messageTemplate?.body || '');
    }
  }, [messageTemplate]);

  const [body, setBody] = useState(
    messageTemplate?.body
      ? messageTemplate.body
      : determineDefaultEmailMessageBody(messageThread.recipientType)
  );

  const [files, setFiles] = useState<FileUpload[]>([]);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const paths = usePaths();

  if (isLoadingMessageTemplate) {
    return <div>Loading...</div>;
  }

  const onSend = () => {
    sendReply(
      {
        email_template_id: messageTemplate?.id,
        body: body || '',
        files: files,
        resolution_case_status: resolutionCaseStatus,
      },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(t(tokens.automator.resolutions.dialogs.send_email_thread_reply.reply_sent));

          if (resolutionCaseStatus == ResolutionCaseStatus.AWAITING_RESPONSE) {
            handleAwaitingResolutionCase(undefined);
            navigate(paths.automator.resolution_dossiers);
          } else if (resolutionCaseStatus == ResolutionCaseStatus.HANDLED) {
            handleResolutionCase(undefined);
            navigate(paths.automator.resolution_dossiers);
          }
        },
      }
    );
  };

  return (
    <Stack
      direction="column"
      gap={2}
      width="70%"
    >
      <HtmlEditor
        content={body}
        onChange={(newBody) => {
          if (newBody === body) {
            return;
          }
          setBody(newBody);
        }}
        insertVariable={insertVariable}
      />

      {messageTemplate && messageTemplate!.documents.length > 0 ? (
        <EmailDocumentsList documents={messageTemplate!.documents} />
      ) : (
        <Box marginLeft={-1}>
          <FileUploadForm
            onChange={(fileUploads: FileUpload[]) => setFiles(fileUploads)}
            multiple
          />
        </Box>
      )}

      <ResolutionCaseStatusToggleButton
        value={resolutionCaseStatus}
        onSelect={setResolutionCaseStatus}
      />

      <ActionButton
        icon={<Email />}
        label={t(tokens.automator.resolutions.dialogs.send_email_thread_reply.send_reply)}
        onClick={() => onSend()}
        isLoading={isLoading || isLoadingHandleResolutionCase || isLoadingAwaitingResolutionCase}
        variant="contained"
        color="primary"
        size="small"
      />
    </Stack>
  );
};

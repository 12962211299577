import { lazy } from 'react';
import type { RouteObject } from 'react-router';

import { authRoutes } from './authRoutes';
import { automatorRoutes } from './automatorRoutes';
import { adminRoutes } from './adminRoutes';
import { customerRoutes } from './customerRoutes';
import { pdRoutes } from './pdRoutes';

const Error401Page = lazy(() => import('src/pages/error/401Page'));
const Error404Page = lazy(() => import('src/pages/error/404Page'));
const Error500Page = lazy(() => import('src/pages/error/500Page'));

export const routes: RouteObject[] = [
  ...authRoutes,
  ...automatorRoutes,
  ...pdRoutes,
  ...adminRoutes,
  ...customerRoutes,
  {
    path: '401',
    element: <Error401Page />,
  },
  {
    path: '404',
    element: <Error404Page />,
  },
  {
    path: '500',
    element: <Error500Page />,
  },
  {
    path: '*',
    element: <Error404Page />,
  },
];

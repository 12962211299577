import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ActionButton from '../../../../components/ActionButton';

interface GotoBolCaseActionButtonProps {
  bolCaseNumber: string | null;
  bolOrderId: string;
  bolThreadOrderItemId: string | null;
}

const GotoBolCaseActionButton = ({
  bolCaseNumber,
  bolOrderId,
  bolThreadOrderItemId,
}: GotoBolCaseActionButtonProps) => {
  const { t } = useTranslation();

  const url = bolCaseNumber
    ? 'https://partner.bol.com/sdd/cases/details/' + bolCaseNumber
    : 'https://partner.bol.com/sdd/cases/new-case/' +
      bolOrderId +
      '/' +
      bolThreadOrderItemId +
      '/LEVERING';

  return (
    <ActionButton
      icon={<ManageAccountsIcon />}
      label={t(tokens.automator.resolutions.actions.go_to_bol_case)}
      onClick={() => {
        window.open(url, '_blank');
      }}
      variant="text"
    />
  );
};

export default GotoBolCaseActionButton;

import { ORDERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { OrderSearchQueryKey } from '../../queryKeys/OrderSearchQueryKey';
import Order from '../../../domain/automator/orders/Order';

export const useSearchOrder = (query: string) => {
  const url = useAutomatorApiAccountUrl(ORDERS_PATH + '/search?' + 'query=' + query);
  return useFetchData<Order | null>(url, OrderSearchQueryKey(query), {
    enabled: query.length >= 10,
  });
};

import Stack from '@mui/material/Stack';
import * as React from 'react';
import { useFetchReturnItem } from '../../../../api/automator/returns/useFetchReturnItem';
import TransporterChip from '../../shipments/components/TransporterChip';

interface ReturnResolutionCaseInfoProps {
  returnItemId: number;
}

export const ReturnResolutionCaseInfo = ({ returnItemId }: ReturnResolutionCaseInfoProps) => {
  const { data: returnItem, isLoading } = useFetchReturnItem(returnItemId);

  if (isLoading || !returnItem) {
    return <div>Loading...</div>;
  }

  return (
    <Stack
      gap={1}
      direction="column"
    >
      {returnItem!.transporterInfos.map((info, index) => {
        return (
          info.trackAndTraceUrl && (
            <TransporterChip
              key={index}
              transporterCode={info.transporterCode}
              trackAndTrace={info.trackAndTraceUrl}
            />
          )
        );
      })}
    </Stack>
  );
};

import { TableListDivider } from './TableListDivider';
import getShortendAlphabeticFormatFromDate from '../../../../helpers/getShortendAlphabeticFormatFromDate';

interface DateTableListDividerProps {
  date: string;
  onClick?: () => void;
}

export const DateTableListDivider = ({ date, onClick }: DateTableListDividerProps) => {
  return (
    <TableListDivider
      chipWidth={100}
      onClick={onClick}
      label={getShortendAlphabeticFormatFromDate(new Date(date))}
    />
  );
};

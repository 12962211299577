import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ReducedResolutionCase from '../../../../domain/automator/resolutionDossiers/ReducedResolutionCase';
import { InitialEmailMessageOpenedIndicator } from '../../orderSummaries/components/InitialEmailMessageOpenedIndicator';
import RecipientTypeChip from './RecipientTypeChip';
import { GeneralResolutionCaseActions } from './GeneralResolutionCaseActions';
import TriggerTypeChip from './TriggerTypeChip';

interface ResolutionCaseSelectProps {
  dossierHasMultipleCases: boolean;
  reducedResolutionCase: ReducedResolutionCase;
  isSelected: boolean;
  setSelectedCaseId: (resolutionCaseId: number) => void;
}

export const ResolutionCaseSelect = ({
  reducedResolutionCase,
  isSelected,
  setSelectedCaseId,
  dossierHasMultipleCases,
}: ResolutionCaseSelectProps) => {
  return (
    <Box
      key={reducedResolutionCase.id}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: isSelected && dossierHasMultipleCases ? '1px dashed #000' : 'none',
      }}
      borderRadius={1}
      alignItems="center"
      paddingX={2}
    >
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        onClick={() => setSelectedCaseId(reducedResolutionCase.id)}
      >
        <Box width={30}>
          {reducedResolutionCase.initialEmailMessageOpenedDateTime && (
            <InitialEmailMessageOpenedIndicator
              initialEmailMessageOpenendDateTime={
                reducedResolutionCase.initialEmailMessageOpenedDateTime
              }
            />
          )}
        </Box>

        <Stack
          direction="row"
          gap={0.5}
          alignItems="center"
          minWidth={200}
        >
          <TriggerTypeChip
            minutesAfterMessageRequiringReply={
              reducedResolutionCase.minutesAfterMessageRequiringReply
            }
            triggerType={reducedResolutionCase.triggerType}
          />
        </Stack>

        <Stack
          direction="row"
          gap={1}
        >
          <RecipientTypeChip recipientType={reducedResolutionCase.recipientType} />
          <Typography variant="body1">{reducedResolutionCase.subject}</Typography>
        </Stack>
      </Stack>

      <GeneralResolutionCaseActions
        key={reducedResolutionCase.id}
        resolutionCase={reducedResolutionCase}
      />
    </Box>
  );
};

import { ORDERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { OrderQueryKey } from '../../queryKeys/OrderQueryKey';
import Order from '../../../domain/automator/orders/Order';

export const useFetchOrder = (orderId: number) => {
  const url = useAutomatorApiAccountUrl(ORDERS_PATH + '/' + orderId);
  return useFetchData<Order>(url, OrderQueryKey(orderId));
};

import { ORDERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import OrderNote from '../../../domain/automator/orders/OrderNote';
import { OrderNoteQueryKey } from '../../queryKeys/OrderNoteQueryKey';

export const useFetchOrderNote = (orderId: number) => {
  const url = useAutomatorApiAccountUrl(ORDERS_PATH + '/' + orderId + '/note');

  return useFetchData<OrderNote>(url, OrderNoteQueryKey(orderId));
};

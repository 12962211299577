import { MESSAGE_THREADS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { ThreadMessagesQueryKey } from '../../queryKeys/ThreadMessagesQueryKey';
import ThreadMessages from '../../../domain/automator/messages/ThreadMessages';

export const useFetchThreadMessages = (messageThreadId: number) => {
  const url = useAutomatorApiAccountUrl(MESSAGE_THREADS_PATH + '/' + messageThreadId + '/messages');
  return useFetchData<ThreadMessages>(url, ThreadMessagesQueryKey(messageThreadId));
};

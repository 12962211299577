import { useFetchProductRanks } from '../../../../../../api/pd/productPerformances/useFetchProductRanks';
import Box from '@mui/material/Box';
import { DateLineChart, DateLineChartSeries } from './DateLineChart';
import { LatestDays } from '../LatestDaysDropdownSelect';
import getLatestDateByLatestDays from '../../../../../../helpers/getLatestDateByLatestDays';
import getYesterday from '../../../../../../helpers/getYesterday';
import { CountryCode } from '../../../../../../domain/automator/orders/CountryCode';
import { useFetchProductPerformances } from '../../../../../../api/pd/productPerformances/useFetchProductPerformances';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/translationTokens';

interface ProductRankChartProps {
  productId: number;
  latestDays: LatestDays;
  categoryId?: number;
  searchTermId?: number;
  countryCode: CountryCode | null;
}

export const ProductRankChart = ({
  productId,
  categoryId,
  searchTermId,
  latestDays,
  countryCode,
}: ProductRankChartProps) => {
  const startDate = getLatestDateByLatestDays(latestDays);
  const endDate = getYesterday();

  const { data: productRanks, isLoading: productRanksLoading } = useFetchProductRanks(
    startDate,
    endDate,
    productId,
    categoryId,
    searchTermId,
    countryCode
  );

  const { data: productPerformances, isLoading: ProductPerformancesLoading } =
    useFetchProductPerformances(productId, startDate, endDate, 1);

  const { t } = useTranslation();

  if (productRanksLoading || ProductPerformancesLoading) {
    return <Box>Loading...</Box>;
  }

  const productPerformancesSorted = productPerformances!.productPerformances.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  const dates = productPerformancesSorted
    .map((productPerformance) => new Date(productPerformance.date))
    .map((date) => date.toISOString().split('T')[0]);

  const chartSeries: DateLineChartSeries[] = [];

  const validProductPerformances = productPerformancesSorted.filter((productPerformance) => {
    return productPerformance.weightedRank != null;
  });

  let rankYMin = Math.min(
    ...validProductPerformances.map((productPerformance) => productPerformance.weightedRank!)
  );
  let rankYMax = Math.max(
    ...validProductPerformances.map((productPerformance) => productPerformance.weightedRank!)
  );

  if (productRanks) {
    const productRanksSorted = productRanks.productRanks.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    rankYMin = Math.min(
      rankYMin,
      Math.min(...productRanksSorted.map((productRank) => productRank.rank))
    );

    rankYMax = Math.max(
      rankYMax,
      Math.max(...productRanksSorted.map((productRank) => productRank.rank))
    );

    const productRanksWithEmpty = dates.map((date) => {
      const productRank = productRanksSorted.find((productRank) => productRank.date === date);
      return productRank || { date, rank: null, impressions: null };
    });

    chartSeries.push({
      name: t(tokens.pd.performance.product_ranks_chart.rank),
      data: productRanksWithEmpty.map((productRank) => productRank.rank),
      invertY: true,
      yMin: rankYMin,
      yMax: rankYMax,
    });

    chartSeries.push({
      name: t(tokens.pd.performance.product_ranks_chart.impressions),
      data: productRanksWithEmpty.map((productRank) => productRank.impressions),
    });
  }

  chartSeries.push({
    name: t(tokens.pd.performance.product_ranks_chart.loaded_rank),
    data: productPerformancesSorted.map((productPerformance) => productPerformance.weightedRank),
    invertY: true,
    yMin: rankYMin,
    yMax: rankYMax,
  });

  return (
    <DateLineChart
      header={t(tokens.pd.performance.product_ranks_chart.product_ranks)}
      categories={dates}
      chartSeries={chartSeries}
    />
  );
};

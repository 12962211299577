import type { ApexOptions } from 'apexcharts';
import DotsHorizontalIcon from '@untitled-ui/icons-react/build/esm/DotsHorizontal';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';

import { Chart } from 'devias/src/components/chart';
import { Scrollbar } from 'devias/src/components/scrollbar';
import { useTranslation } from 'react-i18next';

export interface DeviationChartSeries {
  name: string;
  data: (number | null)[];
  invertY?: boolean;
  yMin?: number;
  yMax?: number;
}

interface DeviationChartProps {
  header: string;
  categories: string[];
  chartSeries: DeviationChartSeries[];
}

export const DeviationBarChart = ({ header, categories, chartSeries }: DeviationChartProps) => {
  const { t } = useTranslation();

  const chartOptions = useChartOptions(categories);

  return (
    <Box
      sx={{
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.100'),
        p: 3,
      }}
    >
      <Card>
        <CardHeader
          action={
            <IconButton>
              <SvgIcon>
                <DotsHorizontalIcon />
              </SvgIcon>
            </IconButton>
          }
          title={t(header)}
        />
        <CardContent>
          <Scrollbar>
            <Box
              sx={{
                height: 375,
                minWidth: 500,
                position: 'relative',
              }}
            >
              <Chart
                height={350}
                options={chartOptions}
                series={chartSeries}
                type="bar"
              />
            </Box>
          </Scrollbar>
        </CardContent>
      </Card>
    </Box>
  );
};

const useChartOptions = (categories: string[]): ApexOptions => {
  const theme = useTheme();

  return {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: [theme.palette.primary.main],
    dataLabels: {
      enabled: false,
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: 1,
      strokeColors: theme.palette.background.default,
      strokeWidth: 1,
    },
    stroke: {
      curve: 'smooth',
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      x: {
        format: 'yyyy-MM-dd',
      },
    },
    xaxis: {
      type: 'datetime',
      categories: categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => (value > 0 ? `${value}` : `${value}`),
        offsetX: -10,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
  };
};

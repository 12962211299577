import { ORDERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { OrderReturnsQueryKey } from '../../queryKeys/OrderReturnsQueryKey';
import Return from '../../../domain/automator/returns/Return';

export const useFetchOrderReturns = (orderId: number) => {
  const url = useAutomatorApiAccountUrl(ORDERS_PATH + '/' + orderId + '/returns');
  return useFetchData<Return[]>(url, OrderReturnsQueryKey(orderId));
};

import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/translationTokens';
import { TableLoading } from '../../../../../../components/TableLoading';
import { TableEmpty } from '../../../../../../components/TableEmpty';
import Category from '../../../../../../domain/pd/Category';
import SearchTerm from '../../../../../../domain/pd/SearchTerm';
import { Scrollbar } from '../../../../../../../devias/src/components/scrollbar';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { ProductRankSearchTermTableRow } from './ProductRankSearchTermTableRow';
import { ProductRankCategoryTableRow } from './ProductRankCategoryTableRow';
import ProductRankCategory from '../../../../../../domain/pd/ProductRankCategory';
import ProductRankSearchTerm from '../../../../../../domain/pd/ProductRankSearchTerm';
import { useState } from 'react';

interface ProductRankSearchTermCategoryListTableProps {
  productRankSearchTerms?: ProductRankSearchTerm[];
  productRankCategories?: ProductRankCategory[];
  selectCategory: (category: Category) => void;
  selectSearchTerm: (searchTerm: SearchTerm) => void;
  selectedCategory: Category | undefined;
  selectedSearchTerm: SearchTerm | undefined;
}

export const ProductRankSearchTermCategoryListTable = ({
  productRankSearchTerms,
  productRankCategories,
  selectCategory,
  selectSearchTerm,
  selectedCategory,
  selectedSearchTerm,
}: ProductRankSearchTermCategoryListTableProps) => {
  const [showAll, setShowAll] = useState<boolean>(false);

  const { t } = useTranslation();

  if (!productRankSearchTerms || !productRankCategories) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (productRankCategories!.length == 0 && productRankSearchTerms!.length == 0) {
    return <TableEmpty message={t(tokens.pd.performance.no_items)} />;
  }

  return (
    <Scrollbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              {t(tokens.pd.performance.product_rank_search_term_category_table.type)}
            </TableCell>
            <TableCell>
              {t(tokens.pd.performance.product_rank_search_term_category_table.label)}
            </TableCell>
            <TableCell>
              {t(tokens.pd.performance.product_rank_search_term_category_table.average_rank)}
            </TableCell>
            <TableCell>
              {t(tokens.pd.performance.product_rank_search_term_category_table.total_impressions)}
            </TableCell>
            <TableCell>
              {t(tokens.pd.performance.product_rank_search_term_category_table.total_volume)}
            </TableCell>
            <TableCell>
              {t(tokens.pd.performance.product_rank_search_term_category_table.is_relevant)}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {productRankSearchTerms
            .filter((productRankSearchTerm) => {
              return showAll || productRankSearchTerm.totalImpressions > 100;
            })
            .sort((a, b) => {
              return a.totalImpressions > b.totalImpressions ? -1 : 1;
            })
            .map((productRankSearchTerm) => {
              return (
                <ProductRankSearchTermTableRow
                  onSelect={selectSearchTerm}
                  key={productRankSearchTerm.searchTerm.id + '_search_term'}
                  productRankSearchTerm={productRankSearchTerm}
                  isSelected={selectedSearchTerm?.id === productRankSearchTerm.searchTerm.id}
                />
              );
            })}
          {productRankCategories
            .filter((productRankCategory) => {
              return showAll || productRankCategory.totalImpressions > 100;
            })
            .sort((a, b) => {
              return a.totalImpressions > b.totalImpressions ? -1 : 1;
            })
            .map((productRankCategory) => {
              return (
                <ProductRankCategoryTableRow
                  onSelect={selectCategory}
                  key={productRankCategory.category.id + '_category'}
                  productRankCategory={productRankCategory}
                  isSelected={selectedCategory?.id === productRankCategory.category.id}
                />
              );
            })}
          {!showAll ? (
            <TableRow
              style={{ cursor: 'pointer', backgroundColor: '#98c9ea', color: 'white' }}
              onClick={() => setShowAll(true)}
            >
              <TableCell colSpan={5}>SHOW ALL</TableCell>
            </TableRow>
          ) : (
            <TableRow
              style={{ cursor: 'pointer', backgroundColor: '#eaaba4', color: 'white' }}
              onClick={() => setShowAll(false)}
            >
              <TableCell colSpan={6}>HIDE</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Scrollbar>
  );
};

import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../../../locales/translationTokens';
import { useUpdatePerformanceNote } from '../../../../../../api/pd/notes/useUpdatePerformanceNote';
import PerformanceNote from '../../../../../../domain/pd/PerformanceNote';

interface UpdatePerformanceNoteFormProps {
  onClick?: () => void;
  performanceNote: PerformanceNote;
}

export const UpdatePerformanceNoteForm = ({
  onClick,
  performanceNote,
}: UpdatePerformanceNoteFormProps) => {
  const [note, setNote] = useState(performanceNote.note);

  const { t } = useTranslation();

  const { mutate: create } = useUpdatePerformanceNote(performanceNote.id);

  const doUpdate = () => {
    create(
      {
        note: note,
      },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(t(tokens.pd.performance.performance_note_form.note_updated));
        },
      }
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <TextField
        id="note-field"
        label={t(tokens.pd.performance.performance_note_form.note)}
        variant="filled"
        multiline
        onBlur={() => {
          doUpdate();
        }}
        rows={4}
        fullWidth
        style={{ marginBottom: '1em' }}
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
    </form>
  );
};

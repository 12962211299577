import { RESOLUTION_DOSSIERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import ResolutionDossiersReport from '../../../domain/automator/resolutionDossiers/ResolutionDossiersReport';
import { ResolutionDossiersReportQueryKey } from './ResolutionDossiersReportQueryKey';

export const useFetchResolutionDossiersReport = () => {
  const url = useAutomatorApiAccountUrl(RESOLUTION_DOSSIERS_PATH + '/report');
  return useFetchData<ResolutionDossiersReport>(url, ResolutionDossiersReportQueryKey());
};

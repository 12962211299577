import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { getDateStringFromDate } from '../../../helpers/getDateStringFromDate';
import { MANUAL_PERFORMANCE_EVENTS } from '../pdApiPaths';
import { ManualPerformanceEventsQueryKey } from '../../queryKeys/ManualPerformanceEventsQueryKey';
import ManualPerformanceEvents from '../../../domain/pd/ManualPerformanceEvents';

export const useFetchManualPerformanceEvents = (
  offerId: number,
  startDate: Date,
  endDate: Date
) => {
  const url = usePdApiAccountUrl(
    MANUAL_PERFORMANCE_EVENTS +
      `?offerId=${offerId}` +
      `&startDate=${getDateStringFromDate(startDate)}` +
      `&endDate=${getDateStringFromDate(endDate)}`
  );
  return useFetchData<ManualPerformanceEvents>(
    url,
    ManualPerformanceEventsQueryKey(offerId, startDate, endDate)
  );
};

import { CUSTOMER_INVOICE_LINES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { CustomerInvoiceRequestsQueryKey } from '../../queryKeys/CustomerInvoiceRequestsQueryKey';
import CustomerInvoiceRequests from '../../../domain/automator/customerInvoiceRequests/CustomerInvoiceRequests';

export const useUpdateCustomerInvoiceLineVatPercentage = (customerInvoiceLineId: number) => {
  const url = useAutomatorApiAccountUrl(
    CUSTOMER_INVOICE_LINES_PATH + '/' + customerInvoiceLineId + `/update-vat-percentage`
  );

  // const updater = useCustomerInvoiceRequestQueryKeysUpdater();
  // const updateData = useUpdateData(updater, 'customerInvoiceRequests');
  //
  // const onSuccess = (customerInvoiceRequests: CustomerInvoiceRequests) => {
  //   updateData(customerInvoiceRequests);
  // };

  return useAuthorizedPost<CustomerInvoiceRequests, UpdateCustomerInvoiceLineVatPercentageData>(
    url,
    [CustomerInvoiceRequestsQueryKey()]
    //undefined,
    //onSuccess
  );
};

interface UpdateCustomerInvoiceLineVatPercentageData {
  vat_percentage: number;
}

import Stack from '@mui/material/Stack';
import EmailActionReport from '../../../../domain/automator/eventRules/EmailActionReport';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';

interface EmailActionReportViewProps {
  emailActionReport: EmailActionReport;
}

export const EmailActionReportView = ({ emailActionReport }: EmailActionReportViewProps) => {
  const { t } = useTranslation();

  const amountOfEmailMessagesSent = emailActionReport.amountOfEmailMessagesSent;
  const percentageOpened =
    amountOfEmailMessagesSent === 0
      ? 0
      : Math.round(
          (emailActionReport.amountOfEmailMessagesOpened / amountOfEmailMessagesSent) * 100 * 10
        ) / 10;
  const percentageReplied =
    amountOfEmailMessagesSent === 0
      ? 0
      : Math.round((emailActionReport.amountOfReplies / amountOfEmailMessagesSent) * 100 * 10) / 10;

  return (
    <Stack
      direction="row"
      gap={0.5}
    >
      <Tooltip
        enterDelay={200}
        enterNextDelay={200}
        title={t(tokens.automator.auto_mail.report.send)}
      >
        <Chip
          size="small"
          label={amountOfEmailMessagesSent}
          color="info"
          variant="outlined"
        />
      </Tooltip>

      <Tooltip
        enterDelay={200}
        enterNextDelay={200}
        title={
          t(tokens.automator.auto_mail.report.opened) +
          ': ' +
          emailActionReport.amountOfEmailMessagesOpened
        }
      >
        <Chip
          size="small"
          label={percentageOpened + '%'}
          color="info"
          variant="outlined"
        />
      </Tooltip>

      <Tooltip
        enterDelay={200}
        enterNextDelay={200}
        title={
          t(tokens.automator.auto_mail.report.replied) + ': ' + emailActionReport.amountOfReplies
        }
      >
        <Chip
          size="small"
          label={percentageReplied + '%'}
          color="info"
          variant="outlined"
        />
      </Tooltip>
    </Stack>
  );
};

import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { PRODUCT_PURCHASE_VOLUMES_PATH } from '../pdApiPaths';
import ProductPurchaseVolumes from '../../../domain/pd/ProductPurchaseVolumes';
import { ProductPurchaseVolumeQueryKey } from '../../queryKeys/ProductPurchaseVolumeQueryKey';

export const useFetchProductPurchaseVolume = (productId: number) => {
  const url = usePdApiAccountUrl(PRODUCT_PURCHASE_VOLUMES_PATH + `?productId=${productId}`);
  return useFetchData<ProductPurchaseVolumes>(url, ProductPurchaseVolumeQueryKey(productId));
};

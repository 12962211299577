import TableRow from '@mui/material/TableRow';
import OfferPerformance from '../../../../../../domain/pd/OfferPerformance';
import { ColorScale, PerformanceDateType, PerformanceTableCell } from '../PerformanceTableCell';
import OfferPerformancesMinMaxReport from '../../../../../../domain/pd/OfferPerformancesMinMaxReport';

interface OfferPerformanceMetricsTableRowProps {
  offerPerformance: OfferPerformance;
  offerPerformancesMinMaxReport: OfferPerformancesMinMaxReport;
}

export const OfferPerformanceMetricsTableRow = ({
  offerPerformance,
  offerPerformancesMinMaxReport,
}: OfferPerformanceMetricsTableRowProps) => {
  return (
    <TableRow>
      <PerformanceTableCell
        value={offerPerformance.profitInCents}
        type={PerformanceDateType.CURRENCY}
        minMaxReport={offerPerformancesMinMaxReport.profitInCents}
        colorScale={ColorScale.ORANGE}
      />
      <PerformanceTableCell
        value={offerPerformance.yearlyProfitInCents}
        type={PerformanceDateType.CURRENCY}
        minMaxReport={offerPerformancesMinMaxReport.yearlyProfitInCents}
        colorScale={ColorScale.ORANGE}
      />
      <PerformanceTableCell
        value={offerPerformance.yearlyReturnOnInvestmentDemandEfficiency}
        type={PerformanceDateType.PERCENTAGE}
        minMaxReport={offerPerformancesMinMaxReport.yearlyReturnOnInvestmentDemandEfficiency}
        colorScale={ColorScale.ORANGE}
      />
    </TableRow>
  );
};

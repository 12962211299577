import Stack from '@mui/material/Stack';
import MessageThreadView from './MessageThreadView';
import OrderResolutionCaseActions from './OrderResolutionCaseActions';
import { useFetchResolutionCase } from '../../../../api/automator/resolutions/useFetchResolutionCase';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { OrderResolutionCaseInfoCard } from './OrderResolutionCaseInfoCard';
import OfferResolutionCaseActions from './OfferResolutionCaseActions';
import { MessageTemplateSelectionCard } from './MessageTemplateSelectionCard';

interface ResolutionCaseViewProps {
  resolutionCaseId: number;
  hasOpenResend: boolean;
}

export const ResolutionCaseView = ({
  resolutionCaseId,
  hasOpenResend,
}: ResolutionCaseViewProps) => {
  const { data: resolutionCase, isLoading } = useFetchResolutionCase(resolutionCaseId);

  const openReplyDialog = useOpenDialog(DialogId.SEND_MESSAGE_THREAD_REPLY);

  if (isLoading || !resolutionCase) {
    return <>...loading</>;
  }

  return (
    <Stack
      direction="row"
      paddingTop={2}
      paddingLeft={4}
      justifyContent="space-between"
    >
      <Stack
        maxWidth={700}
        direction="column"
      >
        {resolutionCase.messageThread.orderId && (
          <OrderResolutionCaseActions
            resolutionCase={resolutionCase}
            hasOpenResend={hasOpenResend}
          />
        )}

        {!resolutionCase.messageThread.orderId && resolutionCase.messageThread.offerId && (
          <OfferResolutionCaseActions resolutionCase={resolutionCase} />
        )}

        <MessageThreadView resolutionCase={resolutionCase} />
      </Stack>

      <Stack
        paddingX={2}
        minWidth={350}
        gap={2}
        direction="column"
        alignItems="flex-end"
        paddingBottom={2}
      >
        <MessageTemplateSelectionCard
          phase={resolutionCase.messageThread.phase}
          onSelect={(messageTemplateId) => {
            openReplyDialog({
              messageThread: resolutionCase.messageThread,
              messageTemplateId: messageTemplateId,
              resolutionCaseId: resolutionCase.id,
            });
          }}
        />

        {resolutionCase.messageThread.orderId && (
          <OrderResolutionCaseInfoCard resolutionCase={resolutionCase} />
        )}
      </Stack>
    </Stack>
  );
};

import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { PickSessionListTable } from './components/PickSessionListTable';
import { useFetchPickSessionsReport } from '../../../api/automator/pickSessions/useFetchPickSessionsReport';
import { TableLoading } from '../../../components/TableLoading';
import { useParams } from 'react-router';
import { useFetchReducedPickSession } from '../../../api/automator/pickSessions/useFetchReducedPickSession';

const PickSessionsPage = () => {
  const { data: pickSessionsReport, isLoading: isLoadingPickSessionReport } =
    useFetchPickSessionsReport();

  const { pickSessionId } = useParams();

  const { data: pickSession, isLoading: isLoadingPickSession } = useFetchReducedPickSession(
    pickSessionId ? (pickSessionId as unknown as number) : undefined
  );

  if (isLoadingPickSessionReport || isLoadingPickSession) {
    return <TableLoading message={'...'} />;
  }

  return (
    <AccountPage title={tokens.automator.pick_sessions.pick_sessions}>
      <PickSessionListTable
        pickSession={pickSession}
        pickSessionsReport={pickSessionsReport!}
      />
    </AccountPage>
  );
};

export default PickSessionsPage;

import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { OfferPerformanceProperty } from '../../../../../../domain/pd/OfferPerformance';
import { tokens } from '../../../../../../locales/translationTokens';
import { ChartProperty, PerformancesDateLineChart } from './PerformancesDateLineChart';
import { useFetchOfferPerformances } from '../../../../../../api/pd/offerPerformances/useFetchOfferPerformances';
import getLatestDateByLatestDays from '../../../../../../helpers/getLatestDateByLatestDays';
import getYesterday from '../../../../../../helpers/getYesterday';
import { CountryCode } from '../../../../../../domain/automator/orders/CountryCode';
import { LatestDays } from '../LatestDaysDropdownSelect';
import { useFetchProductPerformances } from '../../../../../../api/pd/productPerformances/useFetchProductPerformances';
import Offer from '../../../../../../domain/pd/Offer';

interface OfferPerformancesChartProps {
  offer: Offer;
  countryCode: CountryCode | null;
  latestDays: LatestDays;
  rollingAverageDays: number;
}

export const OfferPerformancesChart = ({
  offer,
  countryCode,
  latestDays,
  rollingAverageDays,
}: OfferPerformancesChartProps) => {
  const { data: offerPerformances, isLoading: offerPerformancesLoading } =
    useFetchOfferPerformances(
      offer.id,
      getLatestDateByLatestDays(latestDays),
      getYesterday(),
      rollingAverageDays,
      countryCode
    );

  const { data: productPerformances, isLoading: productPerformancesLoading } =
    useFetchProductPerformances(
      offer.productId,
      getLatestDateByLatestDays(latestDays),
      getYesterday(),
      rollingAverageDays
    );

  const { t } = useTranslation();

  if (
    !offerPerformances ||
    offerPerformancesLoading ||
    productPerformancesLoading ||
    !productPerformances
  ) {
    return <Box>Loading...</Box>;
  }

  const daysIndicator = rollingAverageDays + 'd';

  const availableChartProperties: ChartProperty[] = [
    {
      label: t(tokens.pd.performance.organic_non_organic_table.visits) + daysIndicator,
      property: OfferPerformanceProperty.VISITS,
    },
    {
      label: t(tokens.pd.performance.organic_non_organic_table.articles) + daysIndicator,
      property: OfferPerformanceProperty.ARTICLES_SOLD,
    },
    {
      label: t(tokens.pd.performance.organic_non_organic_table.conversion_rate) + daysIndicator,
      property: OfferPerformanceProperty.CONVERSION_RATE,
    },
    {
      label:
        t(tokens.pd.performance.offer_performance_search_metrics_table.weighted_rank) +
        daysIndicator,
      property: 'weighted_rank',
    },
  ];

  const performanceData = offerPerformances.offerPerformances.map((offerPerformance) => ({
    date: offerPerformance.date,
    [OfferPerformanceProperty.VISITS]: offerPerformance[OfferPerformanceProperty.VISITS],
    [OfferPerformanceProperty.ARTICLES_SOLD]:
      offerPerformance[OfferPerformanceProperty.ARTICLES_SOLD],
    [OfferPerformanceProperty.CONVERSION_RATE]:
      offerPerformance[OfferPerformanceProperty.CONVERSION_RATE],
    weighted_rank:
      productPerformances.productPerformances.find(
        (productPerformance) => productPerformance.date === offerPerformance.date
      )?.weightedRank || null,
  }));

  return (
    <PerformancesDateLineChart
      label={t(tokens.pd.performance.offer_performances_table.offer_performances)}
      chartProperties={availableChartProperties}
      data={performanceData}
    />
  );
};

import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { MANUAL_SEASONAL_EVENTS } from '../pdApiPaths';
import { getDateStringFromDate } from '../../../helpers/getDateStringFromDate';
import ManualSeasonalEvents from '../../../domain/pd/ManualSeasonalEvents';
import { ManualSeasonalEventsQueryKey } from '../../queryKeys/ManualSeasonalEventsQueryKey';

export const useFetchManualSeasonalEvents = (startDate: Date, endDate: Date) => {
  const url = usePdApiAccountUrl(
    MANUAL_SEASONAL_EVENTS +
      `?startDate=${getDateStringFromDate(startDate)}` +
      `&endDate=${getDateStringFromDate(endDate)}`
  );
  return useFetchData<ManualSeasonalEvents>(url, ManualSeasonalEventsQueryKey(startDate, endDate));
};

import { CompetitorTableCellData } from './CompetitorProductPerformancesTable';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ProductThumbnail } from '../../../../../automator/orders/components/ProductThumbnail';
import { CompetitorProductPerformanceTableCellContent } from './CompetitorProductPerformanceTableCellContent';

interface CompetitorProductLegendItemProps {
  cells: CompetitorTableCellData[];
  onHover: (productId: number | null) => void;
}

export const CompetitorProductLegendItem = ({
  cells,
  onHover,
}: CompetitorProductLegendItemProps) => {
  const cellsSorted = cells.sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });

  return (
    <Stack
      direction="row"
      gap={2}
    >
      {cellsSorted.length >= 1 ? (
        cellsSorted.map((cell) => (
          <Stack
            key={cell.productId}
            direction="row"
            alignItems="center"
            gap={1}
          >
            <div title={cell.ean}>
              <ProductThumbnail
                height={45}
                thumbnailUrl={cell.thumbnailUrl}
                onClick={() => window.open(cell.productPageUrl, '_blank')}
              />
            </div>
            <Box
              width={45}
              height={25}
              padding={1}
              border={cell.isHovered ? 2 : 0}
              borderRadius={1.2}
              alignContent="center"
              style={{
                backgroundColor: cell.color,
              }}
              onMouseEnter={() => onHover(cell.productId)}
              onMouseLeave={() => onHover(null)}
            >
              <CompetitorProductPerformanceTableCellContent
                fontSize={12}
                cell={cell}
              />
            </Box>
          </Stack>
        ))
      ) : (
        <Box
          height={45}
          onMouseEnter={() => onHover(null)}
          onMouseLeave={() => onHover(null)}
        />
      )}
    </Stack>
  );
};

import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { CAMPAIGNS_PATH } from '../pdApiPaths';
import Campaigns from '../../../domain/pd/Campaigns';
import { CampaignsQueryKey } from '../../queryKeys/CampaignsQueryKey';
import { getDateStringFromDate } from '../../../helpers/getDateStringFromDate';

export const useFetchCampaigns = (offerId: number, startDate: Date) => {
  const url = usePdApiAccountUrl(
    CAMPAIGNS_PATH + `?offerId=${offerId}&startDate=${getDateStringFromDate(startDate)}`
  );
  return useFetchData<Campaigns>(url, CampaignsQueryKey(offerId, startDate));
};

import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../locales/translationTokens';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

interface OrganicNonOrganicOptionsButtonProps {
  option: OrganicNonOrganicOption;
  setOption: (option: OrganicNonOrganicOption) => void;
}

export enum OrganicNonOrganicOption {
  ALL = 'ALL',
  ORGANIC = 'ORGANIC',
  NONE_ORGANIC = 'NONE_ORGANIC',
}

export const OrganicNonOrganicOptionsButton = ({
  option,
  setOption,
}: OrganicNonOrganicOptionsButtonProps) => {
  const { t } = useTranslation();

  return (
    <FormControl sx={{ minWidth: 120 }}>
      <Select
        value={option}
        onChange={(event) => setOption(event.target.value as OrganicNonOrganicOption)}
        size="small"
      >
        <MenuItem value={OrganicNonOrganicOption.ALL}>
          {t(tokens.pd.performance.organic_non_organic_table.option.all)}
        </MenuItem>
        <MenuItem value={OrganicNonOrganicOption.ORGANIC}>
          {t(tokens.pd.performance.organic_non_organic_table.option.organic)}
        </MenuItem>
        <MenuItem value={OrganicNonOrganicOption.NONE_ORGANIC}>
          {t(tokens.pd.performance.organic_non_organic_table.option.non_organic)}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

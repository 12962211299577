import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useFetchProductGroups } from '../../../../api/automator/products/useFetchProductGroups';
import { ProductGroupChip } from './ProductGroupChip';
import Chip from '@mui/material/Chip';
import { useState } from 'react';
import { useFetchRetailers } from '../../../../api/automator/retailers/useFetchRetailers';
import RetailerChip from './RetailerChip';
import { DropdownSelect } from '../../shared/DropdownSelect';
import { DeliveryCodeDropdown } from '../../orders/components/DeliveryCodeDropdown';
import { DeliveryCode } from '../../../../domain/pd/DeliveryCode';
import { LabelTypeToggleButton } from '../../orders/components/LabelTypeToggleButton';
import { LabelType } from '../../../../domain/automator/products/LabelType';
import ActionButton from '../../../../components/ActionButton';
import { useUpdateOffers } from '../../../../api/automator/offers/useUpdateOffers';
import { toast } from 'react-hot-toast';
import AutomatorProduct from '../../../../domain/automator/products/AutomatorProduct';
import { useUpdateLabelTypeRules } from '../../../../api/automator/labelTypeRules/useUpdateLabelTypeRules';

interface UpdateProductsFormProps {
  products: AutomatorProduct[];
  onSave?: () => void;
}

enum UpdateType {
  DELIVERY_CODE = 'DELIVERY_CODE',
  LABEL_TYPE = 'LABEL_TYPE',
}

export const UpdateProductsForm = ({ onSave, products }: UpdateProductsFormProps) => {
  const { t } = useTranslation();

  const [selectedGroupIds, setSelectedGroupIds] = useState<number[]>([]);
  const [selectedRetailerIds, setSelectedRetailerIds] = useState<number[]>([]);
  const [updateType, setUpdateType] = useState<UpdateType>(UpdateType.DELIVERY_CODE);
  const [deliveryCode, setDeliveryCode] = useState<DeliveryCode | null>(null);
  const [labelType, setLabelType] = useState<LabelType | null>(null);

  const { data: productsGroups, isLoading: isLoadingProductGroups } = useFetchProductGroups();

  const { data: retailers, isLoading: isLoadingRetailers } = useFetchRetailers();

  const { mutate: updateOffers, isLoading: isLoadingUpdateOffers } = useUpdateOffers();

  const { mutate: updateLabelTypeRules, isLoading: isLoadingUpdateLabelTypeRules } =
    useUpdateLabelTypeRules();

  if (isLoadingProductGroups || isLoadingRetailers) {
    return <div>Loading...</div>;
  }

  const onSelectGroup = (groupId: number) => {
    if (selectedGroupIds.includes(groupId)) {
      setSelectedGroupIds(selectedGroupIds.filter((id) => id !== groupId));
    } else {
      setSelectedGroupIds([...selectedGroupIds, groupId]);
    }
  };

  const onSelectAllGroups = () => {
    if (selectedGroupIds.length == 0) {
      setSelectedGroupIds(productsGroups?.productGroups.map((group) => group.id) ?? []);
    } else {
      setSelectedGroupIds([]);
    }
  };

  const onSelectRetailer = (retailerId: number) => {
    if (selectedRetailerIds.includes(retailerId)) {
      setSelectedRetailerIds(selectedRetailerIds.filter((id) => id !== retailerId));
    } else {
      setSelectedRetailerIds([...selectedRetailerIds, retailerId]);
    }
  };

  const onSelectAllRetailers = () => {
    if (selectedRetailerIds.length == 0) {
      setSelectedRetailerIds(retailers?.map((retailer) => retailer.id) ?? []);
    } else {
      setSelectedRetailerIds([]);
    }
  };

  const onSelectUpdateType = (updateType: UpdateType) => {
    setUpdateType(updateType);
    setLabelType(null);
    setDeliveryCode(null);
  };

  const getSelectedProducts = () => {
    return products.filter((product) => {
      const productGroupIds = product.productGroups.map((group) => group.id);
      return selectedGroupIds.some((id) => productGroupIds.includes(id));
    });
  };

  const doUpdateDeliveryCodes = () => {
    const products = getSelectedProducts();

    const offerIds = products
      .flatMap((product) => product.offers)
      .filter((offer) => {
        return selectedRetailerIds.includes(offer.retailer.id);
      })
      .map((offer) => offer.id);

    const data = {
      offer_ids: offerIds,
      delivery_code: deliveryCode!,
      bundle_prices: null,
      internal_reference: null,
    };

    updateOffers(data, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.products.dialogs.update_products.delivery_code_updated));
        onSave?.();
      },
    });
  };

  const doUpdateLabelTypeRules = () => {
    const products = getSelectedProducts();

    const labelTypeRuleIds = products.map((product) => product.labelTypeRule.id);

    const data = {
      label_type_rule_ids: labelTypeRuleIds,
      label_type: labelType!,
      max_items_for_mailbox: null,
    };

    updateLabelTypeRules(data, {
      onSuccess: async () => {
        toast.success(
          t(tokens.automator.products.dialogs.update_products.label_type_rules_updated)
        );
        onSave?.();
      },
    });
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      gap={4}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          width={150}
          variant="h6"
        >
          {t(tokens.automator.products.dialogs.update_products.groups)}
        </Typography>

        <Stack
          direction="row"
          gap={3.5}
        >
          <Stack
            direction="row"
            gap={1}
          >
            {productsGroups?.productGroups.map((group) => (
              <ProductGroupChip
                key={group.id}
                productGroup={group}
                onClick={() => onSelectGroup(group.id)}
                isSelected={selectedGroupIds.includes(group.id)}
              />
            ))}
          </Stack>

          <Chip
            label={t(tokens.automator.products.dialogs.update_products.all)}
            variant="outlined"
            onClick={() => onSelectAllGroups()}
          />
        </Stack>
      </Stack>

      {updateType == UpdateType.DELIVERY_CODE && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            width={150}
            variant="h6"
          >
            {t(tokens.automator.products.dialogs.update_products.stores)}
          </Typography>

          <Stack
            direction="row"
            gap={3.5}
          >
            <Stack
              direction="row"
              gap={1}
            >
              {retailers?.map((retailer) => (
                <RetailerChip
                  key={retailer.id}
                  retailer={retailer}
                  onClick={onSelectRetailer}
                  isSelected={selectedRetailerIds.includes(retailer.id)}
                />
              ))}
            </Stack>

            <Chip
              label={t(tokens.automator.products.dialogs.update_products.all)}
              variant="outlined"
              onClick={() => onSelectAllRetailers()}
            />
          </Stack>
        </Stack>
      )}

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          width={150}
          variant="h6"
        >
          {t(tokens.automator.products.dialogs.update_products.change)}
        </Typography>

        <Stack
          direction="row"
          gap={3.5}
        >
          <DropdownSelect
            options={[
              {
                key: UpdateType.DELIVERY_CODE,
                value: UpdateType.DELIVERY_CODE,
              },
              {
                key: UpdateType.LABEL_TYPE,
                value: UpdateType.LABEL_TYPE,
              },
            ]}
            onSelect={(key) => {
              onSelectUpdateType(key as UpdateType);
            }}
            selected={updateType}
            label={tokens.pd.search_term_dropdown_select.label}
          />

          {updateType === UpdateType.DELIVERY_CODE && (
            <DeliveryCodeDropdown
              selected={deliveryCode}
              onSelect={(deliveryCode) => setDeliveryCode(deliveryCode)}
            />
          )}

          {updateType === UpdateType.LABEL_TYPE && (
            <LabelTypeToggleButton
              value={labelType}
              onChange={(labelType) => setLabelType(labelType)}
            />
          )}
        </Stack>
      </Stack>

      {updateType === UpdateType.DELIVERY_CODE && (
        <ActionButton
          onClick={() => {
            doUpdateDeliveryCodes();
          }}
          isLoading={isLoadingUpdateOffers}
          label={t(tokens.automator.products.dialogs.update_products.update_products)}
          fullWidth
          isDisabled={
            selectedGroupIds.length === 0 ||
            selectedRetailerIds.length === 0 ||
            deliveryCode === null
          }
        />
      )}

      {updateType === UpdateType.LABEL_TYPE && (
        <ActionButton
          onClick={() => {
            doUpdateLabelTypeRules();
          }}
          isLoading={isLoadingUpdateLabelTypeRules}
          label={t(tokens.automator.products.dialogs.update_products.update_products)}
          fullWidth
          isDisabled={selectedGroupIds.length === 0 || labelType === null}
        />
      )}
    </Stack>
  );
};

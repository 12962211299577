import { TableTabs, TabOption } from '../../../../components/TableTabs';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';

interface OrderSummaryTabsProps {
  currentTab: OrderSummaryTab;
  setCurrentTab: (currentTab: OrderSummaryTab) => void;
}

export enum OrderSummaryTab {
  ORDER_EVENTS = 'ORDER_EVENTS',
  MESSAGE_THREADS = 'MESSAGE_THREADS',
}

const OrderSummaryTabs = ({ currentTab, setCurrentTab }: OrderSummaryTabsProps) => {
  const { t } = useTranslation();

  const tabOptions: TabOption<OrderSummaryTab>[] = [
    {
      label: t(tokens.automator.order_summary.order_events),
      value: OrderSummaryTab.ORDER_EVENTS,
    },
    {
      label: t(tokens.automator.order_summary.message_threads),
      value: OrderSummaryTab.MESSAGE_THREADS,
    },
  ];

  return (
    <TableTabs
      value={currentTab}
      onChange={(tab: OrderSummaryTab) => {
        setCurrentTab(tab);
      }}
      tabOptions={tabOptions}
    />
  );
};

export default OrderSummaryTabs;

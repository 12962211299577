import ActionButton from '../../../../components/ActionButton';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { toast } from 'react-hot-toast';
import { useUploadCustomerInvoice } from '../../../../api/automator/customerInvoiceRequests/useUploadCustomerInvoice';

interface UploadCustomerInvoiceButtonProps {
  customerInvoiceId: number;
}

export const UploadCustomerInvoiceButton = ({
  customerInvoiceId,
}: UploadCustomerInvoiceButtonProps) => {
  const { t } = useTranslation();

  const { mutate: uploadCustomerInvoice, isLoading: isLoadingCustomerInvoice } =
    useUploadCustomerInvoice(customerInvoiceId);

  const onUpload = () => {
    uploadCustomerInvoice(undefined, {
      onSuccess: () => {
        toast.success(t(tokens.automator.customer_invoice_requests.invoice_uploaded));
      },
    });
  };

  return (
    <ActionButton
      label={t(tokens.automator.customer_invoice_requests.upload_invoice)}
      onClick={() => onUpload()}
      isLoading={isLoadingCustomerInvoice}
      variant="contained"
    />
  );
};

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import PickSessionProgressPill from './PickSessionProgressPill';
import SvgIcon from '@mui/material/SvgIcon';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PickSessionProgress from '../../../../domain/automator/pickSessions/PickSessionProgress';
import { PickSessionStatus } from '../../../../domain/automator/pickSessions/PickSessionStatus';
import { PickSessionPhase } from '../../../../domain/automator/pickSessions/PickSessionPhase';
import Chip from '@mui/material/Chip';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';

interface PickSessionProgressViewProps {
  progress: PickSessionProgress;
}

export const PickSessionProgressView = ({ progress }: PickSessionProgressViewProps) => {
  const { t } = useTranslation();

  const renderStatus = (progress: PickSessionProgress) => {
    if (progress.status == PickSessionStatus.FINISHED && progress.failures.length == 0) {
      return <> </>;

      // return (
      //   <SvgIcon
      //     fontSize="large"
      //     htmlColor="ForestGreen"
      //   >
      //     <CheckCircleOutlineIcon />
      //   </SvgIcon>
      // );
    } else if (progress.status == PickSessionStatus.FINISHED && progress.failures.length > 0) {
      return (
        <SvgIcon
          fontSize="large"
          htmlColor="Orange"
        >
          <WarningAmberIcon />
        </SvgIcon>
      );
    } else {
      return <PickSessionProgressPill progress={progress} />;
    }
  };

  return (
    <Stack
      direction="row"
      spacing={4}
    >
      <Stack
        direction="row"
        gap={2}
      >
        {progress.approvalPending > 0 && (
          <Chip
            label={
              t(tokens.automator.pick_sessions.phase.approval_pending) +
              ' ' +
              progress.approvalPending +
              '/' +
              progress.total
            }
            color="info"
          />
        )}

        {progress.shipped > 0 && (
          <Chip
            label={
              t(tokens.automator.pick_sessions.phase.shipped) +
              ' ' +
              progress.shipped +
              '/' +
              progress.total
            }
            color="success"
          />
        )}

        {progress.phase != null && progress.phase != PickSessionPhase.APPROVAL && (
          <CircularProgress />
        )}
      </Stack>

      <Box alignContent="center">{renderStatus(progress)}</Box>
    </Stack>
  );
};

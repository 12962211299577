import { EndpointQueryKey } from './EndpointQueryKey';
import { getDateStringFromDate } from '../../helpers/getDateStringFromDate';

export const ManualPerformanceEventsQueryKey = (
  offerId: number,
  startDate: Date,
  endDate: Date
) => {
  return (
    EndpointQueryKey.TIMELINE_NOTES +
    '_' +
    offerId +
    '_' +
    getDateStringFromDate(startDate) +
    '_' +
    getDateStringFromDate(endDate)
  );
};

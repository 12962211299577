export enum ReturnDetailedReason {
  PRODUCT_DAMAGED = 'PRODUCT_DAMAGED',
  PRODUCT_DEFECTIVE = 'PRODUCT_DEFECTIVE',
  PRODUCT_INCOMPLETE = 'PRODUCT_INCOMPLETE',
  QUALITY_NOT_AS_EXPECTED = 'QUALITY_NOT_AS_EXPECTED',
  OTHER = 'OTHER',
  DELIVERY_TOO_LATE = 'DELIVERY_TOO_LATE',
  PRODUCT_INFORMATION_UNCLEAR = 'PRODUCT_INFORMATION_UNCLEAR',
  PRODUCT_IMAGE_UNCLEAR = 'PRODUCT_IMAGE_UNCLEAR',
  TOO_BIG = 'TOO_BIG',
  TOO_SMALL = 'TOO_SMALL',
  RECEIVED_PRODUCT_AS_GIFT = 'RECEIVED_PRODUCT_AS_GIFT',
  CHANGED_MIND = 'CHANGED_MIND',
  UNKNOWN = 'UNKNOWN',
}

import { PROPOSED_ACTIONS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import ProposedActionsReport from '../../../domain/automator/alerts/ProposedActionsReport';
import { ProposedActionsReportQueryKey } from '../../queryKeys/ProposedActionsReportQueryKey';

export const useFetchProposedActionsReport = () => {
  const url = useAutomatorApiAccountUrl(PROPOSED_ACTIONS_PATH + '/report');
  return useFetchData<ProposedActionsReport>(url, ProposedActionsReportQueryKey());
};

import { useTranslation } from 'react-i18next';
import { TableTabs, TabOption } from '../../../../components/TableTabs';
import { tokens } from '../../../../locales/translationTokens';

interface CustomerInvoiceTabsProps {
  selectedTab: CustomerInvoicesPageTab;
  setTab: (tab: CustomerInvoicesPageTab) => void;
}

const CustomerInvoiceTabs = ({ selectedTab, setTab }: CustomerInvoiceTabsProps) => {
  const { t } = useTranslation();

  const tabOptions: TabOption<CustomerInvoicesPageTab>[] = [];
  tabOptions.push(
    ...[
      {
        label: t(tokens.automator.customer_invoice_requests.customer_invoice_requests),
        value: CustomerInvoicesPageTab.CUSTOMER_INVOICE_REQUESTS,
      },
      {
        label: t(tokens.automator.customer_invoices_bundles.customer_invoices_bundles),
        value: CustomerInvoicesPageTab.CUSTOMER_INVOICES_BUNDLES,
      },
      {
        label: t(tokens.automator.customer_invoice_settings.customer_invoice_settings),
        value: CustomerInvoicesPageTab.CUSTOMER_INVOICE_SETTINGS,
      },
    ]
  );

  return (
    <TableTabs
      value={selectedTab}
      onChange={(tab: CustomerInvoicesPageTab) => {
        setTab(tab);
      }}
      tabOptions={tabOptions}
    />
  );
};

export enum CustomerInvoicesPageTab {
  CUSTOMER_INVOICE_REQUESTS = 'CUSTOMER_INVOICE_REQUESTS',
  CUSTOMER_INVOICES_BUNDLES = 'CUSTOMER_INVOICES_BUNDLES',
  CUSTOMER_INVOICE_SETTINGS = 'CUSTOMER_INVOICE_SETTINGS',
}

export default CustomerInvoiceTabs;

import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { DeliveryCode } from '../../../../domain/pd/DeliveryCode';
import Offer from '../../../../domain/automator/offers/Offer';
import { useCallback } from 'react';
import { debounce } from '@mui/material';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../locales/translationTokens';
import RetailerLooksIcon from '../../retailers/components/RetailerLooksIcon';
import { UpdateOfferPriceStack } from '../../orders/components/UpdateOfferPriceStack';
import { useUpdateOffers } from '../../../../api/automator/offers/useUpdateOffers';
import { UpdateDeliveryCodeStack } from './UpdateDeliveryCodeStack';

interface ProductTableRowOfferProps {
  offer: Offer;
}

export const ProductTableRowOffer = ({ offer }: ProductTableRowOfferProps) => {
  const { t } = useTranslation();

  const { mutate: updateOffers, isLoading } = useUpdateOffers();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUnitPrice = useCallback(
    debounce((unitPrice: number) => {
      if (
        unitPrice !==
        offer.bundlePrices.find((bundlePrice) => bundlePrice.quantity === 1)?.unitPriceInCents
      ) {
        doUpdateOffer(null, unitPrice);
      }
    }, 500),
    []
  );

  const doUpdateOffer = (deliveryCode: DeliveryCode | null, unitPrice: number | null) => {
    const newBundlePrices = (unitPrice: number) => {
      const bundlePrices = offer.bundlePrices.filter((bundlePrice) => bundlePrice.quantity !== 1);
      bundlePrices.push({
        quantity: 1,
        unitPriceInCents: unitPrice,
      });
      return bundlePrices;
    };

    const data = {
      offer_ids: [offer.id],
      delivery_code: deliveryCode,
      bundle_prices: unitPrice ? newBundlePrices(unitPrice) : null,
      internal_reference: null,
    };

    updateOffers(data, {
      onSuccess: () => {
        toast.success(t(tokens.automator.products.offer_updated));
      },
    });
  };

  if (isLoading) {
    return <Stack>...</Stack>;
  }

  return (
    <Stack
      key={offer.id}
      gap={3}
      direction="row"
      alignItems="center"
    >
      <RetailerLooksIcon retailerId={offer.retailer.id} />

      <Stack
        direction="row"
        gap={2}
      >
        <UpdateOfferPriceStack
          unitPrice={
            offer.bundlePrices.find((bundlePrice) => bundlePrice.quantity === 1)
              ?.unitPriceInCents ?? 0
          }
          onUpdateUnitPrice={(unitPrice) => {
            debouncedUnitPrice(unitPrice);
          }}
        />
        <UpdateDeliveryCodeStack
          value={offer.fulfillment.deliveryCode}
          onChange={(deliveryCode) => doUpdateOffer(deliveryCode, null)}
        />
      </Stack>
    </Stack>
  );
};

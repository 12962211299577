import { EVENT_RULE_ACTIONS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { EventRulesQueryKey } from '../../queryKeys/EventRulesQueryKey';

export const useArchiveEventRuleAction = (eventRuleActionId: number) => {
  const url = useAutomatorApiAccountUrl(
    EVENT_RULE_ACTIONS_PATH + `/${eventRuleActionId}` + '/archive'
  );
  return useAuthorizedPost<undefined, undefined>(url, [EventRulesQueryKey()]);
};

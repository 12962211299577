import { ReactElement, ReactNode, useState } from 'react';
import { Card, Collapse, Stack, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface CollapsibleCardProps {
  title: string;
  children: ReactNode;
  isOpen: boolean;
  titleComponent?: ReactElement; // This optional prop can receive a ReactElement
}

const CollapsibleCard = ({ title, children, isOpen, titleComponent }: CollapsibleCardProps) => {
  const [isCollapseOpen, setIsCollapseOpen] = useState(isOpen);

  const toggleCollapse = () => {
    setIsCollapseOpen((prevOpen) => !prevOpen);
  };

  return (
    <Card
      variant="outlined"
      style={{ width: '100%', cursor: 'pointer' }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        onClick={toggleCollapse}
      >
        <Stack
          direction="row"
          gap={0.25}
          alignItems="center"
        >
          <Typography variant={'h6'}>{title}</Typography>
          {titleComponent}
        </Stack>
        {isCollapseOpen ? <ExpandLess /> : <ExpandMore />}
      </Stack>

      <Collapse in={isCollapseOpen}>
        <Stack
          paddingTop={2}
          paddingBottom={2}
          paddingX={2}
          direction="column"
        >
          {children}
        </Stack>
      </Collapse>
    </Card>
  );
};

export default CollapsibleCard;

import Box from '@mui/material/Box';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { ChartPropertyButtons } from './ChartPropertyButtons';
import { DateLineChart, DateLineChartSeries } from './DateLineChart';

interface PerformancesDateLineChartProps {
  label: string;
  chartProperties: ChartProperty[];
  data: PerformanceDataLineChartData[];
}

export interface ChartProperty {
  label: string;
  property: string;
}

interface PerformanceDataLineChartData {
  date: string;
}

export const PerformancesDateLineChart = ({
  label,
  chartProperties,
  data,
}: PerformancesDateLineChartProps) => {
  const [selectedChartProperties, setSelectedChartProperties] = useState<ChartProperty[]>([]);

  const { t } = useTranslation();

  const onSelectChartProperty = (selectedProperty: ChartProperty) => {
    if (
      selectedChartProperties.find((property) => property.property == selectedProperty.property)
    ) {
      setSelectedChartProperties(
        selectedChartProperties.filter((property) => property.property != selectedProperty.property)
      );
    } else {
      setSelectedChartProperties([...selectedChartProperties, selectedProperty]);
    }
  };

  const aggregatesSorted = data.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  const dates = aggregatesSorted
    .map((aggregate) => new Date(aggregate.date))
    .map((date) => date.toISOString().split('T')[0]);

  const chartSeries: DateLineChartSeries[] = selectedChartProperties.map((property) => {
    return {
      name: t(property.label),
      data: aggregatesSorted.map((aggregate) => {
        // @ts-ignore
        return aggregate[property.property];
      }),
    };
  });

  return (
    <Stack
      direction="row"
      gap={1}
    >
      <ChartPropertyButtons
        availableProperties={chartProperties}
        selectedProperties={selectedChartProperties}
        onSelect={onSelectChartProperty}
      />

      <Box sx={{ flexGrow: 1 }}>
        <DateLineChart
          header={t(label)}
          categories={dates}
          chartSeries={chartSeries}
        />
      </Box>
    </Stack>
  );
};

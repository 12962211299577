import { useTranslation } from 'react-i18next';
import { TableTabs, TabOption } from '../../../../components/TableTabs';
import { tokens } from '../../../../locales/translationTokens';
import { GetProposedActionsStatus } from '../../../../domain/automator/alerts/GetProposedActionsStatus';

interface AlertTabsProps {
  currentTab: GetProposedActionsStatus;
  setCurrentTab: (tab: GetProposedActionsStatus) => void;
}

const AlertTabs = ({ currentTab, setCurrentTab }: AlertTabsProps) => {
  const { t } = useTranslation();

  const tabOptions: TabOption<GetProposedActionsStatus>[] = [];
  tabOptions.push(
    ...[
      {
        label: t(tokens.automator.alerts.status.open),
        value: GetProposedActionsStatus.OPEN,
      },
      {
        label: t(tokens.automator.alerts.status.executed),
        value: GetProposedActionsStatus.EXECUTED,
      },
    ]
  );

  return (
    <TableTabs
      value={currentTab}
      onChange={(tab: GetProposedActionsStatus) => {
        setCurrentTab(tab);
      }}
      tabOptions={tabOptions}
    />
  );
};

export default AlertTabs;

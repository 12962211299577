import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../../../locales/translationTokens';
import ActionButton from '../../../../../../components/ActionButton';
import { useCreateProductReorderInterval } from '../../../../../../api/pd/offerPerformances/useCreateProductReorderInterval';

interface CreateProductReorderIntervalFormProps {
  onClick?: () => void;
  productId: number;
}

export const CreateProductReorderIntervalForm = ({
  onClick,
  productId,
}: CreateProductReorderIntervalFormProps) => {
  const [amountOfDays, setAmountOfDays] = useState(0);

  const { t } = useTranslation();

  const { mutate: create, isLoading } = useCreateProductReorderInterval();

  const onCreate = () => {
    create(
      {
        product_id: productId,
        amount_of_days: amountOfDays,
        date: '2020-01-01',
      },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(
            t(tokens.pd.performance.create_product_reorder_interval_form.reorder_interval_created)
          );
        },
      }
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <TextField
        id="note-field"
        label={t(tokens.pd.performance.create_product_reorder_interval_form.reorder_interval)}
        variant="filled"
        fullWidth
        style={{ marginBottom: '1em' }}
        value={amountOfDays}
        onChange={(e) => setAmountOfDays(Number(e.target.value))}
      />

      <ActionButton
        label={t(
          tokens.pd.performance.create_product_reorder_interval_form.create_reorder_interval
        )}
        onClick={() => onCreate()}
        isLoading={isLoading}
        variant="contained"
        color="primary"
        size="small"
      />
    </form>
  );
};

import Chip from '@mui/material/Chip';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import EventTrigger from '../../../../domain/automator/eventRules/EventTrigger';

interface ReimbursementOriginChipProps {
  reimbursementRequestId: number | null;
  eventTrigger: EventTrigger | null;
}

export const ReimbursementOriginChip = ({
  reimbursementRequestId,
  eventTrigger,
}: ReimbursementOriginChipProps) => {
  const { t } = useTranslation();

  const determineLabel = () => {
    if (reimbursementRequestId && eventTrigger) {
      return t(tokens.automator.reimbursements.origin.alert);
    } else if (!reimbursementRequestId) {
      return t(tokens.automator.reimbursements.origin.specification);
    } else {
      return t(tokens.automator.reimbursements.origin.self);
    }
  };

  return (
    <Chip
      size="small"
      label={determineLabel()}
      variant="outlined"
      color={'info'}
    />
  );
};

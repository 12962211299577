import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { PerformanceHeaderTableCell } from '../PerformanceHeaderTableCell';
import { tokens } from '../../../../../../locales/translationTokens';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import { LatestDays } from '../LatestDaysDropdownSelect';
import { CountryCode } from '../../../../../../domain/automator/orders/CountryCode';
import getLatestDateByLatestDays from '../../../../../../helpers/getLatestDateByLatestDays';
import getYesterday from '../../../../../../helpers/getYesterday';
import { generateDateRangeList } from '../services/generateDateList';
import { CompetitorProductPerformanceTableRow } from './CompetitorProductPerformanceTableRow';
import { getDateStringFromDate } from '../../../../../../helpers/getDateStringFromDate';
import { useMemo, useState } from 'react';
import { TableLoading } from '../../../../../../components/TableLoading';
import { useFetchProductRanks } from '../../../../../../api/pd/productPerformances/useFetchProductRanks';
import { useFetchPerformanceProducts } from '../../../../../../api/pd/products/useFetchPerformanceProducts';
import { SellerType } from '../../../../../../domain/pd/SellerType';
import ProductRank from '../../../../../../domain/pd/ProductRank';
import uniqueBy from '../../../../helpers/uniqueBy';
import ToggleButton from '@mui/lab/ToggleButton';
import ToggleButtonGroup from '@mui/lab/ToggleButtonGroup';

enum PositionType {
  PRODUCT_RANK = 'PRODUCT_RANKS',
  IMPRESSION_POSITION = 'IMPRESSION_POSITION',
}

interface CompetitorProductPerformancesTableProps {
  categoryId: number;
  latestDays: LatestDays;
  countryCode: CountryCode;
}

export const CompetitorProductPerformancesTable = ({
  categoryId,
  countryCode,
  latestDays,
}: CompetitorProductPerformancesTableProps) => {
  const [positionType, setPositionType] = useState<PositionType>(PositionType.PRODUCT_RANK);

  const startDate = getLatestDateByLatestDays(latestDays);
  const endDate = getYesterday();

  const validPositions = 30;

  const { data: productRanks, isLoading: productRanksLoading } = useFetchProductRanks(
    startDate,
    endDate,
    undefined,
    categoryId,
    undefined,
    countryCode
  );

  const { data: products, isLoading: productIsLoading } = useFetchPerformanceProducts(categoryId);

  const [hoveredProductId, setHoveredProductId] = useState<number | null>(null);

  const dateImpressionRankMap = useMemo(() => {
    if (positionType == PositionType.PRODUCT_RANK || productRanksLoading) {
      const dateImpressionRankMap: Map<string, number[]> = new Map();
      return dateImpressionRankMap;
    }

    return createDateImpressionRankMap(startDate, endDate, productRanks!.productRanks);
  }, [productRanks, positionType]);

  const productIdToInitialRankMap = useMemo(() => {
    return createProductIdToInitialPositionMap(
      productRanksLoading ? [] : productRanks!.productRanks,
      positionType,
      dateImpressionRankMap
    );
  }, [productRanks, positionType, dateImpressionRankMap]);

  const productIdToColorMap = useMemo(() => {
    return createProductIdToColorMap(productIdToInitialRankMap);
  }, [productIdToInitialRankMap]);

  if (productRanksLoading || productIsLoading) {
    return <TableLoading message={'Loading...'} />;
  }

  const dateList = generateDateRangeList(startDate, endDate, false);

  const positions = Array.from({ length: validPositions }, (_, i) => i + 1);

  const validProductRanks = productRanks!.productRanks; // getValidProductRanks(productRanks!.productRanks, validPositions);

  const competitorProductIds = products!.products
    .filter((product) => product.sellerType == SellerType.COMPETITOR)
    .map((product) => product.id);

  const competitorProductRanks = validProductRanks.filter((productRank) =>
    competitorProductIds.includes(productRank.productId)
  );

  const validProductRanksDescending = [...competitorProductRanks].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  const validProductRanksAscending = [...competitorProductRanks].sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );

  const newComerProductIds = uniqueBy(
    // this well get us all the earliest dates of the products
    validProductRanksDescending,
    (productRank) => productRank.productId
  )
    .filter((productRank) => productRank.date != validProductRanksDescending[0].date)
    .map((productRank) => productRank.productId);

  const dropoutProductIds = uniqueBy(
    validProductRanksAscending,
    (productRank) => productRank.productId
  )
    .filter((productRank) => productRank.date != validProductRanksAscending[0].date)
    .map((productRank) => productRank.productId);

  const cells = productRanks!.productRanks.map((productRank) => {
    const productId = productRank.productId;
    const product = products!.products.find((product) => product.id == productId);
    return {
      productId: productId,
      ean: product ? product.ean : 'UNKNOWN',
      isOwnProduct: product && product.sellerType == SellerType.SELF,
      thumbnailUrl: product?.thumbnailUrl,
      productPageUrl: product?.productPageUrl,
      initialPosition: productIdToInitialRankMap.get(productId),
      position: determinePosition(productRank, positionType, dateImpressionRankMap),
      impressions: productRank.impressions,
      wasSponsored: productRank.wasSponsored,
      color: productIdToColorMap.get(productId),
      isHovered: hoveredProductId == productId,
      isNewcomer: newComerProductIds.includes(productId),
      isDropout: dropoutProductIds.includes(productId),
      date: productRank.date,
    } as CompetitorTableCellData;
  });

  return (
    <div style={{ overflowX: 'auto' }}>
      <ToggleButtonGroup
        value={positionType}
        exclusive
        onChange={(_, type) => setPositionType(type)}
        aria-label="text alignment"
      >
        <ToggleButton
          value={PositionType.PRODUCT_RANK}
          aria-label="left aligned"
        >
          Product Rank
        </ToggleButton>
        <ToggleButton
          value={PositionType.IMPRESSION_POSITION}
          aria-label="centered"
        >
          Impression
        </ToggleButton>
      </ToggleButtonGroup>

      <Table size="small">
        <TableHead>
          <TableRow>
            <PerformanceHeaderTableCell
              value={tokens.pd.performance.competitor_product_performances_table.position}
            />

            {
              <>
                {dateList.map((date) => {
                  return (
                    <PerformanceHeaderTableCell
                      key={date.toISOString()}
                      value={getDateStringFromDate(date, false)}
                    />
                  );
                })}
              </>
            }
          </TableRow>
        </TableHead>

        <TableBody>
          {positions.map((position) => {
            return (
              <CompetitorProductPerformanceTableRow
                position={position}
                endDate={endDate}
                startDate={startDate}
                onHover={setHoveredProductId}
                key={position}
                cellData={cells.filter((cell) => cell.position == position)}
              />
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

const determinePosition = (
  productRank: ProductRank,
  positionType: PositionType,
  dateImpressionRankMap: Map<string, number[]>
) => {
  if (positionType == PositionType.PRODUCT_RANK) {
    return productRank.rank;
  }
  const impressionsRankedArray = dateImpressionRankMap.get(productRank.date);

  return impressionsRankedArray!.indexOf(productRank.impressions) + 1;
};

const createProductIdToInitialPositionMap = (
  productRanks: ProductRank[],
  positionType: PositionType,
  dateImpressionRankMap: Map<string, number[]>
) => {
  const productIdToInitialPositionMap: Map<number, number> = new Map();

  const productRankSortedByDate = productRanks.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  const initialProductRanks = uniqueBy(
    productRankSortedByDate,
    (productRank) => productRank.productId
  );

  if (positionType == PositionType.PRODUCT_RANK) {
    initialProductRanks.forEach((productRank) => {
      productIdToInitialPositionMap.set(productRank.productId, productRank.rank);
    });
  } else {
    initialProductRanks.forEach((productRank) => {
      const position =
        dateImpressionRankMap.get(productRank.date)!.indexOf(productRank.impressions) + 1;
      productIdToInitialPositionMap.set(productRank.productId, position);
    });
  }

  return productIdToInitialPositionMap;
};

const createImpressionsRankedArray = (productRanks: ProductRank[]) => {
  return uniqueBy(
    productRanks.map((productRank) => productRank.impressions).sort((a, b) => b - a),
    (impression) => impression
  );
};

const createDateImpressionRankMap = (
  startDate: Date,
  endDate: Date,
  productRanks: ProductRank[]
) => {
  const dateList = generateDateRangeList(startDate, endDate, false);

  const dateImpressionRankMap: Map<string, number[]> = new Map();

  dateList.forEach((date) => {
    const productRanksByDate = productRanks.filter(
      (productRank) => productRank.date == getDateStringFromDate(date)
    );
    const impressionsRankedArray = createImpressionsRankedArray(productRanksByDate);
    dateImpressionRankMap.set(getDateStringFromDate(date), impressionsRankedArray);
  });

  return dateImpressionRankMap;
};

const createProductIdToColorMap = (productIdToInitialRankMap: Map<number, number>) => {
  const usedColors: string[] = [];

  const determineColor = (position: number | undefined) => {
    if (position === undefined) {
      return 'rgba(251,251,251,0.1)';
    }

    function darkenColor(color: string): string {
      const hex = color.slice(1);
      const num = parseInt(hex, 16);
      const r = (num >> 16) + 30;
      const g = ((num >> 8) & 255) + 30;
      const b = (num & 255) + 30;

      const adjustedR = Math.max(0, Math.min(255, r));
      const adjustedG = Math.max(0, Math.min(255, g));
      const adjustedB = Math.max(0, Math.min(255, b));

      return `#${((adjustedR << 16) | (adjustedG << 8) | adjustedB).toString(16).padStart(6, '0')}`;
    }

    const colors: string[] = [
      '#8590ff',
      '#4b61ff',
      '#2c6697',
      '#334259',
      '#374c59',
      '#4c7e99',
      '#4e6178',
      '#0F9AC8',
      '#0DCEC1',
      '#0BD587',
      '#0ADC47',
      '#91ff91',
      '#8bff50',
      '#89bc28',
      '#F3F802',
      '#c1a663',
      '#7ca300',
      '#6EEB47',
      '#67E48B',
      '#269580',
      '#4e5d65',
      '#6f7bcc',
      '#853ee1',
      '#e983fa',
      '#ec6995',
      '#ef5b5b',
      '#996d4c',
      '#8c4d38',
      '#7f4e26',
      '#663a0a',
      '#721916',
    ];

    const colorIndex = position ? position - 1 : 9999;

    let color = colorIndex < colors.length ? colors[colorIndex] : '#d80f0f';

    if (usedColors.find((usedColor) => usedColor == color)) {
      color = darkenColor(color);
    }

    usedColors.push(color);
    return color;
  };

  const productIdToColorMap: Map<number, string> = new Map();

  productIdToInitialRankMap.forEach((position, productId) => {
    productIdToColorMap.set(productId, determineColor(position));
  });

  return productIdToColorMap;
};

export interface CompetitorTableCellData {
  productId: number;
  ean: string;
  thumbnailUrl: string;
  productPageUrl: string;
  color: string;
  position: number;
  initialPosition: number;
  impressions: number;
  isOwnProduct: boolean;
  isDropout: boolean;
  isNewcomer: boolean;
  wasSponsored: boolean;
  isHovered: boolean;
  date: string;
}

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../locales/translationTokens';

export interface DropdownOption<K = unknown, V = string | JSX.Element> {
  key: K;
  value: V;
}

interface DropdownSelectProps<K, V> {
  selected?: K;
  options: DropdownOption<K, V>[];
  onSelect: (selectedKey: K) => void;
  label: string;
}

export const DropdownSelect = <K extends number | string, V extends string | JSX.Element>({
  options,
  onSelect,
  label,
  selected,
}: DropdownSelectProps<K, V>) => {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const id = event.target.value as K;
    onSelect(id);
  };

  if (!selected) {
    // @ts-ignore
    options.unshift({ key: -1, value: t(tokens.common.select) });
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="generic-dropdown-select-label">{t(label)}</InputLabel>
      <Select
        labelId="generic-dropdown-select-label"
        value={!selected ? -1 : selected}
        label={t(label)}
        onChange={(e) => handleChange(e as React.ChangeEvent<{ value: unknown }>)}
        displayEmpty
      >
        {options.map((option) => (
          <MenuItem
            key={option.key.toString()}
            value={option.key}
          >
            {typeof option.value === 'object' ? option.value : t(option.value)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

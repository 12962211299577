import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { AlertsListTable } from './components/AlertsListTable';
import { GetProposedActionsStatus } from '../../../domain/automator/alerts/GetProposedActionsStatus';
import { useState } from 'react';
import AlertTabs from './components/AlertTabs';
import { useFetchProposedActionsReport } from '../../../api/automator/alerts/useFetchProposedActionsReport';

const AlertsPage = () => {
  const [currentTab, setCurrentTab] = useState(GetProposedActionsStatus.OPEN);
  const [date, setDate] = useState<string | null>(null);

  const { data: proposedActionsReport, isLoading: isLoadingProposedActionsReport } =
    useFetchProposedActionsReport();

  const availableDates =
    currentTab === GetProposedActionsStatus.EXECUTED
      ? proposedActionsReport?.executedProposedActionDates
      : proposedActionsReport?.openProposedActionDates;

  return (
    <AccountPage title={tokens.automator.alerts.alerts}>
      <AlertTabs
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />

      <AlertsListTable
        selectedDate={currentTab == GetProposedActionsStatus.EXECUTED ? date : null}
        setDate={setDate}
        isLoading={isLoadingProposedActionsReport}
        availableDates={availableDates || []}
        status={currentTab}
      />
    </AccountPage>
  );
};

export default AlertsPage;

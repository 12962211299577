import { ORDERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { LabelType } from '../../../domain/automator/products/LabelType';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import Orders from '../../../domain/automator/orders/Orders';
import { useOrderQueryKeysUpdater } from './useOrderQueryKeysUpdater';
import { useUpdateData } from '../../useUpdateData';

export const useUpdateOrdersPreferredLabelType = () => {
  const url = useAutomatorApiAccountUrl(ORDERS_PATH + `/preferred-label-type`);

  const updater = useOrderQueryKeysUpdater();
  const updateData = useUpdateData(updater, 'orders');

  const onSuccess = (orders: Orders) => {
    updateData(orders);
  };

  return useAuthorizedPost<Orders, updateOrdersPreferredLabelTypeData>(
    url,
    [],
    undefined,
    onSuccess
  );
};

export interface updateOrdersPreferredLabelTypeData {
  order_ids: number[];
  label_type: LabelType | null;
}

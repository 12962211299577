import Typography from '@mui/material/Typography';
import ShipmentDetails from '../../../../domain/automator/shipments/ShipmentDetails';
import { truncateString } from '../../../../helpers/truncateString';
import { Tooltip } from '@mui/material';

interface ReturnRecipientProps {
  shipmentDetails: ShipmentDetails;
}

export const ReturnRecipient = ({ shipmentDetails }: ReturnRecipientProps) => {
  return (
    <Tooltip
      enterDelay={2000}
      enterNextDelay={2000}
      title={shipmentDetails.firstName + ' ' + shipmentDetails.surname}
    >
      <Typography
        variant="subtitle1"
        display={'flex'}
        gap={0.2}
      >
        {truncateString(shipmentDetails.firstName + ' ' + shipmentDetails.surname, 17)}
      </Typography>
    </Tooltip>
  );
};

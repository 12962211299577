import { HandlingResult } from '../../../../domain/automator/returns/HandlingResult';
import Stack from '@mui/material/Stack';
import ReducedOrderItem from '../../../../domain/automator/orders/ReducedOrderItem';
import Checkbox from '@mui/material/Checkbox';
import { QuantityDropdown } from './QuantityDropdown';
import { HandlingResultDropdown } from './HandlingResultDropdown';
import { TooltippedProductThumbnail } from '../../orders/components/TooltippedProductThumbnail';

interface ReturnOrderItemSelectProps {
  showSelect: boolean;
  orderItem: ReducedOrderItem;
  quantity: number;
  handlingResult: HandlingResult;
  isSelected: boolean;
  setIsSelected: (orderItem: ReducedOrderItem, isSelected: boolean) => void;
  setQuantity: (orderItem: ReducedOrderItem, quantity: number) => void;
  setHandlingResult: (orderItem: ReducedOrderItem, handlingResult: HandlingResult) => void;
}

const ReturnOrderItemSelect = ({
  showSelect,
  orderItem,
  quantity,
  isSelected,
  setIsSelected,
  setQuantity,
  setHandlingResult,
  handlingResult,
}: ReturnOrderItemSelectProps) => {
  return (
    <Stack
      direction="row"
      gap={2}
      alignItems="center"
    >
      {showSelect && (
        <Checkbox
          onClick={() => setIsSelected(orderItem, !isSelected)}
          checked={isSelected}
          size="medium"
        />
      )}

      <TooltippedProductThumbnail
        height={85}
        thumbnailUrl={orderItem.offer.product.thumbnailUrl}
        productTitle={orderItem.offer.product.title}
        quantity={orderItem.quantity}
      />

      {/* NOTE: quantity is the amount ordered, so this won't work if an order item is partially cancelled or shipped */}
      {isSelected && orderItem.quantity > 1 && (
        <QuantityDropdown
          maxQuantity={orderItem.quantity}
          onSelect={(quantity) => setQuantity(orderItem, quantity)}
          quantity={quantity}
        />
      )}

      {isSelected && (
        <HandlingResultDropdown
          onSelect={(handlingResult) => setHandlingResult(orderItem, handlingResult)}
          value={handlingResult}
        />
      )}
    </Stack>
  );
};

export default ReturnOrderItemSelect;

import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../../../locales/translationTokens';
import ActionButton from '../../../../../../components/ActionButton';
import { useCreateOfferPerformanceOverwrite } from '../../../../../../api/pd/offerPerformances/useCreateOfferPerformanceOverwrite';

interface CreateOfferPerformanceOverwriteFormProps {
  onClick?: () => void;
  offerId: number;
  date: string;
}

export const CreateOfferPerformanceOverwriteForm = ({
  onClick,
  date,
  offerId,
}: CreateOfferPerformanceOverwriteFormProps) => {
  const [visits, setVisits] = useState(0);

  const { t } = useTranslation();

  const { mutate: create, isLoading } = useCreateOfferPerformanceOverwrite();

  const onCreate = () => {
    create(
      {
        offer_id: offerId,
        visits: visits,
        date: date,
      },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(
            t(
              tokens.pd.performance.create_offer_performance_overwrite_form
                .offer_performance_overwrite_created
            )
          );
        },
      }
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <TextField
        id="note-field"
        label={t(tokens.pd.performance.create_offer_performance_overwrite_form.visits)}
        variant="filled"
        fullWidth
        style={{ marginBottom: '1em' }}
        value={visits}
        onChange={(e) => setVisits(Number(e.target.value))}
      />

      <ActionButton
        label={t(
          tokens.pd.performance.create_offer_performance_overwrite_form
            .create_offer_performance_overwrite
        )}
        onClick={() => onCreate()}
        isLoading={isLoading}
        variant="contained"
        color="primary"
        size="small"
      />
    </form>
  );
};

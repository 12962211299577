import { DialogId } from '../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../components/ApplicationDialog';
import ActionButton from '../../../components/ActionButton';
import Stack from '@mui/material/Stack';

export const InfoDialog = () => {
  const dialogId = DialogId.INFO;

  const { isOpen, data } = useIsDialogOpen(dialogId);
  // const closeDialog = useCloseDialog(dialogId);
  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={dialogId}
          title={t(data.title)}
        >
          <Stack
            direction="column"
            gap={2}
          >
            <div dangerouslySetInnerHTML={{ __html: t(data.message) as string }}></div>

            <Stack
              direction="row"
              gap={2}
            >
              {data.buttons?.map((button, i) => (
                <ActionButton
                  key={i}
                  label={t(button.label)}
                  onClick={button.onClick}
                />
              ))}
            </Stack>
          </Stack>
        </ApplicationDialog>
      )}
    </>
  );
};

export interface InfoDialogData {
  title: string;
  message: string;
  buttons?: InfoDialogButton[];
}

export interface InfoDialogButton {
  label: string;
  onClick: () => void;
}

import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { ResolutionCaseStatus } from '../../../../domain/automator/resolutionDossiers/ResolutionCaseStatus';

interface ResolutionCaseStatusChipProps {
  resolutionCaseStatus: ResolutionCaseStatus;
}

const ResolutionCaseStatusChip = ({ resolutionCaseStatus }: ResolutionCaseStatusChipProps) => {
  const { t } = useTranslation();

  const determineLabel = (resolutionCaseStatus: ResolutionCaseStatus): string => {
    switch (resolutionCaseStatus) {
      case ResolutionCaseStatus.HANDLED:
        return tokens.automator.resolutions.resolution_case_status.handled;
      case ResolutionCaseStatus.AWAITING_RESPONSE:
        return tokens.automator.resolutions.resolution_case_status.awaiting_response;
      case ResolutionCaseStatus.OPEN:
        return tokens.automator.resolutions.resolution_case_status.open;
      default:
        return '';
    }
  };

  const determineColor = (
    resolutionCaseStatus: ResolutionCaseStatus
  ): 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' => {
    switch (resolutionCaseStatus) {
      case ResolutionCaseStatus.HANDLED:
        return 'success';
      case ResolutionCaseStatus.AWAITING_RESPONSE:
        return 'primary';
      case ResolutionCaseStatus.OPEN:
        return 'secondary';
      default:
        return 'default';
    }
  };

  return (
    <Chip
      size="small"
      label={t(determineLabel(resolutionCaseStatus))}
      variant="filled"
      color={determineColor(resolutionCaseStatus)}
    />
  );
};

export default ResolutionCaseStatusChip;

import { ACCOUNTS_PATH, PD_API_URL } from './pdApiPaths';
import { useAccountIdFromUrl } from '../useAccountIdFromUrl';

export const usePdApiAccountUrl = (path?: string): string => {
  const accountId = useAccountIdFromUrl();

  if (accountId == null) {
    throw Error('Account id can not be null.');
  }

  const apath = path ? `/${path}` : '';

  return `${PD_API_URL}/${ACCOUNTS_PATH}/${accountId}${apath}`;
};

import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { OrderSummary } from '../components/OrderSummary';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';

export const OrderSummaryDialog = () => {
  const id = DialogId.ORDER_SUMMARY;

  const { isOpen, data } = useIsDialogOpen(id);

  const onClose = useCloseDialog(DialogId.ORDER_SUMMARY);

  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          maxWidth={'lg'}
          title={t(tokens.automator.order_summary.order_summary)}
        >
          <OrderSummary
            onClose={onClose}
            query={data.query}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface ViewOrderSummaryDialogData {
  query: string;
}

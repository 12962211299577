import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { AD_GROUP_SEARCH_TERM_PERFORMANCES } from '../pdApiPaths';
import AdGroupSearchTermPerformances from '../../../domain/pd/AdGroupSearchTermPerformances';
import { AdGroupSearchTermPerformancesQueryKey } from '../../queryKeys/AdGroupSearchTermPerformancesQueryKey';
import { getDateStringFromDate } from '../../../helpers/getDateStringFromDate';

export const useFetchAdGroupSearchTermPerformances = (
  adGroupId: number,
  searchTermId: number,
  startDate: Date,
  endDate: Date
) => {
  const url = usePdApiAccountUrl(
    AD_GROUP_SEARCH_TERM_PERFORMANCES +
      `?adGroupId=${adGroupId}&searchTermId=${searchTermId}&startDate=${getDateStringFromDate(
        startDate
      )}&endDate=${getDateStringFromDate(endDate)}`
  );
  return useFetchData<AdGroupSearchTermPerformances>(
    url,
    AdGroupSearchTermPerformancesQueryKey(adGroupId, searchTermId, startDate, endDate)
  );
};

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Order from '../../../../domain/automator/orders/Order';
import Checkbox from '@mui/material/Checkbox';
import { tokens } from '../../../../locales/translationTokens';
import { LabelTypeToggleButton } from './LabelTypeToggleButton';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useUpdateOrdersPreferredLabelType } from '../../../../api/automator/orders/useUpdateOrdersPreferredLabelType';
import { CountryCode } from '../../../../domain/automator/orders/CountryCode';
import { OrderItemsTableCell } from './OrderItemsTableCell';
import { OrderDatesStack } from './OrderDatesStack';
import { LabelType } from '../../../../domain/automator/products/LabelType';
import { OrderStatus } from '../../../../domain/automator/orders/OrderStatus';
import { OrderRecipient } from './OrderRecipient';

import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';
import { useActiveCopyButton } from '../../../../store/activeCopyButton/useActiveCopyButton';
import Stack from '@mui/material/Stack';
import OrderItem from '../../../../domain/automator/orders/OrderItem';
import { OrdersMode } from '../OrdersPage';
import { ShipmentDetailsAddress } from './ShipmentDetailsAddress';
import { OrderCopyButtons } from './copyButtons/OrderCopyButtons';

interface OrderTableRowProps {
  order: Order;
  mode: OrdersMode;
  isOrderSelected: boolean;
  onSelectOrder?: (order: Order) => void;
  onDeselectOrder?: (orderId: number) => void;
  distributionParty: DistributionParty;
  selectedOrderItemIds: number[];
  onSelectOrderItem: (orderItem: OrderItem) => void;
}

export const OrderTableRow = ({
  order,
  mode,
  isOrderSelected,
  onSelectOrder,
  onDeselectOrder,
  distributionParty,
  selectedOrderItemIds,
  onSelectOrderItem,
}: OrderTableRowProps) => {
  const { t } = useTranslation();

  const { activeAggregateId } = useActiveCopyButton();

  const { mutate: updateOrdersPreferredLabelType } = useUpdateOrdersPreferredLabelType();

  const isSelectedLabelTypeDisabled =
    mode != OrdersMode.BULK_ORDERS ||
    order.shipmentDetails.countryCode == CountryCode.BE ||
    order.pickupPoint ||
    order.isPartiallyProcessedExternally ||
    order.hasConflictingHandoverDateTime;

  const doUpdateLabelType = (labelType: LabelType) => {
    const data = {
      order_ids: [order.id],
      label_type: labelType,
    };
    updateOrdersPreferredLabelType(data, {
      onSuccess: () => {
        toast.success(t(tokens.automator.orders.toasts.label_type_changed));
      },
    });
  };

  return (
    <TableRow
      style={{ backgroundColor: activeAggregateId == order.id ? '#ece3e3' : undefined }}
      key={order.id}
    >
      {mode == OrdersMode.BULK_ORDERS && (
        <TableCell padding="checkbox">
          <Checkbox
            disabled={
              order.isPartiallyProcessedExternally ||
              order.hasConflictingHandoverDateTime ||
              order.status == OrderStatus.CAN_NOT_PROCESS
            }
            checked={isOrderSelected}
            onChange={(event) => {
              event.target.checked ? onSelectOrder?.(order) : onDeselectOrder?.(order.id);
            }}
          />
        </TableCell>
      )}

      <OrderItemsTableCell
        distributionParty={distributionParty}
        order={order}
        mode={mode}
        onSelect={onSelectOrderItem}
        selectedOrderItemIds={selectedOrderItemIds}
      />

      <TableCell>
        <OrderDatesStack order={order} />
      </TableCell>

      <TableCell>
        <Stack
          display={'flex'}
          flexDirection={'column'}
          gap={'0.1rem'}
        >
          <OrderRecipient
            shipmentDetails={order.shipmentDetails}
            doubleOrderBolOrderIds={order.doublePlacedBolOrderIds}
            isDoublePlaceOrdersACancelled={order.doublePlaceOrdersAreCancelled || false}
            repeatCustomerBolOrderIds={order.repeatCustomerBolOrderIds}
          />
          <ShipmentDetailsAddress
            pickupPoint={order.pickupPoint}
            shipmentDetails={order.shipmentDetails}
          />
        </Stack>
      </TableCell>

      {order.distributionParty == DistributionParty.BOL && (
        <TableCell>
          <LabelTypeToggleButton
            isDisabled={isSelectedLabelTypeDisabled}
            value={order.labelType}
            onChange={(labelType) => {
              doUpdateLabelType(labelType);
            }}
          />
        </TableCell>
      )}

      {order.status == OrderStatus.SHIPPED && <TableCell>{order.shipmentDateTime}</TableCell>}

      {order.status == OrderStatus.SHIPPED && (
        <TableCell>
          <OrderCopyButtons order={order} />
        </TableCell>
      )}
    </TableRow>
  );
};

import { RETURNS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { useUpdateData } from '../../useUpdateData';
import { useReturnsQueryKeysUpdater } from './useReturnsQueryKeysUpdater';
import Returns, { AGGREGATE_KEY } from '../../../domain/automator/returns/Returns';
import { HandlingResult } from '../../../domain/automator/returns/HandlingResult';
import { ReturnsQueryKey } from '../../queryKeys/ReturnsQueryKey';
import { OrderReturnsQueryKey } from '../../queryKeys/OrderReturnsQueryKey';
import { ReturnItemFilter } from '../../../domain/automator/returns/ReturnItemFilter';

export const useCreateReturn = () => {
  const url = useAutomatorApiAccountUrl(RETURNS_PATH);

  const updater = useReturnsQueryKeysUpdater();
  const updateData = useUpdateData(updater, AGGREGATE_KEY);

  const onSuccess = (returns: Returns) => {
    updateData(returns);
  };

  return useAuthorizedPost<Returns, CreateReturnData>(
    url,
    [ReturnsQueryKey(ReturnItemFilter.HANDLED), OrderReturnsQueryKey()], // NOTE: the created return will be handled
    undefined,
    onSuccess
  );
};

export interface CreateReturnData {
  order_item_id: number;
  quantity: number;
  handling_result: HandlingResult;
}

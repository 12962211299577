import { useAuthorizedPost } from '../../useAuthorizedPost';
import { PRODUCT_SEARCH_TERM_SETTINGS } from '../pdApiPaths';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';

export const useUpdateIsRelevantProductSearchTermSetting = () => {
  const url = usePdApiAccountUrl(PRODUCT_SEARCH_TERM_SETTINGS + `/is-relevant`);
  return useAuthorizedPost<undefined, IsRelevantProductSearchTermSettingData>(
    url,
    undefined,
    undefined
  );
};

export interface IsRelevantProductSearchTermSettingData {
  product_id: number;
  search_term_id: number;
  is_relevant: boolean;
}

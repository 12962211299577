import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { TableEmpty } from '../../../../components/TableEmpty';
import Reimbursement from '../../../../domain/automator/reimbursements/Reimbursement';
import ReimbursementRequest from '../../../../domain/automator/reimbursements/ReimbursementRequest';
import { ReimbursementTableRow, ReimbursementTableRowData } from './ReimbursementTableRow';
import { getDateOnly } from '../../../../helpers/getDateOnly';

interface ReimbursementsListTableProps {
  isLoading: boolean;
  reimbursements?: Reimbursement[];
  reimbursementRequests?: ReimbursementRequest[];
}

export const ReimbursementsListTable = ({
  reimbursementRequests,
  reimbursements,
  isLoading,
}: ReimbursementsListTableProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (reimbursements!.length == 0 && reimbursementRequests!.length == 0) {
    return <TableEmpty message={t(tokens.common.no_items)} />;
  }

  const reimbursementWithRequestsIds: number[] = [];

  const generateRowData = (): ReimbursementTableRowData[] => {
    return [
      ...reimbursementRequests!.map((reimbursementRequest) => {
        const reimbursement = reimbursements!.find(
          (reimbursement) => reimbursementRequest.reimbursementId === reimbursement.id
        );

        if (reimbursement) {
          reimbursementWithRequestsIds.push(reimbursement.id);
        }

        return {
          reimbursementId: reimbursement?.id || null,
          reimbursementRequestId: reimbursementRequest.id,
          amountInCents: reimbursement?.amountInCents || null,
          type: reimbursementRequest.type,
          order: reimbursementRequest.order,
          eventTrigger: reimbursementRequest.eventTrigger,
          bolCaseNumber: reimbursementRequest.bolCaseNumber,
          isSeen: true,
          date: getDateOnly(reimbursementRequest.createdDateTime),
        };
      }),

      ...reimbursements!
        .filter((reimbursement) => {
          return !reimbursementWithRequestsIds.includes(reimbursement.id);
        })
        .map((reimbursement) => {
          return {
            reimbursementId: reimbursement.id || null,
            reimbursementRequestId: null,
            amountInCents: reimbursement?.amountInCents || null,
            type: null,
            order: reimbursement.order,
            eventTrigger: null,
            bolCaseNumber: null,
            isSeen: reimbursement.isSeen,
            date: getDateOnly(reimbursement.createdDateTime),
          };
        }),
    ];
  };

  const sortedRowData = generateRowData().sort((a, b) => {
    return a.date > b.date ? -1 : 1;
  });

  return (
    <Box sx={{ position: 'relative' }}>
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t(tokens.automator.reimbursements.type.type)}</TableCell>
              <TableCell>{t(tokens.automator.reimbursements.trigger)}</TableCell>
              <TableCell>{t(tokens.automator.reimbursements.origin.origin)}</TableCell>
              <TableCell>{t(tokens.automator.reimbursements.order)}</TableCell>
              <TableCell>{t(tokens.automator.reimbursements.status.status)}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRowData.map((data) => {
              return (
                <ReimbursementTableRow
                  key={
                    data.reimbursementRequestId
                      ? 'rq_' + data.reimbursementRequestId
                      : 'r_' + data.reimbursementId
                  }
                  data={data}
                />
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};

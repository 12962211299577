import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Store';
import { ActiveCopyButtonState, setActiveButtonId } from './activeCopyButtonsSlice';

export const useActiveCopyButton = () => {
  const dispatch = useDispatch();
  const activeButtonId = useSelector((state: RootState) => state.active_copy_button.activeButtonId);
  const activeAggregateId = useSelector(
    (state: RootState) => state.active_copy_button.activeAggregateId
  );

  const setActiveButton = (state: ActiveCopyButtonState) => {
    dispatch(setActiveButtonId(state));
  };

  return { activeButtonId, activeAggregateId, setActiveButton };
};

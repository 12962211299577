import axios from 'axios';

export const useNotAuthorizedAxios = () => {
  return axios.create({
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    withCredentials: true, // Equivalent to `credentials: 'include'` in fetch
  });
};

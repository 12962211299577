import { SHIPMENTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { ShipmentsQueryKey, ShipmentsRequestType } from '../../queryKeys/ShipmentsQueryKey';
import { useFetchData } from '../../useFetchData';
import Shipments from '../../../domain/automator/shipments/Shipments';

export const useFetchShipments = (type: ShipmentsRequestType, date: string | null) => {
  const baseUrl = useAutomatorApiAccountUrl(SHIPMENTS_PATH);
  const generateUrl = () => {
    let newUrl = `${baseUrl}?type=${type}`;
    if (date) {
      newUrl += `&date=${date}`;
    }
    return newUrl;
  };

  return useFetchData<Shipments>(generateUrl(), ShipmentsQueryKey(type, date));
};

import { RETAILERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';
import { useFetchData } from '../../useFetchData';
import Retailer from '../../../domain/automator/retailers/Retailer';

export const useFetchRetailers = () => {
  const url = useAutomatorApiAccountUrl(RETAILERS_PATH);
  return useFetchData<Retailer[]>(url, EndpointQueryKey.RETAILERS);
};

import { PICK_SESSION_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';
import PickSession from '../../../domain/automator/pickSessions/PickSession';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { LabelGeneration } from '../../../domain/automator/pickSessions/LabelGeneration';
import { TransporterCode } from '../../../domain/automator/shipments/TransporterCode';
import { PickSessionsQueryKey } from '../../queryKeys/PickSessionsQueryKey';
import { PickSessionsReportQueryKey } from '../../queryKeys/PickSessionsReportQueryKey';

export const useCreatePickSession = () => {
  const url = useAutomatorApiAccountUrl(PICK_SESSION_PATH);
  return useAuthorizedPost<PickSession[], createPickSessionData>(url, [
    PickSessionsQueryKey(),
    PickSessionsReportQueryKey(),
    [EndpointQueryKey.ACCOUNT],
  ]);
};

export interface createPickSessionData {
  order_ids: number[];
  label_generation: LabelGeneration;
  transporter_code: TransporterCode | null;
  is_shipped_automatically: boolean;
}

import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { CountryCode } from '../../../../domain/automator/orders/CountryCode';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../../components/ActionButton';
import BillingDetails from '../../../../domain/automator/orders/BillingDetails';

interface UpdateBillingDetailsFormProps {
  billingDetails: BillingDetails | null;
  onSave: (billingDetails: BillingDetails) => void;
  isVatNumberRequired: boolean;
  isKvkNumberRequired: boolean;
  isLoading: boolean;
}

interface FormInputs {
  zipCode: string;
  streetName: string;
  houseNumber: string;
  houseNumberExtension: string | null;
  city: string;
  company: string;
  vatNumber: string;
  kvkNumber: string;
  countryCode: CountryCode;
}

export const UpdateBillingDetailsForm = ({
  billingDetails,
  onSave,
  isVatNumberRequired,
  isKvkNumberRequired,
  isLoading,
}: UpdateBillingDetailsFormProps) => {
  const { t } = useTranslation();

  const doUpdate = (data: BillingDetails) => {
    onSave(data);
  };

  const zipNLRegex = new RegExp('^\\d{4} ?[a-zA-Z]{2}$');
  const zipBERegex = new RegExp('^\\d{4}$');

  const determineCountryCode = (zipCode: string) => {
    if (zipNLRegex.test(zipCode)) {
      return CountryCode.NL;
    } else if (zipBERegex.test(zipCode)) {
      return CountryCode.BE;
    }
  };

  const onSubmit = (data: FormInputs) => {
    doUpdate({
      salutation: billingDetails?.salutation,
      firstName: billingDetails?.firstName,
      surname: billingDetails?.surname,
      streetName: data.streetName.trim(),
      houseNumber: data.houseNumber.trim(),
      houseNumberExtension: data.houseNumberExtension?.trim(),
      extraAddressInformation: billingDetails?.extraAddressInformation,
      zipCode: data.zipCode.trim().toUpperCase(),
      city: data.city.trim(),
      email: billingDetails?.email,
      deliveryPhoneNumber: billingDetails?.deliveryPhoneNumber,
      countryCode: determineCountryCode(data.zipCode),
      company: data.company,
      vatNumber: data.vatNumber,
      kvkNumber: data.kvkNumber,
      orderReference: billingDetails?.orderReference,
    });
  };

  const validationSchema = Yup.object({
    zipCode: Yup.string()
      .required(t(tokens.common.required) as string)
      .matches(/^(\d{4} ?[a-zA-Z]{2}|\d{4})$/, t(tokens.common.incorrect) as string),
    streetName: Yup.string().required(t(tokens.common.required) as string),
    houseNumber: Yup.string()
      .required(t(tokens.common.required) as string)
      .matches(/^\d+$/, t(tokens.common.incorrect) as string),
    houseNumberExtension: Yup.string().nullable().defined().default(null),
    city: Yup.string().required(t(tokens.common.required) as string),
    company: Yup.string().required(t(tokens.common.required) as string),
    vatNumber: isVatNumberRequired
      ? Yup.string().required(t(tokens.common.required) as string)
      : Yup.string().nullable().defined().default(null),
    kvkNumber: isKvkNumberRequired
      ? Yup.string().required(t(tokens.common.required) as string)
      : Yup.string().nullable().defined().default(null),
  });

  const determineDefaultValues = (): BillingDetails => {
    if (!billingDetails) {
      return {
        salutation: undefined,
        firstName: undefined,
        surname: undefined,
        streetName: undefined,
        houseNumber: undefined,
        houseNumberExtension: undefined,
        extraAddressInformation: undefined,
        zipCode: undefined,
        city: undefined,
        email: undefined,
        deliveryPhoneNumber: undefined,
        countryCode: undefined,
        company: undefined,
        vatNumber: undefined,
        kvkNumber: undefined,
        orderReference: undefined,
      };
    }

    return billingDetails;
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    // @ts-ignore
    resolver: yupResolver(validationSchema),
    defaultValues: determineDefaultValues(),
  });

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={3}>
        <Stack
          direction={'row'}
          gap={1}
        >
          <Controller
            name="company"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t(tokens.automator.customer_invoice_requests.details.company_name)}
                error={Boolean(errors.company)}
                helperText={errors.company?.message}
              />
            )}
          />

          <Controller
            name="kvkNumber"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t(
                  tokens.automator.customer_invoice_requests.details.chamber_of_commerce_number
                )}
                error={Boolean(errors.kvkNumber)}
                helperText={errors.kvkNumber?.message}
                onChange={(event) => field.onChange(event.target.value.trim())}
              />
            )}
          />

          <Controller
            name="vatNumber"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t(tokens.automator.customer_invoice_requests.details.vat_number)}
                error={Boolean(errors.vatNumber)}
                helperText={errors.vatNumber?.message}
                onChange={(event) => field.onChange(event.target.value.trim())}
              />
            )}
          />
        </Stack>

        <Stack
          direction={'row'}
          gap={1}
        >
          <Controller
            name="streetName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t(tokens.common.address.street_name)}
                error={Boolean(errors.streetName)}
                helperText={errors.streetName?.message}
              />
            )}
          />
          <Controller
            name="houseNumber"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t(tokens.common.address.house_number)}
                error={Boolean(errors.houseNumber)}
                helperText={errors.houseNumber?.message}
                onChange={(event) => field.onChange(event.target.value.trim())}
              />
            )}
          />
          <Controller
            name="houseNumberExtension"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t(tokens.common.address.house_number_extension)}
                error={Boolean(errors.houseNumberExtension)}
                helperText={errors.houseNumberExtension?.message}
              />
            )}
          />
        </Stack>

        <Stack
          direction="row"
          gap={1}
        >
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t(tokens.common.address.city)}
                error={Boolean(errors.city)}
                helperText={errors.city?.message}
              />
            )}
          />
          <Controller
            name="zipCode"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t(tokens.common.address.zip_code)}
                error={Boolean(errors.zipCode)}
                helperText={errors.zipCode?.message}
                onChange={(event) => field.onChange(event.target.value.trim())}
              />
            )}
          />
        </Stack>

        <ActionButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          label={t(tokens.automator.customer_invoice_requests.details.billing_details_updated)}
          isLoading={isLoading}
        />
      </Stack>
    </form>
  );
};

import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { DraftsOutlined } from '@mui/icons-material';
import { ActionIcon } from '../../orders/components/ActionIcon';

interface InitialEmailMessageOpenedIndicatorProps {
  initialEmailMessageOpenendDateTime: string;
}

export const InitialEmailMessageOpenedIndicator = ({
  initialEmailMessageOpenendDateTime,
}: InitialEmailMessageOpenedIndicatorProps) => {
  const { t } = useTranslation();

  return (
    <ActionIcon
      icon={<DraftsOutlined />}
      tooltip={
        t(tokens.automator.resolutions.indicator.has_opened_email) +
        ' ' +
        initialEmailMessageOpenendDateTime
      }
    />
  );
};

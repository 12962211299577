import { MESSAGE_TEMPLATES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { MessageTemplatesQueryKey } from '../../queryKeys/MessageTemplatesQueryKey';
import MessageTemplate from '../../../domain/automator/messages/MessageTemplate';
import { EmailRulesQueryKey } from '../../queryKeys/EmailRulesQueryKey';

export const useUpdateMessageTemplate = (messageTemplateId: number) => {
  const url = useAutomatorApiAccountUrl(MESSAGE_TEMPLATES_PATH + '/' + messageTemplateId);

  return useAuthorizedPost<MessageTemplate, UpdateMessageTemplateData>(
    url,
    [MessageTemplatesQueryKey(), EmailRulesQueryKey()],
    undefined
  );
};

export interface UpdateMessageTemplateData {
  name: string;
  subject: string;
  body: string;
}

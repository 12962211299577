import { useAuthorizedPost } from '../../useAuthorizedPost';
import { PRODUCT_PURCHASE_COSTS_PATH } from '../pdApiPaths';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';

export const useCreateProductPurchaseCosts = () => {
  const url = usePdApiAccountUrl(PRODUCT_PURCHASE_COSTS_PATH);
  return useAuthorizedPost<undefined, CreateProductPurchaseCostsData>(url, undefined, undefined);
};

export interface CreateProductPurchaseCostsData {
  product_id: number;
  date: string;
  purchase_costs_in_cents: number;
}

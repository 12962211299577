import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../../components/ActionButton';
import { tokens } from '../../../../locales/translationTokens';
import { useLoadShipmentEvents } from '../../../../api/automator/shipments/useLoadShipmentEvents';

interface DoLoadShipmentEventsButtonProps {
  shipmentIds: number[];
  onClick?: () => void;
}

const DoLoadShipmentEventsButton = ({ shipmentIds, onClick }: DoLoadShipmentEventsButtonProps) => {
  const { mutate: loadShipmentEvents, isLoading: isLoadingLoadShipmentEvents } =
    useLoadShipmentEvents();

  const doLoadShipmentEvent = () => {
    loadShipmentEvents(
      {
        shipment_ids: shipmentIds,
      },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(t(tokens.automator.shipments.loading_shipment_events_started));
        },
      }
    );
  };

  const { t } = useTranslation();

  return (
    <ActionButton
      label={t(tokens.automator.shipments.load_shipment_events)}
      onClick={doLoadShipmentEvent}
      isLoading={isLoadingLoadShipmentEvents}
    />
  );
};

export default DoLoadShipmentEventsButton;

import { useAuthorizedPost } from '../../useAuthorizedPost';
import { PRODUCT_PURCHASE_COSTS_PATH } from '../pdApiPaths';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';

export const useUpdateProductPurchaseCosts = (productPurchaseCostsId: number) => {
  const url = usePdApiAccountUrl(PRODUCT_PURCHASE_COSTS_PATH + '/' + productPurchaseCostsId);
  return useAuthorizedPost<undefined, UpdateProductPurchaseCostsData>(url, undefined, undefined);
};

export interface UpdateProductPurchaseCostsData {
  date: string;
  purchase_costs_in_cents: number;
}

import { TextField, TextFieldProps } from '@mui/material';
import { NumericFormat } from 'react-number-format';

interface CurrencyTextFieldProps {
  value: number;
  onChange: (value: number) => void;
  textFieldProps?: TextFieldProps;
}

const CustomInput = (props: TextFieldProps) => {
  return <TextField {...props} />;
};

const CurrencyTextField = ({ value, onChange, textFieldProps }: CurrencyTextFieldProps) => {
  return (
    <NumericFormat
      // @ts-ignore
      value={(value / 100) as number}
      onValueChange={(values) => {
        onChange(values.floatValue! * 100);
      }}
      customInput={CustomInput}
      decimalSeparator=","
      allowLeadingZeros={false}
      {...textFieldProps}
      decimalScale={2}
      fixedDecimalScale
      prefix="€"
      allowNegative={false}
    />
  );
};
export default CurrencyTextField;

import Typography from '@mui/material/Typography';
import ShipmentDetails from '../../../../domain/automator/shipments/ShipmentDetails';
import RepeatCustomerChip from './RepeatCustomerChip';
import Stack from '@mui/material/Stack';
import DoublePlacedOrderChip from './DoublePlacedOrderChip';
import DoublePlaceOrderCancelledChip from './DoublePlacedOrderCancelledChip';

interface OrderRecipientProps {
  shipmentDetails: ShipmentDetails;
  repeatCustomerBolOrderIds: string[];
  doubleOrderBolOrderIds: string[];
  isDoublePlaceOrdersACancelled: boolean;
}

export const OrderRecipient = ({
  shipmentDetails,
  repeatCustomerBolOrderIds,
  doubleOrderBolOrderIds,
  isDoublePlaceOrdersACancelled,
}: OrderRecipientProps) => {
  return (
    <Stack
      direction={'row'}
      gap={1}
      alignItems={'center'}
    >
      <Typography
        variant="subtitle1"
        display={'flex'}
        gap={0.2}
        noWrap
      >
        {shipmentDetails.firstName} {shipmentDetails.surname}
      </Typography>

      <Stack
        direction="column"
        gap={1}
      >
        {repeatCustomerBolOrderIds.length > 0 && (
          <RepeatCustomerChip bolOrderId={repeatCustomerBolOrderIds[0]} />
        )}
        {doubleOrderBolOrderIds.length > 0 && !isDoublePlaceOrdersACancelled && (
          <DoublePlacedOrderChip bolOrderId={doubleOrderBolOrderIds[0]} />
        )}
        {doubleOrderBolOrderIds.length > 0 && isDoublePlaceOrdersACancelled && (
          <DoublePlaceOrderCancelledChip bolOrderId={doubleOrderBolOrderIds[0]} />
        )}
      </Stack>
    </Stack>
  );
};

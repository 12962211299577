import { PRODUCT_GROUPS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { ProductGroupsQueryKey } from '../../queryKeys/ProductGroupsQueryKey';
import ProductGroup from '../../../domain/automator/products/ProductGroup';

export const useCreateProductGroup = () => {
  const url = useAutomatorApiAccountUrl(`${PRODUCT_GROUPS_PATH}`);
  return useAuthorizedPost<ProductGroup, CreateProductGroupData>(url, [ProductGroupsQueryKey()]);
};

export interface CreateProductGroupData {
  name: string;
}

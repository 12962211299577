import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/translationTokens';
import { useFetchOfferPerformances } from '../../../../../../api/pd/offerPerformances/useFetchOfferPerformances';
import { TableLoading } from '../../../../../../components/TableLoading';
import { TableEmpty } from '../../../../../../components/TableEmpty';
import { LatestDays } from '../LatestDaysDropdownSelect';
import getLatestDateByLatestDays from '../../../../../../helpers/getLatestDateByLatestDays';
import getYesterday from '../../../../../../helpers/getYesterday';
import { CountryCode } from '../../../../../../domain/automator/orders/CountryCode';
import Offer from '../../../../../../domain/pd/Offer';
import { useFetchProductPerformances } from '../../../../../../api/pd/productPerformances/useFetchProductPerformances';
import { useFetchAdPerformances } from '../../../../../../api/pd/productPerformances/useFetchAdPerformances';
import { OfferPerformancesOrganicNonOrganicTable } from './OfferPerformancesOrganicNonOrganicTable';
import { TimelineTable } from './TimelineTable';
import { OfferPerformanceMetricsTable } from './OfferPerformanceMetricsTable';
import { AdPerformancesTable } from './AdPerformancesTable';
import { OfferPerformanceSearchMetricsTable } from './OfferPerformanceSearchMetricsTable';

interface OfferPerformancesListTableProps {
  offer: Offer;
  latestDays: LatestDays;
  rollingAverageDays: number;
  countryCode: CountryCode | null;
}

export const OfferPerformancesListTable = ({
  offer,
  latestDays,
  rollingAverageDays,
  countryCode,
}: OfferPerformancesListTableProps) => {
  const { t } = useTranslation();
  const { data: offerPerformances, isLoading: offerPerformancesLoading } =
    useFetchOfferPerformances(
      offer.id,
      getLatestDateByLatestDays(latestDays),
      getYesterday(),
      rollingAverageDays,
      countryCode
    );

  const { data: productPerformances, isLoading: productPerformancesLoading } =
    useFetchProductPerformances(
      offer.productId,
      getLatestDateByLatestDays(latestDays),
      getYesterday(),
      rollingAverageDays
    );

  const { data: adPerformances, isLoading: adPerformancesLoading } = useFetchAdPerformances(
    offer.id,
    getLatestDateByLatestDays(latestDays),
    getYesterday(),
    rollingAverageDays
  );

  if (offerPerformancesLoading || productPerformancesLoading || adPerformancesLoading) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (offerPerformances?.offerPerformances.length == 0) {
    return <TableEmpty message={t(tokens.pd.performance.no_items)} />;
  }

  return (
    <span style={{ overflowX: 'auto' }}>
      <span style={{ display: 'flex', width: 'max-content' }}>
        <TimelineTable
          offer={offer}
          startDate={getLatestDateByLatestDays(latestDays)}
          endDate={getYesterday()}
          countryCode={countryCode}
        />

        <OfferPerformancesOrganicNonOrganicTable
          offerPerformances={offerPerformances!}
          rollingAverageDays={rollingAverageDays}
        />

        <OfferPerformanceSearchMetricsTable
          offerPerformances={offerPerformances!}
          productPerformances={productPerformances!}
          rollingAverageDays={rollingAverageDays}
        />

        <OfferPerformanceMetricsTable
          offerPerformances={offerPerformances!}
          rollingAverageDays={rollingAverageDays}
        />
        <AdPerformancesTable
          adPerformances={adPerformances!}
          rollingAverageDays={rollingAverageDays}
        />
      </span>
    </span>
  );
};

import convertRatioToPercentage from '../../../helpers/convertRatioToPercentage';
import { convertCentsToEuros } from '../../../helpers/convertCentsToEuros';
import Typography from '@mui/material/Typography';
import roundDownTo1Decimal from '../../../helpers/roundDownTo1Decimal';
import MinMaxReport, { MinMaxType } from '../../../../../domain/pd/MinMaxReport';
import { SlimTableCell } from '../../../../../components/SlimTableCell';

export enum PerformanceDateType {
  ROUNDED = 'ROUNDED',
  DATE = 'DATE',
  PERCENTAGE = 'PERCENTAGE',
  CURRENCY = 'CURRENCY',
  OTHER = 'OTHER',
}

interface PerformanceTableCellProps {
  value?: number | string | undefined | null;
  type: PerformanceDateType;
  minMaxReport?: MinMaxReport;
  colorScale?: ColorScale;
  onClick?: () => void;
  width?: string;
}

export enum ColorScale {
  GREEN = 'GREEN',
  ORANGE = 'ORANGE',
  BLUE = 'BLUE',
  PINK = 'PINK',
}

export const PerformanceTableCell = ({
  value,
  type,
  onClick,
  minMaxReport,
  colorScale,
}: PerformanceTableCellProps) => {
  const getValue = (type: PerformanceDateType, value?: number | string | undefined | null) => {
    switch (type) {
      case PerformanceDateType.ROUNDED:
        return roundDownTo1Decimal(value as number | undefined);
      case PerformanceDateType.CURRENCY:
        return convertCentsToEuros(value as number | undefined);
      case PerformanceDateType.PERCENTAGE:
        return convertRatioToPercentage(value as number | undefined);
      case PerformanceDateType.DATE:
        return (value as string).substring(5);
      default:
        return value;
    }
  };

  const getMinMaxColor = (value: number, minMaxReport: MinMaxReport, colorScale: ColorScale) => {
    const colorScaleArray = getColorScale(colorScale);

    const normalizedValue = (value - minMaxReport.min) / (minMaxReport.max - minMaxReport.min);
    let index: number;
    if (minMaxReport.type === MinMaxType.MAX_BETTER) {
      index = Math.round(normalizedValue * (colorScaleArray.length - 1));
    } else {
      index =
        colorScaleArray.length - 1 - Math.round(normalizedValue * (colorScaleArray.length - 1));
    }
    return colorScaleArray[index];
  };

  const getColorScale = (colorScale: ColorScale) => {
    const colorArrayGreen = [
      '#fefffe',
      '#e4fedf',
      '#d1facb',
      '#c7f3c0',
      '#bdebb6',
      '#b4e4ad',
      '#aadda3',
      '#97d192',
      '#82c581',
      '#6db970',
      '#57ad60',
      '#3da150',
      '#1a9640',
    ];

    const colorArrayOrange = [
      '#fefffe',
      '#fef9f6',
      '#fef4ec',
      '#feefe3',
      '#fee9d8',
      '#fee4cf',
      '#fedec4',
      '#fdd9bb',
      '#fcd4b2',
      '#fecea5',
      '#fdc89a',
      '#fdc38f',
      '#fdbd83',
    ];

    const colorArrayBlue = [
      '#fefffe',
      '#effafe',
      '#e0f6fe',
      '#d1f1fd',
      '#c2edfc',
      '#bae7f6',
      '#b3e1f1',
      '#9ed4ea',
      '#8ac7e4',
      '#78b9df',
      '#66abd9',
      '#569ed3',
      '#4890cc',
    ];

    const colorArrayPink = [
      '#fefffe',
      '#fef4fa',
      '#fee9f6',
      '#fddff1',
      '#fed4ed',
      '#fec9e9',
      '#fbbfe4',
      '#edabd8',
      '#de96ce',
      '#ce82c5',
      '#bc6fbd',
      '#a95db5',
      '#954bae',
    ];

    switch (colorScale) {
      case ColorScale.BLUE:
        return colorArrayBlue;
      case ColorScale.ORANGE:
        return colorArrayOrange;
      case ColorScale.GREEN:
        return colorArrayGreen;
      case ColorScale.PINK:
        return colorArrayPink;
    }
  };

  return (
    <SlimTableCell
      onClick={onClick}
      style={{
        width: '5px',
        backgroundColor:
          value !== undefined && value !== null && minMaxReport
            ? getMinMaxColor(value as number, minMaxReport, colorScale!)
            : undefined,
        cursor: onClick ? 'pointer' : undefined,
        whiteSpace: 'nowrap',
      }}
    >
      <Typography noWrap>{getValue(type, value)}</Typography>
    </SlimTableCell>
  );
};

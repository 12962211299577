import { EVENT_RULES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import EventRules from '../../../domain/automator/eventRules/EventRules';
import { EventRulesQueryKey } from '../../queryKeys/EventRulesQueryKey';
import { EventRuleType } from '../../../domain/automator/eventRules/EventRuleType';
import { EventRuleStatus } from '../../../domain/automator/eventRules/EventRuleStatus';

export const useFetchEventRules = (type: EventRuleType, status: EventRuleStatus[]) => {
  const url = useAutomatorApiAccountUrl(
    EVENT_RULES_PATH + '?type=' + type + status.map((status) => `&status=${status}`).join('&')
  );
  return useFetchData<EventRules>(url, EventRulesQueryKey(type, status));
};

import Box from '@mui/material/Box';
import { LatestDays } from '../LatestDaysDropdownSelect';
import getLatestDateByLatestDays from '../../../../../../helpers/getLatestDateByLatestDays';
import getYesterday from '../../../../../../helpers/getYesterday';
import { useFetchAdGroupSearchTermPerformances } from '../../../../../../api/pd/adPerformances/useFetchAdGroupSearchTermPerformances';
import { tokens } from '../../../../../../locales/translationTokens';
import { AdGroupSearchTermPerformanceProperty } from '../../../../../../domain/pd/AdGroupSearchTermPerformance';
import { useTranslation } from 'react-i18next';
import { ChartProperty, PerformancesDateLineChart } from './PerformancesDateLineChart';
import { useFetchShareOfVoices } from '../../../../../../api/pd/adPerformances/useFetchShareOfVoices';
import { ShareOfVoiceProperty } from '../../../../../../domain/pd/ShareOfVoice';

interface AdGroupSearchTermPerformanceChartProps {
  campaignId: number;
  adGroupId: number;
  latestDays: LatestDays;
  searchTermId: number;
}

export const AdGroupSearchTermPerformanceChart = ({
  campaignId,
  adGroupId,
  searchTermId,
  latestDays,
}: AdGroupSearchTermPerformanceChartProps) => {
  const { data: adGroupSearchTermPerformances, isLoading: isLoadingAdGroupSearchTermPerformances } =
    useFetchAdGroupSearchTermPerformances(
      adGroupId,
      searchTermId,
      getLatestDateByLatestDays(latestDays),
      getYesterday()
    );

  const { data: shareOfVoices, isLoading: isLoadingShareOfVoices } = useFetchShareOfVoices(
    campaignId,
    searchTermId,
    getLatestDateByLatestDays(latestDays),
    getYesterday()
  );

  const { t } = useTranslation();

  if (isLoadingAdGroupSearchTermPerformances || isLoadingShareOfVoices) {
    return <Box>Loading...</Box>;
  }

  const availableChartProperties: ChartProperty[] = [
    {
      label: tokens.pd.performance.ad_group_search_term_performance_chart.impressions,
      property: AdGroupSearchTermPerformanceProperty.IMPRESSIONS,
    },
    {
      label: tokens.pd.performance.ad_group_search_term_performance_chart.clicks,
      property: AdGroupSearchTermPerformanceProperty.CLICKS,
    },
    {
      label: tokens.pd.performance.ad_group_search_term_performance_chart.ctr,
      property: AdGroupSearchTermPerformanceProperty.CTR,
    },
    {
      label: tokens.pd.performance.ad_group_search_term_performance_chart.conversions,
      property: AdGroupSearchTermPerformanceProperty.CONVERSIONS,
    },
    {
      label: tokens.pd.performance.ad_group_search_term_performance_chart.conversion_rate,
      property: AdGroupSearchTermPerformanceProperty.CONVERSION_RATE,
    },
    {
      label: tokens.pd.performance.ad_group_search_term_performance_chart.sales,
      property: AdGroupSearchTermPerformanceProperty.SALES,
    },
    {
      label: tokens.pd.performance.ad_group_search_term_performance_chart.spent,
      property: AdGroupSearchTermPerformanceProperty.SPENT,
    },
    {
      label: tokens.pd.performance.ad_group_search_term_performance_chart.cpc,
      property: AdGroupSearchTermPerformanceProperty.CPC,
    },
    {
      label: tokens.pd.performance.ad_group_search_term_performance_chart.acos,
      property: AdGroupSearchTermPerformanceProperty.ACOS,
    },
    {
      label: tokens.pd.performance.ad_group_search_term_performance_chart.roas,
      property: AdGroupSearchTermPerformanceProperty.ROAS,
    },
    {
      label: tokens.pd.performance.ad_group_search_term_performance_chart.average_winning_bid,
      property: AdGroupSearchTermPerformanceProperty.AVERAGE_WINNING_BID,
    },
    {
      label: tokens.pd.performance.ad_group_search_term_performance_chart.search_volume,
      property: AdGroupSearchTermPerformanceProperty.SEARCH_VOLUME,
    },
    {
      label: tokens.pd.performance.ad_group_search_term_performance_chart.click_share_percentage,
      property: ShareOfVoiceProperty.CLICK_SHARE_PERCENTAGE,
    },
    {
      label:
        tokens.pd.performance.ad_group_search_term_performance_chart.impression_share_percentage,
      property: ShareOfVoiceProperty.IMPRESSION_SHARE_PERCENTAGE,
    },
  ];

  const chartData = adGroupSearchTermPerformances!.adGroupSearchTermPerformances.map(
    (adGroupSearchTermPerformance) => {
      const shareOfVoice = shareOfVoices!.shareOfVoices.find(
        (shareOfVoice) => shareOfVoice.date === adGroupSearchTermPerformance.date
      );

      return {
        date: adGroupSearchTermPerformance.date,
        [AdGroupSearchTermPerformanceProperty.IMPRESSIONS]:
          adGroupSearchTermPerformance.impressions,
        [AdGroupSearchTermPerformanceProperty.CLICKS]: adGroupSearchTermPerformance.clicks,
        [AdGroupSearchTermPerformanceProperty.CTR]: adGroupSearchTermPerformance.ctr,
        [AdGroupSearchTermPerformanceProperty.CONVERSIONS]:
          adGroupSearchTermPerformance.conversions,
        [AdGroupSearchTermPerformanceProperty.CONVERSION_RATE]:
          adGroupSearchTermPerformance.conversionRate,
        [AdGroupSearchTermPerformanceProperty.SALES]:
          adGroupSearchTermPerformance[AdGroupSearchTermPerformanceProperty.SALES],
        [AdGroupSearchTermPerformanceProperty.SPENT]:
          adGroupSearchTermPerformance[AdGroupSearchTermPerformanceProperty.SPENT],
        [AdGroupSearchTermPerformanceProperty.CPC]:
          adGroupSearchTermPerformance[AdGroupSearchTermPerformanceProperty.CPC],
        [AdGroupSearchTermPerformanceProperty.ACOS]: adGroupSearchTermPerformance.acos,
        [AdGroupSearchTermPerformanceProperty.ROAS]: adGroupSearchTermPerformance.roas,
        [AdGroupSearchTermPerformanceProperty.AVERAGE_WINNING_BID]:
          adGroupSearchTermPerformance[AdGroupSearchTermPerformanceProperty.AVERAGE_WINNING_BID],
        [AdGroupSearchTermPerformanceProperty.SEARCH_VOLUME]:
          adGroupSearchTermPerformance.searchVolume,
        [ShareOfVoiceProperty.CLICK_SHARE_PERCENTAGE]:
          shareOfVoice?.[ShareOfVoiceProperty.CLICK_SHARE_PERCENTAGE] || 0,
        [ShareOfVoiceProperty.IMPRESSION_SHARE_PERCENTAGE]:
          shareOfVoice?.[ShareOfVoiceProperty.IMPRESSION_SHARE_PERCENTAGE] || 0,
      };
    }
  );

  return (
    <PerformancesDateLineChart
      label={t(
        tokens.pd.performance.ad_group_search_term_performance_chart
          .ad_group_search_term_performance
      )}
      chartProperties={availableChartProperties}
      data={chartData}
    />
  );
};

import { RETURN_ITEMS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { useUpdateData } from '../../useUpdateData';
import { useReturnsQueryKeysUpdater } from './useReturnsQueryKeysUpdater';
import Returns, { AGGREGATE_KEY } from '../../../domain/automator/returns/Returns';
import { ReturnItemQueryKey } from '../../queryKeys/ReturnItemQueryKey';
import { ReturnScansQueryKey } from '../../queryKeys/ReturnScansQueryKey';
import { OrderReturnsQueryKey } from '../../queryKeys/OrderReturnsQueryKey';

export const useReturnItemAwaitingRepair = (returnItemId: number) => {
  const url = useAutomatorApiAccountUrl(
    RETURN_ITEMS_PATH + '/' + returnItemId + `/awaiting-repair`
  );

  const updater = useReturnsQueryKeysUpdater();
  const updateData = useUpdateData(updater, AGGREGATE_KEY);

  const onSuccess = (returns: Returns) => {
    updateData(returns);
  };

  return useAuthorizedPost<Returns>(
    url,
    [ReturnItemQueryKey(returnItemId), OrderReturnsQueryKey(), ReturnScansQueryKey()],
    undefined,
    onSuccess
  );
};

import { AUTOMATOR_API_URL } from '../automatorApiPaths';
import { AuthResponse } from './AuthResponse';
import JtiStorageService from './JtiStorageService';
import JwtService from './JwtService';
import { useDispatch } from 'react-redux';
import { loginAction } from '../../../store/auth/authSlice';
import { useUnAuthorizedPost } from '../../useUnAuthorizedPost';

export const useRefreshToken = () => {
  const url = `${AUTOMATOR_API_URL}/auth/refresh-token`;
  const dispatch = useDispatch();

  const getData = () => {
    const jti = JtiStorageService.getJti();

    if (!jti) {
      throw 'jti is required';
    }

    return { jti: jti };
  };

  const onSuccess = (data: AuthResponse) => {
    JtiStorageService.setJti(data.jti);
    const accountId = JwtService.getAccountIdFromToken(data.jwt);

    dispatch(
      loginAction({
        userId: JwtService.getUserIdFromToken(data.jwt),
        accountId: accountId,
        jwt: data.jwt,
      })
    );
  };

  return useUnAuthorizedPost<AuthResponse>(url, undefined, getData, onSuccess);
};

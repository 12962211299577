import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { ResolutionDossierStatus } from '../../../domain/automator/resolutionDossiers/ResolutionDossierStatus';
import ResolutionDossierTabs from './components/ResolutionDossierTabs';
import { ResolutionDossierListTable } from './components/ResolutionDossierListTable';
import { useFetchResolutionDossiersReport } from '../../../api/automator/resolutions/useFetchResolutionDossiersReport';
import { useEffect, useState } from 'react';
import useQueryParam from '../../../helpers/useQueryParam';

const ResolutionDossiersPage = () => {
  const status = useQueryParam<ResolutionDossierStatus>('status');

  const [selectedStatus, setSelectedStatus] = useState<ResolutionDossierStatus>(
    status || ResolutionDossierStatus.OPEN
  );

  useEffect(() => {
    if (status != selectedStatus) {
      setSelectedStatus(status || ResolutionDossierStatus.OPEN);
    }
  }, [status]);

  const { data: resolutionDossiersReport, isLoading: isLoadingResolutionDossierReport } =
    useFetchResolutionDossiersReport();

  if (!resolutionDossiersReport) {
    return <>Loading...</>;
  }

  return (
    <AccountPage title={tokens.automator.resolutions.resolution_dossiers}>
      <ResolutionDossierTabs
        report={resolutionDossiersReport ? resolutionDossiersReport : null}
        currentTab={selectedStatus}
      />

      <ResolutionDossierListTable
        isLoading={isLoadingResolutionDossierReport}
        resolutionDossiersReport={resolutionDossiersReport ? resolutionDossiersReport : null}
        selectedStatus={selectedStatus}
      />
    </AccountPage>
  );
};

export default ResolutionDossiersPage;

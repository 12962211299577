import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { AD_PERFORMANCES } from '../pdApiPaths';
import { getDateStringFromDate } from '../../../helpers/getDateStringFromDate';
import AdPerformances from '../../../domain/pd/AdPerformances';
import { AdPerformancesQueryKey } from '../../queryKeys/AdPerformancesQueryKey';

export const useFetchAdPerformances = (
  offerId: number,
  startDate: Date,
  endDate: Date,
  rollingAverageDays: number
) => {
  const url = usePdApiAccountUrl(
    AD_PERFORMANCES +
      `?offerId=${offerId}&startDate=${getDateStringFromDate(
        startDate
      )}&endDate=${getDateStringFromDate(endDate)}&rollingAverageDays=${rollingAverageDays}`
  );
  return useFetchData<AdPerformances>(
    url,
    AdPerformancesQueryKey(offerId, startDate, endDate, rollingAverageDays)
  );
};

import { Tooltip } from '@mui/material';
import { TimelineEventMarkerMessage } from './TimelineEventMarkerMessage.tsx';
import Box from '@mui/material/Box';
import { PerformanceEventType } from '../../../../../domain/pd/PerformanceEventType';
import { DeliveryCode } from '../../../../../domain/pd/DeliveryCode';
import BundlePrice from '../../../../../domain/pd/BundlePrice';

export interface TimelineEvent {
  value1: string | number | DeliveryCode | BundlePrice;
  value2?: string | number | DeliveryCode | BundlePrice | undefined;
  icon?: string;
  type?: PerformanceEventType;
}

interface TimelineEventMarkerProps {
  timelineEvent: TimelineEvent;
}

export const TimeLineEventMarker = ({ timelineEvent }: TimelineEventMarkerProps) => {
  const getColor = (eventType?: PerformanceEventType) => {
    switch (eventType) {
      case PerformanceEventType.OFFER_PRICE:
        return '#77dd77';
      case PerformanceEventType.OFFER_VOLUME_DISCOUNT:
        return '#ffb347';
      case PerformanceEventType.OFFER_DELIVERY_CODE:
        return '#aec6cf';
      case PerformanceEventType.CAMPAIGN_ACOS_PERCENTAGE:
        return '#ffb6c1';
      case PerformanceEventType.CAMPAIGN_TARGET_CHANNELS:
        return '#b39eb5';
      case PerformanceEventType.CAMPAIGN_DAILY_BUDGET:
        return '#fdfd96';
      case PerformanceEventType.CAMPAIGN_TOTAL_BUDGET:
        return '#ff6961';
      case PerformanceEventType.PRODUCT_RATING:
        return '#cfcfc4';
      case PerformanceEventType.COMPETITOR_PRODUCT_IS_IN_STOCK:
        return '#73730d';
      case PerformanceEventType.COMPETITOR_PRODUCT_PRICE:
        return '#085f78';
      default: {
        const name = timelineEvent.value1 as string;

        let hash = 0;
        for (let i = 0; i < name.length; i++) {
          hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (let i = 0; i < 3; i++) {
          const value = (hash >> (i * 8)) & 0xff;
          color += ('00' + (value + 100).toString(16)).substr(-2);
        }
        return color;
      }
    }
  };

  return (
    <Tooltip title={<TimelineEventMarkerMessage timelineEvent={timelineEvent} />}>
      <Box
        sx={{
          width: 24,
          height: 24,
          borderRadius: '50%',
          backgroundColor: getColor(timelineEvent.type),
        }}
      />
    </Tooltip>
  );
};

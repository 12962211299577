import { PRODUCT_GROUPS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import ProductGroups from '../../../domain/automator/products/ProductGroups';
import { ProductGroupsQueryKey } from '../../queryKeys/ProductGroupsQueryKey';

export const useFetchProductGroups = () => {
  const url = useAutomatorApiAccountUrl(`${PRODUCT_GROUPS_PATH}`);
  return useFetchData<ProductGroups>(url, ProductGroupsQueryKey());
};

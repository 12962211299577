import { tss } from 'tss-react/mui-compat';
import { useCancelOrderItem } from '../../../../api/automator/orders/useCancelOrderItem';
import ActionButton from '../../../../components/ActionButton';
import { CancellationReason } from '../../../../domain/automator/orders/CancellationReason';
import { useState } from 'react';
import { CancellationReasonDropdown } from './CancellationReasonDropdown';
import { tokens } from '../../../../locales/translationTokens';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import OrderItem from '../../../../domain/automator/orders/OrderItem';

interface CancelOrderItemFormProps {
  orderItem: OrderItem;
  onClick: () => void;
}

export const CancelOrderItemForm = ({ orderItem, onClick }: CancelOrderItemFormProps) => {
  const [cancellationReason, setCancellationReason] = useState<CancellationReason>(
    orderItem.cancellationRequest
      ? CancellationReason.REQUESTED_BY_CUSTOMER
      : CancellationReason.OTHER
  );

  const { t } = useTranslation();
  const { cx, classes } = useStyles();
  const { mutate: cancelOrderItem, isLoading } = useCancelOrderItem(orderItem.id);

  const onSave = () => {
    cancelOrderItem(
      { cancellation_reason: cancellationReason },
      {
        onSuccess: async () => {
          onClick();
          toast.success(t(tokens.automator.orders.order_item_cancelled));
        },
      }
    );
  };

  return (
    <div className={cx(classes.cancelOrderItemForm)}>
      <div>{t(tokens.automator.orders.order_item_cancellation_explanation)}</div>

      <CancellationReasonDropdown
        selected={cancellationReason}
        onSelect={setCancellationReason}
      />

      <ActionButton
        label={t(tokens.automator.orders.cancel_order_item)}
        onClick={onSave}
        isLoading={isLoading}
      />
    </div>
  );
};

const useStyles = tss.create(() => ({
  cancelOrderItemForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
}));

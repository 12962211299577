import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { OFFERS_PATH } from '../automatorApiPaths';
import { OffersQueryKey } from '../../queryKeys/OffersQueryKey';
import { DeliveryCode } from '../../../domain/automator/offers/DeliveryCode';
import AutomatorProducts from '../../../domain/automator/products/AutomatorProducts';
import { useAutomatorProductQueryKeysUpdater } from '../products/updaters/useAutomatorProductQueryKeysUpdater';
import { useUpdateData } from '../../useUpdateData';
import BundlePrice from '../../../domain/automator/offers/BundlePrice';

export const useUpdateOffers = () => {
  const url = useAutomatorApiAccountUrl(`${OFFERS_PATH}/update`);

  const updater = useAutomatorProductQueryKeysUpdater();
  const updateData = useUpdateData(updater, 'products');

  const onSuccess = (products: AutomatorProducts) => {
    updateData(products);
  };

  return useAuthorizedPost<AutomatorProducts, UpdateOffersData>(
    url,
    [OffersQueryKey()],
    undefined,
    onSuccess
  );
};

export interface UpdateOffersData {
  offer_ids: number[];
  delivery_code: DeliveryCode | null;
  bundle_prices: BundlePrice[] | null;
  internal_reference: string | null;
}

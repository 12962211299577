import { ORDERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import MessageThread from '../../../domain/automator/messages/MessageThread';
import { OrderMessageThreadsQueryKey } from '../../queryKeys/OrderMessageThreadsQueryKey';

export const useFetchOrderMessageThreads = (orderId: number) => {
  const url = useAutomatorApiAccountUrl(ORDERS_PATH + '/' + orderId + '/message-threads');
  return useFetchData<MessageThread[]>(url, OrderMessageThreadsQueryKey(orderId));
};

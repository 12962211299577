import { ORDERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { OrderResolutionCasesQueryKey } from '../../queryKeys/OrderResolutionCasesQueryKey';
import ReducedResolutionCase from '../../../domain/automator/resolutionDossiers/ReducedResolutionCase';

export const useFetchOrderResolutionCases = (orderId: number) => {
  const url = useAutomatorApiAccountUrl(ORDERS_PATH + '/' + orderId + '/resolution-cases');
  return useFetchData<ReducedResolutionCase[]>(url, OrderResolutionCasesQueryKey(orderId));
};

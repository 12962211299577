import Shipment from '../../../../domain/automator/shipments/Shipment';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { ShipmentRecipient } from './ShipmentReceipient';
import RetailerLooksIcon from '../../retailers/components/RetailerLooksIcon';
import DistributionPartyChip from './DistributionPartyChip';

interface ShipmentCardProps {
  shipment: Shipment;
}

const ShipmentCard = ({ shipment }: ShipmentCardProps) => {
  return (
    <Stack direction="column">
      <Stack
        direction="row"
        gap={1}
        alignItems={'center'}
      >
        <DistributionPartyChip distributionParty={shipment.order.distributionParty} />

        <ShipmentRecipient
          shipmentDetails={shipment.order.shipmentDetails}
          pickupPoint={shipment.pickupPoint}
        />
      </Stack>
      <Stack
        direction={'row'}
        spacing={0.5}
        alignItems={'center'}
      >
        <Typography variant="body2">{shipment.order.bolOrderId}</Typography>
        <RetailerLooksIcon retailerId={shipment.retailerId} />
      </Stack>
    </Stack>
  );
};

export default ShipmentCard;

import TableRow from '@mui/material/TableRow';
import { ColorScale, PerformanceDateType, PerformanceTableCell } from '../PerformanceTableCell';
import AdPerformance from '../../../../../../domain/pd/AdPerformance';
import AdPerformanceMinMaxReports from '../../../../../../domain/pd/AdPerformanceMinMaxReports';

interface AdPerformancesTableRowProps {
  adPerformance: AdPerformance;
  adPerformanceMinMaxReports: AdPerformanceMinMaxReports;
}

export const AdPerformancesTableRow = ({
  adPerformance,
  adPerformanceMinMaxReports,
}: AdPerformancesTableRowProps) => {
  return (
    <TableRow>
      <PerformanceTableCell
        value={adPerformance.clicks}
        type={PerformanceDateType.ROUNDED}
        minMaxReport={adPerformanceMinMaxReports.clicks}
        colorScale={ColorScale.BLUE}
      />

      <PerformanceTableCell
        value={adPerformance.conversions}
        type={PerformanceDateType.ROUNDED}
        minMaxReport={adPerformanceMinMaxReports.conversions}
        colorScale={ColorScale.BLUE}
      />

      <PerformanceTableCell
        value={adPerformance.conversionRate}
        type={PerformanceDateType.PERCENTAGE}
        minMaxReport={adPerformanceMinMaxReports.conversionRate}
        colorScale={ColorScale.BLUE}
      />

      <PerformanceTableCell
        value={adPerformance.cpcInCents}
        type={PerformanceDateType.CURRENCY}
        minMaxReport={adPerformanceMinMaxReports.cpcInCents}
        colorScale={ColorScale.BLUE}
      />

      <PerformanceTableCell
        value={adPerformance.spentInCents}
        type={PerformanceDateType.CURRENCY}
        minMaxReport={adPerformanceMinMaxReports.spentInCents}
        colorScale={ColorScale.BLUE}
      />

      <PerformanceTableCell
        value={adPerformance.impressions}
        type={PerformanceDateType.ROUNDED}
        minMaxReport={adPerformanceMinMaxReports.impressions}
        colorScale={ColorScale.BLUE}
      />

      <PerformanceTableCell
        value={adPerformance.acos}
        type={PerformanceDateType.PERCENTAGE}
        minMaxReport={adPerformanceMinMaxReports.acos}
        colorScale={ColorScale.BLUE}
      />

      <PerformanceTableCell
        value={adPerformance.ctr}
        type={PerformanceDateType.PERCENTAGE}
        minMaxReport={adPerformanceMinMaxReports.ctr}
        colorScale={ColorScale.BLUE}
      />
    </TableRow>
  );
};

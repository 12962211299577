import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { TableEmpty } from '../../../../components/TableEmpty';
import ProposedAction from '../../../../domain/automator/alerts/ProposedAction';
import { CollapsableAlertsListTableRows } from './CollapsableAlertsListTableRows';
import EventTrigger from '../../../../domain/automator/eventRules/EventTrigger';
import { DateTableListDivider } from '../../returns/components/DateTableListDivider';
import { GetProposedActionsStatus } from '../../../../domain/automator/alerts/GetProposedActionsStatus';
import { Fragment } from 'react';
import { useFetchProposedActions } from '../../../../api/automator/alerts/useFetchProposedActions';

interface AlertsListTableProps {
  isLoading: boolean;
  selectedDate: string | null;
  setDate: (date: string) => void;
  availableDates: string[];
  status: GetProposedActionsStatus;
}

export const AlertsListTable = ({
  isLoading,
  setDate,
  selectedDate,
  availableDates,
  status,
}: AlertsListTableProps) => {
  const { t } = useTranslation();

  const { data: proposedActions, isLoading: isLoadingProposedActionsReport } =
    useFetchProposedActions(status, selectedDate);

  if (isLoading || isLoadingProposedActionsReport) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (proposedActions?.proposedActions!.length == 0) {
    return <TableEmpty message={t(tokens.common.no_items)} />;
  }

  const groupedProposedActionsByDate = proposedActions!.proposedActions
    .sort(
      (
        a,
        b // NOTE: we assume that if date selected then is dismissed and thus updatedDateTime is time of dismissal
      ) =>
        new Date(
          status == GetProposedActionsStatus.EXECUTED ? b.updatedDateTime! : b.createdDateTime
        ).getTime() -
        new Date(
          status == GetProposedActionsStatus.EXECUTED ? a.updatedDateTime! : a.createdDateTime
        ).getTime()
    )
    .reduce(
      (groups, proposedAction) => {
        const date = (
          status == GetProposedActionsStatus.EXECUTED
            ? proposedAction.updatedDateTime!
            : proposedAction.createdDateTime
        ).substring(0, 10);

        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date]!.push(proposedAction);

        return groups;
      },
      {} as { [key: string]: ProposedAction[] }
    );

  const groupedProposedActionsByEventTrigger = (proposedActions: ProposedAction[]) => {
    return proposedActions.reduce(
      (groups, proposedAction) => {
        const trigger = proposedAction.eventRule.trigger;
        if (!groups[trigger]) {
          groups[trigger] = [];
        }
        groups[trigger]!.push(proposedAction);
        return groups;
      },
      {} as { [key: string]: ProposedAction[] }
    );
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={350}>{t(tokens.automator.alerts.trigger)}</TableCell>
              <TableCell>{t(tokens.automator.alerts.order)}</TableCell>
              <TableCell />
              <TableCell align="right">{t(tokens.automator.alerts.actions)}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {availableDates
              .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
              .map((date) => {
                const alerts = groupedProposedActionsByDate[date];

                const dateObject = new Date(date);
                dateObject.setHours(0, 0, 0, 0);

                return (
                  <Fragment key={date}>
                    <DateTableListDivider
                      onClick={
                        status == GetProposedActionsStatus.EXECUTED
                          ? () => setDate(date)
                          : undefined
                      }
                      date={date}
                    />
                    {(status == GetProposedActionsStatus.OPEN || selectedDate === date) &&
                      Object.entries(groupedProposedActionsByEventTrigger(alerts)).map(
                        ([trigger, alertList], index) => {
                          return (
                            <CollapsableAlertsListTableRows
                              key={index}
                              eventTrigger={trigger as EventTrigger}
                              alerts={alertList}
                            />
                          );
                        }
                      )}
                  </Fragment>
                );
              })}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};

import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { useFetchReturnScans } from '../../../api/automator/returnScans/useFetchReturnScans';
import { ReturnScanListTable } from './components/ReturnScanListTable';

const ReturnScansPage = () => {
  const { data: returnScans, isLoading } = useFetchReturnScans();

  return (
    <AccountPage title={tokens.automator.return_scans.return_scans}>
      <ReturnScanListTable
        isLoading={isLoading}
        returnScans={returnScans}
      />
    </AccountPage>
  );
};

export default ReturnScansPage;

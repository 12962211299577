import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { ActionIcon } from '../../orders/components/ActionIcon';
import { ThreadPhase } from '../../../../domain/automator/messages/ThreadPhase';
import { ActionType } from '../../orders/components/copyButtons/ActionType';
import ActionButton from '../../../../components/ActionButton';
import { tokens } from '../../../../locales/translationTokens';
import * as React from 'react';
import { RecipientType } from '../../../../domain/automator/messages/RecipientType';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { MessageTemplateType } from '../../../../domain/automator/messages/MessageTemplateType';

interface OpenForwardEmailDialogButtonProps {
  orderId: number;
  orderItemId: number | null;
  returnItemId: number | null;
  shipmentId: number | null;
  currentPhase: ThreadPhase;
  body: string | null;
  type: ActionType;
}

export const OpenForwardEmailDialogButton = ({
  orderId,
  orderItemId,
  returnItemId,
  shipmentId,
  currentPhase,
  body,
  type,
}: OpenForwardEmailDialogButtonProps) => {
  const openDialog = useOpenDialog(DialogId.CREATE_EMAIL_THREAD);

  const icon = <ReplyAllIcon />;
  const label = tokens.automator.resolutions.dialogs.send_email.forward_email;

  const determineAggregateId = () => {
    if (returnItemId) {
      return returnItemId;
    } else if (shipmentId) {
      return shipmentId;
    } else if (orderItemId) {
      return orderItemId;
    } else return null;
  };

  const onClick = () => {
    openDialog({
      aggregateId: determineAggregateId(),
      orderId: orderId,
      messageTemplateId: null,
      phase: currentPhase,
      body: body,
      recipientType: RecipientType.CUSTOMER,
      messageTemplateType: MessageTemplateType.MANUAL,
    });
  };

  return type === ActionType.ICON ? (
    <ActionIcon
      icon={icon}
      tooltip={label}
      onClick={onClick}
    />
  ) : (
    <ActionButton
      size="small"
      icon={icon}
      label={label}
      onClick={onClick}
      variant="text"
      color="primary"
    />
  );
};

import ResolutionCase from '../../../../domain/automator/resolutionDossiers/ResolutionCase';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import { useFetchThreadMessages } from '../../../../api/automator/emails/useFetchThreadMessages';
import { OpenSendEmailThreadReplyButton } from './OpenSendEmailThreadReplyButton';
import { Box } from '@mui/system';

interface OfferResolutionCaseActionsProps {
  resolutionCase: ResolutionCase;
}

const OfferResolutionCaseActions = ({ resolutionCase }: OfferResolutionCaseActionsProps) => {
  const { data: emailMessages, isLoading } = useFetchThreadMessages(
    resolutionCase.messageThread.id
  );

  if (isLoading || !emailMessages) {
    return <>Loading...</>;
  }

  return (
    <Stack
      borderRadius={1}
      border="1px solid BLUE"
      direction={'row'}
      gap={0.5}
      alignItems="left"
      paddingY={1}
      paddingX={1}
    >
      <Stack
        gap={2}
        direction="row"
        alignItems="center"
      >
        <Stack
          direction="column"
          alignItems="top"
          gap={0.25}
        >
          <Box>
            <OpenSendEmailThreadReplyButton
              resolutionCaseId={resolutionCase.id}
              messageThread={resolutionCase.messageThread}
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default OfferResolutionCaseActions;

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { tokens } from '../../../../../../locales/translationTokens';
import {
  PerformanceHeaderTableCell,
  PerformanceHeaderTableCellColor,
} from '../PerformanceHeaderTableCell';
import OfferPerformances from '../../../../../../domain/pd/OfferPerformances';
import ProductPerformances from '../../../../../../domain/pd/ProductPerformances';
import { OfferPerformanceSearchMetricsTableRow } from './OfferPerformanceSearchMetricsTableRow';

interface OfferPerformancesSearchMetricsTableProps {
  offerPerformances: OfferPerformances;
  productPerformances: ProductPerformances;
  rollingAverageDays: number;
}

export const OfferPerformanceSearchMetricsTable = ({
  offerPerformances,
  productPerformances,
  rollingAverageDays,
}: OfferPerformancesSearchMetricsTableProps) => {
  const offerPerformancesSorted = offerPerformances.offerPerformances.sort((a, b) =>
    new Date(a.date).getTime() - new Date(b.date).getTime() > 0 ? -1 : 1
  );

  const backgroundColor = PerformanceHeaderTableCellColor.LIGHT_PINK;

  return (
    <Table
      stickyHeader
      size="small"
    >
      <TableHead>
        <TableRow>
          <PerformanceHeaderTableCell
            value={tokens.pd.performance.offer_performance_search_metrics_table.search_metrics}
            backgroundColor={backgroundColor}
            colSpan={3}
          />
        </TableRow>
      </TableHead>

      <TableHead>
        <TableRow>
          <PerformanceHeaderTableCell
            value={tokens.pd.performance.offer_performance_search_metrics_table.impressions}
            backgroundColor={backgroundColor}
            rollingAverageDays={rollingAverageDays}
          />

          <PerformanceHeaderTableCell
            value={tokens.pd.performance.offer_performance_search_metrics_table.ctr}
            backgroundColor={backgroundColor}
            rollingAverageDays={rollingAverageDays}
          />

          <PerformanceHeaderTableCell
            value={tokens.pd.performance.offer_performance_search_metrics_table.weighted_rank}
            backgroundColor={backgroundColor}
            rollingAverageDays={rollingAverageDays}
          />
        </TableRow>
      </TableHead>

      <TableBody>
        {offerPerformancesSorted.map((offerPerformance, index) => {
          return (
            <OfferPerformanceSearchMetricsTableRow
              key={index}
              offerPerformance={offerPerformance}
              offerPerformancesMinMaxReport={offerPerformances.minMaxReports}
              productPerformance={productPerformances.productPerformances.find(
                (productPerformance) => productPerformance.date === offerPerformance.date
              )}
              productPerformancesMinMaxReport={productPerformances.minMaxReports}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

import { ORDERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import Order from '../../../domain/automator/orders/Order';
import { PinnedOrdersQueryKey } from '../../queryKeys/PinnedOrdersQueryKey';

export const useFetchPinnedOrders = () => {
  const url = useAutomatorApiAccountUrl(ORDERS_PATH + '/pinned');
  return useFetchData<Order[]>(url, PinnedOrdersQueryKey());
};

export default interface AdGroupSearchTermPerformance {
  id: number;
  [AdGroupSearchTermPerformanceProperty.IMPRESSIONS]: number;
  [AdGroupSearchTermPerformanceProperty.CLICKS]: number;
  [AdGroupSearchTermPerformanceProperty.CTR]: number;
  [AdGroupSearchTermPerformanceProperty.CONVERSIONS]: number;
  [AdGroupSearchTermPerformanceProperty.CONVERSION_RATE]: number;
  [AdGroupSearchTermPerformanceProperty.SALES]: number;
  [AdGroupSearchTermPerformanceProperty.SPENT]: number;
  [AdGroupSearchTermPerformanceProperty.CPC]: number;
  [AdGroupSearchTermPerformanceProperty.ACOS]: number;
  [AdGroupSearchTermPerformanceProperty.ROAS]: number;
  [AdGroupSearchTermPerformanceProperty.AVERAGE_WINNING_BID]: number;
  [AdGroupSearchTermPerformanceProperty.SEARCH_VOLUME]: number;
  date: string;
}

export enum AdGroupSearchTermPerformanceProperty {
  IMPRESSIONS = 'impressions',
  CLICKS = 'clicks',
  CTR = 'ctr',
  CONVERSIONS = 'conversions',
  CONVERSION_RATE = 'conversionRate',
  SALES = 'salesInCents',
  SPENT = 'spentInCents',
  CPC = 'cpcInCents',
  ACOS = 'acos',
  ROAS = 'roas',
  AVERAGE_WINNING_BID = 'averageWinningBidInCents',
  SEARCH_VOLUME = 'searchVolume',
}

import { useFetchOrderCustomerInvoices } from '../../../api/automator/customerInvoiceRequests/useFetchOrderCustomerInvoices';
import { DownloadCustomerInvoicePdfButton } from '../customerInvoiceRequests/components/DownloadCustomerInvoicePdfButton';
import { tokens } from '../../../locales/translationTokens';
import { OrderSummaryCardItem } from './components/OrderSummaryCardItem';
import Stack from '@mui/material/Stack';

interface OrderSummaryCustomerInvoicesCardItemProps {
  orderId: number;
}

export const OrderSummaryCustomerInvoicesCardItem = ({
  orderId,
}: OrderSummaryCustomerInvoicesCardItemProps) => {
  const { data: customerInvoices, isLoading } = useFetchOrderCustomerInvoices(orderId);

  if (isLoading) {
    return <>...loading</>;
  }

  return (
    <OrderSummaryCardItem label={tokens.automator.order_summary.invoices}>
      <Stack
        direction="column"
        gap={3}
      >
        {customerInvoices!.map((customerInvoice) => {
          return (
            <Stack
              key={customerInvoice.id}
              direction="row"
              gap={2}
            >
              <DownloadCustomerInvoicePdfButton customerInvoice={customerInvoice} />
            </Stack>
          );
        })}
      </Stack>
    </OrderSummaryCardItem>
  );
};

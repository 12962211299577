import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { ThreadPhase } from '../../../../domain/automator/messages/ThreadPhase';
import { ToggleButtonGroup } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';

interface EmailPhaseToggleButtonGroupProps {
  onSelect: (selectedPhase: ThreadPhase) => void;
  value: ThreadPhase;
  selectablePhases: ThreadPhase[];
}

export const EmailPhaseToggleButtonGroup = ({
  onSelect,
  value,
  selectablePhases,
}: EmailPhaseToggleButtonGroupProps) => {
  const { t } = useTranslation();

  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      onChange={(_, value) => onSelect(value as ThreadPhase)}
      aria-label="Platform"
    >
      <ToggleButton
        disabled={!selectablePhases.includes(ThreadPhase.ORDER)}
        value={ThreadPhase.ORDER}
      >
        {t(tokens.automator.resolutions.phase.order)}
      </ToggleButton>
      <ToggleButton
        disabled={!selectablePhases.includes(ThreadPhase.SHIPMENT)}
        value={ThreadPhase.SHIPMENT}
      >
        {t(tokens.automator.resolutions.phase.shipment)}
      </ToggleButton>
      <ToggleButton
        disabled={!selectablePhases.includes(ThreadPhase.RETURN)}
        value={ThreadPhase.RETURN}
      >
        {t(tokens.automator.resolutions.phase.return)}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export function generateDateRangeList(
  startDate: Date,
  endDate: Date,
  isDescending: boolean = true
): Date[] {
  const currentDate = new Date(startDate); // Avoid mutating the original date
  const dates = [];

  while (currentDate <= endDate) {
    if (isDescending) {
      dates.unshift(new Date(currentDate)); // Push a copy of the date
    } else {
      dates.push(new Date(currentDate)); // Push a copy of the date
    }

    currentDate.setDate(currentDate.getDate() + 1); // Increment the date
  }

  return dates;
}

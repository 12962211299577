import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { AD_GROUP_SEARCH_TERM_PERFORMANCE_SEARCH_TERMS } from '../pdApiPaths';
import AdGroupSearchTermPerformanceSearchTerms from '../../../domain/pd/AdGroupSearchTermPerformanceSearchTerms';
import { AdGroupSearchTermPerformanceSearchTermsQueryKey } from '../../queryKeys/AdGroupSearchTermPerformanceSearchTermsQueryKey';
import { getDateStringFromDate } from '../../../helpers/getDateStringFromDate';

export const useFetchAdGroupSearchTermPerformanceSearchTerms = (
  adGroupId: number,
  startDate: Date,
  endDate: Date
) => {
  const url = usePdApiAccountUrl(
    AD_GROUP_SEARCH_TERM_PERFORMANCE_SEARCH_TERMS +
      `?adGroupId=${adGroupId}&startDate=${getDateStringFromDate(
        startDate
      )}&endDate=${getDateStringFromDate(endDate)}`
  );
  return useFetchData<AdGroupSearchTermPerformanceSearchTerms>(
    url,
    AdGroupSearchTermPerformanceSearchTermsQueryKey(adGroupId, startDate, endDate)
  );
};

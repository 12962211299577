import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import { Email } from '@mui/icons-material';
import { toast } from 'react-hot-toast';
import { useUpdateOrderNote } from '../../../../api/automator/orders/useUpdateOrderNote';
import { useFetchOrderNote } from '../../../../api/automator/orders/useFetchOrderNote';

interface UpdateOrderNoteFormProps {
  onClick?: (note: string) => void;
  orderId: number;
}

export const UpdateOrderNoteForm = ({ onClick, orderId }: UpdateOrderNoteFormProps) => {
  const { data: orderNote } = useFetchOrderNote(orderId);
  const [note, setNote] = useState('');

  useEffect(() => {
    if (orderNote) {
      setNote(orderNote.note);
    }
  }, [orderNote]);

  const { t } = useTranslation();

  const orderNoteId = orderNote?.id ?? 0;

  const { mutate: update, isLoading } = useUpdateOrderNote(orderId, orderNoteId);

  const onUpdate = () => {
    update(
      {
        note: note,
      },
      {
        onSuccess: async () => {
          onClick?.(note);
          toast.success(t(tokens.automator.orders.dialogs.update_order_note.note_updated));
        },
      }
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
      onBlur={onUpdate}
    >
      <TextField
        id="note-field"
        label={t(tokens.automator.orders.dialogs.update_order_note.note)}
        variant="filled"
        multiline
        rows={4}
        fullWidth
        style={{ marginBottom: '1em' }}
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />

      <ActionButton
        icon={<Email />}
        label={t(tokens.automator.orders.dialogs.update_order_note.update_note)}
        onClick={() => onUpdate()}
        isLoading={isLoading}
        variant="contained"
        color="primary"
        size="small"
      />
    </form>
  );
};

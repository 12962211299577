import { DialogId } from '../../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../../../../../store/dialogs/useCloseDialog';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../locales/translationTokens';
import { ApplicationDialog } from '../../../../../components/ApplicationDialog';
import OfferShippingCosts from '../../../../../domain/pd/OfferShippingCosts';
import { UpdateOfferShippingCostsForm } from '../components/forms/UpdateOfferShippingCostsForm';

export const UpdateOfferShippingCostsDialog = () => {
  const id = DialogId.UPDATE_OFFER_SHIPPING_COSTS;
  const { isOpen, data } = useIsDialogOpen(id);
  const closeDialog = useCloseDialog(id);
  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          title={t(tokens.pd.performance.update_offer_shipping_costs_form.update_shipping_costs)}
        >
          <UpdateOfferShippingCostsForm
            offerShippingCosts={data.offerShippingCosts}
            onClick={closeDialog}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface UpdateOfferShippingCostsDialogData {
  offerShippingCosts: OfferShippingCosts;
}

import Typography from '@mui/material/Typography';

interface DaysUntilReturnTextProps {
  daysUntilReturn: number;
}

const DaysUntilReturnText = ({ daysUntilReturn }: DaysUntilReturnTextProps) => {
  const days = daysUntilReturn < 0 ? 0 : daysUntilReturn;

  return (
    <Typography
      paddingTop={'0.1rem'}
      variant="body2"
    >
      {`(${days}d)`}
    </Typography>
  );
};

export default DaysUntilReturnText;

import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { DistributionParty } from '../../../domain/automator/orders/DistributionParty';
import { CountryCode } from '../../../domain/automator/orders/CountryCode';
import EventTrigger from '../../../domain/automator/eventRules/EventTrigger';
import { EventRulesQueryKey } from '../../queryKeys/EventRulesQueryKey';
import EventRules from '../../../domain/automator/eventRules/EventRules';
import { EVENT_RULES_PATH } from '../automatorApiPaths';

export const useUpdateEventRule = (eventRuleId: number) => {
  const url = useAutomatorApiAccountUrl(EVENT_RULES_PATH + '/' + eventRuleId);
  return useAuthorizedPost<EventRules, UseUpdateEventRuleData>(url, [EventRulesQueryKey()]);
};

export interface UseUpdateEventRuleData {
  product_ids: number[];
  distribution_party: DistributionParty | null;
  country_code: CountryCode | null;
  trigger: EventTrigger;
}

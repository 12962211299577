import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ReimbursementType } from '../../../../domain/automator/reimbursements/ReimbursementType';
import EventTrigger from '../../../../domain/automator/eventRules/EventTrigger';
import ReducedOrder from '../../../../domain/automator/orders/ReducedOrder';
import { ReimbursementTypeChip } from './ReimbursementTypeChip';
import { CondensedOrderView } from '../../orderSummaries/components/CondensedOrderView';
import { ReimbursementStatusChip } from './ReimbursementStatusChip';
import { DismissReimbursementActionIcon } from './DismissReimbursementActionIcon';
import Stack from '@mui/material/Stack';
import { ReimbursementOriginChip } from './ReimbursementOriginChip';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';

export interface ReimbursementTableRowData {
  reimbursementId: number | null;
  reimbursementRequestId: number | null;
  type: ReimbursementType | null;
  eventTrigger: EventTrigger | null;
  order: ReducedOrder;
  amountInCents: number | null;
  bolCaseNumber: string | null;
  isSeen: boolean;
  date: string;
}

interface ReimbursementTableRowProps {
  data: ReimbursementTableRowData;
}

export const ReimbursementTableRow = ({ data }: ReimbursementTableRowProps) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell>
        <Stack
          direction="column"
          gap={0.5}
        >
          <ReimbursementTypeChip type={data.type} />
          {!data.isSeen && (
            <Chip
              size="small"
              label={t(tokens.common.is_new)}
              variant={'filled'}
              color={'info'}
            />
          )}

          <Typography
            alignSelf="center"
            variant="body2"
          >
            {data.date}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell>{data.eventTrigger}</TableCell>

      <TableCell>
        <Stack
          direction="row"
          gap={2}
        >
          <ReimbursementOriginChip
            reimbursementRequestId={data.reimbursementRequestId}
            eventTrigger={data.eventTrigger}
          />

          {data.bolCaseNumber && <Typography variant="body2">{data.bolCaseNumber}</Typography>}
        </Stack>
      </TableCell>

      <TableCell>
        <CondensedOrderView
          showDistributionParty={true}
          order={data.order}
        />
      </TableCell>

      <TableCell>
        <ReimbursementStatusChip amountInCents={data.amountInCents} />
      </TableCell>

      <TableCell>
        <DismissReimbursementActionIcon
          reimbursementId={data.reimbursementId}
          reimbursementRequestId={data.reimbursementRequestId}
        />
      </TableCell>
    </TableRow>
  );
};

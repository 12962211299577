import { useFetchMessageTemplates } from '../../../../api/automator/emails/useFetchMessageTemplates';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import Stack from '@mui/material/Stack';
import { ThreadPhase } from '../../../../domain/automator/messages/ThreadPhase';
import { ReturnRecoverability } from '../../../../domain/automator/returns/ReturnRecoverability';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import { MessageTemplateType } from '../../../../domain/automator/messages/MessageTemplateType';
import { MessageTemplateSelect } from './MessageTemplateSelect';
import * as React from 'react';

interface MessageTemplateSelectionProps {
  type: MessageTemplateType;
  phase: ThreadPhase;
  recoverability: ReturnRecoverability | null;
  onSelect: (messageTemplateId: number) => void;
  selected: number | null;
}

export const MessageTemplateSelection = ({
  type,
  phase,
  recoverability,
  onSelect,
  selected,
}: MessageTemplateSelectionProps) => {
  const { data: messageTemplates } = useFetchMessageTemplates(type, phase, recoverability);
  const createMessageTemplateDialog = useOpenDialog(DialogId.CREATE_MESSAGE_TEMPLATE);

  return (
    <Stack
      gap={2.5}
      direction="column"
      justifyContent="space-between"
      width="100%"
    >
      <Stack
        gap={1}
        direction="column"
      >
        {messageTemplates?.messageTemplates.map((messageTemplate, index) => (
          <MessageTemplateSelect
            key={index}
            messageTemplate={messageTemplate}
            onSelectMessageTemplate={onSelect}
            selectedMessageTemplateId={selected}
          />
        ))}
      </Stack>

      <ActionButton
        label={tokens.automator.returns.create_email_template}
        onClick={() =>
          createMessageTemplateDialog({
            phase: phase,
            recoverability: recoverability,
            type: type,
            onCreate: onSelect,
          })
        }
        variant="outlined"
      />
    </Stack>
  );
};

import { ACCOUNTS_PATH, AUTOMATOR_API_URL } from './automatorApiPaths';
import { useAccountIdFromUrl } from '../useAccountIdFromUrl';

export const useAutomatorApiAccountUrl = (path?: string): string => {
  const accountId = useAccountIdFromUrl();

  if (accountId == null) {
    throw Error('Account id can not be null.');
  }

  const apath = path ? `/${path}` : '';

  return `${AUTOMATOR_API_URL}/${ACCOUNTS_PATH}/${accountId}${apath}`;
};

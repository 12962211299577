import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { PICK_ORDERS_PATH } from '../automatorApiPaths';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import PickOrders from '../../../domain/automator/pickSessions/PickOrders';
import { PickSessionPickOrdersQueryKey } from '../../queryKeys/PickSessionPickOrdersQueryKey';
import { OrdersQueryKey } from '../../queryKeys/OrdersQueryKey';
import { OrderStatus } from '../../../domain/automator/orders/OrderStatus';
import { DistributionParty } from '../../../domain/automator/orders/DistributionParty';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';
import { PickSessionsQueryKey } from '../../queryKeys/PickSessionsQueryKey';

export const useRejectPickOrders = (pickSessionId: number) => {
  const url = useAutomatorApiAccountUrl(PICK_ORDERS_PATH + `/reject`);

  return useAuthorizedPost<PickOrders, RejectPickOrdersData>(url, [
    PickSessionPickOrdersQueryKey(pickSessionId),
    PickSessionsQueryKey(),
    OrdersQueryKey(OrderStatus.OPEN, DistributionParty.BOL),
    OrdersQueryKey(OrderStatus.OPEN_LATER, DistributionParty.BOL),
    OrdersQueryKey(OrderStatus.SHIPPED, DistributionParty.BOL),
    OrdersQueryKey(OrderStatus.PENDING_PICK_SESSION, DistributionParty.BOL),
    OrdersQueryKey(OrderStatus.FAILED, DistributionParty.BOL),
    OrdersQueryKey(OrderStatus.OPEN, DistributionParty.RETAILER),
    OrdersQueryKey(OrderStatus.OPEN_LATER, DistributionParty.RETAILER),
    OrdersQueryKey(OrderStatus.SHIPPED, DistributionParty.RETAILER),
    OrdersQueryKey(OrderStatus.PENDING_PICK_SESSION, DistributionParty.RETAILER),
    OrdersQueryKey(OrderStatus.FAILED, DistributionParty.RETAILER),
    [EndpointQueryKey.ACCOUNT],
  ]);
};

export interface RejectPickOrdersData {
  pick_order_ids: number[];
}

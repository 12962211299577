import { EndpointQueryKey } from './EndpointQueryKey';
import { getDateStringFromDate } from '../../helpers/getDateStringFromDate';

export const ProductPerformanceQueryKey = (
  productId: number,
  startDate: Date,
  endDate: Date,
  rollingAverageDays: number
) => {
  return (
    EndpointQueryKey.PRODUCT_PERFORMANCES +
    '_' +
    productId +
    '_' +
    getDateStringFromDate(startDate) +
    '_' +
    getDateStringFromDate(endDate) +
    '_' +
    rollingAverageDays
  );
};

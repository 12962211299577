import { EndpointQueryKey } from './EndpointQueryKey';
import { getDateStringFromDate } from '../../helpers/getDateStringFromDate';

export const ShareOfVoiceTotalsQueryKey = (campaignId: number, startDate: Date, endDate: Date) => {
  return (
    EndpointQueryKey.SHARE_OF_VOICE_TOTALS +
    '_' +
    campaignId +
    '_' +
    getDateStringFromDate(startDate) +
    '_' +
    getDateStringFromDate(endDate)
  );
};

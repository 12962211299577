// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { en } from './translations/en';
import { nl } from './translations/nl';

export enum Language {
  NL = 'nl',
  EN = 'en',
}

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(initReactI18next)
  .init({
    resources: {
      [Language.NL]: { translation: nl },
      [Language.EN]: { translation: en },
    },
    lng: Language.NL,
    fallbackLng: Language.NL,
    interpolation: {
      escapeValue: false,
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  })
  .then((_) => null);

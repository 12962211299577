import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { RouterLink } from '../../../../devias/src/components/router-link';
import { Logo } from '../../../../devias/src/components/logo';
import Typography from '@mui/material/Typography';
import { usePaths } from '../../../routes/usePaths';

export const SideNavHeader = () => {
  const paths = usePaths();

  return (
    <Stack
      alignItems="center"
      direction="row"
      spacing={2}
      sx={{ p: 3 }}
    >
      <Box
        component={RouterLink}
        href={paths.automator.vvb_orders}
        sx={{
          borderColor: 'var(--nav-logo-border)',
          borderRadius: 1,
          borderStyle: 'solid',
          borderWidth: 1,
          display: 'flex',
          height: 40,
          p: '4px',
          width: 40,
        }}
      >
        <Logo />
      </Box>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            color="inherit"
            variant="h6"
          >
            BlueEngine
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default interface AdGroupPerformance {
  adGroupId: number;
  [AdGroupPerformanceProperty.IMPRESSIONS]: number;
  [AdGroupPerformanceProperty.CLICKS]: number;
  [AdGroupPerformanceProperty.CONVERSIONS]: number;
  [AdGroupPerformanceProperty.SALES]: number;
  [AdGroupPerformanceProperty.SPENT]: number;
  date: string;
}

export enum AdGroupPerformanceProperty {
  IMPRESSIONS = 'impressions',
  CLICKS = 'clicks',
  CONVERSIONS = 'conversions',
  SALES = 'salesInCents',
  SPENT = 'spentInCents',
}

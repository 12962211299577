import IconCopyButton from '../../../shared/IconCopyButton';

interface CopyPhoneNumberButtonProps {
  aggregateId?: number;
  phoneNumber: string;
}

const CopyPhoneNumberButton = ({ phoneNumber, aggregateId }: CopyPhoneNumberButtonProps) => {
  return (
    <IconCopyButton
      value={phoneNumber}
      label={phoneNumber}
      toastMessage={phoneNumber}
      activeCopyButtonState={
        aggregateId
          ? {
              activeAggregateId: aggregateId,
              activeButtonId: aggregateId + phoneNumber,
            }
          : undefined
      }
    />
  );
};

export default CopyPhoneNumberButton;

import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { OrderSummaryViewHeader } from './OrderSummaryViewHeader';

interface OrderSummaryCardItemProps {
  label: string;
  children?: React.ReactNode;
}

export const OrderSummaryCardItem = ({ label, children }: OrderSummaryCardItemProps) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      alignItems="top"
    >
      <OrderSummaryViewHeader label={t(label)} />
      {children}
    </Stack>
  );
};

import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { PickStepType } from '../../../../domain/automator/pickSessions/PickStepType';

interface PickStepTypeChipProps {
  pickStepType: PickStepType;
}

const PickStepTypeChip = ({ pickStepType }: PickStepTypeChipProps) => {
  const { t } = useTranslation();

  const determineLabel = (pickStepType: PickStepType): string => {
    switch (pickStepType) {
      case PickStepType.APPROVAL:
        return tokens.common.pick_step_type.approval;
      case PickStepType.FETCH_LABEL:
        return tokens.common.pick_step_type.fetch_label;
      case PickStepType.REQUEST_LABEL:
        return tokens.common.pick_step_type.request_label;
      case PickStepType.FETCH_SHIPMENT:
        return tokens.common.pick_step_type.fetch_shipment;
      case PickStepType.REQUEST_SHIPMENT:
        return tokens.common.pick_step_type.request_shipment;
      default:
        return '';
    }
  };

  const determineColor = (pickStepType: PickStepType) => {
    switch (pickStepType) {
      case PickStepType.APPROVAL:
        return 'warning';
      case PickStepType.FETCH_LABEL:
        return 'secondary';
      case PickStepType.REQUEST_LABEL:
        return 'secondary';
      case PickStepType.FETCH_SHIPMENT:
        return 'info';
      case PickStepType.REQUEST_SHIPMENT:
        return 'info';
      default:
        return 'error';
    }
  };

  return (
    <Chip
      style={{ borderRadius: 4 }}
      size="small"
      label={t(determineLabel(pickStepType))}
      variant="filled"
      color={determineColor(pickStepType)}
    />
  );
};

export default PickStepTypeChip;

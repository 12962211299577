import { lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';

const AdminAccountsPage = lazy(() => import('src/pages/admin/AdminAccountsPage'));

export const adminRoutes: RouteObject[] = [
  {
    path: 'admin',
    element: (
      <Suspense>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: 'accounts',
        element: <AdminAccountsPage />,
      },
    ],
  },
];

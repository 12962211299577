import Alert from '@mui/material/Alert';

interface SuccessAlertProps {
  onClose?: () => void;
  children?: React.ReactNode;
}

export const SuccessAlert = ({ onClose, children }: SuccessAlertProps) => {
  return (
    <Alert
      onClose={onClose}
      sx={{ backgroundColor: 'rgb(237, 247, 237)', color: 'rgb(30, 70, 32)' }}
      variant="standard"
      severity="warning"
    >
      {children}
    </Alert>
  );
};

import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { SHARE_OF_VOICE_AVERAGES } from '../pdApiPaths';
import { getDateStringFromDate } from '../../../helpers/getDateStringFromDate';
import { ShareOfVoiceAveragesQueryKey } from '../../queryKeys/ShareOfVoiceAveragesQueryKey';
import ShareOfVoiceAverages from '../../../domain/pd/ShareOfVoiceAverages';

export const useFetchShareOfVoiceAverages = (
  campaignId: number,
  startDate: Date,
  endDate: Date
) => {
  const url = usePdApiAccountUrl(
    SHARE_OF_VOICE_AVERAGES +
      `?campaignId=${campaignId}&startDate=${getDateStringFromDate(
        startDate
      )}&endDate=${getDateStringFromDate(endDate)}`
  );
  return useFetchData<ShareOfVoiceAverages>(
    url,
    ShareOfVoiceAveragesQueryKey(campaignId, startDate, endDate)
  );
};

import TableRow from '@mui/material/TableRow';
import OfferPerformance from '../../../../../../domain/pd/OfferPerformance';
import ProductPerformance from '../../../../../../domain/pd/ProductPerformance';
import { ColorScale, PerformanceDateType, PerformanceTableCell } from '../PerformanceTableCell';
import OfferPerformancesMinMaxReport from '../../../../../../domain/pd/OfferPerformancesMinMaxReport';
import ProductPerformanceMinMaxReports from '../../../../../../domain/pd/ProductPerformanceMinMaxReports';

interface OfferPerformanceSearchMetricsTableRowProps {
  offerPerformance: OfferPerformance;
  offerPerformancesMinMaxReport: OfferPerformancesMinMaxReport;
  productPerformance?: ProductPerformance;
  productPerformancesMinMaxReport?: ProductPerformanceMinMaxReports;
}

export const OfferPerformanceSearchMetricsTableRow = ({
  offerPerformance,
  offerPerformancesMinMaxReport,
  productPerformance,
  productPerformancesMinMaxReport,
}: OfferPerformanceSearchMetricsTableRowProps) => {
  return (
    <TableRow>
      <PerformanceTableCell
        value={offerPerformance.impressions}
        type={PerformanceDateType.ROUNDED}
        minMaxReport={offerPerformancesMinMaxReport.impressions}
        colorScale={ColorScale.PINK}
      />

      <PerformanceTableCell
        value={offerPerformance.ctr}
        type={PerformanceDateType.PERCENTAGE}
        minMaxReport={offerPerformancesMinMaxReport.ctr}
        colorScale={ColorScale.PINK}
      />

      <PerformanceTableCell
        value={productPerformance?.weightedRank}
        type={PerformanceDateType.ROUNDED}
        minMaxReport={productPerformancesMinMaxReport?.weightedRank}
        colorScale={ColorScale.PINK}
      />
    </TableRow>
  );
};

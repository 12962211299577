import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { useState } from 'react';
import { ResendStatus } from '../../../domain/automator/resends/ResendStatus';
import { useFetchResends } from '../../../api/automator/resolutions/useFetchResends';
import ResendTabs from './components/ResendTabs';
import { ResendListTable } from './components/ResendListTable';

const ResendsPage = () => {
  const [currentTab, setCurrentTab] = useState(ResendStatus.OPEN);
  const { data: resends, isLoading } = useFetchResends(currentTab);

  return (
    <AccountPage title={tokens.automator.resolutions.resends}>
      <ResendTabs
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />

      <ResendListTable
        resends={resends?.resends}
        isLoading={isLoading}
      />
    </AccountPage>
  );
};

export default ResendsPage;

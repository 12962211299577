import {SvgIcon, SvgIconProps} from '@mui/material';

export const ParcelShopAlmostExpiredIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 21 21">
      <title>SVG-ParcelShopAlmostExpired</title>
      <path
        id="Shape 19"
        fillRule="evenodd"
        d="m8.4 14.4c-0.6 0-1.1 0.6-1.1 1.3v3.7c0 0.9-0.5 1.6-1.3 1.6h-1.8q-0.1 0-0.1 0-0.1 0-0.2 0h-0.5c-0.7 0-1.3-0.7-1.3-1.6v-4.6q0-0.1 0-0.1v-2.9h-1c-0.6 0-1.1-0.6-1.1-1.3q0-0.6 0.3-1l8.4-9.2c0.3-0.3 0.5-0.3 0.8-0.3 0.2 0 0.5 0.1 0.7 0.3l6.9 7.7q-0.4-0.1-0.8-0.1c-3 0-5.5 2.9-5.8 6.5zm4.6 4.9c-0.9-1.1-1.4-2.6-1.4-4.2 0-1.6 0.5-3.1 1.4-4.2 0.8-1.1 2-1.7 3.3-1.7 1.3 0 2.5 0.6 3.3 1.7 0.9 1.1 1.4 2.6 1.4 4.2 0 1.6-0.5 3.1-1.4 4.2-0.8 1.1-2 1.7-3.3 1.7-1.3 0-2.5-0.6-3.3-1.7zm2.7-1.9q-0.2 0.2-0.2 0.6 0 0.5 0.2 0.7 0.3 0.3 0.6 0.3 0.3 0 0.6-0.3 0.2-0.2 0.2-0.7 0-0.4-0.2-0.6-0.3-0.3-0.6-0.3-0.3 0-0.6 0.3zm0.1-5.6v3.3c0 0.4 0.2 0.6 0.5 0.6 0.3 0 0.5-0.2 0.5-0.6v-3.3c0-0.3-0.2-0.6-0.5-0.6-0.3 0-0.5 0.3-0.5 0.6z"
      />
    </SvgIcon>
  );
};

import Stack from '@mui/material/Stack';
import { TooltippedProductThumbnail } from '../../orders/components/TooltippedProductThumbnail';
import { VatPercentageToggleButtons } from '../../products/components/VatPercentageToggleButtons';
import CustomerInvoiceLine from '../../../../domain/automator/customerInvoiceRequests/CustomerInvoiceLine';
import { useUpdateCustomerInvoiceLineVatPercentage } from '../../../../api/automator/customerInvoiceRequests/useUpdateCustomerInvoiceLineVatPercentage';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';

interface UpdateCustomerInvoiceLineProps {
  customerInvoiceLine: CustomerInvoiceLine;
}

export const UpdateCustomerInvoiceLine = ({
  customerInvoiceLine,
}: UpdateCustomerInvoiceLineProps) => {
  const { mutate: updateVatPercentage, isLoading } = useUpdateCustomerInvoiceLineVatPercentage(
    customerInvoiceLine.id
  );

  const { t } = useTranslation();

  const doUpdate = (vatPercentage: number) => {
    updateVatPercentage(
      { vat_percentage: vatPercentage },
      {
        onSuccess: async () => {
          toast.success(
            t(tokens.automator.customer_invoice_requests.details.billing_details_updated)
          );
        },
      }
    );
  };

  if (isLoading) {
    return <Stack>Loading...</Stack>;
  }

  return (
    <Stack
      direction="row"
      gap={2}
    >
      <TooltippedProductThumbnail
        height={50}
        thumbnailUrl={customerInvoiceLine.offer.product.thumbnailUrl}
        productTitle={customerInvoiceLine.offer.product.title}
        quantity={1}
      />

      <VatPercentageToggleButtons
        onSelect={doUpdate}
        value={customerInvoiceLine.vatPercentage}
      />
    </Stack>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  isAuthenticated: boolean;
  isInitialized: boolean;
  userId: number | null;
  accountId: number | null;
  jwt: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  userId: null,
  accountId: null,
  jwt: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    initialize: (state: AuthState, _) => {
      state.isAuthenticated = false;
      state.isInitialized = true;
    },
    login: (
      state: AuthState,
      action: PayloadAction<{ userId: number; accountId: number; jwt: string }>
    ) => {
      state.isAuthenticated = true;
      state.userId = action.payload.userId;
      state.accountId = action.payload.accountId;
      state.jwt = action.payload.jwt;
    },
    register: (
      state: AuthState,
      action: PayloadAction<{ userId: number; accountId: number; jwt: string }>
    ) => {
      state.isAuthenticated = true;
      state.userId = action.payload.userId;
      state.accountId = action.payload.accountId;
      state.jwt = action.payload.jwt;
    },
    logout: (state: AuthState) => {
      state.isAuthenticated = false;
      state.userId = null;
      state.accountId = null;
      state.jwt = null;
    },
  },
});

export const {
  initialize: initializeAction,
  login: loginAction,
  register: registerAction,
  logout: logoutAction,
} = authSlice.actions;

export const authReducer = authSlice.reducer;

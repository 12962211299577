import ProposedAction from '../../../../domain/automator/alerts/ProposedAction';
import EventTrigger from '../../../../domain/automator/eventRules/EventTrigger';
import { AlertsListTableRows } from './AlertsListTableRows';
import { AlertsListTableEventTriggerGroupRow } from './AlertsListTableEventTriggerGroupRow';
import { useState } from 'react';

interface CollapsableAlertsListTableRowsProps {
  eventTrigger: EventTrigger;
  alerts: ProposedAction[];
}

export const CollapsableAlertsListTableRows = ({
  alerts,
  eventTrigger,
}: CollapsableAlertsListTableRowsProps) => {
  const [isHidden, setIsHidden] = useState<boolean>(true);

  return (
    <>
      <AlertsListTableEventTriggerGroupRow
        onClick={() => setIsHidden(!isHidden)}
        proposedActions={alerts}
        eventTrigger={eventTrigger}
      />

      {isHidden ? (
        <></>
      ) : (
        <>
          <AlertsListTableRows alerts={alerts} />
        </>
      )}
    </>
  );
};

import Stack from '@mui/material/Stack';
import { CountryCode } from '../../../../domain/automator/orders/CountryCode';
import { TransporterCode } from '../../../../domain/automator/shipments/TransporterCode';
import Typography from '@mui/material/Typography';
import { FlagIcon } from '../../orders/components/FlagIcon';
import TransporterChip from '../../shipments/components/TransporterChip';
import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';

interface CreatePickSessionCountrySummaryProps {
  countryCode: CountryCode;
  amount: number;
  transporterCode: TransporterCode | null;
  distributionParty: DistributionParty;
}

export const CreatePickSessionCountrySummary = ({
  countryCode,
  amount,
  transporterCode,
  distributionParty,
}: CreatePickSessionCountrySummaryProps) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction={'row'}
      gap={2}
    >
      <FlagIcon
        countryCode={countryCode}
        size={40}
      />
      <Typography
        variant="body1"
        fontWeight={600}
        fontSize={18}
      >
        {amount + ' x ' + t(tokens.automator.orders.orders).toLocaleLowerCase()}
      </Typography>

      {transporterCode && (
        <TransporterChip
          transporterCode={transporterCode}
          distributionParty={distributionParty}
        />
      )}
    </Stack>
  );
};

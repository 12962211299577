import axios from 'axios';
import { store } from '../store/Store';
import { useRefreshToken } from './automator/auth/useRefreshToken';

let refreshPromise: Promise<string | boolean> | null = null;

export const useAuthorizedAxios = () => {
  const mutateRefresh = useRefreshToken();

  const attemptRefresh = async (): Promise<string | boolean> => {
    if (!refreshPromise) {
      refreshPromise = mutateRefresh
        .mutateAsync()
        .then((data) => {
          return data.jwt;
        })
        .catch((_) => {
          window.location.href = '/auth/login';
          return false;
        })
        .finally(() => {
          refreshPromise = null; // Reset the promise for future requests
        });
    }
    return refreshPromise;
  };

  const axiosInstance = axios.create({
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      responseType: 'blob',
    },
    withCredentials: true,
  });

  axiosInstance.interceptors.request.use(
    async (config) => {
      const state = store.getState();
      const isAuthenticated = state.auth.isAuthenticated;

      if (!isAuthenticated) {
        try {
          const jwt = await attemptRefresh();
          config.headers['Authorization'] = `Bearer ${jwt}`;
        } catch (e) {
          window.location.href = '/auth/login';
          return Promise.reject(e);
        }
      } else {
        const jwt = state.auth.jwt;
        config.headers['Authorization'] = `Bearer ${jwt}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        if (!refreshPromise) {
          await attemptRefresh();
        }
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';

const ShipmentOverdueChip = () => {
  const { t } = useTranslation();

  return (
    <Chip
      size={'small'}
      color={'error'}
      label={t(tokens.automator.shipments.status.overdue)}
    />
  );
};

export default ShipmentOverdueChip;

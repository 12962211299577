import { useRoutes } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import './global.css';
import 'src/locales/i18n';
import { RTL } from 'devias/src/components/rtl';
import { SplashScreen } from 'devias/src/components/splash-screen';
import { routes } from 'src/routes/routes';
import { store, useDispatch } from 'src/store/Store';
import { createTheme } from 'devias/src/theme';
import { useEffect } from 'react';
import { useAuth } from './store/auth/useAuth';
import { Toaster } from './components/Toaster';
import { Dialogs } from './components/Dialogs';
import { QueryClientProvider } from 'react-query';
import { useCreateQueryClient } from './api/useCreateQueryClient';
import { useInitializeAuth } from './store/auth/useInitializeAuth';
import { SettingsProvider } from '../devias/src/contexts/settings';
import { useSettings } from '../devias/src/hooks/use-settings';

export const App = () => {
  const queryClient = useCreateQueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ReduxProvider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider>
            <AppContent />
          </SettingsProvider>
        </LocalizationProvider>
      </ReduxProvider>
    </QueryClientProvider>
  );
};

const AppContent = () => {
  const settings = useSettings();

  const theme = createTheme({
    colorPreset: 'blue',
    contrast: 'high',
    direction: settings.direction,
    paletteMode: settings.paletteMode, // === dark mode / light mode
    responsiveFontSizes: settings.responsiveFontSizes,
  });

  const element = useRoutes(routes);
  const isInitialized = useAuth().isInitialized;
  const showSlashScreen = !isInitialized;
  const dispatch = useDispatch();
  const initializeAuth = useInitializeAuth();

  useEffect(() => {
    initializeAuth();
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={'ltr'}>
        <CssBaseline />
        {showSlashScreen ? <SplashScreen /> : <>{element}</>}
        <Toaster />
        <Dialogs />
      </RTL>
    </ThemeProvider>
  );
};

import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../../../locales/translationTokens';
import ActionButton from '../../../../../../components/ActionButton';
import { useUpdateProductReorderInterval } from '../../../../../../api/pd/offerPerformances/useUpdateProductReorderInterval';
import ProductReorderInterval from '../../../../../../domain/pd/ProductReorderInterval';

interface UpdateProductReorderIntervalFormProps {
  onClick?: () => void;
  productReorderInterval: ProductReorderInterval;
}

export const UpdateProductReorderIntervalForm = ({
  onClick,
  productReorderInterval,
}: UpdateProductReorderIntervalFormProps) => {
  const [amountOfDays, setAmountOfDays] = useState(productReorderInterval.amountOfDays);

  const { t } = useTranslation();

  const { mutate: update, isLoading } = useUpdateProductReorderInterval(productReorderInterval.id);

  const onUpdate = () => {
    update(
      {
        amount_of_days: amountOfDays,
        date: '2020-01-01',
      },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(
            t(tokens.pd.performance.update_product_reorder_interval_form.reorder_interval_updated)
          );
        },
      }
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <TextField
        id="note-field"
        label={t(tokens.pd.performance.update_product_reorder_interval_form.reorder_interval)}
        variant="filled"
        fullWidth
        style={{ marginBottom: '1em' }}
        value={amountOfDays}
        onChange={(e) => setAmountOfDays(Number(e.target.value))}
      />

      <ActionButton
        label={t(
          tokens.pd.performance.update_product_reorder_interval_form.update_reorder_interval
        )}
        onClick={() => onUpdate()}
        isLoading={isLoading}
        variant="contained"
        color="primary"
        size="small"
      />
    </form>
  );
};

import Alert from '@mui/material/Alert';

interface ErrorAlertProps {
  onClose?: () => void;
  children?: React.ReactNode;
}

export const ErrorAlert = ({ onClose, children }: ErrorAlertProps) => {
  return (
    <Alert
      onClose={onClose}
      sx={{ backgroundColor: 'rgb(253, 237, 237)', color: 'rgb(95, 33, 32)' }}
      variant="standard"
      severity="warning"
    >
      {children}
    </Alert>
  );
};

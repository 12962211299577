import { Table, TableCell, TableHead, TableRow } from '@mui/material';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import TableBody from '@mui/material/TableBody';
import { useState } from 'react';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import { PickStepType } from '../../../../domain/automator/pickSessions/PickStepType';
import { PickOrderTableRow } from './PickOrderTableRow';
import { PickOrderApprovalStatus } from '../../../../domain/automator/pickSessions/PickOrderApprovalStatus';
import { useFetchPickSessionPickOrdersPolled } from '../../../../api/automator/pickSessions/useFetchPickSessionPickOrdersPolled';
import ApprovePickOrdersButton from './ApprovePickOrdersButton';
import RejectPickOrdersButton from './RejectPickOrdersButton';

interface PickOrdersListTableProps {
  pickSessionId: number;
}

const PickOrdersListTable = ({ pickSessionId }: PickOrdersListTableProps) => {
  const { data: pickOrders, isLoading: isLoadingPickOrders } =
    useFetchPickSessionPickOrdersPolled(pickSessionId);

  const [selectedPickOrdersIds, setSelectedPickOrdersIds] = useState<number[]>([]);

  const onSelectPickOrderId = (pickOrderId: number) => {
    if (selectedPickOrdersIds.includes(pickOrderId)) {
      setSelectedPickOrdersIds(selectedPickOrdersIds.filter((id) => id !== pickOrderId));
    } else {
      setSelectedPickOrdersIds([...selectedPickOrdersIds, pickOrderId]);
    }
  };

  const { t } = useTranslation();

  if (isLoadingPickOrders) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  const pickOrdersThatNeedApproval = pickOrders!.pickOrders.filter((pickOrder) => {
    return (
      pickOrder.latestPickStep.type == PickStepType.APPROVAL &&
      pickOrder.pickOrderApproval?.status == PickOrderApprovalStatus.PENDING
    );
  });

  const onSelectAll = () => {
    setSelectedPickOrdersIds(
      pickOrdersThatNeedApproval.map((pickOrder) => {
        return pickOrder.id;
      })
    );
  };

  const onDeselectAll = () => {
    setSelectedPickOrdersIds([]);
  };

  const selectedAll =
    selectedPickOrdersIds.length == pickOrdersThatNeedApproval.length &&
    selectedPickOrdersIds.length > 0;
  const selectedSomeNotAll =
    selectedPickOrdersIds.length > 0 &&
    selectedPickOrdersIds.length < pickOrdersThatNeedApproval.length;
  const selectedSome = selectedSomeNotAll || selectedAll;

  return (
    <Box sx={{ position: 'relative' }}>
      {selectedSome && (
        <Stack
          direction="row"
          spacing={2}
          sx={{
            //@TODO to tss
            alignItems: 'center',
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.50',
            display: selectedSome ? 'flex' : 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            paddingLeft: '18px',

            py: 1,
            zIndex: 10,
          }}
        >
          <Checkbox
            checked={selectedAll}
            indeterminate={selectedSomeNotAll}
            onChange={(event) => {
              if (event.target.checked) {
                onSelectAll?.();
              } else {
                onDeselectAll?.();
              }
            }}
          />

          <ApprovePickOrdersButton
            pickSessionId={pickSessionId}
            selectedPickOrdersIds={selectedPickOrdersIds}
            onClick={onDeselectAll}
            showAmount={true}
          />

          <RejectPickOrdersButton
            pickSessionId={pickSessionId}
            selectedPickOrdersIds={selectedPickOrdersIds}
            onClick={onDeselectAll}
            showAmount={true}
          />
        </Stack>
      )}
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ padding: 0 }}
                width="2px"
              />

              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedAll}
                  indeterminate={selectedSomeNotAll}
                  onChange={(event) => {
                    if (event.target.checked) {
                      onSelectAll();
                    } else {
                      onDeselectAll();
                    }
                  }}
                />
              </TableCell>

              <TableCell>{t(tokens.automator.orders.order_items)}</TableCell>
              <TableCell>{t(tokens.automator.orders.bol_order_id)}</TableCell>
              <TableCell>{t(tokens.automator.orders.recipient)}</TableCell>
              <TableCell>{t(tokens.automator.pick_orders.status)}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {pickOrders!.pickOrders.map((pickOrder, index) => (
              <PickOrderTableRow
                key={index}
                pickOrder={pickOrder}
                isPickOrderSelected={selectedPickOrdersIds.includes(pickOrder.id)}
                onSelectPickOrder={onSelectPickOrderId}
              />
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};

export default PickOrdersListTable;

import { PROPOSED_ACTIONS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { useUpdateData } from '../../useUpdateData';
import { useProposedActionQueryKeysUpdater } from './useProposedActionQueryKeysUpdater';
import ProposedActions from '../../../domain/automator/alerts/ProposedActions';
import FileUpload from '../../../pages/automator/shared/FileUpload';
import { ResolutionCaseQueryKey } from '../resolutions/ResolutionCaseQueryKey';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';
import { ProposedActionsReportQueryKey } from '../../queryKeys/ProposedActionsReportQueryKey';

export const useExecuteProposedActions = () => {
  const updater = useProposedActionQueryKeysUpdater();
  const updateData = useUpdateData(updater, 'proposedActions');

  const onSuccess = (proposedActions: ProposedActions) => {
    updateData(proposedActions);
  };

  const url = useAutomatorApiAccountUrl(PROPOSED_ACTIONS_PATH + '/execute');
  return useAuthorizedPost<ProposedActions, ExecuteAlertData>(
    url,
    [ResolutionCaseQueryKey(), EndpointQueryKey.ACCOUNT, ProposedActionsReportQueryKey()],
    undefined,
    onSuccess
  );
};

export interface ExecuteAlertData {
  proposed_action_ids: number[];
  subject?: string;
  body?: string;
  is_start_with_resolution_case?: boolean;
  files?: FileUpload[];
}

import { tokens } from '../../../../../locales/translationTokens';
import IconCopyButton from '../../../shared/IconCopyButton';

interface CopyZipCodeButtonProps {
  aggregateId: number;
  zipCode: string;
}

const CopyZipCodeButton = ({ aggregateId, zipCode }: CopyZipCodeButtonProps) => {
  return (
    <IconCopyButton
      value={zipCode}
      label={tokens.automator.orders.buttons.copy.copy_zip_code}
      toastMessage={tokens.automator.orders.buttons.copy.zip_code_copied}
      activeCopyButtonState={{
        activeButtonId: aggregateId + zipCode,
        activeAggregateId: aggregateId,
      }}
    />
  );
};

export default CopyZipCodeButton;

import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../../../locales/translationTokens';
import ActionButton from '../../../../../../components/ActionButton';
import { useUpdateProductPurchaseVolume } from '../../../../../../api/pd/offerPerformances/useUpdateProductPurchaseVolume';
import ProductPurchaseVolume from '../../../../../../domain/pd/ProductPurchaseVolume';

interface UpdateProductPurchaseVolumeFormProps {
  onClick?: () => void;
  productPurchaseVolume: ProductPurchaseVolume;
}

export const UpdateProductPurchaseVolumeForm = ({
  onClick,
  productPurchaseVolume,
}: UpdateProductPurchaseVolumeFormProps) => {
  const [purchaseVolume, setPurchaseVolume] = useState(productPurchaseVolume.amountOfUnits);

  const { t } = useTranslation();

  const { mutate: update, isLoading } = useUpdateProductPurchaseVolume(productPurchaseVolume.id);

  const onUpdate = () => {
    update(
      {
        amount_of_units: purchaseVolume,
        date: '2020-01-01',
      },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(
            t(tokens.pd.performance.update_product_purchase_volume_form.purchase_volume_updated)
          );
        },
      }
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <TextField
        id="note-field"
        label={t(tokens.pd.performance.update_product_purchase_volume_form.purchase_volume)}
        variant="filled"
        fullWidth
        style={{ marginBottom: '1em' }}
        value={purchaseVolume}
        onChange={(e) => setPurchaseVolume(Number(e.target.value))}
      />

      <ActionButton
        label={t(tokens.pd.performance.update_product_purchase_volume_form.update_purchase_volume)}
        onClick={() => onUpdate()}
        isLoading={isLoading}
        variant="contained"
        color="primary"
        size="small"
      />
    </form>
  );
};

import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { CreateResendForm } from '../components/CreateResendNoteForm';

export const CreateResendDialog = () => {
  const id = DialogId.CREATE_RESEND;
  const { isOpen, data } = useIsDialogOpen(id);
  const closeDialog = useCloseDialog(id);
  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          title={t(tokens.automator.resolutions.dialogs.create_resend.dialog_title)}
        >
          <CreateResendForm
            orderId={data.orderId}
            alertId={data.alertId}
            onClick={closeDialog}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface CreateResendDialogData {
  orderId: number;
  alertId?: number;
}

import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { ReturnReasonReportTable } from './components/ReturnReasonReportTable';

const ReportsPage = () => {
  return (
    <AccountPage title={tokens.automator.reports.reports}>
      <ReturnReasonReportTable />
    </AccountPage>
  );
};

export default ReportsPage;

import { EndpointQueryKey } from './EndpointQueryKey';
import { getDateStringFromDate } from '../../helpers/getDateStringFromDate';
import { CountryCode } from '../../domain/automator/orders/CountryCode';

export const SearchTermVolumesQueryKey = (
  searchTermId: number,
  startDate: Date,
  endDate: Date,
  countryCode: CountryCode | null
) => {
  return (
    EndpointQueryKey.SEARCH_TERM_VOLUMES +
    '_' +
    searchTermId +
    '_' +
    getDateStringFromDate(startDate) +
    '_' +
    getDateStringFromDate(endDate) +
    '_' +
    countryCode
  );
};

import { Box } from '@mui/material';
import { blue } from '@mui/material/colors';

interface IconChipProps {
  icon: React.ReactNode;
}

const IconChip = ({ icon }: IconChipProps) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 42,
        height: 42,
        backgroundColor: blue[600],
        borderRadius: '8px',
      }}
    >
      {icon}
    </Box>
  );
};

export default IconChip;

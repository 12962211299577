import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import RetailerLooksIcon from '../../retailers/components/RetailerLooksIcon';
import RetailerSetting from '../../../../domain/automator/retailers/RetailerSetting';
import { useState } from 'react';
import ActionButton from '../../../../components/ActionButton';
import { useUpdateRetailerSettings } from '../../../../api/automator/retailers/useUpdateRetailerSettings';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';

interface InitialCustomerInvoiceNumberViewProps {
  retailerSetting: RetailerSetting;
}

export const InitialCustomerInvoiceNumberView = ({
  retailerSetting,
}: InitialCustomerInvoiceNumberViewProps) => {
  const { mutate: updateSettings, isLoading } = useUpdateRetailerSettings(retailerSetting.id);

  const currentYear = new Date().getFullYear();

  const existingInitialCustomerInvoiceNumber = retailerSetting.initialCustomerInvoiceNumbers.find(
    (initialCustomerInvoiceNumber) => initialCustomerInvoiceNumber.year === currentYear
  );

  const [customerInvoiceNumber, setCustomerInvoiceNumber] = useState(
    existingInitialCustomerInvoiceNumber?.initialInvoiceNumber || null
  );

  const { t } = useTranslation();

  const onUpdate = () => {
    const data = {
      initial_customer_invoice_numbers: [
        {
          year: currentYear,
          customer_invoice_number: customerInvoiceNumber,
        },
      ],
    };

    // @ts-ignore
    updateSettings(data, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.settings.settings_saved));
      },
    });
  };

  return (
    <Stack
      display="flex"
      alignItems="center"
      direction="row"
      gap={2}
    >
      <RetailerLooksIcon retailerId={retailerSetting.retailerId} />
      <Typography>{currentYear}</Typography>
      <TextField
        label="start at"
        disabled={existingInitialCustomerInvoiceNumber !== undefined}
        value={customerInvoiceNumber}
        onChange={(event) => setCustomerInvoiceNumber(parseInt(event.target.value))}
      />

      {existingInitialCustomerInvoiceNumber == null && (
        <ActionButton
          isDisabled={customerInvoiceNumber == null}
          onClick={() => onUpdate()}
          label={'save'}
          isLoading={isLoading}
        />
      )}
    </Stack>
  );
};

import { PICK_SESSION_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import PickSessionsReport from '../../../domain/automator/pickSessions/PickSessionsReport';
import { PickSessionsReportQueryKey } from '../../queryKeys/PickSessionsReportQueryKey';

export const useFetchPickSessionsReport = () => {
  const url = useAutomatorApiAccountUrl(PICK_SESSION_PATH + '/report');
  return useFetchData<PickSessionsReport>(url, PickSessionsReportQueryKey());
};

import { tokens } from '../../../../../locales/translationTokens';
import IconCopyButton from '../../../shared/IconCopyButton';

interface CopyHouseNumberButtonProps {
  aggregateId: number;
  houseNumber: string;
}

const CopyHouseNumberButton = ({ aggregateId, houseNumber }: CopyHouseNumberButtonProps) => {
  return (
    <IconCopyButton
      value={houseNumber}
      label={tokens.automator.orders.buttons.copy.copy_house_number}
      toastMessage={tokens.automator.orders.buttons.copy.house_number_copied}
      activeCopyButtonState={{
        activeButtonId: aggregateId + houseNumber,
        activeAggregateId: aggregateId,
      }}
    />
  );
};

export default CopyHouseNumberButton;

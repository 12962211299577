import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Category from '../../../../../../domain/pd/Category';
import ProductRankCategory from '../../../../../../domain/pd/ProductRankCategory';
import Stack from '@mui/material/Stack';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface ProductRankCategoryTableRowProps {
  productRankCategory: ProductRankCategory;
  onSelect: (category: Category) => void;
  isSelected: boolean;
}

export const ProductRankCategoryTableRow = ({
  productRankCategory,
  onSelect,
  isSelected,
}: ProductRankCategoryTableRowProps) => {
  return (
    <TableRow
      style={{
        backgroundColor: isSelected ? '#ff0' : '#fff',
        cursor: 'pointer',
      }}
      onClick={() => onSelect(productRankCategory.category)}
    >
      <TableCell>CATEGORY_CHIP</TableCell>
      <TableCell>{productRankCategory.category.name}</TableCell>
      <TableCell>
        {' '}
        <Stack
          direction="row"
          spacing={1}
        >
          {productRankCategory.averageRank}{' '}
          {productRankCategory.averageRankTrend > 0 ? (
            <ArrowDropUpIcon style={{ color: 'green' }} />
          ) : (
            <ArrowDropDownIcon style={{ color: 'red' }} />
          )}
        </Stack>
      </TableCell>
      <TableCell>{productRankCategory.totalImpressions}</TableCell>
      <TableCell>-</TableCell>
    </TableRow>
  );
};

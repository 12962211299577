import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Store';
import { OrderTabsState, setActiveTab } from './orderTabsSlice';

export const useOrderTab = () => {
  const dispatch = useDispatch();
  const orderTab = useSelector((state: RootState) => state.orders_tabs.orderTab);

  const setOrderTab = (state: OrderTabsState) => {
    dispatch(setActiveTab(state));
  };

  return { orderTab, setOrderTab };
};

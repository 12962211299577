import ProposedAction from '../../../../domain/automator/alerts/ProposedAction';
import { AlertsListTableRow } from './AlertsListTableRow';

interface AlertsListTableRowsProps {
  alerts: ProposedAction[];
}

export const AlertsListTableRows = ({ alerts }: AlertsListTableRowsProps) => {
  const groupedAlertsByOrderId = alerts.reduce(
    (groups, alert) => {
      const orderId = alert.order.id;
      if (!groups[orderId]) {
        groups[orderId] = [];
      }
      groups[orderId]!.push(alert);
      return groups;
    },
    {} as { [key: number]: ProposedAction[] }
  );

  const groups = Object.entries(groupedAlertsByOrderId!);
  const length = groups.length;

  const sortedGroups = groups.sort((a, b) => {
    const getProductId = (proposedAction: ProposedAction) => {
      if (proposedAction.orderItemId == null) {
        return proposedAction.order.items[0].offer.product.id;
      }

      return proposedAction.order.items.find((item) => item.id == proposedAction.orderItemId)!.offer
        .product.id;
    };

    return getProductId(a[1][0]) - getProductId(b[1][0]);
  });

  return (
    <>
      {sortedGroups.map(([orderId, alerts], index) => {
        // Check if it is the last element
        const isLastRow = index === length - 1;

        return (
          <AlertsListTableRow
            key={orderId}
            proposedActions={alerts}
            isLastRow={isLastRow}
          />
        );
      })}
    </>
  );
};

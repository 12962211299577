import { DropdownOption, DropdownSelect } from '../../../../automator/shared/DropdownSelect';
import { tokens } from '../../../../../locales/translationTokens';
import AdGroup from '../../../../../domain/pd/AdGroup';

interface AdGroupDropdownSelectProps {
  adGroups: AdGroup[];
  adGroup?: AdGroup;
  onSelect: (adGroup: AdGroup) => void;
}

export const AdGroupDropdownSelect = ({
  adGroups,
  adGroup,
  onSelect,
}: AdGroupDropdownSelectProps) => {
  const options: DropdownOption<number, string>[] = [];

  adGroups.forEach((adGroup) => {
    options.push({
      key: adGroup.id,
      value: adGroup.name,
    });
  });

  return (
    <DropdownSelect
      options={options}
      onSelect={(key) => {
        adGroup = adGroups.find((adGroup) => adGroup.id === key) as AdGroup;
        onSelect(adGroup);
      }}
      selected={adGroup?.id}
      label={tokens.pd.ad_group_dropdown_select.label}
    />
  );
};

export enum DialogId {
  //automator
  WARNING = 'WARNING',
  INFO = 'INFO',

  CANCEL_ORDER_ITEM = 'CANCEL_ORDER_ITEM',
  ADD_RETAILER = 'ADD_RETAILER',
  CONFIRM_CREATE_PICK_SESSION = 'CONFIRM_CREATE_PICK_SESSION',
  FIRST_RETAILER_CREATED = 'FIRST_RETAILER_CREATED',
  HANDLE_RETURN_ITEM = 'HANDLE_RETURN_ITEM',
  CREATE_MESSAGE_TEMPLATE = 'CREATE_MESSAGE_TEMPLATE',
  UPDATE_MESSAGE_TEMPLATE = 'UPDATE_MESSAGE_TEMPLATE',
  CREATE_RETURN = 'CREATE_RETURN',
  SEND_MESSAGE_THREAD_REPLY = 'SEND_MESSAGE_THREAD_REPLY',
  UPDATE_ORDER_NOTE_DIALOG = 'UPDATE_ORDER_NOTE_DIALOG',
  UPDATE_OFFER_INTERNAL_REFERENCE = 'UPDATE_OFFER_INTERNAL_REFERENCE',
  CREATE_RESEND = 'CREATE_RESEND',

  CREATE_EMAIL_EVENT_RULE = 'CREATE_EMAIL_EVENT_RULE',
  UPDATE_AUTO_MAIL_EVENT_RULE = 'UPDATE_AUTO_MAIL_EVENT_RULE',

  SETUP_VAT_PERCENTAGE_PRODUCTS = 'SETUP_VAT_PERCENTAGE_PRODUCTS',

  ORDER_SUMMARY = 'ORDER_SUMMARY',

  CREATE_EMAIL_THREAD = 'CREATE_EMAIL_THREAD',

  VIEW_RETURN_SCAN_PHOTOS = 'VIEW_RETURN_SCAN_PHOTOS',

  UPDATE_OFFERS = 'UPDATE_OFFERS',

  UPDATE_RETAILER_RETURN_ADDRESS = 'UPDATE_RETAILER_RETURN_ADDRESS',

  UPDATE_UPDATED_BILLING_DETAILS = 'UPDATE_UPDATED_BILLING_DETAILS',

  CREATE_PRODUCT_GROUP = 'CREATE_PRODUCT_GROUP',

  UPDATE_PRODUCTS = 'UPDATE_PRODUCTS',

  //pd

  CREATE_PRODUCT_PURCHASE_COSTS = 'CREATE_PRODUCT_PURCHASE_COSTS',
  UPDATE_PRODUCT_PURCHASE_COSTS = 'UPDATE_PRODUCT_PURCHASE_COSTS',

  CREATE_PRODUCT_PURCHASE_VOLUME = 'CREATE_PRODUCT_PURCHASE_VOLUME',
  UPDATE_PRODUCT_PURCHASE_VOLUME = 'UPDATE_PRODUCT_PURCHASE_VOLUME',

  CREATE_PRODUCT_REORDER_INTERVAL = 'CREATE_PRODUCT_REORDER_INTERVAL',
  UPDATE_PRODUCT_REORDER_INTERVAL = 'UPDATE_PRODUCT_REORDER_INTERVAL',

  CREATE_OFFER_SHIPPING_COSTS = 'CREATE_OFFER_SHIPPING_COSTS',
  UPDATE_OFFER_SHIPPING_COSTS = 'UPDATE_OFFER_SHIPPING_COSTS',

  CREATE_OFFER_PERFORMANCE_OVERWRITE = 'CREATE_OFFER_PERFORMANCE_OVERWRITE',
  UPDATE_OFFER_PERFORMANCE_OVERWRITE = 'UPDATE_OFFER_PERFORMANCE_OVERWRITE',

  CREATE_MANUAL_PERFORMANCE_EVENT = 'CREATE_MANUAL_PERFORMANCE_EVENT',
  CREATE_MANUAL_SEASONAL_EVENT = 'CREATE_MANUAL_SEASONAL_EVENT',
}

import { Tooltip } from '@mui/material';
import StepperStraightLine from './StraigthLine';
import StepperNumberBox from './StepperNumberBox';
import Box from '@mui/material/Box';

interface StepperStartProps {
  total?: number;
  color: string;
  lineLength: number;
  tooltip: string;
  activeIcon: React.ReactNode;
  inactiveIcon: React.ReactNode;
  isTerminated?: boolean;
}

const StepperStart = ({
  total,
  color,
  lineLength,
  tooltip,
  activeIcon,
  inactiveIcon,
  isTerminated = false,
}: StepperStartProps) => {
  return (
    <Tooltip
      enterDelay={2000}
      enterNextDelay={2000}
      title={tooltip}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '92px',
          color: color,
          marginRight: '-10px',
        }}
      >
        <StepperNumberBox
          color={color}
          number={total}
          icon={total && total > 0 ? activeIcon : inactiveIcon}
        />

        {isTerminated ? (
          <Box style={{ width: `${length + 23.7}%` }} />
        ) : (
          <StepperStraightLine
            color={isTerminated ? '#F8F9FA' : color}
            length={lineLength}
          />
        )}
      </div>
    </Tooltip>
  );
};

export default StepperStart;

import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { UpdateMessageTemplateForm } from '../../shipments/components/UpdateMessageTemplateForm';
import MessageTemplate from '../../../../domain/automator/messages/MessageTemplate';

export const UpdateMessageTemplateDialog = () => {
  const id = DialogId.UPDATE_MESSAGE_TEMPLATE;
  const { isOpen, data } = useIsDialogOpen(id);
  const closeDialog = useCloseDialog(id);
  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          title={t(tokens.automator.resolutions.dialogs.update_email_template.dialog_title)}
          maxWidth={'md'}
        >
          <UpdateMessageTemplateForm
            messageTemplateId={data.messageTemplateId}
            onUpdate={(messageTemplate: MessageTemplate) => {
              data.onUpdate?.(messageTemplate);
              closeDialog();
            }}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface UpdateMessageTemplateDialogData {
  messageTemplateId: number;
  onUpdate?: (messageTemplate: MessageTemplate) => void;
}

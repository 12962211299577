import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { tss } from 'tss-react/mui-compat';
import Typography from '@mui/material/Typography';

export interface ActionButtonProps {
  label: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  variant?: 'text' | 'outlined' | 'contained';
  size?: 'small' | 'medium' | 'large';
  fullWidth?: boolean;
  isLoading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  isDisabled?: boolean;
  style?: React.CSSProperties;
}

const ActionButton = ({
  label,
  icon,
  onClick,
  color,
  variant,
  size,
  fullWidth,
  type,
  isLoading,
  isDisabled,
  style,
}: ActionButtonProps) => {
  const { t } = useTranslation();
  const { cx, classes } = useStyles({ isLoading: isLoading });

  const determineColor = ():
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning' => {
    if (isDisabled) {
      return 'inherit';
    } else {
      return color ? color : 'primary';
    }
  };

  return (
    <Button
      className={cx(classes.actionButton)}
      onClick={() => {
        onClick?.();
        // e.stopPropagation();
      }}
      style={style}
      startIcon={icon}
      variant={variant ? variant : 'contained'}
      color={determineColor()}
      size={size ? size : 'medium'}
      fullWidth={fullWidth}
      disabled={isLoading || isDisabled == true}
      type={type}
    >
      <Typography
        noWrap
        variant="subtitle2"
        className={cx(classes.actionButtonText)}
      >
        {t(label)}
      </Typography>

      {isLoading && (
        <Box className={cx(classes.actionButtonSpinner)}>
          <CircularProgress size={33} />
        </Box>
      )}
    </Button>
  );
};

const useStyles = tss.withParams<{ isLoading?: boolean }>().create(({ isLoading }) => ({
  actionButton: {
    position: 'relative',
  },
  actionButtonSpinner: {
    marginTop: '0.4rem',
    position: 'absolute',
  },
  actionButtonText: {
    visibility: isLoading ? 'hidden' : 'visible',
  },
}));

export default ActionButton;

import { Box, Dialog } from '@mui/material';
import { DialogId } from '../store/dialogs/DialogId';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { ActionIcon } from '../pages/automator/orders/components/ActionIcon';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import { useIsDialogOpen } from '../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../store/dialogs/useCloseDialog';
import { tokens } from '../locales/translationTokens';

interface DashboardDialogProps {
  dialogId: DialogId;
  title: string;
  children: React.ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  centerTitle?: boolean;
}

export const ApplicationDialog = ({
  dialogId,
  title,
  children,
  maxWidth,
}: DashboardDialogProps) => {
  const { isOpen } = useIsDialogOpen(dialogId);
  const closeDialog = useCloseDialog(dialogId);

  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth || 'sm'}
      onClose={() => closeDialog()}
      open={isOpen}
    >
      <Box sx={{ position: 'relative' }}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          spacing={3}
          marginTop={1.5}
          sx={{ paddingTop: '0.2rem', paddingRight: '1rem' }}
        >
          <Typography variant="h6">{t(title)}</Typography>
        </Stack>

        <Box style={{ position: 'absolute', right: '1rem', top: 5 }}>
          <ActionIcon
            onClick={() => closeDialog()}
            icon={<XIcon />}
            tooltip={t(tokens.common.close)}
          />
        </Box>
      </Box>
      <Box padding={3}>{children}</Box>
    </Dialog>
  );
};

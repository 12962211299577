import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface TableLoadingProps {
  children: ReactNode;
}

export const TableBodyPlaceholder = ({ children }: TableLoadingProps) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="50vh"
    >
      {children}
    </Box>
  );
};

import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import Return from '../../../../domain/automator/returns/Return';
import { ReturnReasonStack } from '../../returns/components/ReturnReasonStack';
import { ProductThumbnail } from '../../orders/components/ProductThumbnail';

interface EmailThreadReturnItemSelectionProps {
  returns: Return[];
  setAggregateId: (aggregateId: number) => void;
  selectedAggregateId: number | null;
}

export const EmailThreadReturnItemSelection = ({
  returns,
  setAggregateId,
  selectedAggregateId,
}: EmailThreadReturnItemSelectionProps) => {
  const returnItems = returns.flatMap((return_) => {
    return return_.returnItems;
  });

  return (
    <Stack
      direction="column"
      gap={1}
    >
      {returnItems.map((returnItem, index) => (
        <Stack
          key={index}
          direction="row"
          gap={2}
        >
          {returnItems.length > 1 && !selectedAggregateId && (
            <Radio
              checked={selectedAggregateId === returnItem.id}
              onChange={() => setAggregateId(returnItem.id)}
            />
          )}

          <ProductThumbnail
            height={50}
            thumbnailUrl={returnItem.thumbnailUrl}
            quantity={returnItem.quantityUnprocessed}
          />

          <ReturnReasonStack returnItem={returnItem} />
        </Stack>
      ))}
    </Stack>
  );
};

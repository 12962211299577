import { ResolutionDossierStatus } from '../../../domain/automator/resolutionDossiers/ResolutionDossierStatus';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';

export const ResolutionDossiersQueryKey = (status?: ResolutionDossierStatus, date?: string) => {
  if (status === undefined && date === undefined) {
    return [EndpointQueryKey.RESOLUTION_DOSSIERS];
  }

  return [EndpointQueryKey.RESOLUTION_DOSSIERS, { status, date }];
};

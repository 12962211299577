import { ProductsListTable } from './components/ProductsListTable';
import { tokens } from '../../../../locales/translationTokens';
import AccountPage from '../../../../components/AccountPage';

const ProductsPage = () => {
  return (
    <AccountPage title={tokens.pd.products.products}>
      <ProductsListTable />
    </AccountPage>
  );
};

export default ProductsPage;

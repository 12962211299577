import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { RecipientType } from '../../../../domain/automator/messages/RecipientType';
import { ToggleButtonGroup } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';

interface RecipientTypeToggleButtonGroupProps {
  onSelect: (selectedKey: RecipientType) => void;
  value: RecipientType;
  selectable?: RecipientType[];
}

export const RecipientTypeToggleButtonGroup = ({
  onSelect,
  value,
  selectable,
}: RecipientTypeToggleButtonGroupProps) => {
  const { t } = useTranslation();

  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      onChange={(_, value) => {
        if (!value) return;
        onSelect(value as RecipientType);
      }}
      aria-label="Platform"
    >
      {!selectable || selectable.includes(RecipientType.CUSTOMER) ? (
        <ToggleButton value={RecipientType.CUSTOMER}>
          {t(tokens.automator.resolutions.recipient_type.customer)}
        </ToggleButton>
      ) : null}

      {!selectable || selectable.includes(RecipientType.BOL) ? (
        <ToggleButton value={RecipientType.BOL}>
          {t(tokens.automator.resolutions.recipient_type.bol)}
        </ToggleButton>
      ) : null}
      {!selectable || selectable.includes(RecipientType.TRANSPORTER) ? (
        <ToggleButton value={RecipientType.TRANSPORTER}>
          {t(tokens.automator.resolutions.recipient_type.transporter)}
        </ToggleButton>
      ) : null}
    </ToggleButtonGroup>
  );
};

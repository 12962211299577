import { LABEL_TYPE_RULES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { LabelType } from '../../../domain/automator/products/LabelType';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { useUpdateData } from '../../useUpdateData';
import AutomatorProducts from '../../../domain/automator/products/AutomatorProducts';
import { useAutomatorProductQueryKeysUpdater } from '../products/updaters/useAutomatorProductQueryKeysUpdater';

export const useUpdateLabelTypeRules = () => {
  const url = useAutomatorApiAccountUrl(`${LABEL_TYPE_RULES_PATH}/update`);

  const updater = useAutomatorProductQueryKeysUpdater();
  const updateData = useUpdateData(updater, 'products');

  const onSuccess = (products: AutomatorProducts) => {
    updateData(products);
  };

  return useAuthorizedPost<AutomatorProducts, UpdateLabelTypeRulesData>(
    url,
    [],
    undefined,
    onSuccess
  );
};

export interface UpdateLabelTypeRulesData {
  label_type_rule_ids: number[];
  max_items_for_mailbox: number | null;
  label_type: LabelType;
}

import { RETAILER_SETTINGS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';
import { useFetchData } from '../../useFetchData';
import RetailerSettings from '../../../domain/automator/retailers/RetailerSettings';

export const useFetchRetailerSettings = () => {
  const url = useAutomatorApiAccountUrl(RETAILER_SETTINGS_PATH);
  return useFetchData<RetailerSettings>(url, EndpointQueryKey.RETAILER_SETTINGS);
};

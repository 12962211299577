import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { useTranslation } from 'react-i18next';
import { TableEmpty } from '../../../../components/TableEmpty';
import groupBy from '../../../../helpers/groupBy';
import { ReturnItemReturnScanTableRow } from './ReturnItemReturnScanTableRow';
import ReturnScans from '../../../../domain/automator/returns/ReturnScans';
import { ReturnScanType } from '../../../../domain/automator/returns/ReturnScanType';
import { DateTableListDivider } from '../../returns/components/DateTableListDivider';

interface ReturnScanListTableProps {
  returnScans?: ReturnScans;
  isLoading: boolean;
}

export const ReturnScanListTable = ({ returnScans, isLoading }: ReturnScanListTableProps) => {
  const { t } = useTranslation();

  if (isLoading || !returnScans) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (returnScans!.returnScans.length == 0) {
    return <TableEmpty message={t(tokens.automator.returns.no_items)} />;
  }

  const groupedReturnScans = groupBy(returnScans.returnScans, (returnScan) =>
    new Date(returnScan.scannedDateTime).toDateString()
  );

  return (
    <Scrollbar>
      <Table size="small">
        {Object.entries(groupedReturnScans)
          .sort(([dateA], [dateB]) => new Date(dateB).getTime() - new Date(dateA).getTime())
          .map(([date, returnScans]) => (
            <TableBody key={date}>
              <DateTableListDivider date={date} />
              {returnScans
                .sort(
                  (a, b) =>
                    new Date(a.scannedDateTime).getTime() - new Date(b.scannedDateTime).getTime()
                )
                .map((returnScan) => {
                  switch (returnScan.type) {
                    case ReturnScanType.RETURN_ITEM:
                      return (
                        <ReturnItemReturnScanTableRow
                          key={returnScan.id}
                          returnScan={returnScan}
                        />
                      );
                  }
                })}
            </TableBody>
          ))}
      </Table>
    </Scrollbar>
  );
};

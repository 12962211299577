import { PICK_SESSION_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchDataPolled } from '../../useFetchDataPolled';
import PickSessions from '../../../domain/automator/pickSessions/PickSessions';
import { OrdersQueryKey } from '../../queryKeys/OrdersQueryKey';
import { OrderStatus } from '../../../domain/automator/orders/OrderStatus';
import { DistributionParty } from '../../../domain/automator/orders/DistributionParty';
import { useQueryClient } from 'react-query';
import { PickSessionsQueryKey } from '../../queryKeys/PickSessionsQueryKey';

export const useFetchPickSessions = (date?: string) => {
  const queryClient = useQueryClient();

  const url = useAutomatorApiAccountUrl(PICK_SESSION_PATH + (date ? `?date=${date}` : ''));
  return useFetchDataPolled<PickSessions>(url, PickSessionsQueryKey(date), {
    initialPollingInterval: 3500,
    stopCondition: (data: PickSessions | undefined) => {
      if (!data) {
        return false;
      }
      return !data.shouldPoll;
    },
    startCondition: (data: PickSessions | undefined) => {
      if (!data) {
        return false;
      }
      return data.shouldPoll;
    },
    onStopPolling: () => {
      const queryKeys = [
        OrdersQueryKey(OrderStatus.OPEN, DistributionParty.BOL),
        OrdersQueryKey(OrderStatus.OPEN_LATER, DistributionParty.BOL),
        OrdersQueryKey(OrderStatus.SHIPPED, DistributionParty.BOL),
        OrdersQueryKey(OrderStatus.PENDING_PICK_SESSION, DistributionParty.BOL),
        OrdersQueryKey(OrderStatus.FAILED, DistributionParty.BOL),
        OrdersQueryKey(OrderStatus.OPEN, DistributionParty.RETAILER),
        OrdersQueryKey(OrderStatus.OPEN_LATER, DistributionParty.RETAILER),
        OrdersQueryKey(OrderStatus.SHIPPED, DistributionParty.RETAILER),
        OrdersQueryKey(OrderStatus.PENDING_PICK_SESSION, DistributionParty.RETAILER),
        OrdersQueryKey(OrderStatus.FAILED, DistributionParty.RETAILER),
      ];

      queryKeys.forEach(async (queryKey) => {
        await queryClient.invalidateQueries(queryKey).then((_) => {});
      });
    },
    incrementFactor: 1.1,
  });
};

import { ORDERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { OrderEventsQueryKey } from '../../queryKeys/OrderEventsQueryKey';
import OrderEvent from '../../../domain/automator/orders/OrderEvent';

export const useFetchOrderEvents = (orderId: number) => {
  const url = useAutomatorApiAccountUrl(ORDERS_PATH + '/' + orderId + '/events');
  return useFetchData<OrderEvent[]>(url, OrderEventsQueryKey(orderId));
};

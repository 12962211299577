import { useAuthorizedPost } from '../../useAuthorizedPost';
import { OFFER_SHIPPING_COSTS_PATH } from '../pdApiPaths';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';

export const useUpdateOfferShippingCosts = (offerShippingCostsId: number) => {
  const url = usePdApiAccountUrl(OFFER_SHIPPING_COSTS_PATH + '/' + offerShippingCostsId);
  return useAuthorizedPost<undefined, UpdateOfferShippingCostsData>(url, undefined, undefined);
};

export interface UpdateOfferShippingCostsData {
  date: string;
  shipping_costs_in_cents: number;
}

import { PICK_SESSION_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import ReducedPickSession from '../../../domain/automator/pickSessions/ReducedPickSession';
import { ReducedPickSessionQueryKey } from '../../queryKeys/ReducedPickSessionQueryKey';

export const useFetchReducedPickSession = (pickSessionId?: number) => {
  const url = useAutomatorApiAccountUrl(PICK_SESSION_PATH + '/' + pickSessionId + '/reduced');
  return useFetchData<ReducedPickSession>(url, ReducedPickSessionQueryKey(pickSessionId), {
    enabled: pickSessionId !== undefined,
  });
};

import Box from '@mui/material/Box';
import { LatestDays } from '../LatestDaysDropdownSelect';
import getLatestDateByLatestDays from '../../../../../../helpers/getLatestDateByLatestDays';
import { DeviationBarChart } from './DeviationBarChart';
import { tokens } from '../../../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { useFetchAssortmentVisits } from '../../../../../../api/pd/productPerformances/useFetchAssortmentVisits';
import getYesterday from '../../../../../../helpers/getYesterday';

interface AssortmentVisitsDeviationChartProps {
  storeId: number;
  latestDays: LatestDays;
}

export const AssortmentVisitsDeviationChart = ({
  storeId,
  latestDays,
}: AssortmentVisitsDeviationChartProps) => {
  const { data: assortmentVisits, isLoading } = useFetchAssortmentVisits(
    storeId,
    getLatestDateByLatestDays(latestDays),
    getYesterday()
  );

  const { t } = useTranslation();

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  const assortmentVisitsSorted = assortmentVisits!.assortmentVisits.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  const chartSeries = [
    {
      name: t(tokens.pd.performance.assortment_visits_chart.visits),
      data: assortmentVisitsSorted.map((assortmentVisit) => assortmentVisit.deviation),
    },
  ];

  return (
    <DeviationBarChart
      header={t(tokens.pd.performance.search_term_volume_chart.title)}
      chartSeries={chartSeries}
      categories={assortmentVisitsSorted.map((assortmentVisit) => assortmentVisit.date)}
    />
  );
};

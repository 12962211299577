import Stack from '@mui/material/Stack';
import { ResolutionCaseStatus } from '../../../../domain/automator/resolutionDossiers/ResolutionCaseStatus';
import { HandleResolutionCaseButton } from './HandleResolutionCaseButton';
import { AwaitingResponseResolutionCaseButton } from './AwaitingResponseResolutionCaseButton';
import ReducedResolutionCase from '../../../../domain/automator/resolutionDossiers/ReducedResolutionCase';

interface GeneralResolutionCaseActionsProps {
  resolutionCase: ReducedResolutionCase;
}

export const GeneralResolutionCaseActions = ({
  resolutionCase,
}: GeneralResolutionCaseActionsProps) => {
  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
    >
      {resolutionCase.status != ResolutionCaseStatus.HANDLED &&
        resolutionCase.status != ResolutionCaseStatus.AWAITING_RESPONSE && (
          <AwaitingResponseResolutionCaseButton resolutionCaseId={resolutionCase.id} />
        )}

      {resolutionCase.status != ResolutionCaseStatus.HANDLED && (
        <HandleResolutionCaseButton resolutionCase={resolutionCase} />
      )}
    </Stack>
  );
};

import ReducedReturn from '../returns/ReducedReturn';
import Return from '../returns/Return';

const transformReducedReturnToReturn = (reducedReturn: ReducedReturn): Return => {
  return {
    id: reducedReturn.id,
    retailerId: reducedReturn.retailerId,
    shipmentDetails: reducedReturn.shipmentDetails,
    registrationDateTime: reducedReturn.registrationDateTime,
    daysSinceRegistration: reducedReturn.daysSinceRegistration,
    returnItems: [],
    orderTransporterInfos: [],
  };
};

export default transformReducedReturnToReturn;

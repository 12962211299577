import { useDispatch } from 'react-redux';
import { closeDialog } from './dialogsSlice';
import { DialogId } from './DialogId';

export const useCloseDialog = (dialogId: DialogId) => {
  const dispatch = useDispatch();

  return () => {
    dispatch(closeDialog(dialogId));
  };
};

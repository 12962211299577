import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RecipientType } from '../../domain/automator/messages/RecipientType';
import FileUpload from '../../pages/automator/shared/FileUpload';

export interface EmailMessageDraft {
  aggregateId: number | null;
  messageTemplateId: number | null;
  recipientType: RecipientType;
  subject: string;
  body: string;
  startWithCase: boolean;
  files: FileUpload[];
}

export interface EmailMessageDraftState {
  emailMessageDrafts: EmailMessageDraft[];
}

const initialState: EmailMessageDraftState = {
  emailMessageDrafts: [],
};

export const emailMessageDraftSlice = createSlice({
  name: 'email_message_drafts',
  initialState,
  reducers: {
    updateEmailMessageDraft: (state, action: PayloadAction<EmailMessageDraft>) => {
      const updatedDraft = action.payload;

      const draftIndex = state.emailMessageDrafts.findIndex(
        (draft) =>
          draft.aggregateId === updatedDraft.aggregateId &&
          draft.recipientType === updatedDraft.recipientType
      );

      if (draftIndex !== -1) {
        state.emailMessageDrafts[draftIndex] = updatedDraft;
      } else {
        state.emailMessageDrafts.push(updatedDraft);
      }
    },
  },
});

export const { updateEmailMessageDraft } = emailMessageDraftSlice.actions;

import { ToggleButtonGroup } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';

interface VatPercentageToggleButtonsProps {
  onSelect: (vatPercentage: number) => void;
  value: number;
}

export const VatPercentageToggleButtons = ({
  onSelect,
  value,
}: VatPercentageToggleButtonsProps) => {
  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      onChange={(_, value) => onSelect(value as number)}
      aria-label="Platform"
    >
      <ToggleButton value={0}>0%</ToggleButton>

      <ToggleButton value={9}>9%</ToggleButton>

      <ToggleButton value={21}>21%</ToggleButton>
    </ToggleButtonGroup>
  );
};

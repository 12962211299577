import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { UpdateOffersForm } from '../components/UpdateOffersForm';

export const UpdateOffersDialog = () => {
  const id = DialogId.UPDATE_OFFERS;

  const { isOpen, data } = useIsDialogOpen(id);

  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          maxWidth={'lg'}
          title={t(tokens.automator.orders.dialogs.update_offer.update_offers)}
        >
          <UpdateOffersForm productId={data.productId} />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface UpdateOfferDialogData {
  productId: number;
}

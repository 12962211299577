import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import { tokens } from '../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import AccountSettings from '../../../domain/automator/account/AccountSettings';
import { useUpdateAccountSettings } from '../../../api/automator/account/useUpdateAccountSettings';
import { LanguageRadioGroup } from './LanguageRadioGroup';
import { Tooltip } from '@mui/material';
import { useQueryClient } from 'react-query';
import ActionButton from '../../../components/ActionButton';
import { OrdersQueryKey } from '../../../api/queryKeys/OrdersQueryKey';
import { OrderStatus } from '../../../domain/automator/orders/OrderStatus';
import { DistributionParty } from '../../../domain/automator/orders/DistributionParty';

interface SettingsFormProps {
  settings: AccountSettings;
  onClick?: () => void;
}

export const SettingsForm = ({ settings, onClick }: SettingsFormProps) => {
  const [automaticallyApproveCancellationRequests, setAutomaticallyApproveCancellationRequests] =
    useState(settings.automaticallyApproveCancellationRequests);

  const [ignoreLatestHandoverDate, setIgnoreLatestHandoverDate] = useState(
    settings.ignoreLatestHandoverDate
  );

  const [forwardAutoMailReplies, setForwardAutoMailReplies] = useState(
    settings.forwardAutoMailReplies
  );

  const [forwardCustomerQuestionReplies, setForwardCustomerQuestionReplies] = useState(
    settings.forwardCustomerQuestionReplies
  );

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const { mutate: updateSettings, isLoading } = useUpdateAccountSettings(settings.id);

  const doUpdate = () => {
    const data = {
      automatically_approve_cancellation_requests: automaticallyApproveCancellationRequests,
      ignore_latest_handover_date: ignoreLatestHandoverDate,
      forward_auto_mail_replies: forwardAutoMailReplies,
      forward_customer_question_replies: forwardCustomerQuestionReplies,
      automatically_send_customer_invoices: settings.automaticallySendCustomerInvoices,
      default_vat_percentage: settings.defaultVatPercentage,
      customer_invoice_information: settings.customerInvoiceInformation,
      initial_customer_invoice_numbering_method: settings.initialCustomerInvoiceNumberingMethod,
      customer_invoice_import_start_date: settings.customerInvoiceImportStartDate,
      customer_invoice_auto_icp_method: settings.customerInvoiceAutoICPMethod,
    };

    updateSettings(data, {
      onSuccess: async () => {
        const queryKeys = [
          OrdersQueryKey(OrderStatus.OPEN, DistributionParty.BOL),
          OrdersQueryKey(OrderStatus.OPEN_LATER, DistributionParty.BOL),
          OrdersQueryKey(OrderStatus.OPEN, DistributionParty.RETAILER),
          OrdersQueryKey(OrderStatus.OPEN_LATER, DistributionParty.RETAILER),
        ];

        queryKeys.forEach((queryKey) => {
          queryClient.invalidateQueries(queryKey);
        });

        toast.success(t(tokens.automator.settings.settings_saved));
        onClick?.();
      },
    });
  };

  return (
    <Box>
      <Stack
        sx={{ p: 3 }}
        spacing={3}
      >
        <Stack spacing={1}>
          <LanguageRadioGroup />
          <Box>
            <Tooltip
              enterDelay={500}
              enterNextDelay={500}
              title={t(tokens.automator.settings.automatically_approve_cancel_requests_tooltip)}
              placement={'right'}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={automaticallyApproveCancellationRequests}
                    onChange={(_, checked) => setAutomaticallyApproveCancellationRequests(checked)}
                  />
                }
                label={t(tokens.automator.settings.automatically_approve_cancel_requests)}
              />
            </Tooltip>
          </Box>

          <Box>
            <Tooltip
              enterDelay={500}
              enterNextDelay={500}
              title={t(tokens.automator.settings.ignore_latest_handover_date_tooltip)}
              placement={'right'}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={ignoreLatestHandoverDate}
                    onChange={(_, checked) => setIgnoreLatestHandoverDate(checked)}
                  />
                }
                label={t(tokens.automator.settings.ignore_latest_handover_date)}
              />
            </Tooltip>
          </Box>

          <Box>
            <Tooltip
              enterDelay={500}
              enterNextDelay={500}
              title={t(tokens.automator.settings.forward_auto_mail_replies_tooltip)}
              placement={'right'}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={forwardAutoMailReplies}
                    onChange={(_, checked) => setForwardAutoMailReplies(checked)}
                  />
                }
                label={t(tokens.automator.settings.forward_auto_mail_replies)}
              />
            </Tooltip>
          </Box>

          <Box>
            <Tooltip
              enterDelay={500}
              enterNextDelay={500}
              title={t(tokens.automator.settings.forward_customer_question_replies)}
              placement={'right'}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={forwardCustomerQuestionReplies}
                    onChange={(_, checked) => setForwardCustomerQuestionReplies(checked)}
                  />
                }
                label={t(tokens.automator.settings.forward_customer_question_replies)}
              />
            </Tooltip>
          </Box>
        </Stack>
      </Stack>

      <Box
        sx={{
          pb: 3,
          px: 3,
        }}
      >
        <ActionButton
          variant="contained"
          onClick={() => doUpdate()}
          label={t(tokens.automator.settings.save_settings)}
          isLoading={isLoading}
        ></ActionButton>
      </Box>
    </Box>
  );
};

import { useTranslation } from 'react-i18next';
import { useDownloadReturnReasonReportCsv } from '../../../../api/automator/reports/useDownloadReturnReasonsReportCsv';
import ActionButton from '../../../../components/ActionButton';

interface DownloadReturnReasonReportCsvButtonProps {
  startDate: string;
  endDate: string;
  label: string;
}

export const DownloadReturnReasonReportCsvButton = ({
  startDate,
  endDate,
  label,
}: DownloadReturnReasonReportCsvButtonProps) => {
  const { t } = useTranslation();

  const { mutate: downloadCsv, isLoading } = useDownloadReturnReasonReportCsv();

  return (
    <ActionButton
      label={t(label)}
      onClick={() => downloadCsv({ start_date: startDate, end_date: endDate })}
      isLoading={isLoading}
    />
  );
};

import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { useTranslation } from 'react-i18next';
import { ResolutionDossierTableRow } from './ResolutionDossierTableRow';
import { ResolutionDossierStatus } from '../../../../domain/automator/resolutionDossiers/ResolutionDossierStatus';
import { useFetchResolutionDossiers } from '../../../../api/automator/resolutions/useFetchResolutionDossiers';
import ResolutionDossier from '../../../../domain/automator/resolutionDossiers/ResolutionDossier';
import { useRef } from 'react';

interface ResolutionDossierListTableRowsProps {
  date: string;
  selectedStatus: ResolutionDossierStatus;
}

export const ResolutionDossierListTableRows = ({
  date,
  selectedStatus,
}: ResolutionDossierListTableRowsProps) => {
  const dossierRefs = useRef<Map<number, HTMLTableRowElement | null>>(new Map());

  //useScrollIntoView(selectedDossierId, dossierRefs.current, [selectedDossierId]);

  const { data: resolutionDossiers, isLoading } = useFetchResolutionDossiers(selectedStatus, date);

  const { t } = useTranslation();

  if (isLoading || !resolutionDossiers) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (resolutionDossiers.resolutionDossiers.length === 0) {
    return <></>;
  }

  const sortedResolutionDossiers = resolutionDossiers.resolutionDossiers.sort((a, b) => {
    const getLatestDate = (dossier: ResolutionDossier): Date => {
      if (dossier.status == ResolutionDossierStatus.HANDLED && dossier.updatedDateTime) {
        return new Date(dossier.updatedDateTime);
      }

      return dossier.resolutionCases.reduce((latestDate, resolutionCase) => {
        const caseLatestReplyDate = resolutionCase?.latestReplyDate
          ? new Date(resolutionCase.latestReplyDate)
          : new Date(0); // Default low-earliest date

        const caseCreatedDate = resolutionCase?.createdDate
          ? new Date(resolutionCase.createdDate)
          : new Date(0); // Default low-earliest date

        const caseMaxDate =
          caseLatestReplyDate > caseCreatedDate ? caseLatestReplyDate : caseCreatedDate;

        return caseMaxDate > latestDate ? caseMaxDate : latestDate;
      }, new Date(0)); // Default low-earliest date in reduce
    };

    return getLatestDate(b).getTime() - getLatestDate(a).getTime();
  });

  //
  // const isAwaitingResponseOnReply = (resolutionDossier: ResolutionDossier) => {
  //   return resolutionDossier.resolutionCases.some((resolutionCase) => {
  //     return resolutionCase.isAWaitingResponseOnReply;
  //   });
  // };

  return (
    <>
      {sortedResolutionDossiers.map((resolutionDossier, index) => {
        return (
          <ResolutionDossierTableRow
            key={resolutionDossier.id}
            ref={(el) => dossierRefs.current.set(resolutionDossier.id, el)}
            isFirst={index === 0}
            isLast={index === sortedResolutionDossiers.length - 1}
            resolutionDossier={resolutionDossier}
            selectedStatus={selectedStatus}
          />
        );
      })}
    </>
  );
};

import { useTranslation } from 'react-i18next';
import { TableTabs, TabOption } from '../../../../components/TableTabs';
import { tokens } from '../../../../locales/translationTokens';
import Stack from '@mui/material/Stack';
import { ResolutionDossierStatus } from '../../../../domain/automator/resolutionDossiers/ResolutionDossierStatus';
import ResolutionDossiersReport from '../../../../domain/automator/resolutionDossiers/ResolutionDossiersReport';
import { useNavigate } from 'react-router-dom';
import { usePaths } from '../../../../routes/usePaths';

interface ResolutionDossierTabsProps {
  report: ResolutionDossiersReport | null;
  currentTab: ResolutionDossierStatus;
}

const ResolutionDossierTabs = ({ currentTab, report }: ResolutionDossierTabsProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const path = usePaths();

  const tabOptions: TabOption<ResolutionDossierStatus>[] = [];
  tabOptions.push(
    ...[
      {
        label: t(tokens.automator.resolutions.resolution_dossier_status.open),
        value: ResolutionDossierStatus.OPEN,
        amount: report?.amountOfOpenDossiers,
      },
      {
        label: t(tokens.automator.resolutions.resolution_dossier_status.awaiting_response),
        value: ResolutionDossierStatus.AWAITING_RESPONSE,
        amount: report?.amountOfAwaitingResponseDossiers,
      },
      {
        label: t(tokens.automator.resolutions.resolution_dossier_status.handled),
        value: ResolutionDossierStatus.HANDLED,
        amount: report?.amountOfHandledDossiers,
      },
    ]
  );

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
    >
      <TableTabs
        value={currentTab}
        onChange={(tab: ResolutionDossierStatus) => {
          navigate(`${path.automator.resolution_dossiers}?status=${tab}`);
        }}
        tabOptions={tabOptions}
      />
    </Stack>
  );
};

export default ResolutionDossierTabs;

import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { ReturnRecoverability } from '../../../../domain/automator/returns/ReturnRecoverability';
import { colors } from '@mui/material';

interface ReturnRecoverabilityChipProps {
  returnRecoverAbility?: ReturnRecoverability;
  onClick?: (returnRecoverability: ReturnRecoverability) => void;
}

const ReturnRecoverabilityChip = ({
  returnRecoverAbility,
  onClick,
}: ReturnRecoverabilityChipProps) => {
  const { t } = useTranslation();

  const determineLabel = (returnRecoverAbility?: ReturnRecoverability) => {
    switch (returnRecoverAbility) {
      case ReturnRecoverability.RECOVERABLE:
        return t(tokens.automator.returns.recoverability.recoverable);
      case ReturnRecoverability.NOT_RECOVERABLE:
        return t(tokens.automator.returns.recoverability.not_recoverable);
      case ReturnRecoverability.UNCLEAR:
        return t(tokens.automator.returns.recoverability.unclear);
      default:
        return t(tokens.automator.returns.recoverability.unclear);
    }
  };

  const determineColor = (returnRecoverAbility?: ReturnRecoverability) => {
    switch (returnRecoverAbility) {
      case ReturnRecoverability.RECOVERABLE:
        return colors.green['A400'];
      case ReturnRecoverability.NOT_RECOVERABLE:
        return colors.red['300'];
      case ReturnRecoverability.UNCLEAR:
        return colors.grey['500'];
      default:
        return 'default';
    }
  };

  return (
    <Chip
      style={{
        width: 90,
        borderRadius: 6,
        color: 'WHITE',
        backgroundColor: determineColor(returnRecoverAbility),
      }}
      size="small"
      label={determineLabel(returnRecoverAbility)}
      variant="filled"
      onClick={() => onClick?.(returnRecoverAbility!)}
    />
  );
};

export default ReturnRecoverabilityChip;

import { useFetchData } from '../../useFetchData';
import { usePdApiAccountUrl } from '../usePdApiAccountUrl';
import { SHARE_OF_VOICE_TOTALS } from '../pdApiPaths';
import { getDateStringFromDate } from '../../../helpers/getDateStringFromDate';
import ShareOfVoiceTotals from '../../../domain/pd/ShareOfVoiceTotals';
import { ShareOfVoiceTotalsQueryKey } from '../../queryKeys/ShareOfVoiceTotalsQueryKey';

export const useFetchShareOfVoiceTotals = (campaignId: number, startDate: Date, endDate: Date) => {
  const url = usePdApiAccountUrl(
    SHARE_OF_VOICE_TOTALS +
      `?campaignId=${campaignId}&startDate=${getDateStringFromDate(
        startDate
      )}&endDate=${getDateStringFromDate(endDate)}`
  );
  return useFetchData<ShareOfVoiceTotals>(
    url,
    ShareOfVoiceTotalsQueryKey(campaignId, startDate, endDate)
  );
};

import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import AutEmailEventRuleForm from '../components/AutoMailEventRuleForm';
import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';
import { CountryCode } from '../../../../domain/automator/orders/CountryCode';
import { RecipientType } from '../../../../domain/automator/messages/RecipientType';
import EventTrigger from '../../../../domain/automator/eventRules/EventTrigger';
import { toast } from 'react-hot-toast';
import { useUpdateEventRule } from '../../../../api/automator/eventRules/useUpdateEventRule';
import EventRule from '../../../../domain/automator/eventRules/EventRule';
import { useUpdateEventRuleAction } from '../../../../api/automator/eventRules/useUpdateEventRuleAction';

export const UpdateAutoEmailEventRuleDialog = () => {
  const id = DialogId.UPDATE_AUTO_MAIL_EVENT_RULE;

  const { isOpen, data } = useIsDialogOpen(id);

  const { mutate: updateEventRule, isLoading: isLoadingUpdateEventRule } = useUpdateEventRule(
    data?.eventRule.id || 0
  );
  const { mutate: updateEventRuleAction, isLoading: isLoadingUpdateEventRuleAction } =
    useUpdateEventRuleAction(data?.eventRule?.eventRuleActions[0].id || 0);

  const closeDialog = useCloseDialog(id);
  const { t } = useTranslation();

  const onUpdate = (
    productIds: number[],
    messageTemplateId: number,
    distributionParty: DistributionParty | null,
    countryCode: CountryCode | null,
    recipientType: RecipientType,
    trigger: EventTrigger,
    delayHours: number | null,
    executeAtTime: string | null,
    isStartWithCase: boolean
  ) => {
    updateEventRule(
      {
        product_ids: productIds,
        distribution_party: distributionParty,
        country_code: countryCode,
        trigger: trigger,
      },
      {
        onSuccess: async () => {
          toast.success(t(tokens.automator.auto_mail.form.event_rule_updated));
          closeDialog();
        },
      }
    );

    updateEventRuleAction(
      {
        email_template_id: messageTemplateId,
        recipient_type: recipientType,
        execute_at_time: executeAtTime,
        execute_in_hours: delayHours,
        is_start_with_resolution_case: isStartWithCase,
      },
      {
        onSuccess: async () => {
          toast.success(t(tokens.automator.auto_mail.form.event_rule_updated));
          closeDialog();
        },
      }
    );
  };

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          title={t(tokens.automator.auto_mail.form.update_event_rule)}
        >
          <AutEmailEventRuleForm
            eventRule={data.eventRule}
            onSave={onUpdate}
            isLoading={isLoadingUpdateEventRule || isLoadingUpdateEventRuleAction}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface UpdateEmailRuleDialogData {
  eventRule: EventRule;
}

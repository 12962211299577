import { EndpointQueryKey } from './EndpointQueryKey';
import { getDateStringFromDate } from '../../helpers/getDateStringFromDate';

export const AdGroupPerformancesQueryKey = (adGroupId: number, startDate: Date, endDate: Date) => {
  return (
    EndpointQueryKey.AD_GROUP_PERFORMANCES +
    '_' +
    adGroupId +
    '_' +
    getDateStringFromDate(startDate) +
    '_' +
    getDateStringFromDate(endDate)
  );
};

import { eachDayOfInterval, endOfToday, format, subDays } from 'date-fns';

export const getLastDays = (amountOfDaysBack: number) => {
  const start = subDays(endOfToday(), amountOfDaysBack - 1);
  const dateArray = eachDayOfInterval({
    start: start,
    end: endOfToday(),
  });

  return dateArray.map((date) => format(date, 'yyyy-MM-dd')).reverse();
};

import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { SetupVatPercentagesForm } from '../components/SetupVatPercentagesForm';

export const SelectDifferentVatPercentageProductsDialog = () => {
  const id = DialogId.SETUP_VAT_PERCENTAGE_PRODUCTS;

  const { isOpen } = useIsDialogOpen(id);

  const closeDialog = useCloseDialog(id);

  const { t } = useTranslation();

  return (
    <>
      {isOpen && (
        <ApplicationDialog
          dialogId={id}
          maxWidth={'md'}
          title={t(tokens.automator.customer_invoice_requests.update_vat_percentages)}
        >
          <SetupVatPercentagesForm onSave={closeDialog} />
        </ApplicationDialog>
      )}
    </>
  );
};

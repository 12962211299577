import TableCell, { TableCellProps } from '@mui/material/TableCell';

interface SlimTableCellProps extends TableCellProps {
  hasBorderBottom?: boolean;
  children: JSX.Element | JSX.Element[];
  style?: React.CSSProperties;
  isSticky?: boolean;
  hasBorderTop?: boolean;
  paddingVertical?: number;
}

export const SlimTableCell = ({
  hasBorderBottom = true,
  hasBorderTop = true,
  children,
  style,
  isSticky,
  paddingVertical = 4,
  ...props
}: SlimTableCellProps) => {
  // const { cx, classes } = useStyles();

  return (
    <TableCell
      {...props}
      style={{
        position: isSticky ? 'sticky' : 'relative',
        left: isSticky ? 0 : undefined,
        zIndex: isSticky ? 2 : 1,
        backgroundColor: 'white',
        overflow: 'hidden',
        borderBottom: hasBorderBottom ? undefined : 'none',
        borderTop: hasBorderTop ? undefined : 'none',
        paddingTop: paddingVertical,
        paddingBottom: paddingVertical,
        ...style,
      }}
    >
      {children}
    </TableCell>
  );
};

import OrdersReport from './OrdersReport';
import Order from './Order';
import OrderDayReport from './OrderDayReport';

export default interface Orders {
  [AGGREGATE_KEY]: Order[];
  report: OrdersReport;
  dayReports: OrderDayReport[];
}

export const AGGREGATE_KEY = 'orders';

import { ORDERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { PinnedOrdersQueryKey } from '../../queryKeys/PinnedOrdersQueryKey';
import { OrderQueryKey } from '../../queryKeys/OrderQueryKey';
import Order from '../../../domain/automator/orders/Order';

export const useUnpinOrder = (orderId: number) => {
  const url = useAutomatorApiAccountUrl(ORDERS_PATH + '/' + orderId + '/unpin');

  return useAuthorizedPost<Order, undefined>(
    url,
    [PinnedOrdersQueryKey(), OrderQueryKey(orderId)],
    undefined
  );
};

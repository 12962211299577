import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import CheckoutSession from '../../../domain/automator/account/CheckoutSession';

export const useCreateCheckout = () => {
  const url = useAutomatorApiAccountUrl('subscribe');
  const { mutate } = useAuthorizedPost<CheckoutSession>(url, [[EndpointQueryKey.ACCOUNT]]);

  return async () => {
    return new Promise(() => {
      mutate(undefined, {
        onSuccess: (checkoutSession) => {
          window.location.href = checkoutSession.paymentLink;
        },
      });
    });
  };
};

import { EndpointQueryKey } from './EndpointQueryKey';

export enum ShipmentsRequestType {
  ALL = 'ALL',
  RETURNED_TO_SENDER = 'RETURNED_TO_SENDER',
  AT_PICK_UP_POINT = 'AT_PICK_UP_POINT',
  OVERDUE = 'OVERDUE',
}

export const ShipmentsQueryKey = (type: ShipmentsRequestType, date: string | null) => {
  return EndpointQueryKey.SHIPMENTS + '_' + type + '_' + date;
};

import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { SenderType } from '../../../../domain/automator/messages/SenderType';

interface SenderTypeChipProps {
  senderType: SenderType;
}

const SenderTypeChip = ({ senderType }: SenderTypeChipProps) => {
  const { t } = useTranslation();

  const determineLabel = (senderType: SenderType): string => {
    switch (senderType) {
      case SenderType.TRANSPORTER:
        return 'V';
      case SenderType.BOL:
        return 'P';
      case SenderType.CUSTOMER:
        return 'K';
      case SenderType.SELLER:
        return 'S';
      default:
        return '';
    }
  };

  const determineColor = (senderType: SenderType) => {
    switch (senderType) {
      case SenderType.SELLER:
        return 'success';
      case SenderType.CUSTOMER:
        return 'primary';
      case SenderType.BOL:
        return 'secondary';
      case SenderType.TRANSPORTER:
        return 'info';
      default:
        return 'default';
    }
  };

  return (
    <Chip
      style={{ borderRadius: 20 }}
      size="small"
      label={t(determineLabel(senderType))}
      variant="filled"
      color={determineColor(senderType)}
    />
  );
};

export default SenderTypeChip;

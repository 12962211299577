import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { useFetchAccount } from '../../../api/automator/account/useFetchAccount';
import { SettingsForm } from './SettingsForm';

const SettingsPage = () => {
  const { data: account } = useFetchAccount();

  if (!account) {
    return;
  }

  return (
    <AccountPage title={tokens.automator.settings.settings}>
      <SettingsForm settings={account.settings} />
    </AccountPage>
  );
};

export default SettingsPage;

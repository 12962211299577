export default interface ShareOfVoice {
  [ShareOfVoiceProperty.IMPRESSION_SHARE_PERCENTAGE]?: number;
  [ShareOfVoiceProperty.CLICK_SHARE_PERCENTAGE]?: number;
  date: string;
}

export enum ShareOfVoiceProperty {
  IMPRESSION_SHARE_PERCENTAGE = 'impressionsSharePercentage14d',
  CLICK_SHARE_PERCENTAGE = 'clickSharePercentage14d',
}

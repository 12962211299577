import TextField from '@mui/material/TextField';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import {
  TransporterCode,
  TransporterCodeTokenMap,
} from '../../../../domain/automator/shipments/TransporterCode';

interface TransporterCodeDropdownProps {
  selected: TransporterCode | null;
  onSelect: (transportCode: TransporterCode | null) => void;
}

export const TransporterCodeDropdown = ({ onSelect, selected }: TransporterCodeDropdownProps) => {
  const { t } = useTranslation();

  const availableTransporterCodes = [
    TransporterCode.TNT,
    TransporterCode.TNT_BRIEF,
    TransporterCode.DHLFORYOU,
    TransporterCode.DPD_NL,
    TransporterCode.BUDBEE,
    TransporterCode.DYL,
    TransporterCode.BPOST_BE,
    TransporterCode.GLS,
    TransporterCode.TSN,
    TransporterCode.TRANSMISSION,
    TransporterCode.PARCEL_NL,
    TransporterCode.PACKS,
    TransporterCode.COURIER,
    TransporterCode.TRUNKRS,
    TransporterCode.PES,
    TransporterCode.UPS,
    TransporterCode.OTHER,
  ];

  const transporterCodeOptions = Object.entries(TransporterCodeTokenMap)
    .map(([key, value]) => ({
      key,
      value,
    }))
    .filter((option) => availableTransporterCodes.includes(option.key as TransporterCode));

  return (
    <TextField
      label={t(tokens.automator.pick_sessions.transporter_codes.transporter_code)}
      select
      value={selected}
      SelectProps={{ native: true }}
      onChange={(e) => onSelect(e.target.value as TransporterCode | null)}
    >
      {transporterCodeOptions.map((option) => (
        <option
          key={option.key}
          value={option.key}
        >
          {t(option.value)}
        </option>
      ))}
    </TextField>
  );
};

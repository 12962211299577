import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useFetchReturnItem } from '../../../../api/automator/returns/useFetchReturnItem';
import { useQueryClient } from 'react-query';
import CircularProgress from '@mui/material/CircularProgress';
import ReturnItemButtons from '../../returns/components/ReturnItemButtons';
import { ReturnItemFilter } from '../../../../domain/automator/returns/ReturnItemFilter';
import { ResolutionDossiersQueryKey } from '../../../../api/automator/resolutions/ResolutionDossiersQueryKey';
import { ResolutionDossierStatus } from '../../../../domain/automator/resolutionDossiers/ResolutionDossierStatus';
import AcceptOrRejectReturnItemButtons from '../../returns/components/AcceptOrRejectReturnItemButtons';

interface ReturnItemResolutionCaseMenuProps {
  returnItemId: number;
}

export default function ReturnItemResolutionCaseMenu({
  returnItemId,
}: ReturnItemResolutionCaseMenuProps) {
  const { data: returnItem, isLoading } = useFetchReturnItem(returnItemId);

  const queryClient = useQueryClient();

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {!returnItem!.isHandled && (
        <ListItem>
          <ReturnItemButtons
            returnItem={returnItem!}
            currentTab={ReturnItemFilter.OVERVIEW}
            onSuccess={() => {
              queryClient
                .invalidateQueries(
                  ResolutionDossiersQueryKey(ResolutionDossierStatus.AWAITING_RESPONSE)
                )
                .then();
              queryClient
                .invalidateQueries(ResolutionDossiersQueryKey(ResolutionDossierStatus.OPEN))
                .then();

              queryClient
                .invalidateQueries(ResolutionDossiersQueryKey(ResolutionDossierStatus.HANDLED))
                .then();
            }}
          />
        </ListItem>
      )}

      {!returnItem!.isHandled && (
        <ListItem>
          <AcceptOrRejectReturnItemButtons returnItem={returnItem!} />
        </ListItem>
      )}
    </List>
  );
}

import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollPreserver = () => {
  const scrollPositionRef = useRef(0); // Use a ref to store the scroll position persistently
  const location = useLocation();

  // Save scroll position before navigating away
  useEffect(() => {
    const saveScrollPosition = () => {
      if (location.pathname.includes('resolution-dossiers')) {
        scrollPositionRef.current = window.scrollY; // Store the current scroll position in the ref
      }
    };

    window.addEventListener('beforeunload', saveScrollPosition);
    return () => {
      saveScrollPosition(); // Call the function to store scroll position before unmounting
      window.removeEventListener('beforeunload', saveScrollPosition);
    };
  }, [location]);

  // Restore scroll position when navigating to the route
  useEffect(() => {
    if (location.pathname.includes('resolution-dossiers')) {
      window.scrollTo(0, scrollPositionRef.current); // Restore the saved scroll position
    }
  }, [location]);

  return null;
};

export default ScrollPreserver;

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useFetchOfferPerformances } from '../../../../../api/pd/offerPerformances/useFetchOfferPerformances';
import getLatestDateByLatestDays from '../../../../../helpers/getLatestDateByLatestDays';
import getYesterday from '../../../../../helpers/getYesterday';
import { LatestDays } from './LatestDaysDropdownSelect';
import { CountryCode } from '../../../../../domain/automator/orders/CountryCode';
import Offer from '../../../../../domain/pd/Offer';
import { tokens } from '../../../../../locales/translationTokens';
import { OfferPerformanceTotal } from './tables/OfferPerformancesTotal';
import convertRatioToPercentage from '../../../helpers/convertRatioToPercentage';
import { convertCentsToEuros } from '../../../helpers/convertCentsToEuros';
import { useFetchProductPurchaseCosts } from '../../../../../api/pd/offerPerformances/useFetchProductPurchaseCosts';
import { useFetchAdPerformances } from '../../../../../api/pd/productPerformances/useFetchAdPerformances';

interface OfferPerformancesTotalsOverviewProps {
  offer: Offer;
  countryCode: CountryCode | null;
  latestDays: LatestDays;
  rollingAverageDays: number;
}

export const OfferPerformancesTotalsOverview = ({
  offer,
  countryCode,
  latestDays,
  rollingAverageDays,
}: OfferPerformancesTotalsOverviewProps) => {
  const { data: offerPerformances, isLoading: offerPerformancesLoading } =
    useFetchOfferPerformances(
      offer.id,
      getLatestDateByLatestDays(latestDays),
      getYesterday(),
      rollingAverageDays,
      countryCode
    );

  const { data: productPurchaseCosts, isLoading: isLoadingProductPurchaseCosts } =
    useFetchProductPurchaseCosts(offer.productId);

  const { data: adPerformances, isLoading: adPerformancesLoading } = useFetchAdPerformances(
    offer.id,
    getLatestDateByLatestDays(latestDays),
    getYesterday(),
    rollingAverageDays
  );

  if (
    !offerPerformances ||
    offerPerformancesLoading ||
    !productPurchaseCosts ||
    isLoadingProductPurchaseCosts ||
    !adPerformances ||
    adPerformancesLoading
  ) {
    return <Box>Loading...</Box>;
  }

  const totals = offerPerformances.totalsReport;

  const purchaseCostsInCents = productPurchaseCosts.productPurchaseCosts[0]
    ? productPurchaseCosts.productPurchaseCosts[0]?.purchaseCostsInCents
    : undefined;

  return (
    <Stack
      padding={2}
      direction="row"
      gap={1}
    >
      <Stack
        border={1}
        direction="row"
        padding={1}
      >
        <OfferPerformanceTotal
          label={tokens.pd.performance.offer_performances_totals.total_profit}
          value={
            convertCentsToEuros(totals.profitInCents) +
            ' // ' +
            convertRatioToPercentage(totals.profitInCents / totals.revenueInCents)
          }
        />

        <OfferPerformanceTotal
          label={tokens.pd.performance.offer_performances_totals.total_ad_costs}
          value={convertCentsToEuros(adPerformances.totalsReport.spentInCents)}
        />

        <OfferPerformanceTotal
          label={tokens.pd.performance.offer_performances_totals.total_visits}
          value={totals.visits}
        />

        <OfferPerformanceTotal
          label={tokens.pd.performance.offer_performances_totals.total_articles_sold}
          value={totals.articlesSold}
        />

        <OfferPerformanceTotal
          label={tokens.pd.performance.offer_performances_totals.conversion_rate}
          value={convertRatioToPercentage(totals.articlesSold / totals.visits)}
        />

        <OfferPerformanceTotal
          label={tokens.pd.performance.offer_performances_totals.total_revenue}
          value={convertCentsToEuros(totals.revenueInCents)}
        />

        <OfferPerformanceTotal
          label={tokens.pd.performance.offer_performances_totals.total_purchase_value}
          value={
            purchaseCostsInCents
              ? convertCentsToEuros(totals.articlesSold * purchaseCostsInCents)
              : '-'
          }
        />
      </Stack>
    </Stack>
  );
};
